import React, { Component } from "react";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { BrowserRouter, Link, useLocation } from "react-router-dom";
import { StaticRouter } from "react-router";
import { WhiteLabelProvider } from "react-whitelabel";
import { Button } from "react-bootstrap";

import "react-dates/initialize";
import "bootstrap/scss/bootstrap.scss";
import "react-dates/lib/css/_datepicker.css";

import Header from "./components/Header/Header.jsx";
import Footer from "./components/Footer/Footer.jsx";
import FooterUpdated from "./components/FooterUpdated/FooterUpdated.jsx";
import BookingContainer from "./components/BookingContainer/BookingContainer.jsx";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUsers as faUsersPro,
  faSuitcase as faSuitcasePro,
  faImages as faImagesLight,
} from "@fortawesome/pro-light-svg-icons";
import {
  faWatch,
  faStopwatch,
  faHourglassHalf,
  faBadge,
  faGlassCheers,
  faPhoneLaptop,
  faUserHeadset,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faUsers,
  faClock,
  faMoneyBillWave,
  faCheckSquare,
  faArrowCircleRight,
  faArrowCircleLeft,
  faStar,
  faSuitcase,
  faCar,
  faCalendar,
  faImages,
  faCaretRight,
  faCaretLeft,
  faBars,
  faPlaneArrival,
  faPhone,
  faComments,
  faCalendarDay,
  faShareSquare,
  faUserShield,
  faBuilding,
  faThumbsUp,
  faLock,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { faStripe } from "@fortawesome/free-brands-svg-icons";

import "./App.scss";

import brands from "./brands.json";
import { keys } from "./globals.js";
import { routePaths } from "./routes/routes";
import useMediaQuery from "./hooks/useMediaQuery.js";
import useLocalStorage from "./hooks/useLocalStorage.js";

library.add(
  faUsers,
  faHourglassHalf,
  faMoneyBillWave,
  faStopwatch,
  faWatch,
  faClock,
  faCheckSquare,
  faUsersPro,
  faArrowCircleRight,
  faArrowCircleLeft,
  faStar,
  faSuitcase,
  faCar,
  faCalendar,
  faSuitcasePro,
  faImagesLight,
  faImages,
  faCaretRight,
  faCaretLeft,
  faBars,
  faBadge,
  faGlassCheers,
  faPlaneArrival,
  faPhone,
  faComments,
  faCalendarDay,
  faShareSquare,
  faUserShield,
  faBuilding,
  faPhoneLaptop,
  faUserHeadset,
  faThumbsUp,
  faStripe,
  faLock,
  faWindowClose
);

const brand = "limos";

const enableGoogleLibrary = true;

const WrappedBookingContainer = () => {
  const [showCookieBar, setShowCookieBar] = useLocalStorage(
    "LIMOS-show-cookie-policy-bar"
  );
  const [track, allowTrack] = useLocalStorage("LIMOS-allow-tracking");

  const { show, update } = useIntercom();
  const location = useLocation();
  let disabledCookieforHome = true;

  if (
    location.pathname == "/" ||
    location.pathname == "/a/thank-you-quote-form" ||
    location.pathname == "/a/thank-you-request-form"
  ) {
    //setShowCookieBar(false);
    disabledCookieforHome = false;
  }

  const updateIntercom = (props) =>
    update({
      customAttributes: {
        ...props,
      },
    });

  const handleOpenIntercom = ({ searchUrl, tripDate, pax }) => {
    updateIntercom({
      "Search Url": searchUrl || null,
      "Trip Date": tripDate || null,
      Passengers: pax || null,
    });
    show();
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleCookieChoice = (choice) => {
    setShowCookieBar(false);
    allowTrack(choice);
  };

  return (
    <>
      {Boolean(track) && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1583197665813378');
            fbq('track', 'PageView');
        `,
          }}
        />
      )}
      <BookingContainer
        handleOpenIntercom={handleOpenIntercom}
        updateIntercom={updateIntercom}
      />
      {showCookieBar && disabledCookieforHome && (
        <div className="cookie-policy-bar">
          <p
            style={{
              margin: 0,
              color: "black",
              textAlign: isMobile ? "center" : "left",
            }}
          >
            {isMobile
              ? `We use cookies to enhance your experience here at Limos.com`
              : `Please note that on our website we use cookies necessary for the
          functioning of our website and for optimizing the performance and
          experience you recieve here at Limos.com. This includes sharing your
          information with third party clients like Google and Facebook.`}{" "}
            Read more here:{" "}
            <Link to={routePaths.privacyPolicy}>Privacy Policy</Link>.
          </p>
          <div className="choice-container">
            <Button
              size="sm"
              style={{ paddingBottom: 0, width: isMobile ? "100%" : "auto" }}
              variant="primary"
              onClick={(e) => handleCookieChoice(true)}
            >
              Accept
            </Button>
            <Button
              size="sm"
              style={{ paddingBottom: 0, width: isMobile ? "100%" : "auto" }}
              variant="secondary"
              onClick={(e) => handleCookieChoice(false)}
            >
              Decline
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const ProviderEnhancedBookingContainer = () => (
  <IntercomProvider appId="iclndeq7" autoBoot>
    <WrappedBookingContainer />
  </IntercomProvider>
);

const PageContainer = () => {
  const location = useLocation();
  if (
    location.pathname == "/" ||
    location.pathname == "/a/thank-you-quote-form" ||
    location.pathname == "/a/request-quote" ||
    location.pathname == "/a/thank-you-request-form" ||
    location.pathname == "/a/phone-quote"
  ) {
    return (
      <div className="new-home-page" style={{ background: "" }}>
        <Header />
        <ProviderEnhancedBookingContainer />
      </div>
    );
  } else {
    return (
      <div className="brand-body">
        <Header />
        <ProviderEnhancedBookingContainer />
        <FooterUpdated />
      </div>
    );
  }
};

export class App extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (!isIE11 && enableGoogleLibrary) {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = `https://maps.googleapis.com/maps/api/js?key=${keys.googleApi}&libraries=directions,places`;
      this.instance.appendChild(s);
    }
  }

  render() {
    const isIE11 =
      typeof window !== "undefined" &&
      !!window.MSInputMethodContext &&
      !!document.documentMode;
    if (isIE11) {
      return (
        <div className="unsupportedBrowser">
          <div>
            <img
              alt="Logo"
              src={brands[brand].logo}
              width="200px"
              height="27px"
            />
          </div>
          <div>
            Your browser is not supported. Please try another browser or mobile
            device.
          </div>
        </div>
      );
    } else {
      return (
        <div ref={(el) => (this.instance = el)}>
          {this.props.location ? (
            <StaticRouter location={this.props.location} context={{}}>
              <WhiteLabelProvider labels={brands} selectedLabel={brand}>
                <div className={brand}>
                  <PageContainer />
                </div>
              </WhiteLabelProvider>
            </StaticRouter>
          ) : (
            <BrowserRouter>
              <WhiteLabelProvider labels={brands} selectedLabel={brand}>
                <div className={brand}>
                  <PageContainer />
                </div>
              </WhiteLabelProvider>
            </BrowserRouter>
          )}
        </div>
      );
    }
  }
}

export default App;
