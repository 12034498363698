import React from "react"
import { BrowserRouter, Link, useLocation } from "react-router-dom"
import { routePaths } from "../../routes/routes"

import "./Page404.scss"

export default class Page404 extends React.Component {
  render() {
    return (
      <div style={{ margin: "124px auto", width: "100%", textAlign: "center" }}>
        <p>Page Not Found - 404</p>
        <div className="page-404-links-wrapper">
          <Link to={routePaths.root}>Navigate back to Home</Link>
          <Link to={routePaths.contact}>Contact Us</Link>
          <Link to={routePaths.siteMap}>Site Map</Link>
        </div>
      </div>
    )
  }
}
