import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withWhiteLabelContext } from "react-whitelabel";
import "./CallAndChat.scss";

class CallAndChat extends Component {
  render() {
    if (!this.props.openChatWindow) {
      throw new Error(
        "CallAndChat requires prop openChatWindow to be specified"
      );
    }
    const { telephone } = this.props.label;
    return (
      <div className="callAndChatContainer">
        {this.props.titleMessage ? (
          <Row>
            <Col
              className="center talkToSpecialist"
              xs={12}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
            >
              {this.props.titleMessage}
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Row>
          <Col
            xs={12}
            md={this.props.fullWidth ? {} : { span: 4}}
            lg={this.props.fullWidth ? {} : { span: 4 }}
          >
            <a href={`tel:${telephone}`} className="btn btn-block linkButton">
              {/* <FontAwesomeIcon icon="phone" />  */}
              Call us at &nbsp; <span className="txt-color">{telephone}</span>
            </a>
          </Col>
          <div className="break-on-small">
            <br />
            <br />
          </div>
          <Col
            xs={12}
            md={this.props.fullWidth ? {} : { span: 4 }}
            lg={this.props.fullWidth ? {} : { span: 4 }}
          >
            <a href={"mailto:support@limos.com"} className="btn btn-block linkButton">
              <FontAwesomeIcon icon="envelope"/> Email us &nbsp;<span className="txt-color"> support@limos.com</span>
            </a>
          </Col>

          <div className="break-on-small">
            <br />
            <br />
          </div>
          <Col
            xs={12}
            md={this.props.fullWidth ? {} : { span: 4 }}
            lg={this.props.fullWidth ? {} : { span: 4 }}
          >
            <Button
              block
              onClick={this.props.openChatWindow}
              className="linkButton chatButton"
            >
              {/* <FontAwesomeIcon icon="comments" /> */}
              <span className="txt-color">Live chat &nbsp;</span>with us now 
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withWhiteLabelContext(CallAndChat);
