import React, { useContext, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { ModalContext } from "../context/ModalContext";
import useLocalStorage from "./useLocalStorage";
import useRouter from "./useRouter";

const BEFORE_UNLOAD_MESSAGE =
  "Before you leave, we would like to offer you a chance to leave some feedback on why you are leaving for a promotional code! Hit cancel if you would like to stay, otherwise okay if you are not interested in the promotional code.";

// https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event

const shouldNotLeaveConditionalCheck = ({ action }) =>
  ["PUSH", "POP", "REPLACE"].includes(action);

const useFunnelExitModal = (condition = true) => {
  const router = useRouter();
  const { setModalShown } = useContext(ModalContext);
  const [funnelExitPromoUsed] = useLocalStorage("LIMOS-funnelExitPromoUsed");
  const [userId] = useLocalStorage("LIMOS-userId");

  useEffect(() => {
    if (!condition || funnelExitPromoUsed) return;

    const onBeforeUnloadCallBack = async (e) => {
      e.preventDefault();
      e.returnValue = "";
      setModalShown(true);
      return BEFORE_UNLOAD_MESSAGE;
    };

    const removeEventListener = () =>
      window?.removeEventListener("beforeunload", onBeforeUnloadCallBack);

    document.body.onclick = (e) => {
      if (e.target.tagName.toLowerCase() === "a") {
        removeEventListener();
      }
    };

    window?.addEventListener("beforeunload", onBeforeUnloadCallBack);
    return () => removeEventListener();
  }, [condition, setModalShown, funnelExitPromoUsed, router.pathname, userId]);

  return {
    OnBeforeUnloadPrompt: () => (
      <Prompt
        when={condition}
        message={(location, action) =>
          shouldNotLeaveConditionalCheck({ location, action })
            ? true
            : BEFORE_UNLOAD_MESSAGE
        }
      />
    ),
  };
};

export default useFunnelExitModal;
