import React from "react";

// NOTE: the onChange method is assumed idempotent, it may be called more than once
export default class PickerGridButton extends React.Component {
  render() {
    return (
      <label
        className={
          "btn btn-primary btn-small " +
          (!!this.props.disabled ? "btn-disabled " : "") +
          this.props.className +
          (this.props.checkedValue === this.props.value ? " active" : "")
        }
      >
        <input
          name={this.props.groupName}
          type="radio"
          autoComplete="off"
          value={this.props.value}
          onChange={() => this.props.onChange(this.props.value)}
          onClick={() => this.props.onChange(this.props.value)}
          checked={this.props.checkedValue === this.props.value}
          disabled={!!this.props.disabled}
        />
        {this.props.children}
      </label>
    );
  }
}
