import React from "react";
import CallAndChat from "../CallAndChat/CallAndChat.jsx";
import "./Contact.scss";
import Col from "react-bootstrap/Col";

const Contact = ({ openChatWindow }) => (
  <div style={{ marginTop: 124 }} className="contactPage">
    <Col
      xs={12}
      md={{ span: 8, offset: 2 }}
      lg={{ span: 6, offset: 3 }}
      className={"pageTitle"}
    >
      Contact Limos.com 24/7 365:
    </Col>
    <Col
      xs={12}
      md={{ span: 8, offset: 2 }}
      lg={{ span: 6, offset: 3 }}
      className={"aboutText"}
    >
      Limos.com's mission is to offer our customers fast and easy access to
      unbeatable prices for limousine and chauffeured transportation services
      for all types of occasions worldwide. Through our partnerships with the
      world's leading limousine companies, we can get you great deals that can't
      be found anywhere else.
    </Col>
    <CallAndChat openChatWindow={openChatWindow} titleMessage={""} />
  </div>
);

export default Contact;
