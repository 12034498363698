import React from "react";
import { fullHumanDateTimeZone } from "../../utils/date-time";
import "./TripToFromTimeDetails.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const TripToFromTimeDetails = ({
  pickupPlace,
  dropoffPlace,
  completeDate,
  returnDate,
  showDate,
  showTrip,
}) => {
  const pickupName =
    pickupPlace.display.startsWith(pickupPlace.name) ||
    !pickupPlace?.name?.length ? (
      <></>
    ) : (
      <>
        <b>{pickupPlace.name}</b>,{" "}
      </>
    );
  const dropoffName =
    dropoffPlace?.display?.startsWith(dropoffPlace?.name) ||
    !dropoffPlace?.name?.length ? (
      <></>
    ) : (
      <>
        <b>{dropoffPlace?.name}</b>,{" "}
      </>
    );
  return (
    <>
      {showTrip ? (
        <div className={"miniTripInfo"}>
          <div className="mini-trip-to-from">
            <span>
              <b>When: &nbsp;</b> {fullHumanDateTimeZone(completeDate)}
            </span>
            <div className="pickup_display">
              <div>
                <b>From:</b> &nbsp; {pickupName}
                {pickupPlace.display}
              </div>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#416fbd" }}
              />
              <div>
                <b>To: &nbsp;</b>
                {dropoffName}
                {dropoffPlace?.display}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default TripToFromTimeDetails;
