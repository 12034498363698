import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./PromoCode.scss";

// TODO move global
// based on http://stackoverflow.com/questions/149055
function formatMoney(n) {
  return (
    (n < 0 ? "-" : "") +
    "$" +
    Math.abs(n / 100)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
  );
}

export default class PromoCode extends React.Component {
  state = {
    text: "",
    value: false,
  };

  setText = (e) => {
    this.setState({ text: e.target.value, failed: false });
  };

  submit = (e) => {
    e.preventDefault();
    this.setState({ value: false, failed: false, text: "" });
    this.props.setPromoCode(0, this.state.text);
  };

  render() {
    return (
      <>
        <div className="promo-flex-box">
          <div className="promo-code-container">
            <input
              type="text"
              placeholder="Promo Code"
              defaultValue={this.state.text}
              onChange={this.setText}
              className="form-control rbt-input-main rbt-input promo-code-input"
            />
          </div>
          <button
            type="submit"
            disabled={this.state.text === ""}
            className="promo-code-button btn btn-primary btn-block"
            onClick={this.submit}
          >
            {this.props.loading ? (
              <Spinner className={"spinner"} animation="border" size={"sm"} />
            ) : (
              "Apply Code"
            )}
          </button>
          <div
            className={
              "promo-code-result" +
              (this.props.promoCode.amount !== 0 || this.props.failedMessage
                ? ""
                : " hidden")
            }
          >
            {this.props.promoCode.amount !== 0
              ? 'Promo Code "' +
                this.props.promoCode.code +
                '" applied successfully! You saved: ' +
                formatMoney(this.props.promoCode.amount)
              : this.props.failedMessage
              ? this.props.failedMessage
              : "Some text."}
          </div>
        </div>
      </>
    );
  }
}
