import React, { useContext } from "react";
import { WhiteLabelContext } from "react-whitelabel";
import { Route, Switch } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import SearchForm from "../SearchForm/SearchForm.jsx";

import "./Carnival.scss";
import { useHistory } from "react-router-dom";
import NewSearchForm from "../SearchForm/NewSearchForm.jsx";
import FooterUpdated from "../FooterUpdated/FooterUpdated.jsx";

const Carnival = (props) => {
  const { searchFormTitle, searchFormSubtitle, searchFormDescription } =
    useContext(WhiteLabelContext);
  return (
    <>
      <div className="container-carnival">
        <div className="carnival-hero-banner">
          <div className="img-container">
            <div className="banner-container" style={{ width: "100%" }}>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Carnival-cruises.jpg"
                className="banner-lg"
              />
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Carnival-cruise-mobile.jpg"
                className="banner-sm"
              />
            </div>
            <div className="sprites-bg">
              <div className="transport-image-lg">
                <img src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Luxury-Fleet+Copy.png" />
              </div>
              <div className="sprite-group">
                <div className="cruise-logo">
                  <img
                    src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Carnival-Cruise.png"
                    height="100%"
                    width="100%"
                  />
                </div>

                <div className="img-desc">
                  <span>
                    Find the best transportation deals to get <br /> you to and
                    from your cruiseport terminal.
                  </span>
                </div>
                <div className="transport-image-sm">
                  <img src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Luxury-Fleet+Copy.png" />
                </div>
                <ul className="banner-sprites">
                  <li className="li-flex">
                    <div className="img-div">
                      <img
                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Group+6.png"
                        width="100%"
                        height="100%"
                      />
                    </div>
                    <div className="li-desc">
                      Ride in Style <br />
                      to Cruiseport
                    </div>
                  </li>
                  <li className="li-flex">
                    <div className="img-div">
                      <img
                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Group+7.png"
                        width="100%"
                        height="100%"
                      />
                    </div>
                    <div className="li-desc">
                      5-star Service <br />
                      5-star Reviews
                    </div>
                  </li>
                  <li className="li-flex">
                    <div className="img-div">
                      <img
                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Group+8.png"
                        width="100%"
                        height="100%"
                      />
                    </div>
                    <div className="li-desc">Priority Customer Service</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="search-form-carnival">
              <NewSearchForm
                pickupPlace={props.pickupPlace}
                dropoffPlace={props.dropoffPlace}
                setPlace={props.setPlace}
                setVehicleOptions={props.setVehicleOptions}
                partySize={props.partySize}
                vehicleTypes={props.vehicleTypes}
                pickupPlaceItem={props.pickupPlaceItem}
                onPlaceQuery={props.onPlaceQuery}
                pickupPlaceQueryResults={props.pickupPlaceQueryResults}
                dropoffPlaceQueryResults={props.dropoffPlaceQueryResults}
                tripWizardValues={props.tripWizardValues}
                setSearchFormErrors={props.setSearchFormErrors}
                searchFormErrors={props.searchFormErrors}
                completeDate={props.completeDate}
                returnDate={props.returnDate}
                finishedTripWizard={props.finishedTripWizard}
                createInitialSearchFormErrorState={
                  props.createInitialSearchFormErrorState
                }
                openChatWindow={props.openChatWindow}
                errors={props.searchFormErrors}
                setError={props.setError}
                onSearchFormChange={props.onSearchFormChange}
                searchForm={props.searchForm}
                setSearchParams={props.setSearchParams}
                roundTrip={props.roundTrip}
                setRoundTrip={props.setRoundTrip}
                setCustomPartySize={props.setCustomPartySize}
                customPartySize={props.customPartySize}
                images={[]}
                formTitle={"Book Cruiseport Transportation"}
                formSubtitle={searchFormSubtitle}
                formButtonText={"Search Now"}
                pageName={"carnival"}
              />
            </div>
          </div>
        </div>
        <div className="cruise-ships-container">
          <span className="cruise-ship-desc">
            Providing transportation to the most popular <br /> cruiseports for
            over 33 years with the best prices
          </span>
          <div className="cruise-ship-names">
            <span> Transportation to Port of Miami </span>
            <span> Transportation to Port Canaveral </span>
            <span> Transportation to Port Everglades </span>
            <span> Transportation to Port of Galveston </span>
            <span> Transportation to Ports of St. Thomas/St. John </span>
            <span> Transportation to Port of New York and New Jersey </span>
            <span> Transportation to Port of New Orleans </span>
            <span> Transportation to Port of Juneau </span>
            <span> Transportation to Port of Tampa </span>
            <span> Transportation to Port of Seattle </span>
            <span> Transportation to Port of Port of Ketchikan </span>
            <span> Transportation to Port of Los Angeles </span>
            <span> Transportation to Port of Palm Beach </span>
            <span> Transportation to Port of Boston </span>
            <span> Transportation to Port of Portland, Maine </span>
            <span> Transportation to Port of Jacksonville </span>
          </div>
        </div>

        <div className="popular-cruise-ports">
          <span> Our Most Popular Cruiseports</span>
          <div className="cruise-ports-container">
            <div className="cruise-ports">
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Port-of-miami.jpg"
                height="100"
                width="100"
              />
              <div className="cruise-port-desc">
                <p>Port of Miami </p>
                <p className="sub-text">starting at $119</p>
              </div>
            </div>
            <div className="cruise-ports">
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Port-canaveral.jpg"
                height="100"
                width="100"
              />
              <div className="cruise-port-desc">
                <p>Port of Los Angeles starting at $79</p>
              </div>
            </div>
            <div className="cruise-ports">
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Port-of-san-diego.jpg"
                height="100"
                width="100"
              />
              <div className="cruise-port-desc">
                <p>Port of Tamps starting at $199</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-carnival-team-sm">
        <button className="">Call Us at 844.427.3445 to Book!</button>
      </div>
    </>
  );
};

export default Carnival;
