import React, { useEffect, useContext } from "react";
import DayPicker from "react-day-picker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WhiteLabelContext } from "react-whitelabel";
import { DateTime } from "luxon";

import "react-day-picker/lib/style.css";
import "./SearchDayPicker.scss";
import "./WizardPages.scss";
import AllTimesLocal from "../AllTimesLocalComponent/AllTimesLocal.jsx";

function Navbar({ onPreviousClick, onNextClick, month }) {
  return (
    <div className="DayPicker-NavBar">
      <div className="flexLeft">
        <span className="wizardPageTitle">
          {month.toLocaleDateString(undefined, {
            month: "short",
            year: "numeric",
          })}
        </span>
      </div>
      <div className="flexRight">
        <span
          tabIndex="0"
          role="button"
          aria-label="Previous Month"
          className="monthSelectButton"
          onClick={() => onPreviousClick()}
        >
          <FontAwesomeIcon icon="caret-left" />
        </span>
        <span
          tabIndex="0"
          role="button"
          aria-label="Next Month"
          className="monthSelectButton"
          onClick={() => onNextClick()}
        >
          <FontAwesomeIcon icon="caret-right" />
        </span>
      </div>
    </div>
  );
}

function EmptyElement() {
  return <span></span>;
}

const SearchDayPicker = ({ setDay, clearTripWizard, startDay }) => {
  const label = useContext(WhiteLabelContext);

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.title = label.title + " - Select Day";
    }
    clearTripWizard();
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row>
        <Col xs={12} className={"wizardPageTitle"}>
          <FontAwesomeIcon icon="calendar" /> Date of Trip
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <DayPicker
            onDayClick={(d) => setDay(DateTime.fromJSDate(d).set({ hour: 0 }))}
            showOutsideDays
            navbarElement={<Navbar />}
            captionElement={<EmptyElement />}
            disabledDays={[
              {
                before: startDay,
              },
            ]}
          />
        </Col>
      </Row>
      <AllTimesLocal />
    </>
  );
};

export default SearchDayPicker;
