import React from "react";
import SlickSlider from "react-slick";

import "./slider.scss";

export default function Slider({ className, children, ...props }) {
  return (
    <SlickSlider className={className} {...props}>
      {children}
    </SlickSlider>
  );
}
