import React, { Component } from "react";
import { Button, Carousel, Modal } from "react-bootstrap";
import { withWhiteLabelContext } from "react-whitelabel";
import { Redirect } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Rating from "react-rating";

import { routePaths } from "../../routes/routes";

import AmenityOverflow from "../AmenityOverflow/AmenityOverflow.jsx";
import MapComponent from "../MapComponent/MapComponent.jsx";
import MiniTripInfoComponent from "../MiniTripInfoComponent/MiniTripInfoComponent.jsx";

import "./SearchResultWide.scss";

// const sentences = [
//   "Only 1 vehicle left! Book fast!",
//   "Only 2 vehicles left! Book fast!",
// ];

class SearchResultWide extends Component {
  state = {
    redirect: false,
    reservationTerms: "",
    showReviewsModal: false,
    propsData: null,
  };

  componentWillReceiveProps(props) {
    if (props.optedReturnedTrip && props.resultHandle1 && props.resultHandle2) {
      this.navigateToCheckout();
    }
  }

  addToCart = () => {
    if (
      this.props.optedReturnedTrip == true &&
      (!this.props.resultHandle1 || !this.props.resultHandle2)
    ) {
      this.props.clickCallBack(this.props.operatorId);
    } else {
      this.navigateToCheckout();
    }
  };

  navigateToCheckout = () => {
    this.props.addItemToCart(
      this.props.searchResult,
      this.props.legName,
      () => {
        if (this.props.cartCallback) {
          this.props.cartCallback();
        }
        if (this.props.showCheckout) {
          this.setState({ redirect: true });
        }
      },
      this.props.resultHandle1,
      this.props.resultHandle2,
      this.props.resultHandler1Data,
      this.props.resultHandler2Data
    );
  };

  setReservationTerms = (reservationTerms) => {
    this.setState({ reservationTerms });
  };

  createPricingBreakdown() {}

  render() {
    if (this.state.redirect) {
      return <Redirect to={routePaths.checkout} push />;
    }

    const amenities = this.props.amenities
      ? this.props.amenities.map((item, key) => (
          <li key={key} className="amenity">
            {item.vehicle_feature_definition.description}
          </li>
        ))
      : [];

    const registerCallChatClick = () => {
      if (typeof window.dataLayer !== "undefined") {
        window.dataLayer.push({ event: "search-call-chat-clicked" });
      }
    };

    const {
      reviewsLink = [],
      showCallChat = false,
      openChatWindow = () => "nothing",
      label,
      searchResult,
    } = this.props;
    const { telephone } = label;

    const { result } = searchResult;

    const isFlatRate = result.rate_type === "FLAT";
    const isHourlyRate = result.rate_type === "HOURLY";
    const extraHours = Number(result.priced_extra_hours) > 0;
    const totalHours = result.total_hours_count;
    const showTotalHours = totalHours > 0 ? totalHours : "";

    const ratePerHourForHourlyWithoutExtraHour =
      isHourlyRate && extraHours === false && parseFloat(result.base_rate);

    const ratePerHourForHourlyWithExtraHour =
      isHourlyRate &&
      extraHours &&
      (parseFloat(result.base_rate) * totalHours) / totalHours;

    const flatRatePerHour = isFlatRate && parseFloat(result.base_rate);

    const calculateDiscountFlateRatePerHour = Math.ceil(
      flatRatePerHour * 0.2 + flatRatePerHour
    );
    const calculateDisForHourlyWithoutExtraHour =
      (ratePerHourForHourlyWithoutExtraHour / 100) * 20 +
      ratePerHourForHourlyWithoutExtraHour;

    const calculateDisForHourlyWithExtraHour =
      (ratePerHourForHourlyWithExtraHour / 100) * 20 +
      ratePerHourForHourlyWithExtraHour;

    const calculateGrandTotalForMoz = parseFloat(result.grand_total);

    const calculateDiscountForMoz =
      (calculateGrandTotalForMoz / 100) * 20 + calculateGrandTotalForMoz;
      
    const isMoizoOperator = this.props.operatorId === "MOZ";

    const { operatorId, images } = this.props;

    return (
      <div className="search-result-wide">
        {/* <div className="searchwide-discount">20% discount if you Book Now!</div> */}
        <div className="features-container">
          {isMoizoOperator && (
            <div className={`image-container moz-image-container`}>
              {images.map((imageUrl, index) => (
                <img
                  key={index}
                  src={imageUrl.replace("http://", "https://")}
                  alt="Car"
                  className="custom-image"
                />
              ))}
            </div>
          )}

          {!isMoizoOperator && (
            <div className="image-carousel-container">
              <Carousel interval={null} key="carousel-images">
                {images.map((imageUrl, index) => {
                  let formatImageURL = imageUrl.replace("http://", "https://");
                  return (
                    <Carousel.Item key={index}>
                      <div className="carousel-image-container">
                        <img
                          src={formatImageURL}
                          alt="Car"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://assets.s3.amazonaws.com/images/front-end/new-landing/limos.com.jpg";
                          }}
                        />
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          )}

          {/* <div
            className={`image-carousel-container ${
              this.props.operatorId === "MOZ" ? "moz-container  " : ""
            }`}
          >
            <Carousel interval={null} key="carousel-images">
              {this.props.images.map((imageUrl, index) => {
                let formatImageURL = imageUrl.replace("http://", "https://");
                return (
                  <Carousel.Item key={index}>
                    <div className="carousel-image-container">
                      <img
                        src={formatImageURL}
                        alt="Car"
                        onError={(e) => {
                          // If the image fails to load, set a fallback image or handle the error
                          e.target.onerror = null; // Prevent infinite loop in case of repeated error
                          e.target.src =
                            "https://assets.s3.amazonaws.com/images/front-end/new-landing/limos.com.jpg"; // Replace with your fallback image URL
                        }}
                      />
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div> */}
          <div className="content">
            <div className="info-container">
              <div className="display-text">
                <h3>{this.props.displayName}</h3>
                <div className="rating-info">
                  {this.props.operatorId && (
                    <div className="ops-id">Op ID: {this.props.operatorId}</div>
                  )}
                  <div
                    className={`rating-star ${
                      this.props.operatorId === "MOZ" ? "hidden-rating" : ""
                    }`}
                  >
                    <Rating
                      start={0}
                      stop={5}
                      fractions={8}
                      initialRating={this.props.starRating}
                      readonly={true}
                      quiet={true}
                      fullSymbol={<FontAwesomeIcon icon={"star"} />}
                      emptySymbol={
                        <FontAwesomeIcon
                          icon={"star"}
                          className={"emptyStar"}
                        />
                      }
                    />
                  </div>
                </div>
                {this.props.resultHandle1 &&
                this.props.resultHandle2 &&
                this.props.roundTripPrice ? (
                  <h4 className="round-trip-label">(Round Trip)</h4>
                ) : (
                  <></>
                )}
              </div>

              <div className="sub-info">
                <div className="about-and-ratings">
                  <div className="about-info">
                    <div className="capacities">
                      <div>
                        <FontAwesomeIcon
                          icon={{ prefix: "fal", iconName: "users" }}
                        />{" "}
                        <span>{this.props.passengerCapacity}</span>
                      </div>
                      <div>
                        <FontAwesomeIcon
                          icon={{ prefix: "fal", iconName: "suitcase" }}
                        />{" "}
                        <span>{this.props.luggageCapacity}</span>
                      </div>
                    </div>
                    <AmenityOverflow amenities={amenities} />
                  </div>
                </div>
              </div>
            </div>

            <div className="new-price-container">
              {this.props.resultHandle1 &&
              this.props.resultHandle2 &&
              this.props.roundTripPrice ? (
                <div className="price-container">
                  <div className="price">
                    <p className="price-wrapper">
                      <span className="dollar-sign">$</span>
                      {(
                        Math.round(this.props.roundTripPrice * 100) / 100
                      ).toFixed(2)}
                    </p>
                    <p className="total-price-label">Total Price</p>
                  </div>
                </div>
              ) : (
                <>
                  {/* <div className="price-container">
                    <div className="price discount">
                      <hr
                        className={`price-red-slash ${isFlatRate && "wide"} ${
                          isMoizoOperator && "moz"
                        }`}
                      />
                      {isMoizoOperator ? (
                        <div className={"moz-disc-price"}>
                          {`${Math.ceil(calculateDiscountForMoz)}`}
                        </div>
                      ) : isFlatRate ? (
                        <div className="flat-rate-wrapper">
                          {`${Math.ceil(calculateDiscountFlateRatePerHour)} Flat
                          Rate`}
                        </div>
                      ) : extraHours ? (
                        <div className="rate-dis-hourly-extra-hrs">
                          {`${Math.ceil(calculateDisForHourlyWithExtraHour)} ${
                            isMoizoOperator ? "" : "/hr"
                          }`}
                        </div>
                      ) : (
                        <div
                          className={
                            isMoizoOperator
                              ? "rate-dis-hourly-not-extrs"
                              : "rate-dis-hourly-not-extrs-hrs"
                          }
                        >
                          {`${Math.ceil(
                            calculateDisForHourlyWithoutExtraHour
                          )} ${isMoizoOperator ? "" : "/hr"}`}
                        </div>
                      )}
                    </div>
                    <div className="price">
                      {isMoizoOperator ? (
                        <>
                          <div className="flat-rate-parent">
                            <p className="flat-rate-hr">
                              ${Math.ceil(calculateGrandTotalForMoz)}
                            </p>
                          </div>
                        </>
                      ) : isFlatRate ? (
                        <div className="flat-rate-parent">
                          <p className="flat-rate">
                            ${Math.ceil(flatRatePerHour)}
                          </p>
                          <p className="rate-text">
                            FLAT <br />
                            RATE
                          </p>
                        </div>
                      ) : extraHours ? (
                        <div>
                          <div className="flat-rate-parent">
                            <p className="flat-rate-hr">
                              ${Math.ceil(ratePerHourForHourlyWithExtraHour)}
                            </p>
                            <p className="rate-text-hr">
                              {isMoizoOperator ? "" : ` /hr*`}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flat-rate-parent">
                            <p className="flat-rate-hr">
                              ${Math.ceil(ratePerHourForHourlyWithoutExtraHour)}
                            </p>
                            <p className="rate-text-hr">
                              {isMoizoOperator ? "" : ` /hr*`}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div> */}

                  {
                    <div className="price-container">
                      <div className="price">
                        <span className="dollar-sign">$</span>
                          {this.props.grandTotal
                             ? Math.ceil(this.props.grandTotal / 100)
                             : Math.ceil(
                            parseFloat(
                              this.props.searchResult.result.grand_total
                            )
                          )}
                      {/* {this.props.grandTotal ? Math.ceil(this.props.grandTotal / 100) : this.props.isHourly ? Math.ceil(parseFloat(this.props.searchResult.result.grand_total) / this.props.searchResult.result.total_hours_count)
                        : Math.ceil(
                            parseFloat(this.props.searchResult.result.grand_total)
                      )} */}
                    </div>
                    <div className="price-label">
                      {this.props.showPriceLabel ? (
                        <>Total Price</>
                      ) : (
                        <>
                          {this.props.grandTotal
                            ? "$" + this.props.grandTotal / 100
                            : this.props.isHourly
                            ? "/hr (total price)"
                            : "(total price)"}
                        </>
                      )}
                    </div>
                    </div>
                  }
                </>
              )}
              {!this.props.displayContextIsCheckout && (
                <div className="add-to-cart-button">
                  {showCallChat ? (
                    <>
                      <Button
                        onClick={(e) => {
                          registerCallChatClick();
                          openChatWindow(e);
                        }}
                        className="btn-rounded chatButton small-font"
                      >
                        <FontAwesomeIcon icon="comments" /> Live Chat Now
                      </Button>
                      <a
                        href={`tel:${telephone}`}
                        className="btn btn-rounded btn-block linkButton small-font"
                        onClick={registerCallChatClick}
                      >
                        <FontAwesomeIcon icon="phone" /> {telephone}
                      </a>
                    </>
                  ) : (
                    <>
                      {/* <div className="trending-container">
                        <span className="trending-first-text">
                          {this.props.indexNumber === 0 && (
                            <span>
                              <img
                                src="https://assets.s3.amazonaws.com/images/front-end/Flame.png"
                                alt="alt"
                                height="20"
                                width="15"
                              />
                              {sentences[randomIndex]}
                            </span>
                          )}
                        </span>
                        <span className="trending-second-text">
                          {this.props.indexNumber === 1 && (
                            <span>
                              <img
                                src="https://assets.s3.amazonaws.com/images/front-end/Flame.png"
                                alt="alt"
                                height="20"
                                width="15"
                              />
                              {sentences[secondRandomIndex]}
                            </span>
                          )}
                        </span>
                      </div> */}

                      <Button onClick={this.addToCart}>Select</Button>
                      <div className="disclaimer-under-button-text">
                        <span className="disclainer-text">
                          {`*fees, gratuity, travel time may be additional ${
                            showTotalHours > 0
                              ? `${showTotalHours} hrs min.`
                              : "."
                          }`}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.props.displayContextIsCheckout ? (
          <>
            <MiniTripInfoComponent
              pickupPlace={this.props.pickupPlace}
              dropoffPlace={this.props.dropoffPlace}
              completeDate={this.props.completeDate}
              showDate={false}
              showTrip={true}
            />

            <MapComponent
              pickupPlace={this.props.pickupPlace}
              dropoffPlace={this.props.dropoffPlace}
            />
          </>
        ) : (
          <></>
        )}
        <Modal
          show={this.state.showReviewsModal}
          onHide={() =>
            this.setState({
              showReviewsModal: false,
            })
          }
        >
          <Modal.Header closeButton>
            Reviews for {this.props.displayName}{" "}
            {this.props.operatorId && `, Op: ${this.props.operatorId}`}
          </Modal.Header>
          <Modal.Body>
            <Carousel
              interval={null}
              prevIcon={
                <FontAwesomeIcon
                  className="carousel-arrow"
                  icon="arrow-circle-left"
                />
              }
              nextIcon={
                <FontAwesomeIcon
                  className="carousel-arrow"
                  icon="arrow-circle-right"
                />
              }
              key="carousel-reviews"
            >
              {reviewsLink
                .filter((review) => {
                  if (
                    review.review === "" ||
                    review.review === null ||
                    review.rating < 3
                  ) {
                    return false;
                  }
                  return true;
                })
                .map((review, index) => (
                  <Carousel.Item key={index}>
                    <div className="carousel-review-container">
                      <div className={"reviews"} style={{ overflow: "auto" }}>
                        <div>
                          <Rating
                            start={0}
                            stop={5}
                            fractions={8}
                            initialRating={review.rating}
                            readonly={true}
                            quiet={true}
                            fullSymbol={<FontAwesomeIcon icon={"star"} />}
                            emptySymbol={
                              <FontAwesomeIcon
                                icon={"star"}
                                className={"emptyStar"}
                              />
                            }
                          />
                        </div>
                        <div>{review.review}</div>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
            </Carousel>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withWhiteLabelContext(SearchResultWide);

