import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { withWhiteLabelContext } from "react-whitelabel";

class PrivacyPolicy extends Component {
  render() {
    const { domainProtocol, fullDomain } = this.props.label;
    return (
      <Row style={{ marginTop: 124, padding: 16 }}>
        <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          <h2>Limos.com Customer Privacy Policy</h2>

          <p>
            This privacy policy applies to{" "}
            <a href={domainProtocol + "://" + fullDomain}>{fullDomain}</a> owned
            and operated by On Demand iCARS Inc. ("Limos.com"). This privacy
            policy describes how Limos.com collects and uses the personal
            information you provide on the Limos.com website. It also describes
            the choices available to you regarding our use of your personal
            information and how you can access and update this information.
          </p>

          <p>
            Limos.com knows that you care how information about you is used and
            shared, and we appreciate your trust that we will do so carefully
            and sensibly. This notice describes our privacy policy.
          </p>

          <p>
            By visiting Limos.com, you are accepting the practices described in
            this Privacy Policy. For clarity, the policies below apply to
            visitors to the Limos.com website (Customers). Note: If you are a
            limousine company (an "LC") enrolled in the Limos.com service, the
            terms governing your use of the website and confidentiality are
            provided in your Terms of Service agreement with Limos.com.
          </p>

          <h3>
            What Personal Information Does Limos.com Gather About Customers?
          </h3>

          <p>
            The information we learn from Customers helps us personalize and
            continually improve your limousine shopping experience at Limos.com.
            Here are the types of information we gather:
          </p>

          <h4>General Information You Give Us:</h4>

          <p>
            We receive and store any information you enter on our website or
            give us in any other way. You can choose not to provide certain
            information, but then you will not be able to take advantage of many
            of our features, including receiving pricing on limo services or
            making a reservation/booking. In order to receive quotes from
            Limos.com's participating third-party LC's, you must first create an
            account by providing your first and last name, email or phone number
            and creating a password. You may also provide such information if
            you participate in a contest or questionnaire, or communicate with
            customer service; provide information in your account or profile;
            communicate with us by phone, e-mail, or otherwise; provide employer
            information when opening a corporate account; provide and rate LC
            reviews; or employ our personal notification services, such as trip
            reminders. As a result of those actions, you might supply us with
            booking or reservation information such as your name, address, and
            phone numbers; and content of reviews and e-mails to us. We use the
            information that you provide for our general commercial purposes
            including to allow us and our LC's to respond to your requests,
            customize future shopping for you and communicate with you.
          </p>

          <h3>Third Party Information You Give Us:</h3>

          <p>
            If you choose to add your friend as a passenger for the reservation
            or Send Invitations on our site, we will ask you for your friend’s
            name and address. Limos.com stores this information for the sole
            purpose of completing the reservation and tracking the success of
            our reservation and invitation.
          </p>

          <p>
            Your friend may contact us at{" "}
            <a href="mailto:customerservice@limos.com">
              customerservice@limos.com
            </a>{" "}
            to request that we remove this information from our database.
          </p>

          <h3>Does Limos.com Share Personal Information?</h3>

          <p>
            Information about our Customers is an important part of our
            business. We do not sell your personal information to third parties.
            We share customer information only in the ways described below:
          </p>

          <p>
            The Limos.com corporate family: We share information received
            through Limos.com within our corporate family to provide joint
            content and services (like registration, transactions and customer
            support), to help detect and prevent potentially illegal acts and
            violations of our policies, and to guide decisions about their
            products, services and communications. Members of our corporate
            family may use this information to send you marketing communications
            if you've requested their services.
          </p>

          <p>
            LC's: We share information with our third-party LC's so that they
            may provide quote information, may communicate with Customers
            directly to provide service information (typically to answer
            Customer questions, describe the LC's services or reservation
            request) and provide the limousine service itself. When you request
            a quote, a reservation or ask a question of an LC, we share your
            "Customer Criteria" with the LC in order for the LC to provide you
            with the above information about their limousine service. Your
            "Customer Criteria" includes your name, your communication
            preference (email and/or phone call), any email or phone number you
            provided, your limousine ride type, vehicle preferences, dates/times
            and location of service and any special requests you submitted. Our
            LC's are prohibited by our Terms of Service agreement from using
            your personal information for any other purpose.
          </p>

          <p>
            Third Party Service Agents: We employ other companies and
            individuals to perform functions on our behalf which may involve
            sharing information. Examples include sending postal mail and
            e-mail, processing credit card payments, and providing customer
            service. They have access to personal information needed to perform
            their functions, but pursuant to our agreements, they must not
            retain, share, store or use personal information.
          </p>

          <p>
            Legal Enforcement: We release account and other personal information
            when we believe release is appropriate to comply with the law such
            as to comply with a subpoena, bankruptcy proceedings, or similar
            legal process; enforce or apply our Conditions of Use and other
            agreements; or protect the rights, property, or safety of Limos.com,
            our users, or others. This includes exchanging information with
            other companies and organizations for fraud protection and credit
            risk reduction. Obviously, however, this does not include selling,
            renting, sharing, or otherwise disclosing personally identifiable
            information from customers for commercial purposes in violation of
            the commitments set forth in this Privacy Policy.
          </p>

          <p>
            We may also disclose your personal information if Limos.com is
            involved in a merger, acquisition, or sale of all or a portion of
            its assets. You will be notified via email and/or a prominent notice
            on our website of any change in ownership or uses of your personal
            information, as well as any choices you may have regarding your
            personal information.
          </p>

          <p>
            With Your Consent: Other than as set out above, you will receive
            notice when information about you might go to third parties, and you
            will have an opportunity to choose not to share the information.
          </p>

          <p>
            We collect your location-based information for the purpose of
            helping you select your pickup or dropoff information. We will only
            share this information with our mapping provider for the sole
            purpose of proving you this service. You may opt-out of
            location-based services at any time by editing the setting at the
            device level.
          </p>

          <h3>What Tracking Technologies Do We Have?</h3>

          <p>
            Technologies such as: cookies, beacons, tags and scripts are used by
            Limos.com and our analytics and marketing partners. These
            technologies are used in analyzing trends, administering the site,
            tracking users’ movements around the site and to gather demographic
            information about our user base as a whole. We may receive reports
            based on the use of these technologies by these companies on an
            individual as well as aggregated basis. This includes cookies from{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/legal/terms/businesstools"
            >
              Facebook
            </a>
            .
          </p>

          <p>
            We use cookies throughout the site to maintain your session. Users
            can control the use of cookies at the individual browser level. If
            you reject cookies, you may still access the site but search,
            reservation and other features will no longer work.
          </p>

          <p>
            <strong>Log Files</strong> - As is true of most web sites, we gather
            certain information automatically and store it in log files. This
            information may include Internet protocol (IP) addresses, browser
            type, Internet service provider (ISP), referring/exit pages,
            operating system, date/time stamp, and/or clickstream data.
          </p>

          <p>
            <strong>
              We do not link this automatically collected data to other
              information we collect about you.
            </strong>
          </p>

          <p>
            <strong>Behavioral Targeting</strong> - We partner with a third
            party to either display advertising on our Web site or to manage our
            advertising on other sites. Our third party partner may use
            technologies such as cookies to gather information about your
            activities on this site and other sites in order to provide you
            advertising based upon your browsing activities and interests. If
            you wish to not have this information used for the purpose of
            serving you interest-based ads, you may opt-out by{" "}
            <a
              href="http://preferences-mgr.truste.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              clicking here
            </a>
            . Please note this does not opt you out of being served ads. You
            will continue to receive generic ads.
          </p>

          <p>
            <strong>Local Storage (HTML5)</strong> - We use Local Storage such
            as HTML5 to store content information and preferences. Third parties
            with whom we partner to provide certain features on our site or to
            display advertising based upon your Web browsing activity use HTML5
            to collect and store information. Various browsers may offer their
            own management tools for removing HTML5 storage.
          </p>

          <h3>What about Links to Other Websites?</h3>

          <p>
            Our Site includes links to other Web sites whose privacy practices
            may differ from those companies. If you submit personal information
            to any of those sites, your information is governed by their privacy
            policies. We encourage you to carefully read the privacy policy of
            any Web site you visit.
          </p>

          <h3>Social Media Widgets</h3>

          <p>
            Our website includes Social Media Features, such as the Facebook
            Like button, and Widgets, such as the Share This button or
            interactive mini-programs that run on our website. These Features
            may collect your Internet protocol address, which page you are
            visiting on our website, and may set a cookie to enable the Feature
            to function properly. Social Media Features and Widgets are either
            hosted by a third party or hosted directly on our website. Your
            interactions with these Features are governed by the privacy
            statement of the company providing it.
          </p>

          <h3>Testimonials</h3>
          <p>
            We display personal testimonials of satisfied customers on our
            website in addition to other endorsements. With your consent, we may
            post your testimonial along with your name. If you wish to update or
            delete your testimonial, you can contact us at{" "}
            <a href="mailto:customerservice@limos.com">
              customerservice@limos.com
            </a>
            .
          </p>

          <h3>What Choices Do I Have?</h3>

          <p>
            Personal Information: As discussed above, you can always choose not
            to provide information, even though it might be needed to obtain
            such features a receiving a quote, making a reservation,
            creating/reviewing your profile, past searches and creating customer
            reviews.
          </p>

          <p>
            Review and Update Personal Information: You can access your
            information at various points on the Limos.com website. These
            include:
          </p>

          <p>
            The "My Account" page on Limos.com, where you can view, update, or
            change your personal information. On this page, you also can change
            your password; elect to have an automatic password sign-in when
            logging on to Limos.com; update or correct names, phone numbers, and
            addresses associated with your profile; and update your preferences.
            The "My Trips" page, where you can view, update, or delete your
            saved trip searches or itineraries.
          </p>

          <p>
            You can close your Limos.com account by selecting the "Close
            Account" option. We will send you an e-mail confirming your request
            to close your account to the e-mail address contained in your member
            profile. Please note that after you close an account, you will not
            be able to sign in or access any of your personal information.
            However, you can open a new account at any time.{" "}
          </p>

          <p>
            If your personal information changes, or request that we no longer
            use your information to provide you services, you may also correct,
            update or delete it by contacting us at
            <a href="mailto:customerservice@limos.com">
              customerservice@limos.com
            </a>
            . We will respond to your request within a reasonable timeframe.
          </p>

          <p>
            We will retain your information for as long as your account is
            active or as needed to provide you services. We will retain and use
            your information as necessary to comply with our legal obligations,
            resolve disputes, and enforce our agreements.
          </p>

          <p>
            Communication Preferences from the website and LC's: If you do not
            want to receive e-mail or phone calls from us or an LC, please
            adjust your customer communication preferences. You may opt-in or
            opt-out of newsletters, travel or customer service alerts, but we
            will still be permitted to send you updates to our policies and
            legal notices. If you wish to subscribe to our newsletter(s), we
            will use your name and email address to send the newsletter to you.
            Out of respect for your privacy, you may choose to stop receiving
            our newsletter or marketing emails by following the unsubscribe
            instructions included in these emails, accessing the email
            preferences in your account settings page or you can contact us at
            <a href="mailto:customerservice@limos.com">
              customerservice@limos.com
            </a>
            .
          </p>

          <p>
            Cookies and Java Script: The help portion of the toolbar on most
            browsers will tell you how to prevent your browser from accepting
            new cookies, how to have the browser notify you when you receive a
            new cookie, or how to disable cookies altogether. Additionally, you
            can disable or delete similar data used by browser add-ons, such as
            Flash cookies, by changing the add-on's settings or visiting the
            website of its manufacturer. However, because cookies and Java
            Script allow you to take advantage of some of Limos.com's essential
            features, we recommend that you leave them turned on.
          </p>

          <h3 id="CA-rights">Your California Privacy Rights</h3>

          <p>
            We may disclose your personal information to our affiliates or other
            third parties for their use in marketing to you. California
            residents are permitted to request information about the manner in
            which we share certain categories of information with third parties
            for their marketing use. To request a copy of our disclosure
            pursuant to California law please contact us at{" "}
            <a href="mailto:CAprivacy@limos.com">CAprivacy@limos.com</a>.
          </p>

          <h3>How Secure Is Information About Me?</h3>

          <p>
            We work to protect the security of your information during
            transmission by using Secure Sockets Layer (SSL) software, which
            encrypts information you input.
          </p>

          <p>
            We reveal only the last five digits of your credit card numbers when
            confirming an order. Of course, we transmit the entire credit card
            number to the appropriate credit card company during order
            processing.
          </p>

          <p>
            It is important for you to protect against unauthorized access to
            your password and to your computer; if we receive a request using
            your user name and password we will assume that you have authorized
            the request. Be sure to sign off when finished using a shared
            computer.
          </p>

          <p>
            We follow generally accepted standards to protect the personal
            information submitted to us, both during transmission and once we
            receive it. No method of transmission over the Internet, or method
            of electronic storage, is 100% secure, however. Therefore, we cannot
            guarantee its absolute security. If you have any questions about
            security on our website, you can contact us at
            <a href="mailto:customerservice@limos.com">
              customerservice@limos.com
            </a>
            .{" "}
          </p>

          <h3>Are Children Allowed to Use Limos.com?</h3>

          <p>
            Limos.com does not offer limousine services for reservation/booking
            by children. While we offer limousine services that may be used by
            children or adults, any limousine services on Limos.com must be
            reserved/booked by an adult. If you are under 18, you may use
            Limos.com only with the involvement of a parent or guardian.
          </p>

          <h3>Conditions of Use, Notices, and Revisions</h3>

          <p>
            If you choose to visit Limos.com, your visit and any dispute over
            privacy is subject to this Privacy Policy and our Terms of Service,
            including limitations on damages, arbitration of disputes, and
            application of the law of the State of California. If you have any
            concern about privacy at Limos.com, please contact us with a
            description via{" "}
            <a href="mailto:customerservice@limos.com">
              customerservice@limos.com
            </a>{" "}
            and we will try to resolve it. If you have questions or concerns
            regarding this statement, you should first contact Limos.com at
            <a href="mailto:customerservice@limos.com">
              customerservice@limos.com
            </a>
            .
          </p>
          <h3>Changes To This Policy</h3>

          <p>
            Our business changes constantly, and our Privacy Policy and our
            Terms of Service will change also. We may e-mail periodic reminders
            of our notices and conditions, unless you have instructed us not to,
            but you should check our Web site frequently where we will post any
            changes with a new Effective Date. Unless stated otherwise, our
            current Privacy Policy applies to all information that we have about
            you and your account. If we make any material changes we will notify
            you by email (sent to the e-mail address specified in your account)
            or by means of a notice on this Site prior to the change becoming
            effective. We encourage you to periodically review this page for the
            latest information on our privacy practices.
          </p>

          <p>
            If you have any questions regarding our privacy policy you can
            contact us via email at
            <a href="mailto:customerservice@limos.com">
              customerservice@limos.com
            </a>{" "}
            or via postal mail at:
          </p>

          <p>
            Limos.com
            <br />
            50 Pier 50, San Francisco, CA 94158
          </p>

          <p>Effective Date: Mar 8, 2018</p>
        </Col>
      </Row>
    );
  }
}

export default withWhiteLabelContext(PrivacyPolicy);
