export const listOfCruisePort = [{
  "label": "Port Canaveral",
  "value": "Port Canaveral",
  "id": "3616",
  "lat": "28.41111",
  "lng": "-80.60833"
}, {
  "label": "Port of Tampa",
  "value": "Port of Tampa",
  "id": "3617",
  "lat": "27.94177",
  "lng": "-82.45767"
}, {
  "label": "Port of Miami",
  "value": "Port of Miami",
  "id": "3618",
  "lat": "25.77989",
  "lng": "-80.18317"
}, {
  "label": "Port of Jacksonville",
  "value": "Port of Jacksonville",
  "id": "3619",
  "lat": "30.396",
  "lng": "-81.5344"
}, {
  "label": "Port of Seattle",
  "value": "Port of Seattle",
  "id": "3620",
  "lat": "47.61096",
  "lng": "-122.34763"
}, {
  "label": "Port of Long Beach",
  "value": "Port of Long Beach",
  "id": "3621",
  "lat": "33.75605",
  "lng": "-118.20315"
}, {
  "label": "Port of Baltimore",
  "value": "Port of Baltimore",
  "id": "3622",
  "lat": "39.28656",
  "lng": "-76.61132"
}, {
  "label": "Port of Boston",
  "value": "Port of Boston",
  "id": "3623",
  "lat": "42.35048",
  "lng": "-71.04245"
}, {
  "label": "Port of Los Angeles",
  "value": "Port of Los Angeles",
  "id": "3624",
  "lat": "33.76094",
  "lng": "-118.2047"
}, {
  "label": "Port of New Orleans",
  "value": "Port of New Orleans",
  "id": "3625",
  "lat": "29.93491",
  "lng": "-90.06171"
}, {
  "label": "Port of Palm Beach",
  "value": "Port of Palm Beach",
  "id": "3626",
  "lat": "26.77321",
  "lng": "-80.04598"
}, {
  "label": "Port of Philadelphia",
  "value": "Port of Philadelphia",
  "id": "3627",
  "lat": "39.98006",
  "lng": "-75.0905"
}, {
  "label": "Port of San Diego",
  "value": "Port of San Diego",
  "id": "3628",
  "lat": "32.72568",
  "lng": "-117.17312"
}, {
  "label": "Port of San Francisco",
  "value": "Port of San Francisco",
  "id": "3629",
  "lat": "37.7964",
  "lng": "-122.39471"
}, {
  "label": "Port of New York",
  "value": "Port of New York",
  "id": "3630",
  "lat": "40.64244",
  "lng": "-74.07528"
}, {
  "label": "Port Everglades",
  "value": "Port Everglades",
  "id": "3631",
  "lat": "26.09677",
  "lng": "-80.12647"
}, {
  "label": "Bayonne Cape Liberty",
  "value": "Bayonne Cape Liberty",
  "id": "3632",
  "lat": "40.67186",
  "lng": "-74.08007"
}, {
  "label": "NYC Passenger Ship Terminal",
  "value": "NYC Passenger Ship Terminal",
  "id": "3633",
  "lat": "40.76804",
  "lng": "-73.99587"
}, {
  "label": "Locust Point Terminal",
  "value": "Locust Point Terminal",
  "id": "3634",
  "lat": "39.24516",
  "lng": "-76.53239"
}, {
  "label": "Port of Houston La Porte",
  "value": "Port of Houston La Porte",
  "id": "3635",
  "lat": "29.67656",
  "lng": "-94.99798"
}, {
  "label": "Port of Galveston Port",
  "value": "Port of Galveston Port",
  "id": "3636",
  "lat": "29.29533",
  "lng": "-94.80786"
}, {
  "label": "Port of Vancouver",
  "value": "Port of Vancouver",
  "id": "3637",
  "lat": "49.28816",
  "lng": "-123.11498"
}, {
  "label": "Port of Copenhagen",
  "value": "Port of Copenhagen",
  "id": "3638",
  "lat": "55.71576",
  "lng": "12.59455"
}, {
  "label": "France Marseille",
  "value": "France Marseille",
  "id": "3639",
  "lat": "43.29528",
  "lng": "5.37434"
}, {
  "label": "France Arles",
  "value": "France Arles",
  "id": "3640",
  "lat": "43.67527",
  "lng": "4.62803"
}, {
  "label": "Brazil Rio De Janeiro",
  "value": "Brazil Rio De Janeiro",
  "id": "3641",
  "lat": "-22.90591",
  "lng": "-43.19096"
}, {
  "label": "Brazil Santos Port",
  "value": "Brazil Santos Port",
  "id": "3642",
  "lat": "-23.95877",
  "lng": "-46.32294"
}, {
  "label": "Harwich International Port",
  "value": "Harwich International Port",
  "id": "1008759",
  "lat": "51.937269",
  "lng": "1.281157"
}, {
  "label": "Mobile Alabama Cruise Terminal",
  "value": "Mobile Alabama Cruise Terminal",
  "id": "1008761",
  "lat": "30.686395",
  "lng": "-88.053239"
}, {
  "label": "Manhattan Cruise Terminal",
  "value": "Manhattan Cruise Terminal",
  "id": "1008762",
  "lat": "40.767683",
  "lng": "-73.996466"
}, {
  "label": "Brooklyn Cruise Terminal",
  "value": "Brooklyn Cruise Terminal",
  "id": "1008763",
  "lat": "40.68257",
  "lng": "-74.007939"
}, {
  "label": "Port of Charleston Cruise Terminal",
  "value": "Port of Charleston Cruise Terminal",
  "id": "1008764",
  "lat": "32.781021",
  "lng": "-79.92572"
}, {
  "label": "Hyannis Ferry Terminal",
  "value": "Hyannis Ferry Terminal",
  "id": "1008765",
  "lat": "41.652495",
  "lng": "-70.281659"
}, {
  "label": "Port of Los Angeles/Worldport",
  "value": "Port of Los Angeles/Worldport",
  "id": "1008766",
  "lat": "33.730876",
  "lng": "-118.269954"
}, {
  "label": "Black Falcon Cruise Terminal",
  "value": "Black Falcon Cruise Terminal",
  "id": "1008768",
  "lat": "42.34395",
  "lng": "-71.030739"
}, {
  "label": "Cape Liberty Cruise Terminal",
  "value": "Cape Liberty Cruise Terminal",
  "id": "1008770",
  "lat": "40.670495",
  "lng": "-74.106976"
}, {
  "label": "Port of Montreal",
  "value": "Port of Montreal",
  "id": "1008772",
  "lat": "45.490636",
  "lng": "-73.544328"
}, {
  "label": "Norfolk Cruise Terminal",
  "value": "Norfolk Cruise Terminal",
  "id": "1008773",
  "lat": "36.846825",
  "lng": "-76.285069"
}, {
  "label": "Philadelphia Cruise Terminal",
  "value": "Philadelphia Cruise Terminal",
  "id": "1008774",
  "lat": "39.888594",
  "lng": "-75.175839"
}, {
  "label": "Port of Quebec",
  "value": "Port of Quebec",
  "id": "1008775",
  "lat": "46.815879",
  "lng": "-71.201869"
}, {
  "label": "Port of Anchorage",
  "value": "Port of Anchorage",
  "id": "1008776",
  "lat": "60.12208",
  "lng": "-149.435659"
}, {
  "label": "Honolulu Cruise Terminal",
  "value": "Honolulu Cruise Terminal",
  "id": "1008777",
  "lat": "21.307399",
  "lng": "-157.865167"
}, {
  "label": "San Francisco Cruise Terminal",
  "value": "San Francisco Cruise Terminal",
  "id": "1008778",
  "lat": "37.803886",
  "lng": "-122.400984"
}, {
  "label": "Seattle Cruise Terminal",
  "value": "Seattle Cruise Terminal",
  "id": "1008779",
  "lat": "47.610886",
  "lng": "-122.348114"
}, {
  "label": "Vancouver Cruise Port",
  "value": "Vancouver Cruise Port",
  "id": "1008780",
  "lat": "49.288003",
  "lng": "-123.1149"
}, {
  "label": "Charleston Cruise Port",
  "value": "Charleston Cruise Port",
  "id": "1008781",
  "lat": "32.781021",
  "lng": "-79.925724"
}, {
  "label": "Port Everglades (FL)",
  "value": "Port Everglades (FL)",
  "id": "1008782",
  "lat": "26.121875",
  "lng": "-80.133314"
}, {
  "label": "Jacksonville Cruise Terminal",
  "value": "Jacksonville Cruise Terminal",
  "id": "1008783",
  "lat": "30.406214",
  "lng": "-81.576236"
}, {
  "label": "Miami Cruise Terminal",
  "value": "Miami Cruise Terminal",
  "id": "1008784",
  "lat": "25.778304",
  "lng": "-80.17769"
}, {
  "label": "Mobile Cruise Terminal",
  "value": "Mobile Cruise Terminal",
  "id": "1008785",
  "lat": "30.685746",
  "lng": "-88.038261"
}, {
  "label": "New Orleans Cruise Terminal",
  "value": "New Orleans Cruise Terminal",
  "id": "1008786",
  "lat": "29.934912",
  "lng": "-90.061714"
}, {
  "label": "Port Canaveral Cruise Terminal",
  "value": "Port Canaveral Cruise Terminal",
  "id": "1008787",
  "lat": "28.416878",
  "lng": "-80.630413"
}, {
  "label": "San Juan Cruise Port",
  "value": "San Juan Cruise Port",
  "id": "1008788",
  "lat": "18.46617",
  "lng": "-66.106654"
}, {
  "label": "Galveston Cruise Terminal",
  "value": "Galveston Cruise Terminal",
  "id": "1008790",
  "lat": "29.30836",
  "lng": "-94.793519"
}, {
  "label": "Long Beach Cruise Terminal",
  "value": "Long Beach Cruise Terminal",
  "id": "1008791",
  "lat": "33.756053",
  "lng": "-118.203147"
}, {
  "label": "Los Angeles Cruise Terminal",
  "value": "Los Angeles Cruise Terminal",
  "id": "1008792",
  "lat": "33.739987",
  "lng": "-118.281543"
}, {
  "label": "San Diego Cruise Port",
  "value": "San Diego Cruise Port",
  "id": "1008793",
  "lat": "32.717478",
  "lng": "-117.17326"
}, {
  "label": "Port of Athens",
  "value": "Port of Athens",
  "id": "1008795",
  "lat": "37.942711",
  "lng": "23.64736"
}, {
  "label": "Barcelona Cruise Port",
  "value": "Barcelona Cruise Port",
  "id": "1008796",
  "lat": "39.633121",
  "lng": "3.39875"
}, {
  "label": "Copenhagen Cruise Terminal",
  "value": "Copenhagen Cruise Terminal",
  "id": "1008797",
  "lat": "55.714344",
  "lng": "12.595926"
}, {
  "label": "Port of Dover",
  "value": "Port of Dover",
  "id": "1008798",
  "lat": "51.121035",
  "lng": "1.31356"
}, {
  "label": "Port of Istanbul",
  "value": "Port of Istanbul",
  "id": "1008799",
  "lat": "41.040855",
  "lng": "28.986183"
}, {
  "label": "Port of Lisbon",
  "value": "Port of Lisbon",
  "id": "1008800",
  "lat": "38.725679",
  "lng": "-9.150371"
}, {
  "label": "Port of Nice",
  "value": "Port of Nice",
  "id": "1008801",
  "lat": "52.883289",
  "lng": "-1.97685"
}, {
  "label": "Port of Oslo",
  "value": "Port of Oslo",
  "id": "1008802",
  "lat": "59.91228",
  "lng": "10.74998"
}, {
  "label": "Civitavecchia/Rome Cruise Port",
  "value": "Civitavecchia/Rome Cruise Port",
  "id": "1008803",
  "lat": "42.096082",
  "lng": "11.791606"
}, {
  "label": "Rotterdam Cruise Terminal",
  "value": "Rotterdam Cruise Terminal",
  "id": "1008804",
  "lat": "51.92289",
  "lng": "4.47842"
}, {
  "label": "Southampton Cruise Terminal",
  "value": "Southampton Cruise Terminal",
  "id": "1008805",
  "lat": "50.894958",
  "lng": "-1.39907"
}, {
  "label": "Port of Stockholm",
  "value": "Port of Stockholm",
  "id": "1008806",
  "lat": "59.029449",
  "lng": "17.55142"
}, {
  "label": "Venice Cruise Terminal",
  "value": "Venice Cruise Terminal",
  "id": "1008807",
  "lat": "39.991537",
  "lng": "18.412138"
}, {
  "label": "Port of Aabenraa",
  "value": "Port of Aabenraa",
  "id": "1008808",
  "lat": "55.044958",
  "lng": "9.421387"
}, {
  "label": "Port of Aarhus",
  "value": "Port of Aarhus",
  "id": "1008809",
  "lat": "56.156612",
  "lng": "10.21198"
}, {
  "label": "Port of Adelaide",
  "value": "Port of Adelaide",
  "id": "1008810",
  "lat": "-34.926472",
  "lng": "138.599915"
}, {
  "label": "Alanya Cruise Port",
  "value": "Alanya Cruise Port",
  "id": "1008811",
  "lat": "36.5556",
  "lng": "31.975575"
}, {
  "label": "Port of Albany Western Australia",
  "value": "Port of Albany Western Australia",
  "id": "1008812",
  "lat": "-35.030739",
  "lng": "117.895699"
}, {
  "label": "Port of Ålesund",
  "value": "Port of Ålesund",
  "id": "1008813",
  "lat": "62.475276",
  "lng": "6.154098"
}, {
  "label": "Port of Alabama",
  "value": "Port of Alabama",
  "id": "1008814",
  "lat": "30.686395",
  "lng": "-88.053239"
}, {
  "label": "Port of Albany",
  "value": "Port of Albany",
  "id": "1008815",
  "lat": "42.651455",
  "lng": "-73.755274"
}, {
  "label": "Port of Altamira",
  "value": "Port of Altamira",
  "id": "1008816",
  "lat": "22.38857",
  "lng": "-97.939994"
}, {
  "label": "Port of Amsterdam",
  "value": "Port of Amsterdam",
  "id": "1008817",
  "lat": "52.37312",
  "lng": "4.893195"
}, {
  "label": "Port of Anacortes",
  "value": "Port of Anacortes",
  "id": "1008818",
  "lat": "48.512625",
  "lng": "-122.613399"
}, {
  "label": "Port of Antwerp",
  "value": "Port of Antwerp",
  "id": "1008820",
  "lat": "51.221693",
  "lng": "4.397452"
}, {
  "label": "Port of Buenos Aires",
  "value": "Port of Buenos Aires",
  "id": "1008821",
  "lat": "-34.554539",
  "lng": "-58.469082"
}, {
  "label": "Port of Auckland",
  "value": "Port of Auckland",
  "id": "1008822",
  "lat": "-36.884109",
  "lng": "174.770416"
}, {
  "label": "Barbados Port Inc.",
  "value": "Barbados Port Inc.",
  "id": "1008824",
  "lat": "13.09209",
  "lng": "-59.611938"
}, {
  "label": "Port of Barcelona",
  "value": "Port of Barcelona",
  "id": "1008825",
  "lat": "41.385725",
  "lng": "2.17005"
}, {
  "label": "Port of Barranquilla",
  "value": "Port of Barranquilla",
  "id": "1008826",
  "lat": "10.94056",
  "lng": "-74.779739"
}, {
  "label": "Port of Baton Rouge",
  "value": "Port of Baton Rouge",
  "id": "1008827",
  "lat": "30.44342",
  "lng": "-91.186874"
}, {
  "label": "Port of Beaumont",
  "value": "Port of Beaumont",
  "id": "1008828",
  "lat": "30.07861",
  "lng": "-94.091827"
}, {
  "label": "Port of Belfast",
  "value": "Port of Belfast",
  "id": "1008829",
  "lat": "54.595805",
  "lng": "-5.934939"
}, {
  "label": "Port of Belgrade",
  "value": "Port of Belgrade",
  "id": "1008830",
  "lat": "44.812607",
  "lng": "20.464145"
}, {
  "label": "Port of Belledune",
  "value": "Port of Belledune",
  "id": "1008831",
  "lat": "47.861591",
  "lng": "-65.861517"
}, {
  "label": "Port of Bellingham",
  "value": "Port of Bellingham",
  "id": "1008832",
  "lat": "48.75235",
  "lng": "-122.471219"
}, {
  "label": "Port of Bolivar",
  "value": "Port of Bolivar",
  "id": "1008833",
  "lat": "3.2625",
  "lng": "80.003611"
}, {
  "label": "Port of Bordeaux",
  "value": "Port of Bordeaux",
  "id": "1008834",
  "lat": "44.836635",
  "lng": "-0.581039"
}, {
  "label": "Port of Bremen",
  "value": "Port of Bremen",
  "id": "1008836",
  "lat": "53.075161",
  "lng": "8.80466"
}, {
  "label": "Port of Bermerhaven",
  "value": "Port of Bermerhaven",
  "id": "1008837",
  "lat": "53.54429",
  "lng": "8.582082"
}, {
  "label": "Port of Brest",
  "value": "Port of Brest",
  "id": "1008838",
  "lat": "48.390238",
  "lng": "-4.486228"
}, {
  "label": "Port of Bridgeport",
  "value": "Port of Bridgeport",
  "id": "1008839",
  "lat": "41.18188",
  "lng": "-73.191269"
}, {
  "label": "Port of Brisbane",
  "value": "Port of Brisbane",
  "id": "1008840",
  "lat": "-27.38549",
  "lng": "153.172318"
}, {
  "label": "British Virgin Islands Ports Authority",
  "value": "British Virgin Islands Ports Authority",
  "id": "1008841",
  "lat": "18.4424",
  "lng": "-64.616592"
}, {
  "label": "Port of Brownsville",
  "value": "Port of Brownsville",
  "id": "1008842",
  "lat": "25.951136",
  "lng": "-97.41144"
}, {
  "label": "Port of Broome",
  "value": "Port of Broome",
  "id": "1008843",
  "lat": "-17.953079",
  "lng": "122.240517"
}, {
  "label": "Port of Buenaventura",
  "value": "Port of Buenaventura",
  "id": "1008844",
  "lat": "3.88369",
  "lng": "-77.040314"
}, {
  "label": "Port of Burnie",
  "value": "Port of Burnie",
  "id": "1008846",
  "lat": "-41.05265",
  "lng": "145.906357"
}, {
  "label": "Port of Bunbury",
  "value": "Port of Bunbury",
  "id": "1008847",
  "lat": "-33.321659",
  "lng": "115.636909"
}, {
  "label": "Port of Cairns",
  "value": "Port of Cairns",
  "id": "1008848",
  "lat": "-16.92646",
  "lng": "145.77597"
}, {
  "label": "Port of Cartagena",
  "value": "Port of Cartagena",
  "id": "1008849",
  "lat": "10.38706",
  "lng": "-75.505699"
}, {
  "label": "Port of Charleston",
  "value": "Port of Charleston",
  "id": "1008850",
  "lat": "32.781155",
  "lng": "-79.931599"
}, {
  "label": "Port of Coatzacoalcos",
  "value": "Port of Coatzacoalcos",
  "id": "1008851",
  "lat": "18.137945",
  "lng": "-94.436514"
}, {
  "label": "Port of Coos Bay",
  "value": "Port of Coos Bay",
  "id": "1008852",
  "lat": "43.367955",
  "lng": "-124.219519"
}, {
  "label": "Port of Corpus Christi",
  "value": "Port of Corpus Christi",
  "id": "1008853",
  "lat": "27.79635",
  "lng": "-97.403564"
}, {
  "label": "Port of Curacao",
  "value": "Port of Curacao",
  "id": "1008854",
  "lat": "52.108089",
  "lng": "5.33033"
}, {
  "label": "Port of Cyprus",
  "value": "Port of Cyprus",
  "id": "1008855",
  "lat": "34.920021",
  "lng": "33.63821"
}, {
  "label": "Port of Darwin",
  "value": "Port of Darwin",
  "id": "1008856",
  "lat": "-12.46395",
  "lng": "130.845474"
}, {
  "label": "Port of Delaware",
  "value": "Port of Delaware",
  "id": "1008857",
  "lat": "39.945255",
  "lng": "-75.119129"
}, {
  "label": "Port of Devonport",
  "value": "Port of Devonport",
  "id": "1008858",
  "lat": "-41.179859",
  "lng": "146.348587"
}, {
  "label": "Port of Detroit",
  "value": "Port of Detroit",
  "id": "1008859",
  "lat": "42.352217",
  "lng": "-82.993918"
}, {
  "label": "Port of Duluth",
  "value": "Port of Duluth",
  "id": "1008860",
  "lat": "46.755202",
  "lng": "-92.100516"
}, {
  "label": "Port of Esmeraldas",
  "value": "Port of Esmeraldas",
  "id": "1008861",
  "lat": "0.95423",
  "lng": "-79.660179"
}, {
  "label": "Port of Guayaquil",
  "value": "Port of Guayaquil",
  "id": "1008862",
  "lat": "-2.20382",
  "lng": "-79.897461"
}, {
  "label": "Port of Manta",
  "value": "Port of Manta",
  "id": "1008863",
  "lat": "-0.94719",
  "lng": "-80.720833"
}, {
  "label": "Port of Puerto Bolivar",
  "value": "Port of Puerto Bolivar",
  "id": "1008864",
  "lat": "-3.2667",
  "lng": "-79.983299"
}, {
  "label": "Port of Eden",
  "value": "Port of Eden",
  "id": "1008865",
  "lat": "-1.62768",
  "lng": "-76.227791"
}, {
  "label": "Port of Ensenada",
  "value": "Port of Ensenada",
  "id": "1008866",
  "lat": "31.86546",
  "lng": "-116.593394"
}, {
  "label": "Port of Everett",
  "value": "Port of Everett",
  "id": "1008867",
  "lat": "47.976575",
  "lng": "-122.206419"
}, {
  "label": "Port of Felixstowe",
  "value": "Port of Felixstowe",
  "id": "1008868",
  "lat": "51.946339",
  "lng": "1.32261"
}, {
  "label": "Port of Ft. Lauderdale",
  "value": "Port of Ft. Lauderdale",
  "id": "1008869",
  "lat": "26.096769",
  "lng": "-80.126472"
}, {
  "label": "Fraser River Port",
  "value": "Fraser River Port",
  "id": "1008870",
  "lat": "60.800615",
  "lng": "-115.797234"
}, {
  "label": "Port of Freeport",
  "value": "Port of Freeport",
  "id": "1008872",
  "lat": "28.950945",
  "lng": "-95.347584"
}, {
  "label": "Port of Galveston",
  "value": "Port of Galveston",
  "id": "1008873",
  "lat": "28.950945",
  "lng": "-95.347584"
}, {
  "label": "Port of Gdansk",
  "value": "Port of Gdansk",
  "id": "1008874",
  "lat": "54.352766",
  "lng": "18.650052"
}, {
  "label": "Port of Geelong",
  "value": "Port of Geelong",
  "id": "1008875",
  "lat": "-38.148769",
  "lng": "144.360046"
}, {
  "label": "Port of Savannah",
  "value": "Port of Savannah",
  "id": "1008876",
  "lat": "32.08078",
  "lng": "-81.090719"
}, {
  "label": "Port of Brunswick",
  "value": "Port of Brunswick",
  "id": "1008877",
  "lat": "31.149765",
  "lng": "-81.495564"
}, {
  "label": "Port of Genova",
  "value": "Port of Genova",
  "id": "1008878",
  "lat": "44.410339",
  "lng": "8.9389"
}, {
  "label": "Port of Geraldton",
  "value": "Port of Geraldton",
  "id": "1008879",
  "lat": "-28.7763",
  "lng": "114.626068"
}, {
  "label": "Port of Gibraltar",
  "value": "Port of Gibraltar",
  "id": "1008880",
  "lat": "36.15787",
  "lng": "-5.361727"
}, {
  "label": "Port of Gladstone",
  "value": "Port of Gladstone",
  "id": "1008881",
  "lat": "-23.843821",
  "lng": "151.253555"
}, {
  "label": "Port of Goteburg",
  "value": "Port of Goteburg",
  "id": "1008882",
  "lat": "57.701328",
  "lng": "11.96689"
}, {
  "label": "Port of Grays Harbor",
  "value": "Port of Grays Harbor",
  "id": "1008883",
  "lat": "46.933333",
  "lng": "123.85"
}, {
  "label": "Port of Guam",
  "value": "Port of Guam",
  "id": "1008884",
  "lat": "13.4646",
  "lng": "144.695007"
}, {
  "label": "Port of Guanta",
  "value": "Port of Guanta",
  "id": "1008885",
  "lat": "10.23094",
  "lng": "-64.603653"
}, {
  "label": "Port of Gulfport",
  "value": "Port of Gulfport",
  "id": "1008886",
  "lat": "30.38233",
  "lng": "-89.090259"
}, {
  "label": "Port of Haifa",
  "value": "Port of Haifa",
  "id": "1008887",
  "lat": "32.818635",
  "lng": "34.987725"
}, {
  "label": "Port of Halifax",
  "value": "Port of Halifax",
  "id": "1008888",
  "lat": "44.64616",
  "lng": "-63.573919"
}, {
  "label": "Port of Hamburg",
  "value": "Port of Hamburg",
  "id": "1008889",
  "lat": "53.553341",
  "lng": "9.99244"
}, {
  "label": "Port of Hamilton",
  "value": "Port of Hamilton",
  "id": "1008890",
  "lat": "43.26099",
  "lng": "-79.888454"
}, {
  "label": "Port of Helsingborg",
  "value": "Port of Helsingborg",
  "id": "1008891",
  "lat": "56.043919",
  "lng": "12.69587"
}, {
  "label": "Port of Hobart",
  "value": "Port of Hobart",
  "id": "1008892",
  "lat": "-42.88163",
  "lng": "147.331589"
}, {
  "label": "Port of Houston",
  "value": "Port of Houston",
  "id": "1008893",
  "lat": "29.732348",
  "lng": "-95.267197"
}, {
  "label": "Port of Hueneme",
  "value": "Port of Hueneme",
  "id": "1008894",
  "lat": "34.1497",
  "lng": "-119.195584"
}, {
  "label": "Port of Humboldt Bay",
  "value": "Port of Humboldt Bay",
  "id": "1008895",
  "lat": "40.809822",
  "lng": "-124.159083"
}, {
  "label": "Port of Iberia",
  "value": "Port of Iberia",
  "id": "1008896",
  "lat": "30.005135",
  "lng": "-91.817594"
}, {
  "label": "Port of Burns Harbor",
  "value": "Port of Burns Harbor",
  "id": "1008897",
  "lat": "41.621852",
  "lng": "-87.157747"
}, {
  "label": "Port of Mount Vernon",
  "value": "Port of Mount Vernon",
  "id": "1008898",
  "lat": "37.925336",
  "lng": "-87.87069"
}, {
  "label": "Port of Jeffersonville",
  "value": "Port of Jeffersonville",
  "id": "1008899",
  "lat": "38.314799",
  "lng": "-85.671829"
}, {
  "label": "Port of Alghero",
  "value": "Port of Alghero",
  "id": "1008900",
  "lat": "40.57825",
  "lng": "8.3173"
}, {
  "label": "Port of Ancona",
  "value": "Port of Ancona",
  "id": "1008901",
  "lat": "43.620505",
  "lng": "13.508383"
}, {
  "label": "Port of Anzio",
  "value": "Port of Anzio",
  "id": "1008902",
  "lat": "41.4506",
  "lng": "12.62445"
}, {
  "label": "Port of Arbatax",
  "value": "Port of Arbatax",
  "id": "1008903",
  "lat": "39.938709",
  "lng": "9.68976"
}, {
  "label": "Port of Augusta",
  "value": "Port of Augusta",
  "id": "1008904",
  "lat": "37.22187",
  "lng": "15.219703"
}, {
  "label": "Port of Avola",
  "value": "Port of Avola",
  "id": "1008905",
  "lat": "37.076922",
  "lng": "15.279578"
}, {
  "label": "Port of Bagnoli/Naples",
  "value": "Port of Bagnoli/Naples",
  "id": "1008906",
  "lat": "40.839901",
  "lng": "14.251852"
}, {
  "label": "Port of Livorno",
  "value": "Port of Livorno",
  "id": "1008955",
  "lat": "43.556128",
  "lng": "10.303897"
}, {
  "label": "Sydney Overseas Passenger Terminal",
  "value": "Sydney Overseas Passenger Terminal",
  "id": "1008958",
  "lat": "-33.857746",
  "lng": "151.20972"
}, {
  "label": "Port of Dubrovnik",
  "value": "Port of Dubrovnik",
  "id": "1008959",
  "lat": "42.66",
  "lng": "18.085"
}, {
  "label": "Port of Manhattan",
  "value": "Port of Manhattan",
  "id": "1008972",
  "lat": "40.767683",
  "lng": "-73.996466"
}, {
  "label": "Portsmouth Harbour",
  "value": "Portsmouth Harbour",
  "id": "1008975",
  "lat": "50.824691",
  "lng": "-1.117449"
}, {
  "label": "Pier 31",
  "value": "Pier 31",
  "id": "1009018",
  "lat": "37.7793",
  "lng": "-122.4192"
}, {
  "label": "Dublin Port Ireland",
  "value": "Dublin Port Ireland",
  "id": "1009026",
  "lat": "53.34609",
  "lng": "-6.20831"
}, {
  "label": "Navy Pier - Chicago",
  "value": "Navy Pier - Chicago",
  "id": "1009033",
  "lat": "41.891389",
  "lng": "-87.599722"
}, {
  "label": "Port of Cleveland",
  "value": "Port of Cleveland",
  "id": "1009062",
  "lat": "41.519444",
  "lng": "-81.688611"
}, {
  "label": "Fremantle Passenger Terminal",
  "value": "Fremantle Passenger Terminal",
  "id": "1009063",
  "lat": "-32.05",
  "lng": "115.733333"
}, {
  "label": "Portofino",
  "value": "Portofino",
  "id": "1009064",
  "lat": "44.303889",
  "lng": "9.207778"
}, {
  "label": "Portovenere",
  "value": "Portovenere",
  "id": "1009065",
  "lat": "44.05",
  "lng": "9.833333"
}, {
  "label": "La Spezia",
  "value": "La Spezia",
  "id": "1009066",
  "lat": "44.1",
  "lng": "9.816667"
}, {
  "label": "Pier 66",
  "value": "Pier 66",
  "id": "1009067",
  "lat": "40.7525",
  "lng": "-74.009167"
}, {
  "label": "Cape Liberty Cruise Port- Bayonne NJ",
  "value": "Cape Liberty Cruise Port- Bayonne NJ",
  "id": "1009084",
  "lat": "40.664644",
  "lng": "-74.073947"
}, {
  "label": "Taipa Ferry Terminal Macau",
  "value": "Taipa Ferry Terminal Macau",
  "id": "1009102",
  "lat": "22.197417",
  "lng": "113.558972"
}, {
  "label": "Port of Whittier",
  "value": "Port of Whittier",
  "id": "1009108",
  "lat": "60.776667",
  "lng": "-148.686667"
}, {
  "label": "Port of Seward",
  "value": "Port of Seward",
  "id": "1009109",
  "lat": "60.118611",
  "lng": "-149.4325"
}, {
  "label": "Singapore Cruise Centre",
  "value": "Singapore Cruise Centre",
  "id": "1009118",
  "lat": "1.26436",
  "lng": "103.820239"
}, {
  "label": "Marina Bay Cruise Centre",
  "value": "Marina Bay Cruise Centre",
  "id": "1009119",
  "lat": "1.266789",
  "lng": "103.860063"
}];