import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../routes/routes";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./ThankYouRequestForm.scss";

const IndexPage = (props) => {
  let history = useHistory();

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML =
      "gtag('event', 'conversion', {'send_to' : 'AW-1072543120/TkRsCI6qv-oCEJDrtv8D'});";
    document.body.appendChild(script);
  });
  const navigateToHome = () => {
    history.push(routePaths.root);
  };
  return (
    <>
      <div className="thank-you-container">
        <div>
          <img
            className="green-tick"
            src="https://rptestsf.com/limos-assets/images/green-tick.png"
            alt=""
          />
        </div>
        <div>
          <img
            className="limos-black-logo"
            src="https://rptestsf.com/limos-assets/images/black-logo.png"
            alt=""
          />
        </div>
        <div>
          <p>
            We’ve got your information and will be in touch! If you have any
            questions in the meantime,
            <br /> you can always email us at booking@limos.com or call us
            anytime at 877-326-5390
          </p>
        </div>
        <div className="call-btn">
          <button onClick={navigateToHome}>Go to Home Page</button>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
