import React from "react";

import "./ServicesLanding.scss";

const ServicesLanding = () => (
  <div className="landing-page">
    <div className="landing-header">
      <div className="landing-inner">
        <h1>Our Services</h1>
        <div className="divider"></div>
        <img
          alt="A row of vehicles."
          src="https://assets.s3.amazonaws.com/images/front-end/Vehicles.png"
        />
        <h2>
          Vehicle groups available: Sedan, SUV, Stretch Limo, Stretch SUV, Vans,
          Shuttles, Coaches
        </h2>
      </div>
    </div>

    <div className="landing-section flex">
      <img
        alt="Person sitting in back of car reading paper."
        src="https://assets.s3.amazonaws.com/images/front-end/Airport Transfer.jpg"
      />
      <div>
        <h2>Multi-Vehicle Services</h2>
        <p>
          Call/Live chat booking only, hire multiple vehicles for any occasion.
          We have a fleet of luxury vehicles ready to serve you. Contact us with
          your details and we can help you plan all your transportation needs
          and various types of events.
        </p>
        <h2>Multi-Day Services</h2>
        <p>
          Hire Vehicles for multiple days as needed and use multiple different
          vehicle types to assist you in providing transportation. We can work
          together to plan all your transportation needs and strategies on
          optimal transportation management for your occasion.{" "}
        </p>
      </div>
    </div>

    <div className="landing-section flex reverse">
      <img
        alt="An auditorium full of people looking at a stage."
        src="https://assets.s3.amazonaws.com/images/front-end/Corporate Event.jpg"
      />
      <div>
        <h2>Corporate Events</h2>
        <p>
          Limos.com specializes in providing end-to-end global transportation
          management for corporate meetings and events. We’ll take care of
          selecting and booking the most cost effective, professional and
          reliable vendors to fulfill all your corporate transportation needs.
        </p>
        <p>
          Full Service Ground Transportation Solution Includes:
          <ul>
            <li>
              Pre-event consultation to ensure we book the perfect
              transportation for your next event
            </li>
            <li>Airport arrival and departure service</li>
            <li>Full service greet staff</li>
            <li>24/7/365 customer care support</li>
            <li>Local shuttle transportation</li>
            <li>
              Hourly as directed charters for holiday parties and special events
            </li>
          </ul>
        </p>
      </div>
    </div>

    <div className="landing-section">
      <div>
        <h2>Hourly as Directed</h2>
        <p>
          We offer a unique service for travelers with several stops to make and
          need a driver to wait on location for them. This is a specialized
          service that allows you to have a driver at your fingertips to
          complete any of your transportation needs.
        </p>
      </div>
    </div>

    <div className="landing-section flex">
      <img
        alt="A person leaving back of car with suitcase."
        src="https://assets.s3.amazonaws.com/images/front-end/Point to point.jpg"
      />
      <div>
        <h2>To & From Airport</h2>
        <p>
          Transportation to & from airports, round trips available. Affiliates
          track flights when picking up from the airport
        </p>
        <p>
          Limos.com offers the widest variety of airport limo and car services.
          So don't schlep your luggage around in a taxi or shared shuttle when
          you can book any of our airport transportation services for close to
          the same cost. From executive limos and town cars to shuttle buses and
          airport vans, starting and ending your trip in style is easy, and
          surprisingly affordable. Happy travels!
        </p>
      </div>
    </div>

    <div className="landing-section">
      <div>
        <h2>Point to Point</h2>
        <p>
          One-Way service from one location to another, round trips available
          with this option as well. If you have transportation needs that are
          not described in the options on this page, then this one is for you.
          We can customize a pickup and drop off that is specific to your
          transportation needs. Just give us the details and we can optimize
          your journey for you!
        </p>
        <h2>Just Drive (Hourly)</h2>
        <p>
          with this hourly service, you just provide an itinerary and we drive
          you to where you need to go for however many hours you need
          transportation.. This hourly service can be used how you want it
          curated., so call us today to find out how we can customize your
          transportation needs.
        </p>
      </div>
    </div>

    <div className="landing-section">
      <div className="flex">
        <img
          alt="People in a group dancing."
          src="https://assets.s3.amazonaws.com/images/front-end/Prom.jpg"
        />
        <div>
          <h2>Prom/School Event</h2>
          <p>
            Hourly service to & from prom events. Appearances are everything
            when arriving to the big dance. Riding in a classy limo will get you
            style points.{" "}
          </p>
          <p>ARRIVE AT THE PROM WITH STYLE POINTS AWARDED</p>
          <p>
            For high school students, many consider prom to be the greatest
            night of their lives, a coming of age into adulthood. As reputations
            and appearances are everything, there is no greater feeling than
            arriving like the night’s main attraction. Trust Limos.com to help
            your young ladies and gentlemen arrive safely in style while getting
            the best value for your money.
          </p>
        </div>
      </div>
      <div style={{ paddingTop: 0 }}>
        <p>
          Combining elegance and fun with safety and peace of mind for parents,
          our network of nationwide service providers and luxury vehicles are
          rigorously screened to ensure perfection for the big night, including
          proper levels of insurance and state vehicle registration
        </p>
        <p>
          When combing through our database of luxury transportation providers,
          always ensure that you are checking prices of comparable vehicles and
          services. Some providers offer hourly rates while others offer
          all-inclusive packages for a set number of hours, which can be a great
          option. If you select the hourly route, typically a three-hour
          minimum, pay attention to surcharges and other fees not included in
          the base price. Fortunately, Limos.com service partners always provide
          the full price in our quotes upfront without hidden fees or other
          unwanted surprises.
        </p>
      </div>
    </div>

    <div className="landing-section">
      <div className="flex reverse">
        <img
          alt="A person in the back of a sedan holding flowers."
          src="https://assets.s3.amazonaws.com/images/front-end/Wedding.jpg"
        />
        <div>
          <h2>Wedding</h2>
          <p>
            Hourly service for transportation for weddings or any
            wedding-centered events
          </p>
          <p>
            The big day is set. The wedding venue and reception are secured. Now
            is the time to find your trustworthy chariot. From the bachelor and
            bachelorette outings, attendee transportation to the chapel or
            reception and that magical journey toward the honeymoon, trust
            Limos.com for a sophisticated experience.
          </p>
        </div>
      </div>
      <div style={{ paddingTop: 0 }}>
        <p>
          For even the most regal of events across all 50 states, our service
          providers and luxury vehicles are rigorously screened to ensure
          perfection on your big day. On request, many chauffeurs provide a red
          carpet and champagne for an added touch of class.
        </p>
        <p>
          Whether it be an intimate gathering or a few hundred attendees,
          Limos.com can coordinate multiple vehicles to get the couple and
          anyone else celebrating with you where they need to be, hassle free
          and on time, leaving you with the peace of mind to focus on embracing
          the main event.
        </p>
      </div>
    </div>

    <div className="landing-section">
      <div className="flex">
        <img
          alt="Three people celebrating with beer."
          src="https://assets.s3.amazonaws.com/images/front-end/Birthday.jpg"
        />
        <div>
          <h2>Birthday/Anniversary</h2>
          <p>
            Hourly Service for any age birthday parties & anniversaries. On your
            special day, keep your mind focused on the adventures ahead and
            leave the driving to us, to and from every hot-spot in town.
          </p>
          <p>BIRTHDAY CELEBRATIONS – RIDE IN STYLE</p>
        </div>
      </div>
      <div style={{ paddingTop: 0 }}>
        <p>
          Birthdays only come once a year, and on that special day, arranging a
          limo for your entourage ensures fun times together without anyone
          having to refrain from libations. Trust Limos.com to arrive at your
          party in style and leave the safe travel with us. No matter how big or
          small your group may be, Limos.com has the right vehicles that will
          have you riding in style. Offering a wide selection around the globe
          of exotic and specialty vehicles, including luxury sedans, classic
          town cars, stretch limousine or even a party bus, we have the right
          vehicle to get your party started on the road.
        </p>
        <p>
          Combining elegance and fun with safety and peace of mind, our network
          of nationwide service providers and luxury vehicles are rigorously
          screened to ensure perfection for the big day, including commercial
          levels of insurance and state and federal vehicle registration.
        </p>
      </div>
    </div>

    <div className="landing-section">
      <div>
        <h2>Night on the Town</h2>
        <p>
          Hourly service for multi-stop trips on the town. For your
          bachelor/bachelorette or girls night out, have your entourage arriving
          to the club in red carpet style.
        </p>
        <p>NIGHT OUT – LEAVE THE DRIVING TO A PRO</p>
        <p>
          We’ve all been there…Long hours at the office, shuttling the kids to
          and from everywhere and a thousand errands in between. For that needed
          break from the daily grind, gather your entourage together for a fun
          night out with Limos.com. Offering a wide selection, around the globe,
          of exotic and specialty vehicles, including luxury sedans, classic
          town cars, stretch limousine or even a party bus, we have the right
          vehicle to make your night out, one to remember.
        </p>
        <p>
          Combining elegance and fun with safety and peace of mind, our network
          of nationwide service providers and luxury vehicles are rigorously
          screened to ensure perfection for that big night out, including
          professionally trained chauffeurs and commercial levels of insurance,
          meeting state and federal vehicle registration.
        </p>
      </div>
    </div>

    <div className="landing-section flex reverse">
      <img
        alt="A view of concertgoers facing the stage."
        src="https://assets.s3.amazonaws.com/images/front-end/Festival or Concert.jpg"
      />
      <div>
        <h2>Concert/Sporting Event</h2>
        <p>
          Hourly Service to & from concerts & sporting events. Grab your friends
          and get to the event in style! We offer multiple different vehicle
          types for all occasion, so call us today to customize your event
          transportation.
        </p>
        <p>Ride like an MVP to the game, scoring big points with your group.</p>
        <p>
          For your night of rock, roll into the venue like the star attraction.
        </p>
      </div>
    </div>

    <div className="landing-section">
      <div>
        <h2>Bachelor/Bachelorette Party</h2>
        <p>
          Hourly Service for bachelor(ette) parties. We have what you need to
          have an incredible night out with your bridal party. We are here to
          help make this night/multiple days come alive with our vehicles that
          are equipped with lighting, high quality sound systems and ice trays
          for that great time out on the town celebrating your love.
        </p>
      </div>
    </div>

    <div className="landing-section">
      <div>
        <h2>To & From Cruiseport</h2>
        <p>Transportation to & from cruise ports, round trips available. </p>
        <p>
          All aboard the world's largest and most trusted online marketplace for
          limousine and car service to cruise terminals. Limos.com offers the
          widest variety of private car vehicles without the sea sickening
          price. Compare prices, vehicles and customer reviews for all our
          cruise port limo, sedan and town car service providers. Bon voyage!
        </p>
      </div>
    </div>

    <div className="landing-section flex">
      <img
        alt="Two people drinking and smelling wine at a table."
        src="https://assets.s3.amazonaws.com/images/front-end/Wine Tour.jpg"
      />
      <div>
        <h2>Wine/Private Tour</h2>
        <p>
          Hourly service for multi-vineyard wine tours or city sight-seeing
          tours. Ride in the lap of luxury for your high society vineyard trip
          with transportation worthy of even the greatest connoisseurs.
        </p>
      </div>
    </div>

    <div className="landing-section">
      <div>
        <h2>Funeral</h2>
        <p>
          Hourly service for funeral service transportation. We can provide you
          with a fleet of vehicles or just one, contact us to see how we can
          help you with your funeral transportation arrangements. This is
          something that we are experienced with, so you don’t have to worry
          about this on this significant day.
        </p>
      </div>
    </div>
  </div>
);

export default ServicesLanding;
