import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const INIT_MAP = {
  "LIMOS-userId": uuidv4(),
  "LIMOS-funnelExitPromoUsed": false,
  "LIMOS-show-cookie-policy-bar": false,
  "LIMOS-allow-tracking": false,
};

export default function useLocalStorage(key) {
  const init = INIT_MAP[key];
  const [storedValue, setStoredValue] = useState(() => {
    try {
      let item;
      if (typeof window !== "undefined") {
        item = JSON.parse(window?.localStorage.getItem(key));
      }
      return item || init;
    } catch (error) {
      console.log(error);
      return init;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== "undefined") {
        window?.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}
