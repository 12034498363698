import React, { Fragment } from "react";
import "./PickerGrid.scss";

const segmentArray = (arr, size) => {
  return arr.reduce((acc, curr, i) => {
    if (!(i % size)) {
      // if index is 0 or can be divided by the `size`...
      acc.push(arr.slice(i, i + size)); // ..push a chunk of the original array to the accumulator
    }
    return acc;
  }, []);
};

export default class PickerGrid extends React.Component {
  render() {
    const children = React.Children.toArray(this.props.children);
    return (
      <div
        role="group"
        className={"grid " + (this.props.className ? this.props.className : "")}
      >
        {segmentArray(children, 4).map((arr, idx) => (
          <div key={idx} className="grid-row btn-group btn-group-toggle">
            {arr.map((child, idx) => (
              <Fragment key={idx}>
                {React.cloneElement(child, {
                  groupName: this.props.name,
                  onChange: this.props.onChange,
                  checkedValue: this.props.defaultValue,
                })}
              </Fragment>
            ))}
          </div>
        ))}
      </div>
    );
  }
}
