import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { fullHumanDateTimeZone } from "../../utils/date-time";
import "./MiniTripInfoComponent.scss";

const MiniTripInfoComponent = ({
  pickupPlace,
  dropoffPlace,
  completeDate,
  returnDate,
  showDate,
  showTrip,
}) => {
  const pickupName =
    pickupPlace.display.startsWith(pickupPlace.name) ||
    !pickupPlace?.name?.length ? (
      <></>
    ) : (
      <>
        <b>{pickupPlace.name}</b>,{" "}
      </>
    );
  const dropoffName =
    dropoffPlace?.display?.startsWith(dropoffPlace?.name) ||
    !dropoffPlace?.name?.length ? (
      <></>
    ) : (
      <>
        <b>{dropoffPlace?.name}</b>,{" "}
      </>
    );
  return (
    <>
      {showDate ? (
        <Row>
          <Col xs={12} className={"searchResultDate"}>
            Trip Date: {returnDate ? "Departure Trip: " : ""}
            {fullHumanDateTimeZone(completeDate)}
            {returnDate ? (
              <>
                <br />
                Return Trip: {fullHumanDateTimeZone(returnDate)}
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {showTrip ? (
        <div className={"miniTripInfo"}>
          <Row>
            <Col xs={12} className={"miniAddress miniPickupAddress"}>
              <img
                alt="pickup map marker"
                className={"pickupMapMarker mapMarker"}
                src={
                  "https://assets.s3.amazonaws.com/images/front-end/pickupLocationMarker.png"
                }
              />
              {pickupName}
              {pickupPlace.display}
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={"miniAddress miniDropOffAddress"}>
              {dropoffName}
              {dropoffPlace?.display}
              <img
                alt="dropoff map marker"
                className={"dropoffMapMarker mapMarker"}
                src={
                  "https://assets.s3.amazonaws.com/images/front-end/dropoffLocationMarker.png"
                }
              />
            </Col>
          </Row>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MiniTripInfoComponent;
