import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import PickerGrid from "../PickerGrid/PickerGrid.jsx";
import PickerGridButton from "../PickerGrid/PickerGridButton.jsx";
import { withWhiteLabelContext } from "react-whitelabel";

class SearchRoundTripPicker extends React.Component {
  constructor(props) {
    super(props);
    if (typeof document !== "undefined") {
      document.title = this.props.label.title + " - Select Round Trip";
    }
  }

  render() {
    return (
      <div className="party-size-group">
        <div
          style={{ marginBottom: 16, color: "white" }}
          className="col-xs-12 form-label-limos"
        >
          <FontAwesomeIcon icon="users" />{" "}
          <Form.Label> Need a return ride? </Form.Label>
        </div>
        <PickerGrid
          className="round-trip-picker"
          name="isRoundTrip"
          segmentSize={4}
          onChange={this.props.setRoundTrip}
          defaultValue={
            typeof this.props.isRoundTrip !== "undefined"
              ? this.props.isRoundTrip
              : undefined
          }
        >
          <PickerGridButton value={true}>YES</PickerGridButton>
          <PickerGridButton value={false}>NO</PickerGridButton>
        </PickerGrid>
      </div>
    );
  }
}

export default withWhiteLabelContext(SearchRoundTripPicker);
