import React from "react"
import { BrowserRouter, Link, useLocation } from "react-router-dom"
import { routePaths } from "../../routes/routes"
import "./GoogleMap.scss"

export default class GoogleMap extends React.Component {
  render() {
    return (
      <div className="google-map-wrapper">
        <iframe
          loading="lazy"
          allowfullscreen
          referrerpolicy="no-referrer-when-downgrade"
          style={{ width: "100%", height: "300px" }}
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBoV70Uz0-1wgcUrdDCDDmoMtc9gP4Iz_E
              &q=pier 50, san francisco 94158"
        ></iframe>
      </div>
      //   <p>Google Map</p>
    )
  }
}
