import React from "react";
import "react-day-picker/lib/style.css";
import SearchHourPicker from "../SearchHourPicker/SearchHourPicker.jsx";
import SearchDayPicker from "../SearchDayPicker/SearchDayPicker.jsx";
import SearchMinutePicker from "../SearchMinutePicker/SearchMinutePicker.jsx";
import SearchRoundTripPicker from "../SearchRoundTripPicker/SearchRoundTripPicker.jsx";
import SearchIsHourlyPicker from "../SearchIsHourlyPicker/SearchIsHourlyPicker.jsx";
import SearchTripTypePicker from "../SearchTripTypePicker/SearchTripTypePicker.jsx";
import { Redirect, Route, Switch } from "react-router-dom";
import DurationPicker from "../DurationPicker/DurationPicker.jsx";
import { withRouter } from "react-router";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { routePaths } from "../../routes/routes";

class TripInfoWizard extends React.Component {
  state = {
    redirect: false,
    fromCarnival: false,
  };

  componentDidMount() {
    if (this.props.history.location.state.fromCarnival) {
      this.setState({ fromCarnival: true });
    }
  }

  setTripWizardValue = (key, value) => {
    this.props.setTripWizardValue(key, value, () => {
      this.setState({
        redirect: this.getNextUrl(this.props.location.pathname),
      });
    });
  };

  makeTripWizardFunction = (key) => {
    return (value) => this.setTripWizardValue(key, value);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.redirect !== this.state.redirect) {
      this.setState({ redirect: false });
    }
  }

  getNextUrl = (currentUrl) => {
    switch (currentUrl) {
      case routePaths.tripWizard.date:
        return routePaths.tripWizard.hour;
      case routePaths.tripWizard.hour:
        return routePaths.tripWizard.minute;
      case routePaths.tripWizard.minute:
        return routePaths.tripWizard.tripIsHourly;
      case routePaths.tripWizard.tripIsHourly:
        if (this.props.tripWizardValues.isHourly) {
          return routePaths.tripWizard.duration;
        } else {
          return routePaths.tripWizard.roundTrip;
        }
      case routePaths.tripWizard.duration:
        return routePaths.tripWizard.tripType;
      case routePaths.tripWizard.tripType:
        return this.state.fromCarnival ? routePaths.carnival : routePaths.root;
      case routePaths.tripWizard.roundTrip:
        if (this.props.tripWizardValues.isRoundTrip) {
          return routePaths.tripWizard.returnDate;
        } else {
          return this.state.fromCarnival
            ? routePaths.carnival
            : routePaths.root;
        }
      case routePaths.tripWizard.returnDate:
        return routePaths.tripWizard.returnHour;
      case routePaths.tripWizard.returnHour:
        return routePaths.tripWizard.returnMinute;
      case routePaths.tripWizard.returnMinute:
        return this.state.fromCarnival ? routePaths.carnival : routePaths.root;
      default:
        return this.state.fromCarnival ? routePaths.carnival : routePaths.root;
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    } else if (
      !this.props.tripWizardValues.date &&
      this.props.location.pathname !== routePaths.tripWizard.date
    ) {
      return <Redirect to={routePaths.tripWizard.date} />;
    } else {
      const returnHourDate =
        this.props.completeDate && this.props.returnDate
          ? this.props.completeDate.toLocaleString() ===
            this.props.returnDate.toLocaleString()
            ? this.props.completeDate
            : this.props.returnDate
          : new Date();
      return (
        <Container style={{ marginTop: 124, padding: "0 32px" }}>
          <Row>
            <Col
              xs={12}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
            >
              <Switch>
                <Route path={routePaths.tripWizard.date}>
                  <SearchDayPicker
                    setDay={this.makeTripWizardFunction("date")}
                    clearTripWizard={this.props.clearTripWizard}
                    startDay={new Date()}
                  />
                </Route>
                <Route path={routePaths.tripWizard.hour}>
                  <SearchHourPicker
                    setHour={this.makeTripWizardFunction("hour")}
                    date={this.props.completeDate}
                    selectedHour={this.props.tripWizardValues.hour}
                  />
                </Route>
                <Route path={routePaths.tripWizard.minute}>
                  <SearchMinutePicker
                    setMinute={this.makeTripWizardFunction("minute")}
                    date={this.props.completeDate}
                    selectedHour={this.props.tripWizardValues.hour}
                    selectedMinute={this.props.tripWizardValues.minute}
                  />
                </Route>
                <Route path={routePaths.tripWizard.tripIsHourly}>
                  <SearchIsHourlyPicker
                    setTripType={this.makeTripWizardFunction("isHourly")}
                    date={this.props.completeDate}
                    isHourly={this.props.tripWizardValues.isHourly}
                  />
                </Route>
                <Route path={routePaths.tripWizard.duration}>
                  <DurationPicker
                    setDuration={this.makeTripWizardFunction("durationInHours")}
                    date={this.props.completeDate}
                    durationInHours={
                      this.props.tripWizardValues.durationInHours
                    }
                  />
                </Route>
                <Route path={routePaths.tripWizard.roundTrip}>
                  <SearchRoundTripPicker
                    setRoundTrip={this.makeTripWizardFunction("isRoundTrip")}
                    date={this.props.completeDate}
                    isRoundTrip={this.props.tripWizardValues.isRoundTrip}
                  />
                </Route>
                <Route path={routePaths.tripWizard.tripType}>
                  <SearchTripTypePicker
                    setTripType={this.makeTripWizardFunction("tripType")}
                    date={this.props.completeDate}
                  />
                </Route>
                <Route path={routePaths.tripWizard.returnDate}>
                  <SearchDayPicker
                    setDay={this.makeTripWizardFunction("returnDate")}
                    clearTripWizard={() => "nothing"}
                    startDay={
                      this.props.completeDate
                        ? this.props.completeDate.toJSDate()
                        : new Date()
                    }
                  />
                </Route>
                <Route path={routePaths.tripWizard.returnHour}>
                  <SearchHourPicker
                    setHour={this.makeTripWizardFunction("returnHour")}
                    date={returnHourDate}
                    selectedHour={this.props.tripWizardValues.returnHour}
                  />
                </Route>
                <Route path={routePaths.tripWizard.returnMinute}>
                  <SearchMinutePicker
                    setMinute={this.makeTripWizardFunction("returnMinute")}
                    date={this.props.returnDate}
                    selectedHour={this.props.tripWizardValues.returnHour}
                    selectedMinute={this.props.tripWizardValues.returnMinute}
                  />
                </Route>
              </Switch>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}
export default withRouter(TripInfoWizard);
