export const endpoints = { limos: process.env.REACT_APP_LIMOS_API_ENDPOINT };

export const keys = {
  googleApi: process.env.REACT_APP_GOOGLE_API_KEY,
  limos: process.env.REACT_APP_LIMOS_API_KEY,
  stripe: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  mesKey: process.env.REACT_APP_LIMOS_MES_API_KEY
};


export const features = {
  disableBrowserPayment: process.env.REACT_APP_FEATURE_DISABLE_BROWSER_PAYMENT,
  disableChat: process.env.REACT_APP_DISABLE_CHAT,
  siteDown: false,
};

export const version = process.env.REACT_APP_VERSION;
