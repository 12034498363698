import React, { useEffect, useState, useContext } from "react"; // eslint-disable-line no-unused-vars
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCountDown from "react-countdown-hook";
import { DateTime } from "luxon";
import { jsx } from "@emotion/react/macro"; // eslint-disable-line no-unused-vars
import { css } from "@emotion/css/macro"; // eslint-disable-line no-unused-vars
import { useLocation } from "react-router-dom";
import { WhiteLabelContext } from "react-whitelabel";

import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import Spinner from "react-bootstrap/Spinner";

import MiniTripInfoComponent from "../MiniTripInfoComponent/MiniTripInfoComponent";
import MapComponent from "../MapComponent/MapComponent";
import SearchResult from "../SearchResult/SearchResult";

/* const auctionDetails = {
 *     length: 24,
 *     auctionStart: DateTime.local()
 * }; */

const countdownDisplay = (timeLeft) =>
  (Math.floor(timeLeft / 1000 / 60 / 60) + "").padStart(2, 0) +
  ":" +
  (Math.floor((timeLeft / 1000 / 60) % 60) + "").padStart(2, 0) +
  ":" +
  (Math.floor((timeLeft / 1000) % 60) + "").padStart(2, 0);

const statuses = {
  LOADING: "loading",
  IN_PROGRESS: "in-progress",
  CLOSED: "closed",
  EXPIRED: "expired",
};

const setOfImages = (searchResult) => {
  let images = [];
  if (searchResult.num_images === 0) {
    images = [searchResult.vehicle_url];
  } else {
    images = [
      searchResult.vehicle_url,
      ...searchResult.vehicle_images.urls.split(","),
    ];
  }
  return images;
};

const AuctionStatus = ({
  pickupPlace,
  dropoffPlace,
  completeDate,
  searchResults,
  addItemToCart,
}) => {
  const label = useContext(WhiteLabelContext);
  const [status, setStatus] = useState(statuses.LOADING);
  const [loaded, setLoaded] = useState(false);
  const [auctionDetails, setAuctionDetails] = useState({ operators: [] });

  const [timeLeft, timerActions] = useCountDown(10000, 1000);

  const path = useLocation().pathname.split("/");
  const uuid = path[path.length - 1];

  useEffect(() => {
    if (loaded) {
      timerActions.start(
        auctionDetails.auctionEnd
          .diff(DateTime.local(), "seconds")
          .as("seconds") * 1000
      );
      return () => timerActions.pause();
    }
  }, [loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetch(`/api/bid/${uuid}/auction.json`)
      .then((res) => res.json())
      .then((data) => {
        setAuctionDetails({
          uuid: data.uuid,
          auctionStart: DateTime.fromISO(data.bid_request.start_time),
          auctionEnd: DateTime.fromISO(data.bid_request.end_time),
          operators: data.operator_bids,
        });
        setStatus(statuses.IN_PROGRESS);
        setLoaded(true);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (status === statuses.CLOSED) {
      timerActions.start(3600 * 1000);
      return () => timerActions.pause();
    }
  }, [status, timerActions]);

  useEffect(() => {
    const id = setInterval(
      () =>
        fetch(`/api/bid/${uuid}/auction.json`)
          .then((res) => res.json())
          .then((data) => {
            setAuctionDetails({
              ...auctionDetails,
              operators: data.operator_bids,
            });
          }),
      5000
    );
    return () => clearInterval(id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO isHourly in search result, needs to be in results

  const textStatus = {
    [statuses.LOADING]: (
      <>
        <Spinner
          css={{ marginBottom: "0.7rem" }}
          className={"spinner"}
          animation="border"
          size={"sm"}
        />{" "}
        Loading Your Auction...
      </>
    ),
    [statuses.IN_PROGRESS]: (
      <>
        <FontAwesomeIcon icon={"check-square"} /> Auction In Progress
      </>
    ),
    [statuses.CLOSED]: (
      <>
        <FontAwesomeIcon icon={"check-square"} /> Auction Closed
      </>
    ),
    [statuses.EXPIRED]: (
      <>
        <FontAwesomeIcon icon={"window-close"} /> Auction Expired
      </>
    ),
  };

  const statusColor = {
    [statuses.LOADING]: label.colorDisabled,
    [statuses.IN_PROGRESS]: label.colorBlue,
    [statuses.CLOSED]: label.colorBlue,
    [statuses.EXPIRED]: label.colorRed,
  };

  let statusBar;
  switch (status) {
    case statuses.LOADING:
      statusBar = "Loading";
      break;
    case statuses.CLOSED:
      statusBar = "Bids Expire in " + countdownDisplay(timeLeft);
      break;
    case statuses.EXPIRED:
      statusBar = "Expired";
      break;
    default:
      statusBar = countdownDisplay(timeLeft) + " remaining";
  }

  return (
    <div className="hero-form">
      <Row>
        <Col xs={12}>
          <button onClick={() => setStatus(statuses.CLOSED)}>Close</button>
          <button onClick={() => setStatus(statuses.EXPIRED)}>Expire</button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={5}>
          <Row>
            <Col className={"checkoutPageTitle"} xs={12}>
              <h1>Reverse Auction</h1>
            </Col>
          </Row>
          <Row>
            <Col className={"checkoutPageTitle"} xs={12}>
              {textStatus[status]}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div
                css={{ backgroundColor: statusColor[status] }}
                className={`bookingStatus`}
              >
                {statusBar}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Jumbotron className="bookedJumbotron">
                {status !== statuses.LOADING ? (
                  <>
                    <div className={"pageTitle"}>Auction Number: {uuid}</div>
                    <MiniTripInfoComponent
                      pickupPlace={pickupPlace}
                      dropoffPlace={dropoffPlace}
                      completeDate={completeDate}
                      showDate={true}
                      showTrip={true}
                    />
                    <MapComponent
                      pickupPlace={pickupPlace}
                      dropoffPlace={dropoffPlace}
                    />
                    <Row>
                      <Col xs={12} css={{ marginTop: "1rem" }}>
                        <a href="/" css={{ textDecoration: "underline" }}>
                          Cancel Auction
                        </a>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </Jumbotron>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={7}>
          <Row>
            <Col xs={12}>
              <img
                src="https://media2.giphy.com/media/26BoEyibl9KsN7Pws/giphy.gif?cid=ecf05e47k46vi6xjy643ay8w7qrta83064jqpqd5etj57x8k&rid=giphy.gif"
                alt="gavel"
                height="200px"
              />
              <h2>Bids Placed</h2>
              <hr />
              {auctionDetails.operators.length < 1 &&
              (status === statuses.LOADING ||
                status === statuses.IN_PROGRESS) ? (
                <p>
                  <em>When someone places a bid you'll see it here.</em>
                </p>
              ) : (
                <></>
              )}
              {auctionDetails.operators.length > 0 &&
              status === statuses.CLOSED ? (
                <Col xs={12}>
                  <Alert variant={"danger"}>
                    <strong>Select a bid soon before they expire!</strong>
                  </Alert>
                </Col>
              ) : (
                <></>
              )}
              {status === statuses.EXPIRED ? (
                <Col xs={12}>
                  <Alert variant={"danger"}>
                    <strong>
                      This auction has expired. Start a new auction to get more
                      bids.
                    </strong>
                  </Alert>
                </Col>
              ) : (
                <></>
              )}
            </Col>
            {searchResults
              .slice(
                0,
                Math.min(
                  status === statuses.EXPIRED ? 0 : 100,
                  auctionDetails.operators.length,
                  searchResults.length
                )
              )
              .map((searchResult, i) => (
                <Col xs={12} lg={8} xl={6} key={i}>
                  <SearchResult
                    displayName={`${auctionDetails.operators[i].year} ${auctionDetails.operators[i].color} ${auctionDetails.operators[i].make} ${auctionDetails.operators[i].model}`}
                    images={setOfImages(searchResult.result)}
                    starRating={searchResult.result.average_review}
                    operatorId={searchResult.result.operator_handle}
                    reviewsLink={searchResult.result.reviews}
                    displayPrice={auctionDetails.operators[i].price}
                    grandTotal={auctionDetails.operators[i].price * 100}
                    passengerCapacity={auctionDetails.operators[i].passengers}
                    luggageCapacity={searchResult.result.luggage_capacity}
                    resultHandle={searchResult.result.result_handle}
                    addItemToCart={addItemToCart}
                    searchResult={{
                      ...searchResult,
                      bidId: auctionDetails.operators[i].id,
                    }}
                    amenities={searchResult.result.vehicle_features}
                    hourlyDuration={searchResult.result.total_hours_count}
                    isHourly={false}
                    showCallChat={false}
                    showPrice={true}
                    id={auctionDetails.operators[i].id}
                  />
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AuctionStatus;
