const tripWizardRoot = "/a/trip-wizard"

export const routePaths = {
  root: "/",
  onlineBookingPage: "/a/online-booking",
  landingPage: "/a/landing-page",
  thankyou: "/a/thank-you-quote-form",
  thankyouLanding: "/a/thank-you-request-form",
  success: "/a/success",
  searchResults: "/a/search-results",
  tripWizard: {
    root: tripWizardRoot,
    date: tripWizardRoot + "/date",
    hour: tripWizardRoot + "/hour",
    minute: tripWizardRoot + "/minute",
    tripIsHourly: tripWizardRoot + "/trip-is-hourly",
    tripType: tripWizardRoot + "/trip-type",
    duration: tripWizardRoot + "/duration",
    roundTrip: tripWizardRoot + "/round-trip",
    returnDate: tripWizardRoot + "/return-date",
    returnHour: tripWizardRoot + "/return-hour",
    returnMinute: tripWizardRoot + "/return-minute",
  },
  checkout: "/a/checkout-page",
  confirmation: "/a/confirmation",
  contact: "/a/contact",
  siteTerms: "/a/terms",
  privacyPolicy: "/a/privacy-policy",
  airport: "/airport-limo",
  submitAuction: "/a/submit-auction",
  auctionStatus: "/a/auction-status",
  covid: "/a/covid-19",
  giveaway: "/a/giveaway",
  carnival: "/a/carnival",
  requestQuote: "/a/request-quote",
  requestPhoneQuote: "/a/phone-quote",
  services: "/a/services",
  vehicles: "/a/vehicles",
  events: "/a/events",
  operator: "/a/operator-form",
  bidMyRide: "/a/new-bid-my-ride",
  contest: "/a/prom-contest",
  siteMap: "/a/sitemap",
}

export const routesAsStrings = () => {
  const parseRoute = routes =>
    Object.values(routes).map(route =>
      typeof route === "object" ? parseRoute(route) : route
    )
  return parseRoute(routePaths).flat()
}
