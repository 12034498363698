import React from "react";
import "./BookingConditions.scss";
import axios from "axios";
import { endpoints, keys } from "../../globals.js";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { withWhiteLabelContext } from "react-whitelabel";

// The terms API gives us HTML encoded text sometimes. This is a safe way to decode it.
const decodeText = (str) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(
    "<!doctype html><body>" + str,
    "text/html"
  );
  return dom.body.textContent;
};

const renderBookingConditions = (
  conditions,
  depth,
  highlights = [],
  hooks = {},
  hidden = {}
) => {
    const HTag = `h${depth + 4}`;
    return Object.keys(conditions).map((condition, idx) => (
      <div
        key={idx}
        className={
          (highlights.includes(condition) ? "conditionHighlight" : "") +
          (hooks[condition] ? " conditionClickable " : "") +
          (hidden[condition] ? " conditionHidden" : "")
        }
        onClick={hooks[condition] ? hooks[condition] : () => "nothing"}
      >
        <HTag>{conditions[condition].title}</HTag>
        {typeof conditions[condition].body[0] === "string" ? (
          conditions[condition].body.length === 1 ? (
            <p>{decodeText(conditions[condition].body[0])}</p>
          ) : (
            <ul>
              {conditions[condition].body.map((x, idx) => (
                <li key={idx}>{decodeText(x)}</li>
              ))}
            </ul>
          )
        ) : (
          renderBookingConditions(
            conditions[condition].body[0],
            ++depth,
            highlights,
            hooks,
            hidden
          )
        )}
      </div>
    ));
};

class BookingConditions extends React.Component {
  state = {
    pathHasBeenReturned: false,
    reservationTerms: [],
  };

  componentDidMount() {
    const url = `${endpoints.limos}/api/terms/${this.props.resultHandle}/get_terms.json?api_key=${keys.limos}`;

    axios.get(url).then((response) => {
      this.setState({ reservationTerms: response.data.reservation_terms });
      this.props.setReservationTerms(response.data.reservation_terms);
    });
  }

  render() {
    const { reservationTerms } = this.state;
    const { hidden, hooks } = this.props;
    return (
      <>
        <Modal
          show={this.props.showModal}
          onHide={this.props.toggleShow}
          className={this.props.label.name}
        >
          <Modal.Header closeButton>
            <h3>Booking Conditions</h3>
          </Modal.Header>
          <Modal.Body className="termsModal">
            <div className="bookingTermsFrame">
              {/* <h3>Booking Conditions</h3> */}
              <div className="bookingTermsContainer booking-terms">
                {renderBookingConditions(
                  reservationTerms,
                  0,
                  [],
                  hooks,
                  hidden
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {Object.keys(reservationTerms).length > 0 ? (
          <>
            <div className="bookingTermsContainer">
              {renderBookingConditions(
                {
                  changes_cancellations: reservationTerms.changes_cancellations,
                },
                0,
                [],
                hooks,
                {
                  ...hidden,
                  with_trip_assurance: true,
                  with_out_trip_assurance: true,
                }
              )}
            </div>
          </>
        ) : (
          <div style={{ margin: "24px auto", width: "100%" }}>
            <Spinner className={"spinner"} animation="border" size={"sm"} />{" "}
            Loading terms...
          </div>
        )}
      </>
    );
  }
}

export default withWhiteLabelContext(BookingConditions);
