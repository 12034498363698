import React, { useContext } from "react";
import { WhiteLabelContext } from "react-whitelabel";
import Modal from "react-bootstrap/Modal";

import CallAndChat from "../CallAndChat/CallAndChat.jsx";

const CallAndChatModal = ({ showModal, toggleShow, openChatWindow }) => {
  const label = useContext(WhiteLabelContext);
  return (
    <>
      <Modal show={showModal} onHide={toggleShow} className={label.name}>
        <Modal.Header closeButton>
          <h3>Book Your Ride</h3>
        </Modal.Header>
        <Modal.Body className="termsModal">
          <h3>This ride requires special attention</h3>
          <br />
          <CallAndChat
            openChatWindow={openChatWindow}
            titleMessage={"Talk to a booking specialist now:"}
            fullWidth={true}
            multiLine={true}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CallAndChatModal;
