import { GoogleSpreadsheet } from "google-spreadsheet";
const cred = require("./limos-key.json");

const DataUpdater = (sheetNo) => {
  const SHEET_ID = sheetNo;
  const CLIENT_EMAIL = cred.client_email;
  const PRIVATE_KEY = cred.private_key;

  const appendSpreadsheet = async (row) => {
    try {
      const doc = new GoogleSpreadsheet(
        "1aTu_zx4YPe39VXwrNJvsAvN-x45pohews-qaC2x-_hY"
      );

      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });

      await doc.loadInfo();
      const sheet = doc.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  return { appendSpreadsheet };
};

export default DataUpdater;
