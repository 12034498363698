import React from "react"
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import axios from "axios"
import { Alert, Button } from "react-bootstrap"
import SearchResultWide from "../SearchResultWide/SearchResultWide.jsx"
import CallAndChat from "../CallAndChat/CallAndChat.jsx"
import Container from "react-bootstrap/Container"
import Jumbotron from "react-bootstrap/Jumbotron"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import { withWhiteLabelContext } from "react-whitelabel"
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup"
import ToggleButton from "react-bootstrap/ToggleButton"
import { routePaths } from "../../routes/routes"
import { endpoints, keys } from "../../globals.js"
import { DateTime } from "luxon"
import CarSpinner from "../CarSpinner/CarSpinner.jsx"

import CountdownTimer from "../CountdownTimer/CountdownTimer.jsx"

import TripToFromTimeDetails from "../TripToFromTimeDetails/TripToFromTimeDetails.jsx"
import NoResultsEmail from "../NoResultsEmail/NoResultsEmail.jsx"

import "./SearchResultsPage.scss"

const sortFunctionArray = [
  {
    name: "Best Value",
    sortFunction: (a, b) =>
      parseFloat(b.result.average_review) / parseFloat(b.result.grand_total) -
      parseFloat(a.result.average_review) / parseFloat(a.result.grand_total),
  },
  { name: "Vehicle Type", sortFunction: (a, b) => 0 },
  {
    name: "Lowest Price",
    sortFunction: (a, b) => a.result.grand_total - b.result.grand_total,
  },
  {
    name: "Highest Rating",
    sortFunction: (a, b) =>
      parseFloat(b.result.average_review) - parseFloat(a.result.average_review),
  },
  {
    name: "Most Reviews",
    sortFunction: (a, b) => b.result.num_reviews - a.result.num_reviews,
  },
  {
    name: "Luggage Capacity",
    sortFunction: (a, b) =>
      b.result.luggage_capacity - a.result.luggage_capacity,
  },
  {
    name: "Passenger Capacity",
    sortFunction: (a, b) =>
      b.result.vehicle_capacity - a.result.vehicle_capacity,
  },
]

const CancelToken = axios.CancelToken
let searchResultsCancel

class SearchResultsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      sortFunction: sortFunctionArray[0].sortFunction,
      hideOnDepatureSelect: true,
      checkedFilters: [],
      processedResults: [],
      secondLegProcessedResults: [],
      resultHandler1: null,
      resultHandler2: null,
      resultHandler1Data: null,
      resultHandler2Data: null,
      vehicleTypes: [],
      tripDate: false,
      errorMessage: false,
      callChatEveryResult: false,
      // secondLeg: this.isItRoundTrip() == "1" ? true : false,
      secondLeg: false,
      secondLegResultHandler: "",
      uuid: "unknown",
      showCountdown: false,
      search_uuid_number: "",
      search_result_count: 0,
      firstLegOperatorNameSelected: null,
    }

    if (typeof document !== "undefined") {
      document.title = this.props.label.title + " - Search Results"
    }
  }
  isItRoundTrip = () => {
    let urlString = document.location.href
    var url = new URL(urlString)
    var is_round_trip = url.searchParams.get("is_round_trip")
    return is_round_trip
  }

  updateHandlerData = (resultHandler, handlerIdData, operator = "") => {
    if (resultHandler == "resultHandler1") {
      if (this.isItRoundTrip() === "1") {
        if (operator === "MOZ") {
          const filterMOZResults = this.state.secondLegProcessedResults.filter(
            item => item.result.operator_handle === operator
          )
          const filterSameMozOpsFromResults = filterMOZResults.filter(
            item =>
              item.result.result_handle === handlerIdData.result.result_handle
          )
          this.setState({
            secondLegProcessedResults: filterSameMozOpsFromResults,
          })
        } else {
          const filterNonMozResults =
            this.state.secondLegProcessedResults.filter(
              item => item.result.operator_handle !== "MOZ"
            )
          this.setState({ secondLegProcessedResults: filterNonMozResults })
        }
      }

      this.setState({ firstLegOperatorNameSelected: operator })
      this.setState({ resultHandler1Data: handlerIdData.result })
      this.setState({ resultHandler1: handlerIdData.result.result_handle })
    } else if (resultHandler == "resultHandler2") {
      this.setState({ resultHandler2Data: handlerIdData.result })
      this.setState({ resultHandler2: handlerIdData.result.result_handle })
    }

    this.setState({ hideOnDepatureSelect: false })
  }

  update = () => {
    const urlParams = new URLSearchParams(window.location.search)
    this.props.setSearchURL(window.location.href)
    const search = {
      ride_date: urlParams.get("ride_date"),
      is_round_trip: urlParams.get("is_round_trip"),
      round_trip_ride_date: urlParams.get("round_trip_ride_date"),
      round_trip_pickup_time: urlParams.get("round_trip_pickup_time"),
      result_handle_leg2: "nothing",
      // pickup_time: '00%3A00',
      pickup_time: urlParams.get("pickup_time"),
      pickup_place: urlParams.get("pickup_place"),
      drop_off_place: urlParams.get("drop_off_place"),
      pickup_place_id: urlParams.get("pickup_place_id"),
      dropoff_place_id: urlParams.get("dropoff_place_id"),
      pax: urlParams.get("pax"), // small, med and large will get us here
      vehicle_types: urlParams.get("vehicle_types"),
      service_type: urlParams.get("service_type"), //will know if its hourly
      drop_off_time: urlParams.get("drop_off_time"),
      api_key: keys.limos,
      mozio: urlParams.get("mozio"),
    }
    this.props.setBoookingParamValues(search)
    const dateParts = search.ride_date.split("/"),
      timeParts = search.pickup_time.split(":")
    const returnDateParts = search.is_round_trip
        ? search.round_trip_ride_date.split("/")
        : false,
      returnTimeParts = search.is_round_trip
        ? search.round_trip_pickup_time.split(":")
        : false

    let returnParams = ""
    if (search.is_round_trip) {
      returnParams =
        `is_round_trip=${search.is_round_trip}&` +
        `round_trip_ride_date=${search.round_trip_ride_date}&` +
        `round_trip_pickup_time=${search.round_trip_pickup_time}&`
    }
    let mozioParams = ""
    if (search.mozio === "1") {
      mozioParams = `mozio=${search.mozio}&`
    }
    let serviceBookingUrl = ""
    if (search.service_type == "101" || search.service_type == "102") {
      serviceBookingUrl =
        endpoints.limos +
        "/v1/biz/search.json?" +
        returnParams +
        mozioParams +
        "ride_date=" +
        search["ride_date"] +
        "&" +
        "pickup_time=" +
        search["pickup_time"] +
        "&" +
        "pickup_place=" +
        search["pickup_place"] +
        "&" +
        "drop_off_place=" +
        search["drop_off_place"] +
        "&" +
        "drop_off_time=" +
        search["drop_off_time"] +
        "&" +
        "pax=" +
        search["pax"] +
        "&" +
        "vehicle_types=" +
        (search["vehicle_types"]
          ? search["vehicle_types"]
          : "1,2,3,4,5,6,7,12,13") +
        "&" +
        "service_type=" +
        search["service_type"] +
        "&" +
        "api_key=" +
        search["api_key"]
      if (this.props.pickupPlace.placeId) {
        serviceBookingUrl =
          serviceBookingUrl +
          "&" +
          "is_pickup_google_place_id=true" +
          "&" +
          "is_dropoff_google_place_id=false"
      }
      if (this.props.dropoffPlace.placeId) {
        serviceBookingUrl =
          serviceBookingUrl + "&" + "is_dropoff_google_place_id=true"
      }
    } else {
      serviceBookingUrl =
        endpoints.limos +
        "/v1/biz/search.json?" +
        returnParams +
        mozioParams +
        "ride_date=" +
        search["ride_date"] +
        "&" +
        "pickup_time=" +
        search["pickup_time"] +
        "&" +
        "pickup_place=" +
        search["pickup_place"] +
        "&" +
        "drop_off_place=" +
        search["drop_off_place"] +
        "&" +
        "drop_off_time=" +
        search["drop_off_time"] +
        "&" +
        "is_pickup_google_place_id=" +
        search["pickup_place_id"] +
        "&" +
        "is_dropoff_google_place_id=" +
        search["dropoff_place_id"] +
        "&" +
        "pax=" +
        search["pax"] +
        "&" +
        "vehicle_types=" +
        (search["vehicle_types"]
          ? search["vehicle_types"]
          : "1,2,3,4,5,6,7,12,13") +
        "&" +
        "service_type=" +
        search["service_type"] +
        "&" +
        "api_key=" +
        search["api_key"]
    }

    axios
      .get(serviceBookingUrl, {
        cancelToken: new CancelToken(function executor(c) {
          searchResultsCancel = c
        }),
      })

      .then(response => {
        if (response.data && response.data.response_code === 200) {
          if (response.data.search.search_uuid) {
            this.props.setUUIDNumber(response.data.search.search_uuid)
            this.setState({
              search_uuid_number: response.data.search.search_uuid,
            })
          }
          const dropOffTimeParts = response.data.search.is_hourly
            ? search.drop_off_time.split(":")
            : false
          const pickupHour = parseInt(timeParts[0])
          const dropoffHour = dropOffTimeParts
            ? parseInt(dropOffTimeParts[0])
            : pickupHour // make a 0 duration
          const pickupDateTime = DateTime.fromObject({
            year: parseInt(dateParts[2]),
            month: parseInt(dateParts[0]),
            day: parseInt(dateParts[1]),
            hour: pickupHour,
            minute: parseInt(timeParts[1]),
            zone: response.data.search.pickup_time_zone,
          })
          let returnDateTime = null
          if (search.is_round_trip) {
            const returnHour = parseInt(returnTimeParts[0])
            returnDateTime = DateTime.fromObject({
              year: parseInt(returnDateParts[2]),
              month: parseInt(returnDateParts[0]),
              day: parseInt(returnDateParts[1]),
              hour: returnHour,
              minute: parseInt(returnTimeParts[1]),
              zone: response.data.search_second_leg.pickup_time_zone,
            })
          }
          this.props.setCompleteDate(
            pickupDateTime,
            returnDateTime,
            pickupHour > dropoffHour
              ? dropoffHour + 24 - pickupHour
              : dropoffHour - pickupHour,
            response.data.search.is_hourly
          )
          this.props.setSearchResults(
            response.data.search.results,
            response.data.search_second_leg
              ? response.data.search_second_leg.search.results
              : false,
            response.data.search.resolved_pickup_place,
            response.data.search.resolved_dropoff_place,
            response.data.search.is_hourly,
            () => this.setState({ loading: false })
          )
        } else {
          if (
            response.data &&
            response.data.search &&
            response.data.search.errors &&
            typeof response.data.search.errors === "object" &&
            response.data.search.errors.length > 0
          ) {
            this.props.setUUIDNumber(response.data.search.search_uuid)
            this.setState({
              loading: false,
              errorMessage: response.data.search.errors[0].error.message,
              uuid: response.data.search.search_uuid,
            })
          } else {
            this.setState({
              loading: false,
              errorMessage: "An unknown error occurred",
            })
            this.props.setError(response)
          }
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          this.props.setError(err)
        }
      })
  }

  componentDidMount() {
    if (this.props.searchLocation !== window.location.href) {
      window.scrollTo(0, 0)
      this.setState({ loading: true })
      this.update()
    } else {
      this.setState({ loading: false })
      this.processSearchResults()
    }
    var searchResultPageBg = document.getElementsByClassName("brand-body")[0]
    if (searchResultPageBg) {
      searchResultPageBg.style = "background: black"
    }
  }

  componentWillUnmount() {
    searchResultsCancel && searchResultsCancel()
  }

  processSearchResults = () => {
    let vehicleTypes = new Set() // vehicle_type_string
    let secondLegVehicleTypes = new Set() // vehicle_type_string
    let currentResults = this.props.searchResults
    // this.setState({ search_result_count: currentResults });
    currentResults.forEach(result =>
      vehicleTypes.add(result.result.vehicle_type_string)
    )

    let currentSecondLegResults = this.props.secondLegResults
      ? this.props.secondLegResults
      : []
    currentSecondLegResults.forEach(result =>
      secondLegVehicleTypes.add(result.result.vehicle_type_string)
    )

    let searchResults = [...currentResults]
    let secondSearchResults = [...currentSecondLegResults]

    if (this.state.checkedFilters.length > 0) {
      searchResults = searchResults.filter(result =>
        this.state.checkedFilters.includes(result.result.vehicle_type_string)
      )
    }

    if (this.state.checkedFilters.length > 0) {
      secondSearchResults = secondSearchResults.filter(result =>
        this.state.checkedFilters.includes(result.result.vehicle_type_string)
      )
    }

    searchResults = searchResults.sort(this.state.sortFunction)
    secondSearchResults = secondSearchResults.sort(this.state.sortFunction)
    const callChatEveryResult =
      Math.min.apply(
        Math,
        searchResults.map(x => parseFloat(x.result.grand_total))
      ) >= this.props.label.forceCallChatAt
    if (typeof window.dataLayer !== "undefined" && callChatEveryResult) {
      window.dataLayer.push({ event: "show-call-chat-only" })
    }

    this.setState({
      processedResults: searchResults,
      secondLegProcessedResults: secondSearchResults,
      vehicleTypes,
      callChatEveryResult,
      search_result_count: searchResults.length,
      // showCountdown: true,
    })
  }

  setSort = func => {
    this.setState({ sortFunction: func })
  }

  setFilter = checkedFilters => {
    this.setState({ checkedFilters })
  }

  setImages = searchResult => {
    let images = []
    if (searchResult.num_images === 0) {
      images = [searchResult.vehicle_url]
    } else {
      images = [...searchResult.vehicle_images.urls.split(",")]
    }
    return images
  }

  showLeg = legName => {
    this.setState(
      { secondLeg: legName === "dropoffLeg" },
      this.processSearchResults
    )
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.searchResults &&
      (prevProps.searchResults !== this.props.searchResults ||
        prevState.checkedFilters !== this.state.checkedFilters ||
        prevState.sortFunction !== this.state.sortFunction)
    ) {
      this.processSearchResults()
    }
  }

  onCountdownEnd = () => {
    window.history.back()
    this.setState({
      showCountdown: false,
    })
  }

  // Function to change order
  interleaveArrays = (arr1, arr2) => {
    const result = []
    let i = 0,
      j = 0

    while (i < arr1.length && j < arr2.length) {
      if (arr1[i].vehicle_capacity <= arr2[j].vehicle_capacity) {
        result.push(arr1[i++])
        if (j < arr2.length) result.push(arr2[j++])
      } else {
        result.push(arr2[j++])
        if (i < arr1.length) result.push(arr1[i++])
      }
    }

    while (i < arr1.length) result.push(arr1[i++])
    while (j < arr2.length) result.push(arr2[j++])

    return result
  }

  render() {
    if (this.state.loading) {
      return (
        <aside className="loading-state">
          <Col xs={12} className="spinner">
            <div style={{ marginTop: 124 }}>
              <CarSpinner white />
            </div>
          </Col>
          <Row>
            <Col
              xs={12}
              style={{ textAlign: "center", marginTop: 100, color: "white" }}
              className="spinnerText"
            >
              Searching our transportation marketplace for awesome deals!
            </Col>
          </Row>
        </aside>
      )
    } else if (
      (this.state.errorMessage &&
        this.state.errorMessage ===
          "Sorry, no results found matching your criteria") ||
      (!this.state.errorMessage && this.state.processedResults.length === 0)
    ) {
      if (typeof window.dataLayer !== "undefined") {
        window.dataLayer.push({ event: "no-results" })
      }
      return (
        <div
          style={{
            margin: "124px auto 0 auto",
            maxWidth: 1024,
            padding: "0 32px",
          }}
        >
          <CallAndChat
            openChatWindow={this.props.openChatWindow}
            titleMessage={
              "Hey there, this search requires you to contact us to find the best deals for you. We will get back to you right away."
            }
          />
          <NoResultsEmail uuid={this.state.uuid} />
        </div>
      )
    } else if (this.state.errorMessage) {
      return (
        <div
          style={{
            margin: "124px auto 0 auto",
            maxWidth: 1024,
            padding: "0 32px",
          }}
        >
          {this.state.errorMessage ? (
            <Alert variant={"danger"}>{this.state.errorMessage}</Alert>
          ) : (
            "no results"
          )}
        </div>
      )
    } else if (this.props.searchResults) {
      const mozOperators = this.state.processedResults.filter(
        item => item.result.operator_handle === "MOZ"
      )
      const otherOperators = this.state.processedResults.filter(
        item => item.result.operator_handle !== "MOZ"
      )
      let sortedAndInterleavedResults = this.interleaveArrays(
        mozOperators,
        otherOperators
      )
      // const reorderedArr = mozOperators.concat(otherOperators)
      return (
        <div className="search-results-page">
          <Container>
            {this.props.secondLegResults && (
              <Row>
                <Col xs={12}>
                  {this.state.secondLeg && (
                    <Button
                      className="buttonAsLink"
                      onClick={() => this.showLeg("pickupLeg")}
                    >
                      Modify Departure Trip
                    </Button>
                  )}
                </Col>
              </Row>
            )}
            {!this.props.secondLegResults && (
              <>
                <div className="specialize-section-image-container">
                  <img
                    src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Luxury-Fleet-Vehicle.png"
                    style={{ height: "100px" }}
                    alt="Luxury-Fleet-Vehicle.png"
                  />
                </div>
                <div className="searchResultsPageTitle">
                  <h1>Select Your Ride</h1>
                  <div className="assistance-text-container">
                    <a
                      href="tel:8444273445"
                      className="assistance-text-container__link"
                    >
                      <img
                        className="small-image"
                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Limos-help-center_v2.png"
                        alt="lady-img"
                      ></img>
                      <div className="text-container">
                        <span className="line1">
                          Need some help? Call us @ 844.427.3445
                        </span>
                        <span className="line2">
                          {`Reservation #${
                            this.state.search_uuid_number
                              ? this.state.search_uuid_number
                              : ""
                          }`}
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </>
            )}
            <TripToFromTimeDetails
              pickupPlace={
                this.state.secondLeg
                  ? this.props.dropoffPlace
                  : this.props.pickupPlace
              }
              dropoffPlace={
                this.state.secondLeg
                  ? this.props.pickupPlace
                  : this.props.dropoffPlace
              }
              completeDate={
                this.state.secondLeg
                  ? this.props.returnDate
                  : this.props.completeDate
              }
              showDate={true}
              showTrip={true}
            />

            {/* <Row
              style={{
                maxWidth: "100%",
                margin: "auto",
                backgroundColor: "#000",
              }}
            >
              <Col
                xs={12}
                lg={8}
                xl={8}
                md={8}
                style={{ margin: "auto", position: "relative" }}
              >
                <Jumbotron className="jumbotron">
                  <h3>Any promo codes can be applied at Checkout!</h3>
                </Jumbotron>
                 <div
                  className="testme"
                  style={{
                    color: "red",
                    position: "absolute",
                    top: "11px",
                    right: "-15%",
                  }}
                >
                  {this.state.search_result_count} matches
                </div>
              </Col>
            </Row> */}

            <Row style={{ maxWidth: "100%", margin: "32px auto 16px auto" }}>
              <div>
                <Accordion>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Modify, Sort & Filter
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <Link
                          className={"modifySearchButton"}
                          to={routePaths.root}
                        >
                          Modify Search
                        </Link>
                        <Row>
                          <Col xs={6}>
                            <h4>Sort by:</h4>
                            <ToggleButtonGroup
                              type="radio"
                              className={"sortAndFilter"}
                              name="search-results-sort"
                              defaultValue="Best Value"
                            >
                              {sortFunctionArray.map((sortObj, idx) => {
                                return (
                                  <ToggleButton
                                    key={idx}
                                    onClick={() =>
                                      this.setSort(sortObj.sortFunction)
                                    }
                                    value={sortObj.name}
                                  >
                                    {sortObj.name}
                                  </ToggleButton>
                                )
                              })}
                            </ToggleButtonGroup>
                          </Col>
                          <Col xs={6}>
                            <h4>Filter By:</h4>
                            <ToggleButtonGroup
                              className={"sortAndFilter"}
                              type="checkbox"
                              name="search-results-sort"
                              onChange={this.setFilter}
                            >
                              {[...this.state.vehicleTypes].map(
                                (vehicleType, idx) => (
                                  <ToggleButton key={idx} value={vehicleType}>
                                    {vehicleType}
                                  </ToggleButton>
                                )
                              )}
                            </ToggleButtonGroup>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </Row>
            <Row className="results-container">
              {this.state.secondLeg ? (
                <>
                  <h1
                    style={{
                      width: "100%",
                      marginTop: "2rem",
                      textAlign: "center",
                    }}
                  >
                    {!this.state.secondLeg ? "Return Trip" : "Departure Trip"}{" "}
                    Added To Cart
                  </h1>
                  <h3
                    style={{
                      width: "100%",
                      marginTop: "2rem",
                      textAlign: "center",
                    }}
                  >
                    Next, select your{" "}
                    {this.state.secondLeg ? "Return Trip" : "Departure Trip"}
                  </h3>
                </>
              ) : (
                <></>
              )}

              {this.state.hideOnDepatureSelect && (
                <>
                  {sortedAndInterleavedResults.map((searchResult, i) => (
                    <Col xs={12} key={i++}>
                      <SearchResultWide
                        displayName={
                          searchResult.result.vehicle_make +
                          " " +
                          searchResult.result.vehicle_model
                        }
                        images={this.setImages(searchResult.result)}
                        starRating={searchResult.result.average_review}
                        operatorId={searchResult.result.operator_handle}
                        reviewsLink={searchResult.result.reviews}
                        displayPrice={searchResult.result.grand_total}
                        passengerCapacity={searchResult.result.vehicle_capacity}
                        luggageCapacity={searchResult.result.luggage_capacity}
                        resultHandle={searchResult.result.result_handle}
                        resultHandle1={this.state.resultHandler1}
                        resultHandle2={this.state.resultHandler2}
                        resultHandler1Data={this.state.resultHandler1Data}
                        resultHandler2Data={this.state.resultHandler2Data}
                        clickCallBack={operator =>
                          this.updateHandlerData(
                            "resultHandler1",
                            searchResult,
                            operator
                          )
                        }
                        addItemToCart={this.props.addItemToCart}
                        optedReturnedTrip={this.isItRoundTrip()}
                        legName={
                          !this.props.secondLegResults ||
                          (this.props.secondLegResults && !this.state.secondLeg)
                            ? "pickupLeg"
                            : "dropoffLeg"
                        }
                        cartCallback={
                          this.props.secondLegResults && !this.state.secondLeg
                            ? () => this.showLeg("dropoffLeg")
                            : null
                        }
                        showCheckout={
                          (this.props.secondLegResults &&
                            this.state.secondLeg) ||
                          !this.props.secondLegResults
                        }
                        searchResult={searchResult}
                        amenities={searchResult.result.vehicle_features}
                        hourlyDuration={searchResult.result.total_hours_count}
                        isHourly={this.props.isHourly}
                        showCallChat={this.state.callChatEveryResult}
                        showPrice={!this.state.callChatEveryResult}
                        openChatWindow={this.props.openChatWindow}
                        searchUUIDNumber={this.state.search_uuid_number}
                      />
                    </Col>
                  ))}
                </>
              )}

              <div className="assistance-sticky-wrapper">
                <p className="assistance-sticky-title">
                  Need assistance booking or have questions about the pricing ?
                </p>
                <p className="assistance-sticky-description">
                  Call us at 8667300524 and mention your Search ID (
                  {this.state.search_uuid_number}).
                </p>
              </div>

              {this.state.secondLegProcessedResults.length > 0 && (
                <div className="return-trip-heading">
                  <h1>Please choose Return Trip</h1>
                </div>
              )}
              <div className="return-trip-results-wrapper">
                {this.state.secondLegProcessedResults.map((searchResult, i) => (
                  <Col xs={12} key={i++}>
                    <SearchResultWide
                      displayName={
                        searchResult.result.vehicle_make +
                        " " +
                        searchResult.result.vehicle_model
                      }
                      images={this.setImages(searchResult.result)}
                      starRating={searchResult.result.average_review}
                      operatorId={searchResult.result.operator_handle}
                      reviewsLink={searchResult.result.reviews}
                      displayPrice={searchResult.result.grand_total}
                      passengerCapacity={searchResult.result.vehicle_capacity}
                      luggageCapacity={searchResult.result.luggage_capacity}
                      resultHandle={searchResult.result.result_handle}
                      resultHandle1={this.state.resultHandler1}
                      resultHandle2={this.state.resultHandler2}
                      resultHandler1Data={this.state.resultHandler1Data}
                      resultHandler2Data={this.state.resultHandler2Data}
                      clickCallBack={operator =>
                        this.updateHandlerData(
                          "resultHandler2",
                          searchResult,
                          operator
                        )
                      }
                      addItemToCart={this.props.addItemToCart}
                      optedReturnedTrip={this.isItRoundTrip()}
                      legName={
                        !this.props.secondLegResults ||
                        (this.props.secondLegResults && !this.state.secondLeg)
                          ? "pickupLeg"
                          : "dropoffLeg"
                      }
                      cartCallback={
                        this.props.secondLegResults && !this.state.secondLeg
                          ? () => this.showLeg("dropoffLeg")
                          : null
                      }
                      showCheckout={
                        (this.props.secondLegResults && this.state.secondLeg) ||
                        !this.props.secondLegResults
                      }
                      searchResult={searchResult}
                      amenities={searchResult.result.vehicle_features}
                      hourlyDuration={searchResult.result.total_hours_count}
                      isHourly={this.props.isHourly}
                      showCallChat={this.state.callChatEveryResult}
                      showPrice={!this.state.callChatEveryResult}
                      openChatWindow={this.props.openChatWindow}
                    />
                  </Col>
                ))}
              </div>
            </Row>
            {this.state.showCountdown && (
              <CountdownTimer
                minutes={10}
                text="Act fast before these offers expire!"
                onCountdownEnd={this.onCountdownEnd}
              />
            )}
          </Container>
        </div>
      )
    } else {
      return <> no results </>
    }
  }
}

export default withWhiteLabelContext(SearchResultsPage)
