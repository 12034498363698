import React from "react"
import { routePaths } from "../../routes/routes"
import { Link } from "react-router-dom"
import FaqListing from "../FaqListing/FaqListing.jsx"
import SocialLinks from "../SocialLinks/SocialLinks.jsx"
import GoogleMap from "../GoogleMap/GoogleMap.jsx"

const FooterUpdated = () => {
  const openIntercomeLiveChatWindow = event => {
    event.preventDefault()
    var intercomeIcon = document.getElementsByClassName("intercom-launcher")[0]
    if (intercomeIcon) {
      intercomeIcon.click()
    }
  }
  return (
    <>
      <div className="page-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FaqListing />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <GoogleMap />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-12 footer-columns">
              <p className="footer-links-heading">Book Now</p>
              <div>
                <ul className="footer-main-links">
                  <li>
                    <a href="tel:8772419318">Call 877-241-9318 for Sales</a>
                  </li>

                  <li>
                    <a href="tel:8778708837">
                      Call 877-870-8837 for Customer Service
                    </a>
                  </li>

                  <li>
                    <a href="tel:8775850797">
                      Call 877-585-0797 for Operator Support
                    </a>
                  </li>

                  <li>
                    <a href="mailto:booking@limos.com">booking@limos.com</a>
                  </li>
                  <li>
                    <a href={routePaths.root}>Book Online &gt; </a>
                  </li>
                  <li>
                    <a href="" onClick={openIntercomeLiveChatWindow}>
                      Live Chat &gt;
                    </a>
                  </li>
                  <li>
                    <Link to={routePaths.siteMap}>SiteMap</Link>
                  </li>
                  <li>
                    <span className="hidden-text">
                      Call 877-404-0552 for additional Sales support
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-4 col-12  footer-columns">
              <p className="footer-links-heading">About</p>
              <div>
                <ul className="footer-main-links">
                  <li>
                    <a href={routePaths.siteTerms}>Terms</a>
                  </li>
                  <li>
                    <a href={routePaths.privacyPolicy}>Privacy</a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/limo-price-guide">
                      Pricing Guide
                    </a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/vehicle-guide">
                      Vehicle Guide
                    </a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/limos-101-pricing-and-planning-guide">
                      Limos 101
                    </a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/customers/faqs">FAQs </a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/operators/join">
                      Partner with Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <SocialLinks />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FooterUpdated
