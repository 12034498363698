import React, { useState } from "react";
import Button from "react-bootstrap/Button";

import "./AmenityOverflow.scss";

const AmenityOverflow = ({ amenities }) => {
  const [showMore, setShowMore] = useState(false);
  const [visibleItems, setVisibleItems] = useState(3);

  const handleShowMore = () => {
    setVisibleItems(amenities.length);
    setShowMore(true);
  };

  const handleHide = () => {
    setVisibleItems(3);
    setShowMore(false);
    itemsToShow.slice(0, 3);
  };

  const columnCount = showMore ? Math.ceil(amenities.length / 3) : 1;
  const itemsToShow = showMore ? amenities : amenities.slice(0, visibleItems);

  return (
    <>
      <div className="container">
        {amenities.length > 0 && (
          <React.Fragment>
            <ul className="column" style={{ columnCount }}>
              {itemsToShow}
            </ul>
            {!showMore && amenities.length > visibleItems ? (
              <button onClick={handleShowMore} className="show-more">
                Show all...
              </button>
            ) : (
              <button onClick={handleHide} className="show-more">
                Hide all...
              </button>
            )}
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default AmenityOverflow;
