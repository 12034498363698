import React, { useState, useEffect } from "react";

import "./CountdownTimer.scss";

export default function CountdownTimer({
    minutes = 10,
    seconds = 0,
    text,
    onCountdownEnd = () => console.log("countdown OVER!"),
  }) {
    const [time, setTime] = useState({
      minutes,
      seconds,
    });
  
    const calculateTimeLeft = (time) => {
      let newSeconds = time?.seconds;
      let newMinutes = time?.minutes;
  
      if (time?.minutes === 0 && time?.seconds === 0) {
        return;
      }
  
      if (time?.seconds === 0 && time?.minutes) {
        newSeconds = 59;
      } else {
        newSeconds = newSeconds - 1;
      }
  
      if (time?.minutes > 0 && newSeconds === 59) {
        newMinutes = newMinutes - 1;
      }
  
      return {
        minutes: newMinutes,
        seconds: newSeconds,
      };
    };
  
    const timer = setInterval(() => setTime(calculateTimeLeft(time)), 1000);
  
    useEffect(() => {
      return () => clearInterval(timer);
    }, [time, timer]);
  
    useEffect(() => {
      !time?.minutes &&
        !time?.seconds &&
        onCountdownEnd() &&
        clearInterval(timer);
    }, [time, onCountdownEnd, timer]);
  
    return (
      <div className="countdownContainer">
        <div className="wrapper">
          <div className="countdown">
            <div className="time-section">
              <div className="time">{time?.minutes || "00"}</div>
            </div>
            <div className="time-section">
              <div className="time">:</div>
            </div>
            <div className="time-section">
              <div className="time">
                {time?.seconds < 10 ? `0${time?.seconds}` : time?.seconds || "00"}
              </div>
            </div>
          </div>
          <p>{text}</p>
        </div>
      </div>
    );
  }
