const serviceTypeObj = [
  { code: "100", label: "To Airport" },
  { code: "99", label: "From Airport" },
  { code: "131072", label: "Point-to-Point (one-way)" },
  { code: "4096", label: "Just Drive (hourly)" },
  { code: "16384", label: "Prom / School Event" },
  { code: "4", label: "Wedding" },
  { code: "64", label: "Birthday / Anniversary" },
  { code: "8192", label: "Night-on-the-Town" },
  { code: "128", label: "Concert / Sporting Event" },
  { code: "32", label: "Bachelor(ette) Party" },
  { code: "102", label: "To Cruiseport" },
  { code: "101", label: "From Cruiseport" },
  { code: "65536", label: "Wine/Private Tour" },
  { code: "256", label: "Funeral" },
];

export const getServiceTypeFormCode = (code) => {
  const findLabel = serviceTypeObj.find((label) => {
    return label.code == code;
  });
  return findLabel.label;
};
