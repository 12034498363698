import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { withWhiteLabelContext } from "react-whitelabel";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { fullHumanDateTime } from "../../utils/date-time";
import { addressComponent } from "../../utils/address.js";

import Spinner from "../UI/Spinner/Spinner";
import TypeAheadFormField from "../TypeAheadFormField/TypeAheadFormField.jsx";
import TripInfoWizard from "../TripInfoWizard/TripInfoWizard.jsx";
import CallAndChatModal from "../CallAndChatModal/CallAndChatModal.jsx";
import Slider from "../Slider/Slider.jsx";

import FieldInvalid from "./FieldInvalid.jsx";

import { routePaths } from "../../routes/routes";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "./SearchForm.scss";

// import SearchResultsPage from "../SearchResultsPage/SearchResultsPage";
const SCREENS = { form: "form", tripInfoWizard: "tripInfoWizard" };

const hasErrors = (errObj) =>
  Object.values(errObj).some((error) => error.length !== 0);

const Slide = ({ img, key, ...props }) => (
  <div
    key={key}
    className="slide"
    style={{
      ...props.styles,
      backgroundImage: `url("${img}")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    }}
  />
);

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

class SearchForm extends Component {
  state = {
    redirectToSearchResults: false,
    redirectToSubmitAuction: false,
    screen: SCREENS.form,
    redirectItem: { rideDate: "", pickupTime: "" },
    loading: false,
    partySizeState: [1, 0, 0],
    pickupPlaceValid: true,
    dropoffPlaceValid: true,
    showContactModal: false,
    badLocations: {
      pickupPlace: false,
      dropoffPlace: false,
    },
    slideshowImages: shuffle(this.props.images) || [],
  };

  constructor(props) {
    super(props);
    if (typeof document !== "undefined") {
      document.title = this.props.label.title;
    }
  }

  toggleModalShow = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showContactModal: !this.state.showContactModal });
  };

  setPlace = (place, key) => {
    if (place && !place.is_airport) {
      this.service = new window.google.maps.places.PlacesService(this.instance);
      this.service.getDetails(
        {
          placeId: place.placeId,
          fields: [
            "types",
            "geometry",
            "formatted_address",
            "address_components",
          ],
        },
        (res, status) => {
          if (
            !addressComponent(res.address_components, "street_number") ||
            !addressComponent(res.address_components, "route")
          ) {
            this.setState({
              badLocations: {
                ...this.state.badLocations,
                [key]:
                  "We were unable to find a street address for this place. Please try again entering a full street address instead. For airports, select the first result.",
              },
            });
          } else {
            this.setState({
              badLocations: { ...this.state.badLocations, [key]: false },
            });
          }
          return this.props.setPlace(
            place,
            key,
            res.geometry.location.lat(),
            res.geometry.location.lng(),
            res.formatted_address,
            place.placeId,
            false,
            ""
          );
        }
      );
    } else if (place && place.is_airport) {
      this.props.setPlace(
        place,
        key,
        "",
        "",
        place.display,
        "",
        true,
        place.placeId
      );
    }
  };

  setPlaceValid = (key, valid) => {
    this.setState({ [key]: valid }, this.intermediateValidate);
  };

  intermediateValidate = () => {
    const { errors } = this.props;
    if (!this.state.pickupPlaceValid) {
      errors.pickupPlace =
        "Your pickup address is too broad. Please enter an exact address.";
    }
    if (!this.state.dropoffPlaceValid) {
      errors.dropoffPlace =
        "Your dropoff address is too broad. Please enter an exact address.";
    }
  };

  validate = () => {
    const errors = this.props.createInitialSearchFormErrorState();
    this.props.setSearchFormErrors(errors);
    if (this.props.pickupPlace.lat === 0 && this.props.pickupPlace.lng === 0) {
      errors.pickupPlace = "Pickup location required.";
    }
    if (!this.state.pickupPlaceValid) {
      errors.pickupPlace =
        "Your pickup address is too broad. Please enter a more specific location.";
    }
    if (
      this.props.dropoffPlace.lat === 0 &&
      this.props.dropoffPlace.lng === 0
    ) {
      errors.dropoffPlace = "Dropoff location required.";
    }
    if (!this.state.dropoffPlaceValid) {
      errors.dropoffPlace =
        "Your dropoff address is too broad. Please enter a a more specific location.";
    }
    if (!this.props.tripWizardValues.date) {
      errors.date = "Trip date and time is required";
      /* errors.date = 'Trip date is required'; */
    }
    if (this.props.tripWizardValues.hour === false) {
      errors.hour = "Trip date and time is required";
      /* errors.hour = 'Departure time is missing' */
    }
    if (this.props.tripWizardValues.minute === false) {
      errors.minute = "Trip date and time is required";
      /* errors.minute = 'Departure time is missing' */
    }
    if (this.props.label.emailRequired && this.props.searchForm.email === "") {
      errors.email = "Email address required.";
    }
    if (!this.props.partySize) {
      errors.pax = "Passengers is required.";
    } else if (
      this.props.partySize === "custom" &&
      !this.props.customPartySize
    ) {
      errors.customPax = "Passengers is required.";
    }

    return !hasErrors(errors);
  };

  componentDidMount() {
    if (hasErrors(this.props.searchFormErrors)) {
      this.validate();
    }
  }

  componentDidUpdate(prevProps) {
    if (hasErrors(this.props.searchFormErrors)) {
      if (
        prevProps.pickupPlace !== this.props.pickupPlace ||
        prevProps.dropoffPlace !== this.props.dropoffPlace ||
        prevProps.tripWizardValues.minute !== this.props.tripWizardValues.minute
      ) {
        this.validate();
      }
    }
  }

  onTypeaheadSearch = (query, id, finished) => {
    let googleResults = [],
      airportResults = [],
      googleFinished = false,
      airportFinished = query.length < 2;
    const google = window.google;

    const finishSearch = () => {
      if (googleFinished && airportFinished) {
        this.props.onPlaceQuery(id, airportResults.concat(googleResults));
        finished();
      }
    };

    const googleSuggestions = (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        console.log(`google place api error: ${status}`);
        googleFinished = true;
        finished();
        return;
      }

      googleResults = predictions
        .filter(
          (res) =>
            !res.types.includes("locality") && !res.types.includes("airport")
        )
        .map((res) => {
          return {
            placeId: res.place_id,
            types: res.types,
            display: res.description,
          };
        });
      googleFinished = true;
      finishSearch();
    };

    const airportSuggestions = (data) => {
      airportResults = data.slice(0, 1).map((res) => {
        return { placeId: res.id, display: res.text, is_airport: true };
      });
      airportFinished = true;
      finishSearch();
    };

    new google.maps.places.AutocompleteService().getPlacePredictions(
      { input: query, types: [] },
      googleSuggestions
    );
    fetch(`/services/location/airports?n=${encodeURIComponent(query)}`)
      .then((res) => res.json())
      .then(airportSuggestions);
  };

  bidsHandler = () => {
    this.orderHandler(true);
  };

  orderHandler = (bids = false) => {
    // let code = "131072";
    // if (this.props.pickupPlace.isAirport) {
    //   code = "99";
    // } else if (this.props.dropoffPlace?.isAirport) {
    //   code = "100";
    // }
    // if (this.validate()) {
    //   this.trackEvent("search-submitted");
    //   const dropoffDateTime = this.props.completeDate.plus({
    //     hours: this.props.tripWizardValues.durationInHours,
    //   });
    //   const roundTripParams = this.props.returnDate
    //     ? {
    //         is_round_trip: "1",
    //         round_trip_ride_date: this.props.tripWizardValues.returnDate.toFormat(
    //           "MM/dd/yyyy"
    //         ),
    //         round_trip_pickup_time:
    //           this.props.tripWizardValues.returnHour +
    //           ":" +
    //           zeroPad(this.props.tripWizardValues.returnMinute),
    //       }
    //     : {};

    //   const queryParams = {
    //     ...roundTripParams,
    //     ride_date: this.props.tripWizardValues.date.toFormat("MM/dd/yyyy"), // TODO make locale independent
    //     pickup_time:
    //       this.props.tripWizardValues.hour +
    //       ":" +
    //       zeroPad(this.props.tripWizardValues.minute),
    //     drop_off_time:
    //       dropoffDateTime.hour + ":" + zeroPad(dropoffDateTime.minute),
    //     pickup_place_id: !this.props.pickupPlace.airportCode,
    //     dropoff_place_id: !this.props.dropoffPlace?.airportCode,
    //     pickup_place: this.props.pickupPlace.isAirport
    //       ? this.props.pickupPlace.airportCode
    //       : this.props.pickupPlace.placeId,
    //     drop_off_place: this.props.dropoffPlace?.isAirport
    //       ? this.props.dropoffPlace?.airportCode
    //       : this.props.dropoffPlace?.placeId,
    //     /* pickup_place: this.props.pickupPlace.isAirport ? this.props.pickupPlace.airportCode : this.props.pickupPlace.display,
    //      * drop_off_place: this.props.dropoffPlace.isAirport ? this.props.dropoffPlace.airportCode : this.props.dropoffPlace.display, */
    //     // use the below for lat, lng addresses instead
    //     /* pickup_place: this.props.pickupPlace.isAirport ? this.props.pickupPlace.airportCode : this.props.pickupPlace.lat + ',' + this.props.pickupPlace.lng,
    //      * drop_off_place: this.props.dropoffPlace.isAirport ? this.props.dropoffPlace.airportCode : this.props.dropoffPlace.lat + ',' + this.props.dropoffPlace.lng, */
    //     pax:
    //       this.props.partySize === "custom"
    //         ? this.props.customPartySize
    //         : this.props.partySize,
    //     /* vehicle_types: this.props.vehicleTypes, */
    //     vehicle_types: [1, 2, 3, 4, 5, 6, 7, 11, 12, 13], // all types for now, limit by pax (size)
    //     service_type:
    //       this.props.tripWizardValues.isHourly &&
    //       !(
    //         this.props.pickupPlace.isAirport ||
    //         this.props.dropoffPlace?.isAirport
    //       )
    //         ? this.props.tripWizardValues.tripType
    //         : code,
    //     email: this.props.searchForm?.email,
    //     bids: bids,
    //   };
    //   this.props.setSearchParams(queryParams);
    //   this.setState({
    //     loading: true,
    //     redirectToSearchResults: !bids,
    //     redirectToSubmitAuction: bids,
    //     redirectItem: buildUrl("", {
    //       queryParams,
    //     }),
    //   });
    // }
  };

  setScreen = (screen) => {
    this.setState({ screen });
  };

  trackEvent = (name) => {
    if (typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({ event: name, source: "search-form" });
    }
  };

  setPartySize = (v) => {
    this.props.setVehicleOptions({ size: v, types: "unused" });
  };

  setCustomPartySize = (v) => {
    this.props.setCustomPartySize(v);
  };

  navigateToHome = () => {
    window.location.href = '/';
  };

  render() {
    if (this.state.redirectToSearchResults) {
      return (
        <Redirect
          push
          to={routePaths.searchResults + this.state.redirectItem}
        />
      );
    } else if (this.state.redirectToSubmitAuction) {
      return <Redirect push to={routePaths.submitAuction} />;
    } else {
      // maybe change this to a switch
      if (this.state.screen === SCREENS.form) {
        const formElementsArray = [];
        for (let key in this.state.searchForm) {
          formElementsArray.push({
            id: key,
            config: this.state.searchForm[key],
          });
        }
        const errors = this.props.searchFormErrors;
        const invalidClass = (value) => (value ? " field-invalid" : ""),
          completeDate = this.props.completeDate,
          returnDate = this.props.returnDate;
        let dateValue = "";
        if (this.props.finishedTripWizard) {
          dateValue = fullHumanDateTime(completeDate);
          if (returnDate) {
            dateValue += " & " + fullHumanDateTime(returnDate);
          }
        }
        const previousPickupPlace =
          (this.props.pickupPlace?.display && [
            { display: this.props.pickupPlace?.display },
          ]) ||
          [];
        const previousDropoffPlace =
          (this.props.dropoffPlace?.display && [
            { display: this.props.dropoffPlace?.display },
          ]) ||
          [];
        const { searchFormButtonText, bids, emailRequired } = this.props.label;
        let form = (
          <div className="hero-form" align="center">
            <div ref={(el) => (this.instance = el)}></div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                bids ? this.bidsHandler() : this.orderHandler();
              }}
            >
              <div
                className="form-field"
                onClick={() => this.trackEvent("pickup-place")}
              >
                <div className="form-label-limos">
                  <Form.Label> Pickup Location </Form.Label>
                </div>
                <TypeAheadFormField
                  setPlaceValid={(valid) =>
                    this.setPlaceValid("pickupPlaceValid", valid)
                  }
                  inputProps={{
                    "data-hj-whitelist": true,
                    className: "data-hj-whitelist",
                    "data-test": "pickup-place",
                  }}
                  name="pickup_place"
                  id="pickupPlace"
                  useGoogle={true}
                  onSearch={this.onTypeaheadSearch}
                  google={typeof window !== "undefined" && window.google}
                  className={invalidClass(
                    errors.pickupPlace || this.state.badLocations.pickupPlace
                  )}
                  placeholder={
                    this.props.pickupPlaceholder || "City, Zip or Airport"
                  }
                  value={this.props.pickupPlace.item || previousPickupPlace[0]}
                  onPlaceQuery={this.props.onPlaceQuery}
                  queryResults={
                    this.props.pickupPlaceQueryResults.length > 0
                      ? this.props.pickupPlaceQueryResults
                      : previousPickupPlace
                  }
                  onSelected={(selectedItem) =>
                    this.setPlace(selectedItem, "pickupPlace")
                  }
                />
              </div>
              <FieldInvalid value={errors.pickupPlace} />
              <FieldInvalid value={this.state.badLocations.pickupPlace} />
              <div
                className="form-field"
                onClick={() => this.trackEvent("dropoff-place")}
              >
                <div className="form-label-limos">
                  <Form.Label> Dropoff Location </Form.Label>
                </div>
                <TypeAheadFormField
                  inputProps={{
                    "data-hj-whitelist": true,
                    className: "data-hj-whitelist",
                    "data-test": "dropoff-place",
                  }}
                  name="dropoff_place"
                  setPlaceValid={(valid) =>
                    this.setPlaceValid("dropoffPlaceValid", valid)
                  }
                  useGoogle={true}
                  google={typeof window !== "undefined" && window.google}
                  className={invalidClass(
                    errors.dropoffPlace || this.state.badLocations.dropoffPlace
                  )}
                  placeholder="City, Zip or Airport"
                  id="dropoffPlace"
                  onSearch={this.onTypeaheadSearch}
                  value={
                    this.props.dropoffPlace?.item || previousDropoffPlace[0]
                  }
                  queryResults={
                    this.props.dropoffPlaceQueryResults.length > 0
                      ? this.props.dropoffPlaceQueryResults
                      : previousDropoffPlace
                  }
                  onPlaceQuery={this.props.onPlaceQuery}
                  onSelected={(selectedItem) =>
                    this.setPlace(selectedItem, "dropoffPlace")
                  }
                />
              </div>
              <FieldInvalid value={errors.dropoffPlace} />
              <FieldInvalid value={this.state.badLocations.dropoffPlace} />
              <div
                className={
                  "form-field" +
                  invalidClass(errors.date || errors.hour || errors.minute)
                }
                onClick={() => this.trackEvent("trip-wizard")}
              >
                {" "}
                <div className="form-label-limos">
                  <Form.Label> Trip Date </Form.Label>
                </div>
                <Link
                  to={{
                    pathname: routePaths.tripWizard.date,
                    state: { fromCarnival: this.props.fromCarnival },
                  }}
                >
                  <input
                    data-hj-whitelist
                    name="ride_date"
                    placeholder={"Date"}
                    defaultValue={dateValue}
                    className="form-control rbt-input-main rbt-input data-hj-whitelist"
                  />
                </Link>
              </div>
              <FieldInvalid
                value={errors.date || errors.hour || errors.minute}
              />
              <div className="party-size-group">
                <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className={"rbt " + invalidClass(errors.pax)}
                >
                  <div className="form-label-limos">
                    <Form.Label> Passengers </Form.Label>
                  </div>
                  <FieldInvalid value={errors.pax} />
                  <Form.Control
                    as="select"
                    data-test="pax"
                    name="partySize"
                    onChange={(e) => this.setPartySize(e.target.value)}
                    value={this.props.partySize}
                  >
                    <option value="">Select Number of Passengers</option>
                    {Array(8)
                      .fill()
                      .map((_, i) => (
                        <option value={i + 1} key={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    <option value="custom">9+</option>
                  </Form.Control>
                </Form.Group>
              </div>
              {this.props.partySize === "custom" && (
                <div className={"rbt " + invalidClass(errors.customPax)}>
                  <FieldInvalid value={errors.customPax} />
                  <input
                    type="number"
                    placeholder="Enter Number of Passengers"
                    className="form-control rbt-input-main rbt-input data-hj-whitelist"
                    onChange={(e) => this.setCustomPartySize(e.target.value)}
                    value={this.props.customPartySize}
                  />
                </div>
              )}
              {emailRequired ? (
                <div className={"rbt " + invalidClass(errors.email)}>
                  <FieldInvalid value={errors.email} />
                  <input
                    type="email"
                    placeholder="Email Address"
                    className="form-control rbt-input-main rbt-input data-hj-whitelist"
                    onChange={(e) =>
                      this.props.onSearchFormChange("email", e.target.value)
                    }
                  />
                </div>
              ) : (
                <></>
              )}
              <Button
                block
                className="search-button"
                type="submit"
                data-test="submit-search"
                onClick={this.navigateToHome}
              >
                {searchFormButtonText}
              </Button>
              {bids ? (
                <Button
                  block
                  className="search-button button-inverse"
                  onClick={() => this.orderHandler()}
                >
                  Free instant quote
                </Button>
              ) : (
                <></>
              )}
            </form>
            {/* <div className="contact-container">
            <h4>Please click on below home search page link. <br></br> <a href="https://www.limos.com/" className="redirect-home-page">Home Page Serach</a></h4>
            </div> */}
            {/* <div className="contact-container">
              <h4>Looking for booking contracts or need assistance?</h4>
              <div className="button-container">
                <a
                  href={`tel:${this.props.label.telephone}`}
                  className="btn btn-block linkButton"
                >
                  <FontAwesomeIcon icon="phone" /> {this.props.label.telephone}
                </a>
                <Button
                  block
                  onClick={this.props.openChatWindow}
                  className="linkButton chatButton"
                >
                  <FontAwesomeIcon icon="comments" /> Live Chat
                </Button>
              </div>
            </div> */}
          </div>
        );
        if (this.state.loading) {
          form = <Spinner />;
        }
        return (
          <div id="index-page">
            <CallAndChatModal
              openChatWindow={this.props.openChatWindow}
              showModal={this.state.showContactModal}
              toggleShow={this.toggleModalShow}
            />
            {this.state.slideshowImages && (
              <div className="jumbotron-header">
                <div className="jumbotron-content">
                  <div className="info-text">
                    <div className="vehicle-image">
                      <img src="https://assets.s3.amazonaws.com/images/front-end/Vehicles.png" alt="" />
                    </div>
                    <h1>{this.props.formTitle}</h1>
                    <h2>{this.props.formSubtitle}</h2>
                  </div>
                  {form}
                </div>
                {this.props.muteSlideImages && <div className="colorLayer" />}
                <Slider
                  {...{
                    dots: false,
                    infinite: true,
                    speed: 5000,
                    autoplay: true,
                    fade: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    pauseOnHover: false,
                  }}
                >
                  {this.state.slideshowImages.map((img, i) => (
                    <Slide img={img} key={i} />
                  ))}
                </Slider>
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div style={{ marginTop: 124 }}>
            <TripInfoWizard />
          </div>
        );
      }
    }
  }
}

export default withWhiteLabelContext(SearchForm);
