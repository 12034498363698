import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchForm from "../SearchForm/SearchForm.jsx";
import "./AirportPage.scss";

export default class AirportPage extends React.Component {
  render() {
    return (
      <>
        <SearchForm
          pickupPlace={this.props.pickupPlace}
          dropoffPlace={this.props.dropoffPlace}
          setPlace={this.props.setPlace}
          setVehicleOptions={this.props.setVehicleOptions}
          partySize={this.props.partySize}
          vehicleTypes={this.props.vehicleTypes}
          pickupPlaceItem={this.props.pickupPlaceItem}
          onPlaceQuery={this.props.onPlaceQuery}
          pickupPlaceQueryResults={this.props.pickupPlaceQueryResults}
          dropoffPlaceQueryResults={this.props.dropoffPlaceQueryResults}
          tripWizardValues={this.props.tripWizardValues}
          setSearchFormErrors={this.props.setSearchFormErrors}
          searchFormErrors={this.props.searchFormErrors}
          completeDate={this.props.completeDate}
          finishedTripWizard={this.props.finishedTripWizard}
          createInitialSearchFormErrorState={
            this.props.createInitialSearchFormErrorState
          }
          openChatWindow={this.props.openChatWindow}
          errors={this.props.searchFormErrors}
          setError={this.props.setError}
          setCustomPartySize={this.props.setCustomPartySize}
          customPartySize={this.props.customPartySize}
          images={[
            "https://assets.s3.amazonaws.com/images/front-end/businesswoman-in-a-black-mask-sitting-inside-a-car-YHKFH8N-2.jpg",
            "https://assets.s3.amazonaws.com/images/front-end/woman-wear-medical-face-mask-and-use-of-cellphone--8PXLAR2-2.jpg",
            "https://assets.s3.amazonaws.com/images/front-end/multiple-vehicles-1.jpg",
            "https://assets.s3.amazonaws.com/images/front-end/Extra-2.jpg",
          ]}
          formTitle="RELIABLE CAR SERVICE 24/7"
          formSubtitle="SEARCH, COMPARE & BOOK"
        />
        <Container>
          <Row>
            <Col
              xs={12}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
            >
              <div className="airportIconContainer">
                <FontAwesomeIcon icon="plane-arrival" />
              </div>
              <p>
                Limos.com offers the widest variety of airport limo and car
                services. So don't schlep your luggage around in a taxi or
                shared shuttle when you can book any of our airport
                transportation services for close to the same cost. From
                executive limos and town cars to shuttle buses and airport vans,
                starting and ending your trip in style is easy, and surprisingly
                affordable. Happy travels!
              </p>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
