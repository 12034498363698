export const fullHumanDateTime = (date) => {
  return date
    ? date.toLocaleString({
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      })
    : "";
};

export const fullHumanDateTimeZone = (date) => {
  return date
    ? date.toLocaleString({
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
      })
    : "";
};

export const tripWizardDate = (date) => {
  return date
    ? date.toLocaleString({ year: "numeric", month: "long", day: "numeric" })
    : "";
};

export const tripWizardDateHour = (date) => {
  if (date) {
    const s = date.toLocaleString({
      year: "numeric",
      month: "long",
      day: "numeric",
      hour12: true,
      hour: "numeric",
    });
    return s.slice(0, s.length - 3) + ": " + s.slice(-2);
  } else {
    return "";
  }
};

export const tripWizardDateTime = (date) => {
  return date
    ? date.toLocaleString({
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      })
    : "";
};
