import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import App from "./App.js";
import React from "react";
import TagManager from "react-gtm-module";

if (
  window.location &&
  window.location.hostname &&
  window.location.hostname === "www.limos.com"
) {
  const tagManagerArgs = {
    gtmId: "GTM-NPP6ZLZ",
  };
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.hydrate(<App />, document.getElementById("root"));
