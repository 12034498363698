import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const CovidLanding = () => {
  return (
    <div style={{ marginTop: 124 }}>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          <div style={{ margin: "64px auto" }}>
            <h1>COVID-19: Our Response</h1>
            <p>
              During this time of unprecedented uncertainty, all of us at
              Limos.com are concerned about the safety and well-being of our
              families, colleagues, customers, partners, and the many friends
              who make up Limo’s family.
            </p>
            <iframe
              title="Covid19 Safety & Cleaning Video"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/b2OXN2xf_Ts"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <p>
              View our{" "}
              <a href="https://youtu.be/b2OXN2xf_Ts">
                Covid19 Safety & Cleaning Video
              </a>
              .
            </p>
            <p>
              Our hearts are with those who have been affected personally by the
              outbreak of Corona virus (COVID-19) as well as the many
              communities around the world that are facing extreme measures in
              the attempt to slow its spread.
            </p>
            <p>
              We have been honored by the trust you place in us, and in today’s
              challenging environment, we want to provide you more information
              as to how we are securing our vehicles and looking out for your
              safety.
            </p>
          </div>
          <div style={{ margin: "64px auto" }}>
            <h2>GUIDANCE</h2>
            <p>
              Limos.com has established a Pandemic Task force, working to
              coordinate all efforts around the COVID-19 situation and provide
              guidance to Limos.com staff.
            </p>
            <p>Notable actions include but are not limited to:</p>
            <ul>
              <li>All staff meetings are conducted virtually.</li>
              <li>
                Strict hygiene policies have been implemented, and all employees
                are applying “social distancing” and hygiene protocols based on
                guidance from health authorities and our Pandemic Task Force.
              </li>
              <li>
                Limos.com also provides gloves and masks for the protection of
                employees.
              </li>
            </ul>
          </div>
          <div style={{ margin: "64px auto" }}>
            <h2>CUSTOMER ASSURANCE</h2>
            <p>
              For Limos.com maintenance and support functions, we have business
              continuity plans in place that are activated on demand. All
              internal systems, tools, monitors, etc. are designed to allow for
              remote work. We have already activated these measures with no
              disruption in service.
            </p>
          </div>
          <div style={{ margin: "64px auto" }}>
            <h2>HYGIENE STANDARDS</h2>
            <ul>
              <li>
                Perform individual confirmation of chauffeur health prior to
                starting their shift by taking a thermal scan. Employee’s with a
                temperature of 99 degrees or greater will not be permitted to
                work. Other symptoms we watch for include:
                <ul>
                  <li>Persistent cough and/or sneezing</li>
                  <li>Fever</li>
                  <li>Shortness of breath or breathing difficulties</li>
                </ul>
              </li>
              <li>
                Employees must notify management if they believe they may have
                been in contact with an infected individual or if they have
                traveled to a country with significant Covid-19 cases (see{" "}
                <a href="https://www.cdc.gov/coronavirus/2019-ncov/travelers/index.html">
                  CDC list
                </a>
                )
              </li>
              <li>
                Hand hygiene is the most important tactic to prevent infection.
                <ul>
                  <li>Proper hand washing</li>
                  <li>Avoid touching mouth, nose and eyes</li>
                  <li>Use of hand sanitizer</li>
                </ul>
              </li>
              <li>
                Respiratory hygiene standards must also be strictly adhered to
                and enforced.
                <ul>
                  <li>
                    Cover mouth with clean tissue when coughing or sneezing.
                    Dispose of tissue immediately.
                  </li>
                  <li>
                    When tissue is not available, cough into bend of elbow. Not
                    hands.
                  </li>
                </ul>
              </li>
              <li>
                Preventative / proactive increased cleaning of high-touch areas
              </li>
              <li>
                Wash and completely sanitize the inside of vehicles every night.
              </li>
              <li>
                Chauffeurs wipe key areas inside the vehicles at the end of each
                trip.
                <ul>
                  <li>
                    Horizontal surfaces using approved EPA registered
                    cleaner/disinfectant and cleaning cloths, moving from clean
                    to dirty, high to low, so cross-contamination does not occur
                    during the cleaning process.
                  </li>
                  <li>Handrails</li>
                  <li>Armrests</li>
                  <li>Tray tables</li>
                  <li>All door handles</li>
                </ul>
              </li>
              <li>
                Utilize proper personal protective equipment and techniques such
                as:
                <ul>
                  <li>
                    Gloves worn during all cleaning times and change between
                    each cleaning.
                  </li>
                  <li>
                    Wash hands before and after breaks, lunch, and at the
                    beginning and end of each day.
                  </li>
                  <li>Wear masks and gloves at all times while on duty.</li>
                </ul>
              </li>
              <li>Reduced person-to-person contact</li>
              <li>
                Load all busses from back to front; prevents walking past other
                passengers.
              </li>
              <li>
                Passengers are required to wear facial coverings/masks and
                maintain six feet of spatial separation before boarding vehicle.
              </li>
              <li>
                Passengers sit no closer than six feet from the driver and other
                passengers. If you would like, we can mark specific seats
                “UNAVAILABLE”  for passenger usage. Not using these seats, will
                reinforce the social distancing requirement.
              </li>
              <li>
                Safety On-Site: Where on-site work is required, staff who are
                on-site to accomplish specific tasks are spending only the
                minimum time required in locations. Additionally, teams have
                implemented rotating schedules to reduce the number of people in
                a single location and to separate individuals in common teams as
                much as possible. Limos.com  has also sourced gloves and masks
                for the protection of our employees.
              </li>
              <li>
                Disinfection Protocols: Trained teams are available to
                administer disinfection protocols at Limos.com as required and
                are prepared to respond to any infections that may be
                identified.
              </li>
              <li>
                Our Suppliers: Limos.com operations are supported by a number of
                strategic suppliers and we are in contact with all of them
                continually to ensure they apply comparable mitigation practices
                with their teams, while still maintaining service levels. For
                those who supply operational resources, we are focusing on
                scheduling and in-office practices to reduce the impact of any
                potential event. Finally, we are maintaining a database of
                updates and feedback from suppliers to ensure we can quickly
                locate and confirm documented information outlining the COVID-19
                protocols and practices they are supporting.
              </li>
            </ul>
            <p>
              As this situation evolves, your Limos.com team will proactively
              reach out if there is additional support we can provide. In the
              meantime, if you have any further questions about Limos.com
              response to COVID-19, please email{" "}
              <a href="mailto:gary@limos.com">Gary@limos.com</a>.
            </p>
            <p>
              Be Safe and Healthy,
              <br />
              Gary Bauer, CEO / President
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CovidLanding;
