import { useState, useCallback } from "react";
import { makeApiCall } from "../api/api";

const useApi = (url) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const enrollEmailForContest = useCallback(
    async (fetchFrom, method, data) => {
      makeApiCall(fetchFrom, method, JSON.stringify(data))
        .then((response) => {
          if (response.status === 200) {
            setData("Registered Successfully");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setError(error.response.data);
        });
    },
    [data]
  );

  return {
    enrollEmailForContest,
    loading,
    data,
    error,
  };
};

export default useApi;
