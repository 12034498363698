import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../routes/routes";

import "./MemberApplicationForm.scss";

export default function MemberApplicationForm() {
  let history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const [operatorErrors, setOperatorErrors] = useState([]);

  const onSubmit = async (data) => {
    if (errors && Object.keys(errors).length === 0) {
      setOperatorErrors([]);
      let filterCaptchFromData = {};
      let captchObj = {};

      for (const [key, value] of Object.entries(data)) {
        if (key !== "g-recaptcha-response") {
          filterCaptchFromData[key] = value;
        } else {
          captchObj[key] = value;
        }
      }

      const formData = {
        operator_signup: {
          garage_address_same: "0",
          num_insured_vehicles: "1",
          black_vehicle_count: "",
          tell_us_more: "",
          former_account_name: "",
          ...filterCaptchFromData,
        },
        ...captchObj,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      };

  
      try {
        let fetchResponse = await fetch(
          "https://www.limos.com/operators/new_operator_signup",
          requestOptions
        );
        const data = await fetchResponse.json();
        if (data && data.status === "success") {
          history.push(routePaths.thankyou);
        } else {
          setOperatorErrors((arr) => [...arr, data.error]);
        }
      } catch (e) {
        console.log("e", e);
      }
    }
  };

  const handleSetRecaptcha = (captchaData) => {
    setValue("g-recaptcha-response", captchaData);
  };

  return (
    <form className="operator-signup-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="input-field">
        <label htmlFor="legal_name">Company legal name*</label>
        <input
          type="text"
          name="legal_name"
          placeholder="Company legal name"
          {...register("legal_name", {
            required: "Company legal name is required.",
            maxLength: { value: 80, message: "Max length is 80" },
          })}
        />
      </div>
      <div className="error-field">
        {errors?.legal_name && <p>{errors.legal_name.message}</p>}
      </div>

      <div className="input-field">
        <label htmlFor="dba_name">Public display name (DBA)*</label>
        <input
          type="text"
          placeholder="Public display name (DBA)"
          {...register("dba_name", {
            required: "Public display name is required.",
            maxLength: { value: 100, message: "Max length is 100" },
          })}
        />
      </div>
      <div className="error-field">
        {errors.dba_name && <p>{errors.dba_name.message}</p>}
      </div>

      <div className="input-field">
        <label htmlFor="website">Company website</label>
        <input
          type="text"
          placeholder="Company website"
          {...register("website", { required: false })}
        />
      </div>
      <div className="error-field">
        {errors.website && <p>{errors.website.message}</p>}
      </div>

      <div className="input-field">
        <label htmlFor="contact_first">Preferred contact first name*</label>
        <input
          type="text"
          placeholder="Preferred contact first name"
          {...register("contact_first", {
            required: "Preferred contact first name is required.",
            maxLength: {
              value: 100,
              message: "Max length is 100",
            },
          })}
        />
      </div>
      <div className="error-field">
        {errors.contact_first && <p>{errors.contact_first.message}</p>}
      </div>

      <div className="input-field">
        <label htmlFor="contact_last">Preferred contact last name*</label>
        <input
          type="text"
          placeholder="Preferred contact last name"
          {...register("contact_last", {
            required: "Preferred contact last name is required.",
            maxLength: {
              value: 100,
              message: "Max length is 100",
            },
          })}
        />
      </div>
      <div className="error-field">
        {errors.contact_last && <p>{errors.contact_last.message}</p>}
      </div>

      <div className="input-field">
        <label htmlFor="contact_phone">Preferred contact phone number*</label>
        <input
          type="number"
          placeholder="Preferred contact phone number"
          {...register("contact_phone", {
            required: "Preferred contact phone number is required",
            maxLength: 11,
            minLength: 10,
          })}
        />
      </div>
      <div className="error-field">
        {errors.contact_phone && <p>{errors.contact_phone.message}</p>}
      </div>

      <div className="input-field">
        <label htmlFor="contact_email">Preferred contact email*</label>
        <input
          type="email"
          placeholder="Preferred contact email"
          {...register("contact_email", {
            required: "Preferred contact email is required.",
            pattern: /^\S+@\S+$/i,
          })}
        />
      </div>
      <div className="error-field">
        {errors.contact_email && <p>{errors.contact_email.message}</p>}
      </div>

      <div className="input-field">
        <label htmlFor="address_street">Street Address*</label>
        <input
          type="text"
          placeholder="Street Address"
          {...register("address_street", {
            required: "Street Address is required.",
            maxLength: {
              value: 100,
              message: "Max length is 100",
            },
          })}
        />
      </div>
      <div className="error-field">
        {errors.address_street && <p>{errors.address_street.message}</p>}
      </div>

      <div className="input-field">
        <label htmlFor="address_city">City*</label>
        <input
          type="text"
          placeholder="City"
          {...register("address_city", {
            required: "City is required",
            maxLength: {
              value: 100,
              message: "Max length is 100",
            },
          })}
        />
      </div>
      <div className="error-field">
        {errors.address_city && <p>{errors.address_city.message}</p>}
      </div>

      <div className="input-field">
        <label htmlFor="address_zip">Postal Code/Zip Code*</label>
        <input
          type="text"
          placeholder="Postal Code/Zip Code"
          {...register("address_zip", {
            required: "Postal Code/Zip Code is required.",
            maxLength: {
              value: 6,
              message: "Max length is 6",
            },
          })}
        />
      </div>
      <div className="error-field">
        {errors.address_zip && <p>{errors.address_zip.message}</p>}
      </div>

      <div className="input-field">
        <label htmlFor="address_country">Country*</label>
        <select
          {...register("address_country", { required: "Country is required." })}
        >
          <optgroup label="Americas, Caribbean">
            <option value="AI">Anguilla</option>
            <option value="AG">Antigua and Barbuda</option>
            <option value="AR">Argentina</option>
            <option value="AW">Aruba</option>
            <option value="BS">Bahamas, The</option>
            <option value="BB">Barbados</option>
            <option value="BZ">Belize</option>
            <option value="BM">Bermuda</option>
            <option value="BO">Bolivia</option>
            <option value="BR">Brazil</option>
            <option value="VG">British Virgin Islands</option>
            <option value="CA">Canada</option>
            <option value="KY">Cayman Islands</option>
            <option value="CL">Chile</option>
            <option value="CO">Colombia</option>
            <option value="CR">Costa Rica</option>
            <option value="CU">Cuba</option>
            <option value="DM">Dominica</option>
            <option value="DO">Dominican Republic</option>
            <option value="EC">Ecuador</option>
            <option value="SV">El Salvador</option>
            <option value="FK">Falkland Islands (Islas Malvinas)</option>
            <option value="GF">French Guiana</option>
            <option value="GD">Grenada</option>
            <option value="GP">Guadeloupe</option>
            <option value="GT">Guatemala</option>
            <option value="GY">Guyana</option>
            <option value="HT">Haiti</option>
            <option value="HN">Honduras</option>
            <option value="JM">Jamaica</option>
            <option value="MQ">Martinique</option>
            <option value="MX">Mexico</option>
            <option value="MS">Montserrat</option>
            <option value="AN">Netherlands Antilles</option>
            <option value="NI">Nicaragua</option>
            <option value="PA">Panama</option>
            <option value="PY">Paraguay</option>
            <option value="PE">Peru</option>
            <option value="PR">Puerto Rico</option>
            <option value="KN">Saint Kitts and Nevis</option>
            <option value="LC">Saint Lucia</option>
            <option value="VC">Saint Vincent and the Grenadines</option>
            <option value="SR">Suriname</option>
            <option value="TT">Trinidad and Tobago</option>
            <option value="TC">Turks and Caicos Islands</option>
            <option value="US" selected="selected">
              United States
            </option>
            <option value="UY">Uruguay</option>
            <option value="VE">Venezuela</option>
            <option value="VI">Virgin Islands</option>
          </optgroup>
          <optgroup label="Europe, Eastern Europe">
            <option value="AL">Albania</option>
            <option value="AD">Andorra</option>
            <option value="AT">Austria</option>
            <option value="BY">Belarus</option>
            <option value="BE">Belgium</option>
            <option value="BG">Bulgaria</option>
            <option value="HR">Croatia</option>
            <option value="CY">Cyprus</option>
            <option value="CZ">Czech Republic</option>
            <option value="DK">Denmark</option>
            <option value="EE">Estonia</option>
            <option value="FO">Faroe Islands</option>
            <option value="FI">Finland</option>
            <option value="FR">France</option>
            <option value="DE">Germany</option>
            <option value="GI">Gibraltar</option>
            <option value="GR">Greece</option>
            <option value="GL">Greenland</option>
            <option value="VA">Holy See (Vatican City)</option>
            <option value="HU">Hungary</option>
            <option value="IS">Iceland</option>
            <option value="IE">Ireland</option>
            <option value="IT">Italy</option>
            <option value="LV">Latvia</option>
            <option value="LI">Liechtenstein</option>
            <option value="LT">Lithuania</option>
            <option value="LU">Luxembourg</option>
            <option value="MK">
              The Former Yugoslav Republic of Macedonia
            </option>
            <option value="MT">Malta</option>
            <option value="MD">Moldova, Republic of</option>
            <option value="MC">Monaco</option>
            <option value="NL">Netherlands</option>
            <option value="NO">Norway</option>
            <option value="PL">Poland</option>
            <option value="PT">Portugal</option>
            <option value="RO">Romania</option>
            <option value="RU">Russia</option>
            <option value="SM">San Marino</option>
            <option value="CS">Serbia and Montenegro</option>
            <option value="SK">Slovakia</option>
            <option value="SI">Slovenia</option>
            <option value="ES">Spain</option>
            <option value="SE">Sweden</option>
            <option value="CH">Switzerland</option>
            <option value="TR">Turkey</option>
            <option value="UA">Ukraine</option>
            <option value="GB">United Kingdom</option>
          </optgroup>
          <optgroup label="Africa, Middle East">
            <option value="DZ">Algeria</option>
            <option value="AO">Angola</option>
            <option value="BH">Bahrain</option>
            <option value="BJ">Benin</option>
            <option value="BW">Botswana</option>
            <option value="BF">Burkina Faso</option>
            <option value="BI">Burundi</option>
            <option value="CM">Cameroon</option>
            <option value="CV">Cape Verde</option>
            <option value="CF">Central African Republic</option>
            <option value="TD">Chad</option>
            <option value="KM">Comoros</option>
            <option value="CD">Congo, Democratic Republic of the</option>
            <option value="CG">Congo, Republic of the</option>
            <option value="CI">Cote d'Ivoire</option>
            <option value="DJ">Djibouti</option>
            <option value="EG">Egypt</option>
            <option value="GQ">Equatorial Guinea</option>
            <option value="ER">Eritrea</option>
            <option value="ET">Ethiopia</option>
            <option value="GA">Gabon</option>
            <option value="GM">Gambia, The</option>
            <option value="GH">Ghana</option>
            <option value="GN">Guinea</option>
            <option value="GW">Guinea-Bissau</option>
            <option value="IQ">Iraq</option>
            <option value="IL">Israel</option>
            <option value="JO">Jordan</option>
            <option value="KE">Kenya</option>
            <option value="KW">Kuwait</option>
            <option value="LB">Lebanon</option>
            <option value="LS">Lesotho</option>
            <option value="LR">Liberia</option>
            <option value="LY">Libyan Arab</option>
            <option value="MG">Madagascar</option>
            <option value="MW">Malawi</option>
            <option value="ML">Mali</option>
            <option value="MR">Mauritania</option>
            <option value="MU">Mauritius</option>
            <option value="YT">Mayotte</option>
            <option value="MA">Morocco</option>
            <option value="MZ">Mozambique</option>
            <option value="NA">Namibia</option>
            <option value="NE">Niger</option>
            <option value="NG">Nigeria</option>
            <option value="OM">Oman</option>
            <option value="QA">Qatar</option>
            <option value="RW">Rwanda</option>
            <option value="ST">Sao Tome and Principe</option>
            <option value="SA">Saudi Arabia</option>
            <option value="SN">Senegal</option>
            <option value="SC">Seychelles</option>
            <option value="SL">Sierra Leone</option>
            <option value="SO">Somalia</option>
            <option value="ZA">South Africa</option>
            <option value="LK">Sri Lanka</option>
            <option value="SD">Sudan</option>
            <option value="SZ">Swaziland</option>
            <option value="SY">Syrian Arab Republic</option>
            <option value="TZ">Tanzania, United Republic of</option>
            <option value="TG">Togo</option>
            <option value="TN">Tunisia</option>
            <option value="UG">Uganda</option>
            <option value="AE">United Arab Emirates</option>
            <option value="EH">Western Sahara</option>
            <option value="YE">Yemen</option>
            <option value="ZM">Zambia</option>
            <option value="ZW">Zimbabwe</option>
          </optgroup>
          <optgroup label="Asia, Pacific">
            <option value="AS">American Samoa</option>
            <option value="AM">Armenia</option>
            <option value="AU">Australia</option>
            <option value="AZ">Azerbaijan</option>
            <option value="BD">Bangladesh</option>
            <option value="BT">Bhutan</option>
            <option value="BA">Bosnia and Herzegovina</option>
            <option value="BN">Brunei</option>
            <option value="KH">Cambodia</option>
            <option value="CN">China</option>
            <option value="CK">Cook Islands</option>
            <option value="TL">East Timor</option>
            <option value="FJ">Fiji</option>
            <option value="PF">French Polynesia</option>
            <option value="GE">Georgia</option>
            <option value="GU">Guam</option>
            <option value="HK">Hong Kong</option>
            <option value="IN">India</option>
            <option value="ID">Indonesia</option>
            <option value="JP">Japan</option>
            <option value="KZ">Kazakhstan</option>
            <option value="KI">Kiribati</option>
            <option value="KR">Korea, South</option>
            <option value="KG">Kyrgyzstan</option>
            <option value="LA">Laos</option>
            <option value="MO">Macau</option>
            <option value="MY">Malaysia</option>
            <option value="MV">Maldives</option>
            <option value="MH">Marshall Islands</option>
            <option value="FM">Micronesia, Federated States of</option>
            <option value="MN">Mongolia</option>
            <option value="NR">Nauru</option>
            <option value="NP">Nepal</option>
            <option value="NC">New Caledonia</option>
            <option value="NZ">New Zealand</option>
            <option value="MP">Northern Mariana Islands</option>
            <option value="PK">Pakistan</option>
            <option value="PW">Palau</option>
            <option value="PG">Papua New Guinea</option>
            <option value="PH">Philippines</option>
            <option value="RE">Reunion</option>
            <option value="WS">Samoa</option>
            <option value="SG">Singapore</option>
            <option value="SB">Solomon Islands</option>
            <option value="TW">Taiwan</option>
            <option value="TJ">Tajikistan</option>
            <option value="TH">Thailand</option>
            <option value="TO">Tonga</option>
            <option value="TM">Turkmenistan</option>
            <option value="UZ">Uzbekistan</option>
            <option value="VU">Vanuatu</option>
            <option value="VN">Vietnam</option>
          </optgroup>
        </select>
      </div>
      <div className="error-field">
        {errors.address_country && <p>{errors.address_country.message}</p>}
      </div>

      <div className="input-field input-radio-field">
        <label>Do you have $1 million in auto liability insurance?</label>
        <input
          {...register("has_enough_insurance", { required: false })}
          type="radio"
          value="true"
        />
        <label for="yes">Yes</label>

        <input
          {...register("has_enough_insurance", { required: false })}
          type="radio"
          value="false"
          style={{ marginLeft: "20px" }}
        />
        <label for="no">No</label>
      </div>

      <div className="input-field">
        <label htmlFor="markets_serviced">What markets do you service?</label>
        <textarea
          {...register("markets_serviced", {
            required: false,
            max: 100,
            min: -1,
            maxLength: 100,
          })}
        />
      </div>
      <div className="error-field">
        {errors.markets_serviced && <p>{errors.markets_serviced.message}</p>}
      </div>

      <div className="input-field">
        <label htmlFor="how_heard">How did you hear about us?</label>
        <input
          type="text"
          placeholder=""
          {...register("how_heard", { required: false })}
        />
      </div>

      <div className="form-item google-recaptcha">
        <ReCAPTCHA
          sitekey="6LfQXQ4TAAAAAKOCRzXHyTkP7rnlCgvCM1MlPw9q"
          onChange={handleSetRecaptcha}
        />
      </div>
      <div className="error-field">
        {errors.gcaptcha && <p>{errors.gcaptcha.message}</p>}
      </div>

      {operatorErrors.length > 0 && (
        <div className="error-field">
          {operatorErrors.map((error, index) => {
            return <p>{error}</p>;
          })}
        </div>
      )}

      <input type="submit" value="Sign Up as an Operator!" />
    </form>
  );
}
