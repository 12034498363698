import React from "react";
import PickerGrid from "../PickerGrid/PickerGrid.jsx";
import PickerGridButton from "../PickerGrid/PickerGridButton.jsx";

import { withWhiteLabelContext } from "react-whitelabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const durations = new Array(16).fill().map((x, i) => i + 1);

class DurationPicker extends React.Component {
  constructor(props) {
    super(props);
    if (typeof document !== "undefined") {
      document.title = this.props.label.title + " - Select Duration";
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col className={"wizardPageTitle"}>
            <FontAwesomeIcon icon="stopwatch" /> Duration of Trip
          </Col>
        </Row>
        <Row>
          <Col className={"wizardPageTitle"}>
            {this.props.date.toLocaleString()}
          </Col>
        </Row>
        <Row>
          <Col className={"wizardPageTitle"}>How many hours are needed?</Col>
        </Row>
        <PickerGrid
          name="duration-picker"
          defaultValue={this.props.durationInHours}
          onChange={this.props.setDuration}
          segmentSize={4}
        >
          {durations.map((duration, idx) => (
            <PickerGridButton value={idx + 1} key={idx}>
              {duration}
            </PickerGridButton>
          ))}
        </PickerGrid>
      </>
    );
  }
}

export default withWhiteLabelContext(DurationPicker);
