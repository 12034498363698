import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import React from "react"
import buildUrl from "build-url"
import axios from "axios"
import { endpoints } from "../../globals.js"
import "./SiteMap.scss"

export default class SiteMap extends React.Component {
  state = {
    siteMapLinks: [
      "https://www.limos.com/",
      "https://www.limos.com/a/services",
      "https://www.limos.com/a/vehicles",
      "https://www.limos.com/a/events",
      "https://www.limos.com/signin-rsvrlookup",
      "https://www.limos.com/a/covid-19",
      "https://www.limos.com/operators/join",
      "https://www.limos.com/a/operator-form",
      "https://www.limos.com/a/terms",
      "https://www.limos.com/a/privacy-policy",
      "https://www.limos.com/limo-price-guide",
      "https://www.limos.com/vehicle-guide",
      "https://www.limos.com/limos-101-pricing-and-planning-guide",
      "https://www.limos.com/customers/faqs",
      "https://www.limos.com/sign-in",
      "https://www.limos.com/signin",
      "https://www.limos.com/reviews",
      "https://www.limos.com/event-request",
      "https://www.limos.com/users/password/forgot",
      "https://www.limos.com/register",
      "https://www.limos.com/customers/terms-privacy",
      "https://www.limos.com/geo",
      "https://www.limos.com/special-occasions/cruiseport",
      "https://www.limos.com/special-occasions/destination",
      "https://www.limos.com/special-occasions/wedding",
      "https://www.limos.com/special-occasions/prom",
      "https://www.limos.com/special-occasions/birthday",
      "https://www.limos.com/special-occasions/nightout",
      "https://www.limos.com/special-occasions/sports",
      "https://www.limos.com/special-occasions/concert",
      "https://www.limos.com/special-occasions/wine",
      "https://www.limos.com/special-occasions/anniversary",
      "https://www.limos.com/special-occasions/bachelor",
      "https://www.limos.com/special-occasions/bachelorette",
      "https://www.limos.com/special-occasions/corporate",
      "https://www.limos.com/special-occasions/holiday_light_tour",
      "https://www.limos.com/special-occasions/hourly",
      "https://www.limos.com/special-occasions/school",
      "https://www.limos.com/geo/United-States/GA/Atlanta",
      "https://www.limos.com/geo/United-States/TX/Austin",
      "https://www.limos.com/geo/United-States/MD/Baltimore",
      "https://www.limos.com/geo/United-States/MA/Boston",
      "https://www.limos.com/geo/United-States/NC/Charlotte",
      "https://www.limos.com/geo/United-States/IL/Chicago",
      "https://www.limos.com/geo/United-States/OH/Columbus",
      "https://www.limos.com/geo/United-States/TX/Dallas",
      "https://www.limos.com/geo/United-States/CO/Denver",
      "https://www.limos.com/geo/United-States/TX/Houston",
      "https://www.limos.com/geo/United-States/IN/Indianapolis",
      "https://www.limos.com/geo/United-States/CA/Los-Angeles",
      "https://www.limos.com/geo/United-States/NV/Las-Vegas",
      "https://www.limos.com/geo/United-States/FL/Miami",
      "https://www.limos.com/geo/United-States/MN/Minneapolis",
      "https://www.limos.com/geo/United-States/NY/New-York",
      "https://www.limos.com/geo/United-States/FL/Orlando",
      "https://www.limos.com/geo/United-States/PA/Philadelphia",
      "https://www.limos.com/geo/United-States/AZ/Phoenix",
      "https://www.limos.com/geo/United-States/OR/Portland",
      "https://www.limos.com/geo/United-States/MO/Saint-Louis",
      "https://www.limos.com/geo/United-States/TX/San-Antonio",
      "https://www.limos.com/geo/United-States/CA/San-Diego",
      "https://www.limos.com/geo/United-States/CA/San-Jose",
      "https://www.limos.com/geo/United-States/CA/San-Francisco",
      "https://www.limos.com/geo/United-States/WA/Seattle",
      "https://www.limos.com/geo/United-States/DC/Washington",
      "https://www.limos.com/geo/United-States",
      "https://www.limos.com/geo/Canada",
      "https://www.limos.com/geo/Mexico",
      "https://www.limos.com/geo/United-Kingdom",
      "https://www.limos.com/corporate",
      "https://www.limos.com/mobile",
      "https://www.limos.com/airport-limo",
      "https://www.limos.com/a/trip-wizard/date",
      "https://www.limos.com/geo/United-States/GA",
      "https://www.limos.com/geo/United-States/GA/Atlanta/Reviews",
      "https://www.limos.com/airports/United-States/ATL",
      "https://www.limos.com/airports/United-States/MGE",
      "https://www.limos.com/airports/United-States/ATL-FBO",
      "https://www.limos.com/airports/United-States/PDK",
      "https://www.limos.com/airports/United-States/FTY",
      "https://www.limos.com/geo/United-States/MD",
      "https://www.limos.com/geo/United-States/MD/Baltimore/Reviews",
      "https://www.limos.com/airports/United-States/ADW",
      "https://www.limos.com/airports/United-States/APG",
      "https://www.limos.com/airports/United-States/BWI",
      "https://www.limos.com/airports/United-States/DCA",
      "https://www.limos.com/airports/United-States/BWI-FBO",
      "https://www.limos.com/airports/United-States/DCA-FBO",
      "https://www.limos.com/geo/United-States/MA",
      "https://www.limos.com/geo/United-States/MA/Boston/Reviews",
      "https://www.limos.com/airports/United-States/BED",
      "https://www.limos.com/airports/United-States/BOS",
      "https://www.limos.com/airports/United-States/PVD",
      "https://www.limos.com/airports/United-States/SFZ",
      "https://www.limos.com/airports/United-States/BOS-FBO",
      "https://www.limos.com/airports/United-States/PVD-FBO",
      "https://www.limos.com/airports/United-States/MHT-FBO",
      "https://www.limos.com/airports/United-States/MHT",
      "https://www.limos.com/airports/United-States/9B1",
      "https://www.limos.com/geo/United-States/NC",
      "https://www.limos.com/geo/United-States/NC/Charlotte/Reviews",
      "https://www.limos.com/airports/United-States/CLT",
      "https://www.limos.com/airports/United-States/HKY",
      "https://www.limos.com/airports/United-States/CLT-FBO",
      "https://www.limos.com/airports/United-States/JQF",
      "https://www.limos.com/geo/United-States/TX",
      "https://www.limos.com/geo/United-States/TX/Austin/Reviews",
      "https://www.limos.com/airports/United-States/AUS",
      "https://www.limos.com/airports/United-States/AUS-FBO",
      "https://www.limos.com/airports/United-States/KDZB",
      "https://www.limos.com/geo/United-States/IL",
      "https://www.limos.com/geo/United-States/IL/Chicago/Airports",
      "https://www.limos.com/geo/United-States/IL/Chicago/Reviews",
      "https://www.limos.com/geo/United-States/IL/Chicago/Things-To-Do",
      "https://www.limos.com/airports/United-States/DPA",
      "https://www.limos.com/airports/United-States/MDW",
      "https://www.limos.com/airports/United-States/ORD",
      "https://www.limos.com/airports/United-States/UGN",
      "https://www.limos.com/airports/United-States/MDW-FBO",
      "https://www.limos.com/airports/United-States/ORD-FBO",
      "https://www.limos.com/special-occasions",
      "https://www.limos.com/geo/United-States/OH",
      "https://www.limos.com/geo/United-States/OH/Columbus/Reviews",
      "https://www.limos.com/airports/United-States/CMH",
      "https://www.limos.com/airports/United-States/LCK",
      "https://www.limos.com/airports/United-States/CMH-FBO",
      "https://www.limos.com/airports/United-States/OSU",
      "https://www.limos.com/airports/United-States/KDLZ",
      "https://www.limos.com/geo/United-States/TX/Dallas/Airports",
      "https://www.limos.com/geo/United-States/TX/Dallas/Reviews",
      "https://www.limos.com/geo/United-States/TX/Dallas/Things-To-Do",
      "https://www.limos.com/airports/United-States/DAL",
      "https://www.limos.com/airports/United-States/DFW",
      "https://www.limos.com/airports/United-States/FTW",
      "https://www.limos.com/airports/United-States/GVT",
      "https://www.limos.com/airports/United-States/DFW-FBO",
      "https://www.limos.com/airports/United-States/DAL-FBO",
      "https://www.limos.com/airports/United-States/ADS",
      "https://www.limos.com/geo/United-States/CO",
      "https://www.limos.com/geo/United-States/CO/Denver/Reviews",
      "https://www.limos.com/airports/United-States/BKF",
      "https://www.limos.com/airports/United-States/DEN",
      "https://www.limos.com/airports/United-States/DEN-FBO",
      "https://www.limos.com/airports/United-States/BJC",
      "https://www.limos.com/airports/United-States/APA",
      "https://www.limos.com/airports/United-States/KAPA",
      "https://www.limos.com/geo/United-States/TX/Houston/Reviews",
      "https://www.limos.com/airports/United-States/CXO",
      "https://www.limos.com/airports/United-States/EFD",
      "https://www.limos.com/airports/United-States/GLS",
      "https://www.limos.com/airports/United-States/HOU",
      "https://www.limos.com/airports/United-States/IAH",
      "https://www.limos.com/airports/United-States/IAH-FBO",
      "https://www.limos.com/airports/United-States/HOU-FBO",
      "https://www.limos.com/airports/United-States/SGR",
      "https://www.limos.com/airports/United-States/DWH",
      "https://www.limos.com/geo/United-States/IN",
      "https://www.limos.com/geo/United-States/IN/Indianapolis/Reviews",
      "https://www.limos.com/airports/United-States/IND",
      "https://www.limos.com/airports/United-States/IND-FBO",
      "https://www.limos.com/geo/United-States/CA",
      "https://www.limos.com/geo/United-States/CA/Los-Angeles/Airports",
      "https://www.limos.com/geo/United-States/CA/Los-Angeles/Reviews",
      "https://www.limos.com/geo/United-States/CA/Los-Angeles/Things-To-Do",
      "https://www.limos.com/airports/United-States/BUR",
      "https://www.limos.com/airports/United-States/HHR",
      "https://www.limos.com/airports/United-States/LAX",
      "https://www.limos.com/airports/United-States/LGB",
      "https://www.limos.com/airports/United-States/NTK",
      "https://www.limos.com/airports/United-States/ONT",
      "https://www.limos.com/airports/United-States/PMD",
      "https://www.limos.com/airports/United-States/RAL",
      "https://www.limos.com/airports/United-States/SNA",
      "https://www.limos.com/airports/United-States/BUR-FBO",
      "https://www.limos.com/airports/United-States/SNA-FBO",
      "https://www.limos.com/airports/United-States/LGB-FBO",
      "https://www.limos.com/airports/United-States/LAX-FBO",
      "https://www.limos.com/airports/United-States/ONT-FBO",
      "https://www.limos.com/airports/United-States/VNY",
      "https://www.limos.com/airports/United-States/SMO",
      "https://www.limos.com/geo/United-States/FL",
      "https://www.limos.com/geo/United-States/FL/Miami/Reviews",
      "https://www.limos.com/airports/United-States/BCT",
      "https://www.limos.com/airports/United-States/FLL",
      "https://www.limos.com/airports/United-States/FXE",
      "https://www.limos.com/airports/United-States/HST",
      "https://www.limos.com/airports/United-States/HWO",
      "https://www.limos.com/airports/United-States/MIA",
      "https://www.limos.com/airports/United-States/OPF",
      "https://www.limos.com/airports/United-States/TMB",
      "https://www.limos.com/airports/United-States/TNT",
      "https://www.limos.com/airports/United-States/FLL-FBO",
      "https://www.limos.com/airports/United-States/MIA-FBO",
      "https://www.limos.com/geo/United-States/NV",
      "https://www.limos.com/geo/United-States/NV/Las-Vegas/Reviews",
      "https://www.limos.com/airports/United-States/INS",
      "https://www.limos.com/airports/United-States/LAS",
      "https://www.limos.com/airports/United-States/LSV",
      "https://www.limos.com/airports/United-States/LAS-FBO",
      "https://www.limos.com/airports/United-States/VGT",
      "https://www.limos.com/airports/United-States/HSH",
      "https://www.limos.com/geo/United-States/MN",
      "https://www.limos.com/geo/United-States/MN/Minneapolis/Reviews",
      "https://www.limos.com/airports/United-States/MSP",
      "https://www.limos.com/airports/United-States/PNM",
      "https://www.limos.com/airports/United-States/MSP-FBO",
      "https://www.limos.com/airports/United-States/FCM",
      "https://www.limos.com/geo/United-States/NY",
      "https://www.limos.com/geo/United-States/NY/New-York/Airports",
      "https://www.limos.com/geo/United-States/NY/New-York/Reviews",
      "https://www.limos.com/geo/United-States/NY/New-York/Things-To-Do",
      "https://www.limos.com/airports/United-States/EWR",
      "https://www.limos.com/airports/United-States/HPN",
      "https://www.limos.com/airports/United-States/ISP",
      "https://www.limos.com/airports/United-States/JFK",
      "https://www.limos.com/airports/United-States/LGA",
      "https://www.limos.com/airports/United-States/TEB",
      "https://www.limos.com/airports/United-States/JFK-FBO",
      "https://www.limos.com/airports/United-States/LGA-FBO",
      "https://www.limos.com/airports/United-States/ISP-FBO",
      "https://www.limos.com/airports/United-States/EWR-FBO",
      "https://www.limos.com/airports/United-States/HPN-FBO",
      "https://www.limos.com/airports/United-States/MMU",
      "https://www.limos.com/airports/United-States/FRG",
      "https://www.limos.com/airports/United-States/CDW",
      "https://www.limos.com/geo/United-States/NY/Brooklyn",
      "https://www.limos.com/geo/United-States/NY/Bronx",
      "https://www.limos.com/geo/United-States/NY/Staten-Island",
      "https://www.limos.com/geo/United-States/NY/Queens-Village",
      "https://www.limos.com/geo/United-States/FL/Orlando/Reviews",
      "https://www.limos.com/airports/United-States/MCO",
      "https://www.limos.com/airports/United-States/SFB",
      "https://www.limos.com/airports/United-States/ORL",
      "https://www.limos.com/airports/United-States/MCO-FBO",
      "https://www.limos.com/airports/United-States/SFB-FBO",
      "https://www.limos.com/airports/United-States/DAB",
      "https://www.limos.com/airports/United-States/GIF",
      "https://www.limos.com/geo/United-States/PA",
      "https://www.limos.com/geo/United-States/PA/Philadelphia/Reviews",
      "https://www.limos.com/airports/United-States/ACY",
      "https://www.limos.com/airports/United-States/ILG",
      "https://www.limos.com/airports/United-States/MIV",
      "https://www.limos.com/airports/United-States/NEL",
      "https://www.limos.com/airports/United-States/NXX",
      "https://www.limos.com/airports/United-States/PHL",
      "https://www.limos.com/airports/United-States/PNE",
      "https://www.limos.com/airports/United-States/TTN",
      "https://www.limos.com/airports/United-States/WRI",
      "https://www.limos.com/airports/United-States/ACY-FBO",
      "https://www.limos.com/airports/United-States/PHL-FBO",
      "https://www.limos.com/airports/United-States/ABE-FBO",
      "https://www.limos.com/airports/United-States/ABE",
      "https://www.limos.com/geo/United-States/OR",
      "https://www.limos.com/geo/United-States/OR/Portland/Reviews",
      "https://www.limos.com/airports/United-States/MMV",
      "https://www.limos.com/airports/United-States/PDX",
      "https://www.limos.com/airports/United-States/SPB",
      "https://www.limos.com/airports/United-States/PDX-FBO",
      "https://www.limos.com/airports/United-States/HIO",
      "https://www.limos.com/airports/United-States/HIO-FBO",
      "https://www.limos.com/geo/United-States/AZ",
      "https://www.limos.com/geo/United-States/AZ/Phoenix/Reviews",
      "https://www.limos.com/airports/United-States/LUF",
      "https://www.limos.com/airports/United-States/PHX",
      "https://www.limos.com/airports/United-States/PHX-FBO",
      "https://www.limos.com/airports/United-States/SDL-FBO",
      "https://www.limos.com/airports/United-States/AZA",
      "https://www.limos.com/airports/United-States/DVT",
      "https://www.limos.com/airports/United-States/GEU",
      "https://www.limos.com/geo/United-States/MO",
      "https://www.limos.com/geo/United-States/MO/Saint-Louis/Reviews",
      "https://www.limos.com/airports/United-States/BLV",
      "https://www.limos.com/airports/United-States/STL",
      "https://www.limos.com/airports/United-States/STL-FBO",
      "https://www.limos.com/airports/United-States/SUS",
      "https://www.limos.com/airports/United-States/CPS",
      "https://www.limos.com/geo/United-States/TX/San-Antonio/Reviews",
      "https://www.limos.com/airports/United-States/RND",
      "https://www.limos.com/airports/United-States/SAT",
      "https://www.limos.com/airports/United-States/SKF",
      "https://www.limos.com/airports/United-States/SAT-FBO",
      "https://www.limos.com/geo/United-States/CA/San-Diego/Reviews",
      "https://www.limos.com/airports/United-States/NKX",
      "https://www.limos.com/airports/United-States/NZY",
      "https://www.limos.com/airports/United-States/SAN",
      "https://www.limos.com/airports/United-States/SAN-FBO",
      "https://www.limos.com/airports/United-States/CLD",
      "https://www.limos.com/airports/United-States/CRQ-FBO",
      "https://www.limos.com/geo/United-States/CA/San-Jose/Reviews",
      "https://www.limos.com/airports/United-States/NUQ",
      "https://www.limos.com/airports/United-States/OAK",
      "https://www.limos.com/airports/United-States/SFO",
      "https://www.limos.com/airports/United-States/SJC",
      "https://www.limos.com/airports/United-States/OAK-FBO",
      "https://www.limos.com/airports/United-States/SJC-FBO",
      "https://www.limos.com/airports/United-States/SFO-FBO",
      "https://www.limos.com/geo/United-States/CA/San-Francisco/Airports",
      "https://www.limos.com/geo/United-States/CA/San-Francisco/Reviews",
      "https://www.limos.com/geo/United-States/CA/San-Francisco/Things-To-Do",
      "https://www.limos.com/airports/United-States/SUU",
      "https://www.limos.com/airports/United-States/APC",
      "https://www.limos.com/geo/United-States/WA",
      "https://www.limos.com/geo/United-States/WA/Seattle/Reviews",
      "https://www.limos.com/airports/United-States/BFI",
      "https://www.limos.com/airports/United-States/GRF",
      "https://www.limos.com/airports/United-States/NUW",
      "https://www.limos.com/airports/United-States/PAE",
      "https://www.limos.com/airports/United-States/SEA",
      "https://www.limos.com/airports/United-States/TCM",
      "https://www.limos.com/airports/United-States/SEA-FBO",
      "https://www.limos.com/geo/United-States/DC",
      "https://www.limos.com/geo/United-States/DC/Washington/Reviews",
      "https://www.limos.com/airports/United-States/IAD",
      "https://www.limos.com/airports/United-States/NYG",
      "https://www.limos.com/airports/United-States/IAD-FBO",
      "https://www.limos.com/airports/United-States/HEF",
      "https://www.limos.com/geo/Canada/BC",
      "https://www.limos.com/geo/Canada/ON",
      "https://www.limos.com/geo/Canada/NS",
      "https://www.limos.com/geo/Canada/QC",
      "https://www.limos.com/geo/United-States/AL",
      "https://www.limos.com/geo/United-States/MT",
      "https://www.limos.com/geo/United-States/AK",
      "https://www.limos.com/geo/United-States/NE",
      "https://www.limos.com/geo/United-States/AR",
      "https://www.limos.com/geo/United-States/NH",
      "https://www.limos.com/geo/United-States/NJ",
      "https://www.limos.com/geo/United-States/NM",
      "https://www.limos.com/geo/United-States/CT",
      "https://www.limos.com/geo/United-States/DE",
      "https://www.limos.com/geo/United-States/ND",
      "https://www.limos.com/geo/United-States/OK",
      "https://www.limos.com/geo/United-States/HI",
      "https://www.limos.com/geo/United-States/ID",
      "https://www.limos.com/geo/United-States/RI",
      "https://www.limos.com/geo/United-States/SC",
      "https://www.limos.com/geo/United-States/IA",
      "https://www.limos.com/geo/United-States/SD",
      "https://www.limos.com/geo/United-States/KS",
      "https://www.limos.com/geo/United-States/TN",
      "https://www.limos.com/geo/United-States/KY",
      "https://www.limos.com/geo/United-States/LA",
      "https://www.limos.com/geo/United-States/UT",
      "https://www.limos.com/geo/United-States/ME",
      "https://www.limos.com/geo/United-States/VT",
      "https://www.limos.com/geo/United-States/VA",
      "https://www.limos.com/geo/United-States/MI",
      "https://www.limos.com/geo/United-States/WV",
      "https://www.limos.com/geo/United-States/WI",
      "https://www.limos.com/geo/United-States/MS",
      "https://www.limos.com/geo/United-States/WY",
      "https://www.limos.com/geo/Mexico/Acanc%C3%BAn",
      "https://www.limos.com/geo/Mexico/Miacatl%C3%A1n",
      "https://www.limos.com/geo/Mexico/Acayuca",
      "https://www.limos.com/geo/Mexico/Misne-Bal%C3%A1n",
      "https://www.limos.com/geo/Mexico/Actunch%C3%A9n",
      "https://www.limos.com/geo/Mexico/Moctezuma",
      "https://www.limos.com/geo/Mexico/Acuautla",
      "https://www.limos.com/geo/Mexico/Muyil",
      "https://www.limos.com/geo/Mexico/Ajacuba",
      "https://www.limos.com/geo/Mexico/Nogales",
      "https://www.limos.com/geo/Mexico/Alpuyeca",
      "https://www.limos.com/geo/Mexico/Nuevo-Le%C3%B3n",
      "https://www.limos.com/geo/Mexico/Altavista",
      "https://www.limos.com/geo/Mexico/Ocotl%C3%A1n",
      "https://www.limos.com/geo/Mexico/Amecameca",
      "https://www.limos.com/geo/Mexico/Odzceh",
      "https://www.limos.com/geo/Mexico/Ameyalco",
      "https://www.limos.com/geo/Mexico/Otumba",
      "https://www.limos.com/geo/Mexico/Anenecuilco",
      "https://www.limos.com/geo/Mexico/Palmul",
      "https://www.limos.com/geo/Mexico/Apan",
      "https://www.limos.com/geo/Mexico/Panaba",
      "https://www.limos.com/geo/Mexico/Apaxco",
      "https://www.limos.com/geo/Mexico/Pereboch",
      "https://www.limos.com/geo/Mexico/Atlatlahucan",
      "https://www.limos.com/geo/Mexico/Playa-del-Carmen",
      "https://www.limos.com/geo/Mexico/Autopan",
      "https://www.limos.com/geo/Mexico/Popolnah",
      "https://www.limos.com/geo/Mexico/Axapusco",
      "https://www.limos.com/geo/Mexico/Puebla",
      "https://www.limos.com/geo/Mexico/Aztahuacan",
      "https://www.limos.com/geo/Mexico/Pueblo-Nuevo",
      "https://www.limos.com/geo/Mexico/Calotmul",
      "https://www.limos.com/geo/Mexico/Puerto-Ju%C3%A1rez",
      "https://www.limos.com/geo/Mexico/Calpan",
      "https://www.limos.com/geo/Mexico/Puerto-Morelos",
      "https://www.limos.com/geo/Mexico/Calpulalpan",
      "https://www.limos.com/geo/Mexico/Punta-Molas-del-Norte",
      "https://www.limos.com/geo/Mexico/Canamui",
      "https://www.limos.com/geo/Mexico/R%C3%ADo-Lagartos",
      "https://www.limos.com/geo/Mexico/Canc%C3%BAn",
      "https://www.limos.com/geo/Mexico/San-Felipe-de-Jes%C3%BAs",
      "https://www.limos.com/geo/Mexico/Capulhuac",
      "https://www.limos.com/geo/Mexico/Santa-Cruz",
      "https://www.limos.com/geo/Mexico/Catz%C3%ADn",
      "https://www.limos.com/geo/Mexico/Santa-Mar%C3%ADa-del-Monte",
      "https://www.limos.com/geo/Mexico/Cedral",
      "https://www.limos.com/geo/Mexico/Santo-Domingo",
      "https://www.limos.com/geo/Mexico/Chacalal",
      "https://www.limos.com/geo/Mexico/Sibisch%C3%A9n",
      "https://www.limos.com/geo/Mexico/Chacmuchuch",
      "https://www.limos.com/geo/Mexico/Sucila",
      "https://www.limos.com/geo/Mexico/Chancenote",
      "https://www.limos.com/geo/Mexico/Tecamac",
      "https://www.limos.com/geo/Mexico/Chanch%C3%A9n",
      "https://www.limos.com/geo/Mexico/Tejas",
      "https://www.limos.com/geo/Mexico/Chemax",
      "https://www.limos.com/geo/Mexico/Tekom",
      "https://www.limos.com/geo/Mexico/Chiautzingo",
      "https://www.limos.com/geo/Mexico/Tenancingo",
      "https://www.limos.com/geo/Mexico/Chichimil%C3%A1",
      "https://www.limos.com/geo/Mexico/Tenango-de-Arista",
      "https://www.limos.com/geo/Mexico/Chicoloapan",
      "https://www.limos.com/geo/Mexico/Tepeji",
      "https://www.limos.com/geo/Mexico/Chipept%C3%A9",
      "https://www.limos.com/geo/Mexico/Tepetlaoxtoc",
      "https://www.limos.com/geo/Mexico/Cholula",
      "https://www.limos.com/geo/Mexico/Tepoztl%C3%A1n",
      "https://www.limos.com/geo/Mexico/Chuluc",
      "https://www.limos.com/geo/Mexico/Texmelucan",
      "https://www.limos.com/geo/Mexico/Chunyaxch%C3%A9",
      "https://www.limos.com/geo/Mexico/Tezontepec",
      "https://www.limos.com/geo/Mexico/Cixk%C3%BA",
      "https://www.limos.com/geo/Mexico/Tixcancab",
      "https://www.limos.com/geo/Mexico/Coacalco",
      "https://www.limos.com/geo/Mexico/Tizayuca",
      "https://www.limos.com/geo/Mexico/Coatlinch%C3%A1n",
      "https://www.limos.com/geo/Mexico/Tizim%C3%ADn",
      "https://www.limos.com/geo/Mexico/Cob%C3%A1",
      "https://www.limos.com/geo/Mexico/Tlahuapan",
      "https://www.limos.com/geo/Mexico/Cocoyoc",
      "https://www.limos.com/geo/Mexico/Tlalancaleca",
      "https://www.limos.com/geo/Mexico/Colonia-Yucatan",
      "https://www.limos.com/geo/Mexico/Tlalmanalco",
      "https://www.limos.com/geo/Mexico/Cozumel",
      "https://www.limos.com/geo/Mexico/Tlalnepantla",
      "https://www.limos.com/geo/Mexico/Cuautla",
      "https://www.limos.com/geo/Mexico/Cuernavaca",
      "https://www.limos.com/geo/Mexico/Tlaltizap%C3%A1n",
      "https://www.limos.com/geo/Mexico/Dos-Estrellas",
      "https://www.limos.com/geo/Mexico/Tlaxcoapan",
      "https://www.limos.com/geo/Mexico/El-Cuyo",
      "https://www.limos.com/geo/Mexico/Tolcayuca",
      "https://www.limos.com/geo/Mexico/El-D%C3%ADaz",
      "https://www.limos.com/geo/Mexico/Toluca",
      "https://www.limos.com/geo/Mexico/Emal",
      "https://www.limos.com/geo/Mexico/Tulum",
      "https://www.limos.com/geo/Mexico/Emiliano-Zapata",
      "https://www.limos.com/geo/Mexico/Valladolid",
      "https://www.limos.com/geo/Mexico/Espita",
      "https://www.limos.com/geo/Mexico/Xcan",
      "https://www.limos.com/geo/Mexico/Idzint%C3%A9",
      "https://www.limos.com/geo/Mexico/Xelh%C3%A1",
      "https://www.limos.com/geo/Mexico/Jilotepec",
      "https://www.limos.com/geo/Mexico/Xonacatl%C3%A1n",
      "https://www.limos.com/geo/Mexico/Juchitepec",
      "https://www.limos.com/geo/Mexico/Yautepec",
      "https://www.limos.com/geo/Mexico/Kancab%C3%A1",
      "https://www.limos.com/geo/Mexico/Yaxch%C3%A9",
      "https://www.limos.com/geo/Mexico/Kantunil-Kin",
      "https://www.limos.com/geo/Mexico/Yaxcucul",
      "https://www.limos.com/geo/Mexico/Leona-Vicario",
      "https://www.limos.com/geo/Mexico/Zaragoza",
      "https://www.limos.com/geo/Mexico/Loch%C3%A9",
      "https://www.limos.com/geo/Mexico/Zumpango",
      "https://www.limos.com/geo/Mexico/Mexico-City",
      "https://www.limos.com/geo/United-Kingdom/Abbess-Roding",
      "https://www.limos.com/geo/United-Kingdom/Larkfield",
      "https://www.limos.com/geo/United-Kingdom/Abbey-Wood",
      "https://www.limos.com/geo/United-Kingdom/Latchingdon",
      "https://www.limos.com/geo/United-Kingdom/Abbots-Langley",
      "https://www.limos.com/geo/United-Kingdom/Latham",
      "https://www.limos.com/geo/United-Kingdom/Abridge",
      "https://www.limos.com/geo/United-Kingdom/Laughton",
      "https://www.limos.com/geo/United-Kingdom/Acol",
      "https://www.limos.com/geo/United-Kingdom/Leaden-Roding",
      "https://www.limos.com/geo/United-Kingdom/Acrise",
      "https://www.limos.com/geo/United-Kingdom/Leamouth",
      "https://www.limos.com/geo/United-Kingdom/Acton",
      "https://www.limos.com/geo/United-Kingdom/Leatherhead",
      "https://www.limos.com/geo/United-Kingdom/Addington",
      "https://www.limos.com/geo/United-Kingdom/Leaves-Green",
      "https://www.limos.com/geo/United-Kingdom/Addiscombe",
      "https://www.limos.com/geo/United-Kingdom/Leavesden",
      "https://www.limos.com/geo/United-Kingdom/Addlestone",
      "https://www.limos.com/geo/United-Kingdom/Lee",
      "https://www.limos.com/geo/United-Kingdom/Adisham",
      "https://www.limos.com/geo/United-Kingdom/Leeds",
      "https://www.limos.com/geo/United-Kingdom/Albourne",
      "https://www.limos.com/geo/United-Kingdom/Leigh",
      "https://www.limos.com/geo/United-Kingdom/Aldenham",
      "https://www.limos.com/geo/United-Kingdom/Leigh-Green",
      "https://www.limos.com/geo/United-Kingdom/Aldersbrook",
      "https://www.limos.com/geo/United-Kingdom/Aldington",
      "https://www.limos.com/geo/United-Kingdom/Leith-Hill",
      "https://www.limos.com/geo/United-Kingdom/Alfriston",
      "https://www.limos.com/geo/United-Kingdom/Lenham",
      "https://www.limos.com/geo/United-Kingdom/Alkham",
      "https://www.limos.com/geo/United-Kingdom/Lenham-Heath",
    ],
  }

  render() {
    return (
      <div className="sitemap-container">
        {this.state.siteMapLinks.map((r, i) => (
          <p>
            <a href={r} target="_blank">
              {r}
            </a>
          </p>
        ))}
      </div>
    )
  }
}
