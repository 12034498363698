import React, { useState } from "react"
import { useForm } from "react-hook-form"
import emailjs from "@emailjs/browser"
import "./ContactForm.scss"
import DataUpdater from "../GoogleSheetAPI/GoogleSheetAPI.jsx"
import SpinnerLoader from "react-bootstrap/Spinner"

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isMsgShown, setIsMsgShown] = useState(false)

  const onSubmit = async (data, e) => {
    e.preventDefault()

    const dataUpdater = DataUpdater(2119062806)
    try {
      const templateParams = {
        contact_email: data.email,
        contact_phone: data.mobile,
      }

      const newRow = {
        Email: templateParams.contact_email,
        PhoneNumber: templateParams.contact_phone,
      }
      setIsLoading(true)

      dataUpdater
        .appendSpreadsheet(newRow)
        .then(response => {
          console.log("Data updated successfully:", response)
          setIsLoading(false)
          showSuccessMessage()
        })
        .catch(error => {
          setIsLoading(false)
          console.error("Data update failed:", error)
        })

      /*   await emailjs.send(
        "service_p6dvwdj",
        "template_6odwzsh",
        templateParams,
        "2CC3n-4BQ-cOrf3jv"
      );
        console.log("Email sent successfully!");
      */
      console.log("Email Stored successfully")

      reset()
    } catch (error) {
      console.error("Email sending failed:", error)
    }
    const showSuccessMessage = () => {
      setIsMsgShown(true)
      setTimeout(() => {
        setIsMsgShown(false)
      }, 5000)
    }
  }

  return (
    <>
      <div className="contact-form-container">
        <h2 className="signup-form">
          Signup to get notified for discounts and promos!
        </h2>

        <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
          <div className="form-row">
            <input
              type="text"
              placeholder="Email*"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Please enter a valid email address",
                },
              })}
            />
            {errors.email && (
              <span className="error">{errors.email.message}</span>
            )}
          </div>
          <div className="form-row">
            <input
              type="number"
              placeholder="Mobile Number*"
              {...register("mobile", {
                required: "Mobile number is required",
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: "Please enter a valid 10-digit mobile number",
                },
              })}
            />
            {errors.mobile && (
              <span className="error">{errors.mobile.message}</span>
            )}
          </div>
          <button type="submit">Submit</button>
          <div className="container-spinner-success">
            {isMsgShown && (
              <div className="post-success">Successfully submitted!</div>
            )}
            {isLoading && (
              <SpinnerLoader
                animation="border"
                role="status"
                className="loader"
              >
                <span className="visually-hidden"></span>
              </SpinnerLoader>
            )}
          </div>
        </form>
      </div>
    </>
  )
}

export default ContactForm
