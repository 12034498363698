import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withWhiteLabelContext } from "react-whitelabel";
import buildUrl from "build-url";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import Spinner from "../UI/Spinner/Spinner";
import TypeAheadFormField from "../TypeAheadFormField/TypeAheadFormField.jsx";
import TripInfoWizard from "../TripInfoWizard/TripInfoWizard.jsx";
import CallAndChatModal from "../CallAndChatModal/CallAndChatModal.jsx";
import DatePicker from "react-datepicker";
import Slider from "../Slider/Slider.jsx";
import FieldInvalid from "./FieldInvalid.jsx";
import { fullHumanDateTime } from "../../utils/date-time";
import { addressComponent } from "../../utils/address.js";
import { getServiceTypeFormCode } from "../../utils/serviceType.js";
import { routePaths } from "../../routes/routes";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./RequestForm.scss";
import "react-datepicker/dist/react-datepicker.css";
import DataUpdater from "../GoogleSheetAPI/GoogleSheetAPI.jsx";
import SpinnerLoader from "react-bootstrap/Spinner";

import { listOfCruisePort } from "../../utils/ListOfCursiePort";

const SCREENS = { form: "form", tripInfoWizard: "tripInfoWizard" };

const zeroPad = (n) => ("0" + n).slice(-2);

const roundTripCode = ["100", "99", "131072", "102", "101"];

const hourlyTripCode = [
  "4",
  "65536",
  "128",
  "32",
  "64",
  "8192",
  "256",
  "4096",
  "16384",
];
function convertToFormattedPST(date) {
  const utcDateTime = moment.utc(date);
  const pstDateTime = utcDateTime.utcOffset(-480);

  return pstDateTime.format("MMMM D, h:mm:ss A") + " PST";
}

const hasErrors = (errObj) =>
  Object.values(errObj).some((error) => error.length !== 0);

const submitFormData = async (payload) => {
  const dataUpdaterNew = DataUpdater(1648816083);
  const dataUpdater = DataUpdater(0);

  const utcTimestamp = new Date().toISOString();
  const formattedEstTimestamp = convertToFormattedPST(utcTimestamp);

  const newRow = {
    TimeStamp: formattedEstTimestamp,
    ServiceType: payload.serviceType,
    PickupLocation: payload.pickUpLocation,
    DropOffLocation: payload.dropOffLocation,
    IsRoundTrip: payload?.isRoundTrip || "no",
    TripDate: payload?.TripDate,
    TripTime: payload.TripTime,
    ReturnDate: payload?.ReturnDate || "-",
    ReturnTime: payload?.ReturnTime || "-",
    Passengers: payload.pax,
    VehicleType: payload.vehicleType,
    NoOfDays: payload.noOfDays,
    Email: payload.email,
    Phone: payload.Phone,
    Name: payload.Name,
    Notes: payload.notes,
  };

  const updatePromises = [
    dataUpdaterNew.appendSpreadsheet(newRow),
    dataUpdater.appendSpreadsheet(newRow),
  ];

  try {
    await Promise.all(updatePromises);
    console.log("Both sheets updated successfully");
  } catch (error) {
    console.error("Error updating sheets:", error.message);
  }
};

const Slide = ({ img, key, ...props }) => (
  <div
    key={key}
    className="slide"
    style={{
      ...props.styles,
      backgroundImage: `url("${img}")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    }}
  />
);

const storeFormDataInLocalStorage = {
  serviceType: "",
  rideDate: null,
  hour: 17,
  minute: 0,
  returnHour: 18,
  returMinute: 0,
  pax: "",
  duration: "",
};

const errorsObj = {
  firstName: "First name is required.",
  lastName: "Last Name is required.",
  email: "Email is required.",
  phoneNumber: "Phone Number is required.",
  serviceType: "Service Type is required.",
  rideDate: "Trip date is required.",
  pax: "Passengers is required.",
  duration: "Hours is required.",
  returnDate: "Return date is required.",
  selectedToCruisePortOption: "To Cruise port is required.",
  selectedFromCruisePortOption: "From Cruise port is required.",
};

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

class RequestForm extends Component {
  state = {
    redirectToSearchResults: false,
    redirectToSubmitAuction: false,
    screen: SCREENS.form,
    redirectItem: { rideDate: "", pickupTime: "" },
    loading: false,
    partySizeState: [1, 0, 0],
    pickupPlaceValid: true,
    dropoffPlaceValid: true,
    showContactModal: false,
    badLocations: {
      pickupPlace: false,
      dropoffPlace: false,
    },
    slideshowImages: shuffle(this.props.images) || [],
    rideDate: null,
    hour: 17,
    minute: 0,
    returnDate: null,
    returnHour: 18,
    returMinute: 0,
    serviceType: "",
    pax: "",
    isRoundTrip: false,
    sameAsPickAddress: false,
    selectedToCruisePortOption: null,
    selectedFromCruisePortOption: null,
    defaultDropoffValue: [],
    duration: "",
    email: "",
    notes: "",
    phoneNumber: "",
    gcaptcha: null,
    firstName: "",
    lastName: "",
    data: "",
    email: "",
    phoneNumber: "",
    successMsg: false,
    vehicleType: "",
    noOfDays: "",
    notes: "",
    isLoading: false,
  };
  getPayloadFromState = () => {
    this.trackEvent("search-submitted");
    const roundTripParams = this.state.isRoundTrip
      ? {
          is_round_trip: "1",
          round_trip_ride_date: moment(this.state.returnDate).format(
            "MM/DD/YYYY"
          ),
          round_trip_pickup_time:
            this.state.returnHour + ":" + zeroPad(this.state.returMinute),
        }
      : {};
    const dropoffTimeForHourly = () => {
      const dropoffTime = moment(
        this.state.hour + ":" + zeroPad(this.state.minute),
        "HH:mm"
      )
        .add(this.state.duration * 60, "minutes")
        .format("HH:mm");
      return dropoffTime;
    };

    const queryParams = {
      ...roundTripParams,
      ride_date: moment(this.state.rideDate).format("MM/DD/YYYY"), // TODO make locale independent
      pickup_time: this.state.hour + ":" + zeroPad(this.state.minute),
      drop_off_time: this.state.isRoundTrip
        ? this.state.returnHour + ":" + zeroPad(this.state.returMinute)
        : hourlyTripCode.includes(this.state.serviceType)
        ? dropoffTimeForHourly()
        : this.state.hour + ":" + zeroPad(this.state.minute),
      pickup_place_id: !this.props.pickupPlace.airportCode,
      dropoff_place_id: !this.props.dropoffPlace?.airportCode,
      pickup_place: this.state.selectedFromCruisePortOption
        ? this.state.selectedFromCruisePortOption.lat +
          ", " +
          this.state.selectedFromCruisePortOption.lng
        : this.props.pickupPlace.isAirport
        ? this.props.pickupPlace.airportCode
        : this.props.pickupPlace.placeId,
      drop_off_place: this.state.selectedToCruisePortOption
        ? this.state.selectedToCruisePortOption.lat +
          ", " +
          this.state.selectedToCruisePortOption.lng
        : this.props.dropoffPlace?.isAirport
        ? this.props.dropoffPlace?.airportCode
        : this.props.dropoffPlace?.placeId,
      pax: this.state.pax,
      service_type: this.state.serviceType,
      email: this.state.email,
      notes: this.state.notes,
      firstName: this.state.firstName,
      vehicleType: this.state.vehicleType,
      noOfDays: this.state.noOfDays,
      pageName: this.props?.pageName,
    };

    const payloadBefore = {
      ...queryParams,
      ride_date: moment(this.state.rideDate).format("MMM DD,YYYY"),
      round_trip_ride_date: this.state.isRoundTrip
        ? moment(this.state.returnDate).format("MMM DD,YYYY")
        : "",
      is_round_trip: this.state.isRoundTrip ? "Yes" : "No",
      round_trip_pickup_time: this.state.isRoundTrip
        ? this.state.returnHour + ":" + zeroPad(this.state.returMinute)
        : "",
      hours: this.state.hour,
      service_type: getServiceTypeFormCode(this.state.serviceType),
      pickup_place: this.state.selectedFromCruisePortOption
        ? this.state.selectedFromCruisePortOption?.label
        : this.props.pickupPlace.item?.display,
      drop_off_place: this.state.selectedToCruisePortOption
        ? this.state.selectedToCruisePortOption?.label
        : this.props.dropoffPlace.item?.display,
      email: this.state.email,
      notes: this.state.notes,
      phoneNumber: this.state.phoneNumber,
      "g-recaptcha-response": this.state.gcaptcha,
    };
    const payload = {
      serviceType: payloadBefore.service_type,
      pickUpLocation: payloadBefore.pickup_place,
      dropOffLocation: payloadBefore.drop_off_place,
      isRoundTrip: payloadBefore?.is_round_trip || "no",
      TripDate: payloadBefore?.ride_date,
      TripTime: payloadBefore.pickup_time,
      ReturnDate: payloadBefore?.round_trip_ride_date || "",
      ReturnTime: payloadBefore?.round_trip_pickup_time || "",
      pax: payloadBefore.pax,
      vehicleType: payloadBefore.vehicleType,
      noOfDays: payloadBefore.noOfDays,
      email: payloadBefore.email,
      Phone: payloadBefore.phoneNumber,
      Name: payloadBefore.firstName,
      notes: payloadBefore.notes,
    };

    return payload;
  };

  constructor(props) {
    console.log(props);
    super(props);
    if (typeof document !== "undefined") {
      document.title = this.props.label.title;
    }
  }

  fetchDataFromSessionStorage() {
    const storedData = sessionStorage.getItem("formData");
    if (storedData) {
      this.setState({ data: storedData });
    } else {
      this.setState({ data: "Data not found" });
    }
  }

  toggleModalShow = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showContactModal: !this.state.showContactModal });
  };

  setPlace = (place, key) => {
    if (place && !place.is_airport) {
      this.service = new window.google.maps.places.PlacesService(this.instance);
      this.service.getDetails(
        {
          placeId: place.placeId,
          fields: [
            "types",
            "geometry",
            "formatted_address",
            "address_components",
          ],
        },
        (res, status) => {
          if (
            !addressComponent(res.address_components, "street_number") &&
            !addressComponent(res.address_components, "route")
          ) {
            this.setState({
              badLocations: {
                ...this.state.badLocations,
                [key]:
                  "We were unable to find a street address for this place. ",
              },
            });
          } else {
            this.setState({
              badLocations: { ...this.state.badLocations, [key]: false },
            });
          }
          return this.props.setPlace(
            place,
            key,
            res.geometry.location.lat(),
            res.geometry.location.lng(),
            res.formatted_address,
            place.placeId,
            false,
            ""
          );
        }
      );
      this.getDataFromLocalStorage(key, place);
    } else if (place && place.is_airport) {
      this.props.setPlace(
        place,
        key,
        "",
        "",
        place.display,
        "",
        true,
        place.placeId
      );
      this.getDataFromLocalStorage(key, place);
    }
  };

  setPlaceValid = (key, valid) => {
    this.setState({ [key]: valid }, this.intermediateValidate);
  };

  intermediateValidate = () => {
    const { errors } = this.props;
    if (!this.state.pickupPlaceValid) {
      errors.pickupPlace =
        "Your pickup address is too broad. Please enter an exact address.";
    }
    if (!this.state.dropoffPlaceValid) {
      errors.dropoffPlace =
        "Your dropoff address is too broad. Please enter an exact address.";
    }
  };

  validate = () => {
    const errors = this.props.createInitialSearchFormErrorState();
    this.props.setSearchFormErrors(errors);

    if (this.state.serviceType == "101") {
      if (!this.state.selectedFromCruisePortOption) {
        errors.selectedFromCruisePortOption = "From Cruise port is required";
      }
    } else {
      if (
        this.props.pickupPlace.lat === 0 &&
        this.props.pickupPlace.lng === 0
      ) {
        errors.pickupPlace = "Pickup location required.";
      }
    }

    if (!this.state.pickupPlaceValid) {
      errors.pickupPlace =
        "Your pickup address is too broad. Please enter a more specific location.";
    }

    if (this.state.serviceType == "102") {
      if (!this.state.selectedToCruisePortOption) {
        errors.selectedToCruisePortOption = "To Cruise port is required";
      }
    } else {
      if (
        this.props.dropoffPlace.lat === 0 &&
        this.props.dropoffPlace.lng === 0
      ) {
        errors.dropoffPlace = "Dropoff location required.";
      }
    }

    if (!this.state.dropoffPlaceValid) {
      errors.dropoffPlace =
        "Your dropoff address is too broad. Please enter a a more specific location.";
    }

    if (this.state.serviceType === "") {
      errors.serviceType = "Service Type is required.";
    }

    if (!this.state.rideDate) {
      errors.rideDate = "Trip date is required";
    }

    if (!this.state.hour) {
      errors.rideHour = "Trip hour is required";
    }

    if (!this.state.returnDate && this.state.isRoundTrip) {
      errors.returnDate = "Return date is required";
    }

    if (this.state.isRoundTrip) {
      if (this.state.rideDate && this.state.returnDate) {
        let rideD = moment(this.state.rideDate);
        let returnD = moment(this.state.returnDate);
        if (rideD.diff(returnD, "days") === 0) {
          if (
            this.state.hour > this.state.returnHour ||
            this.state.hour === this.state.returnHour
          ) {
            if (!(this.state.returMinute - this.state.minute > 59)) {
              errors.returnDate = "Round trip should be more than 1hrs";
            } else {
              errors.returnDate =
                "Return Date should be after Trip Date & Time";
            }
          }
        }
        if (rideD.diff(returnD, "days") > 0) {
          errors.returnDate = "Return Date should be after Trip Date & Time";
        }
      }
    }

    if (hourlyTripCode.includes(this.state.serviceType)) {
      if (!this.state.duration) {
        errors.duration = "Hours is required";
      }
      if (this.state.duration < 1) {
        errors.duration = "Minimum 1 hrs required";
      }
    }

    if (!this.state.returnHour && this.state.isRoundTrip) {
      errors.returnHour = "Trip return hour is required";
    }

    if (!this.state.pax) {
      errors.pax = "Passengers is required.";
    }
    if (!this.state.vehicleType) {
      errors.vehicleType = "Vehicle type is required.";
    }
    if (!this.state.noOfDays) {
      errors.noOfDays = "No of Days is required.";
    }
    if (!this.state.email) {
      errors.email = "Email address is required.";
    }
    if (
      !this.state.phoneNumber ||
      !/^[0-9]{10,}$/.test(this.state.phoneNumber)
    ) {
      errors.phoneNumber = this.state.phoneNumber
        ? "Please enter a valid phone number"
        : "Phone Number is required.";
    }
    if (!this.state.firstName) {
      errors.firstName = "Name is required.";
    }
    if (!this.state.gcaptcha) {
      errors.gcaptcha = "Captcha is required.";
    }
    if (this.props.isRequestAQuoteForm) {
      if (!isValidEmail(this.state.email)) {
        errors.email = "Please enter a valid email";
      }

      if (!/^[0-9]{10,}$/.test(this.state.phoneNumber)) {
        errors.phoneNumber = "Please enter a valid phone number";
      }

      if (!this.state.firstName) {
        errors.firstName = "First name is required.";
      }

      if (!this.state.lastName) {
        errors.lastName = "Last Name is required.";
      }

      if (!this.state.gcaptcha) {
        errors.gcaptcha = "Captcha is required.";
      }
    }

    return !hasErrors(errors);
  };

  onBlurValidate = (e) => {
    let fieldName;
    const erros = this.props.searchFormErrors;
    this.props.setSearchFormErrors(erros);
    if (typeof e === "undefined") {
      fieldName = "rideDate";
    } else {
      fieldName = e.target.name;
    }
    let state = this.state;
    if (!state[fieldName]) {
      erros[fieldName] = errorsObj[fieldName];
    } else {
      erros[fieldName] = "";
    }
  };

  componentDidMount() {
    if (hasErrors(this.props.searchFormErrors)) {
      this.validate();
    }
    if (!this.props.isRequestAQuoteForm) {
      let localItem = sessionStorage.getItem("formData");
      sessionStorage.removeItem("formData");

      if (!localItem) {
        sessionStorage.setItem(
          "formData",
          JSON.stringify(storeFormDataInLocalStorage)
        );
      } else {
        localItem = JSON.parse(localItem);
        this.setState({
          serviceType: localItem.serviceType,
          rideDate: new Date(localItem.rideDate),
          duration: localItem.duration,
          pax: localItem.pax,
          hour: localItem.hour,
          minute: localItem.minute,
          returnHour: localItem.returnHour,
          returMinute: localItem.returMinute,
          isRoundTrip: localItem.isRoundTrip,
          returnDate: new Date(localItem.returnDate),
          returnHour: localItem.returnHour,
          returMinute: localItem.returMinute,
        });
      }
    }

    if (sessionStorage?.getItem("formData")) {
      this.setPlace(
        sessionStorage?.getItem("formData")?.pickupPlace,
        "pickupPlace"
      );
    }
  }

  onTypeaheadSearch = (query, id, showOnlyAirports, finished) => {
    let googleResults = [],
      airportResults = [],
      googleFinished = false,
      airportFinished = query.length < 2;
    const google = window.google;

    const finishSearch = () => {
      if (googleFinished && airportFinished) {
        this.props.onPlaceQuery(id, airportResults.concat(googleResults));
        finished();
      }
    };

    const googleSuggestions = (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        console.log(`google place api error: ${status}`);
        googleFinished = true;
        finished();
        return;
      }

      googleResults = predictions
        .filter(
          (res) =>
            !res.types.includes("locality") && !res.types.includes("airport")
        )
        .map((res) => {
          return {
            placeId: res.place_id,
            types: res.types,
            display: res.description,
          };
        });
      googleFinished = true;
      finishSearch();
    };

    const airportSuggestions = (data) => {
      let filterSearchAirport = data.filter((item, index) => {
        return item.id.toLowerCase() === query.toLowerCase();
      });
      airportResults = filterSearchAirport.map((res) => {
        return { placeId: res.id, display: res.text, is_airport: true };
      });
      airportFinished = true;
      finishSearch();
    };

    new google.maps.places.AutocompleteService().getPlacePredictions(
      { input: query, types: showOnlyAirports ? ["airport"] : [] },
      googleSuggestions
    );
    fetch(`/services/location/airports?n=${encodeURIComponent(query)}`)
      .then((res) => res.json())
      .then(airportSuggestions);
  };

  bidsHandler = () => {
    this.orderHandler(true);
  };

  orderHandler = (bids = false) => {
    let code = "131072";
    if (this.props.pickupPlace.isAirport) {
      code = "99";
    } else if (this.props.dropoffPlace?.isAirport) {
      code = "100";
    }
    if (this.validate()) {
      this.trackEvent("search-submitted");
      const roundTripParams = this.state.isRoundTrip
        ? {
            is_round_trip: "1",
            round_trip_ride_date: moment(this.state.returnDate).format(
              "MM/DD/YYYY"
            ),
            round_trip_pickup_time:
              this.state.returnHour + ":" + zeroPad(this.state.returMinute),
          }
        : {};
      const dropoffTimeForHourly = () => {
        const dropoffTime = moment(
          this.state.hour + ":" + zeroPad(this.state.minute),
          "HH:mm"
        )
          .add(this.state.duration * 60, "minutes")
          .format("HH:mm");
        return dropoffTime;
      };

      const queryParams = {
        ...roundTripParams,
        ride_date: moment(this.state.rideDate).format("MM/DD/YYYY"), // TODO make locale independent
        pickup_time: this.state.hour + ":" + zeroPad(this.state.minute),
        drop_off_time: this.state.isRoundTrip
          ? this.state.returnHour + ":" + zeroPad(this.state.returMinute)
          : hourlyTripCode.includes(this.state.serviceType)
          ? dropoffTimeForHourly()
          : this.state.hour + ":" + zeroPad(this.state.minute),
        pickup_place_id: !this.props.pickupPlace.airportCode,
        dropoff_place_id: !this.props.dropoffPlace?.airportCode,
        pickup_place: this.state.selectedFromCruisePortOption
          ? this.state.selectedFromCruisePortOption.lat +
            ", " +
            this.state.selectedFromCruisePortOption.lng
          : this.props.pickupPlace.isAirport
          ? this.props.pickupPlace.airportCode
          : this.props.pickupPlace.placeId,
        drop_off_place: this.state.selectedToCruisePortOption
          ? this.state.selectedToCruisePortOption.lat +
            ", " +
            this.state.selectedToCruisePortOption.lng
          : this.props.dropoffPlace?.isAirport
          ? this.props.dropoffPlace?.airportCode
          : this.props.dropoffPlace?.placeId,
        pax: this.state.pax,
        vehicle_types: [1, 2, 3, 4, 5, 6, 7, 11, 12, 13], // all types for now, limit by pax (size)
        service_type: this.state.serviceType,
        email: this.props.searchForm?.email,
        bids: bids,
      };

      if (this.props.isRequestAQuoteForm) {
        const payload = {
          ...queryParams,
          ride_date: moment(this.state.rideDate).format("MMM DD,YYYY"),
          round_trip_ride_date: this.state.isRoundTrip
            ? moment(this.state.returnDate).format("MMM DD,YYYY")
            : "",
          is_round_trip: this.state.isRoundTrip ? "Yes" : "No",
          round_trip_pickup_time: this.state.isRoundTrip
            ? this.state.returnHour + ":" + zeroPad(this.state.returMinute)
            : "",
          hours: this.state.hour,
          service_type: getServiceTypeFormCode(this.state.serviceType),
          pickup_place: this.state.selectedFromCruisePortOption
            ? this.state.selectedFromCruisePortOption?.label
            : this.props.pickupPlace.item?.display,
          drop_off_place: this.state.selectedToCruisePortOption
            ? this.state.selectedToCruisePortOption?.label
            : this.props.dropoffPlace.item?.display,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          notes: this.state.notes,
          phoneNumber: this.state.phoneNumber,
          "g-recaptcha-response": this.state.gcaptcha,
        };

        emailjs
          .send(
            "service_p6dvwdj",
            "template_5m7pyiq",
            payload,
            "2CC3n-4BQ-cOrf3jv"
          )
          .then(
            function () {
              console.log("Confirmation SUCCESS!");
              window.location = `${routePaths.thankyou}`;
            },
            function (error) {
              console.log("Confirmation Failure!", error);
            }
          );
      } else {
        this.props.setSearchParams(queryParams);
        this.setState({
          loading: true,
          redirectToSearchResults: !bids,
          redirectToSubmitAuction: bids,
          redirectItem: buildUrl("", {
            queryParams,
          }),
        });
      }
    }
  };

  setScreen = (screen) => {
    this.setState({ screen });
  };

  trackEvent = (name) => {
    if (typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({ event: name, source: "search-form" });
    }
  };

  setPartySize = (v) => {
    const regexForNum = /^[1-9][0-9]*$/;
    if (v === "" || regexForNum.test(v)) {
      this.getDataFromLocalStorage("pax", v);
      this.setState({ pax: v });
    }
  };

  setDuration = (v) => {
    const regexForNum = /^(0?[1-9]|[1-9][0-9])$/;
    if (v === "" || regexForNum.test(v)) {
      this.getDataFromLocalStorage("duration", v);
      this.setState({ duration: v });
    }
  };
  handleVehicleTypeChange = (event) => {
    const selectedValue = event.target.value;
    this.setState({ vehicleType: selectedValue });
  };
  handleNoOfDaysChange = (event) => {
    const selectedValue = event.target.value;
    this.setState({ noOfDays: selectedValue });
  };
  setCustomPartySize = (v) => {
    this.props.setCustomPartySize(v);
  };

  setRideDate = (date) => {
    this.getDataFromLocalStorage("rideDate", date);
    this.setState({ rideDate: date });
  };

  setHour = (v) => {
    this.getDataFromLocalStorage("hour", Number(v));
    this.setState({ hour: Number(v) });
  };

  setMinute = (v) => {
    this.getDataFromLocalStorage("minute", Number(v));
    this.setState({ minute: Number(v) });
  };

  setReturnDate = (date) => {
    this.setState({ returnDate: date });
  };

  setReturnHour = (v) => {
    this.getDataFromLocalStorage("returnHour", Number(v));
    this.setState({ returnHour: Number(v) });
  };

  setReturnMinute = (v) => {
    this.getDataFromLocalStorage("returnMinute", Number(v));
    this.setState({ returMinute: Number(v) });
  };

  setServiceType = (v) => {
    this.getDataFromLocalStorage("serviceType", v);
    this.setState({
      serviceType: v,
      isRoundTrip: false,
      sameAsPickAddress: false,
      rideDate: null,
      hour: 17,
      minute: 0,
      pax: "",
      returnDate: null,
      returMinute: 0,
      returnHour: 18,
      selectedToCruisePortOption: null,
      selectedFromCruisePortOption: null,
      duration: "",
    });
  };

  handleIsRoundTrip = () => {
    this.getDataFromLocalStorage("isRoundTrip", !this.state.isRoundTrip);
    this.setState((state) => {
      return { isRoundTrip: !state.isRoundTrip };
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.validate()) {
      this.setState({ isLoading: true });

      const payload = this.getPayloadFromState();
      payload.pageName = this.props.pageName;
      console.log(payload.pageName);
      if (this.props.pageName === "phone-quote") {
        console.log(payload.pageName, "entry inside");

        Promise.all([
          submitFormData(payload),
          emailjs.send(
            "service_p6dvwdj",
            "template_e8lgycd",
            payload,
            "2CC3n-4BQ-cOrf3jv"
          ),
        ])
          .then(
            function () {
              console.log("Confirmation SUCCESS!");
              window.location = `${routePaths.thankyouLanding}`;
            },
            function (error) {
              console.log("Confirmation Failure!", error);
            }
          )
          .finally(() => {
            this.setState({ isLoading: false });
          });
      } else {
        console.log(payload.pageName, "entry second loop");
        Promise.all([
          submitFormData(payload),
          emailjs.send(
            "service_p6dvwdj",
            "template_l3xan8f",
            payload,
            "2CC3n-4BQ-cOrf3jv"
          ),
          emailjs.send(
            "service_p6dvwdj",
            "template_h3xdj18",
            payload,
            "2CC3n-4BQ-cOrf3jv"
          ),
        ])
          .then(
            function () {
              console.log("Confirmation SUCCESS!");
              window.location = `${routePaths.thankyouLanding}`;
            },
            function (error) {
              console.log("Confirmation Failure!", error);
            }
          )
          .finally(() => {
            this.setState({ isLoading: false });
          });
      }
    } else {
      this.setState({ successMsg: false });
    }
  };

  handleSameAsPickAddress = () => {
    this.setState((state) => {
      return { sameAsPickAddress: !state.sameAsPickAddress };
    });
    if (
      this.state.serviceType == "4096" &&
      this.props.pickupPlace &&
      this.props.pickupPlace.item
    ) {
      this.setState({ defaultDropoffValue: [this.props.pickupPlace.item] });
    }
  };

  handleOnChangeToCruisePort = (v) => {
    this.setState({
      selectedToCruisePortOption: v,
    });
  };

  handleOnChangeFromCruisePort = (v) => {
    this.setState(
      {
        selectedFromCruisePortOption: v,
      },
      () => {
        this.props.setPlace(
          "",
          "pickupPlace",
          v.lat,
          v.lng,
          v.label,
          "",
          false,
          ""
        );
      }
    );
  };

  getDataFromLocalStorage = (fieldType, inputValue) => {
    let formObject = sessionStorage.getItem("formData");
    if (formObject) {
      formObject = JSON.parse(formObject);
      formObject[fieldType] = inputValue;
      sessionStorage.setItem("formData", JSON.stringify(formObject));
    }
  };

  handleSetRecaptcha = (captchaData) => {
    this.setState({
      gcaptcha: captchaData,
    });
  };

  setValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { isLoading } = this.state;

    if (this.state.redirectToSearchResults) {
      return (
        <Redirect
          push
          to={routePaths.searchResults + this.state.redirectItem}
        />
      );
    } else if (this.state.redirectToSubmitAuction) {
      return <Redirect push to={routePaths.submitAuction} />;
    } else {
      // maybe change this to a switch
      if (this.state.screen === SCREENS.form) {
        const formElementsArray = [];
        for (let key in this.state.searchForm) {
          formElementsArray.push({
            id: key,
            config: this.state.searchForm[key],
          });
        }
        const errors = this.props.searchFormErrors;
        const invalidClass = (value) => (value ? " field-invalid" : ""),
          completeDate = this.props.completeDate,
          returnDate = this.props.returnDate;
        let dateValue = "";
        if (this.props.finishedTripWizard) {
          dateValue = fullHumanDateTime(completeDate);
          if (returnDate) {
            dateValue += " & " + fullHumanDateTime(returnDate);
          }
        }
        const previousPickupPlace =
          (this.props.pickupPlace?.display && [
            { display: this.props.pickupPlace?.display },
          ]) ||
          [];
        const previousDropoffPlace =
          (this.props.dropoffPlace?.display && [
            { display: this.props.dropoffPlace?.display },
          ]) ||
          [];
        const {
          formTitle,
          isRequestAQuoteForm = false,
          formButtonText,
          classFlag,
        } = this.props;
        const { bids } = this.props.label;
        let form = (
          <></>
        );
        if (this.state.loading) {
          form = <Spinner />;
        }
        return (
          <div id="index-page">
            <CallAndChatModal
              openChatWindow={this.props.openChatWindow}
              showModal={this.state.showContactModal}
              toggleShow={this.toggleModalShow}
            />
            {this.state.slideshowImages && (
              <div className="jumbotron-header">
                <div className="jumbotron-content">{form}</div>
                {this.props.muteSlideImages && <div className="colorLayer" />}
                <Slider
                  {...{
                    dots: false,
                    infinite: true,
                    speed: 5000,
                    autoplay: true,
                    fade: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    pauseOnHover: false,
                  }}
                >
                  {this.state.slideshowImages.map((img, i) => (
                    <Slide img={img} key={i} />
                  ))}
                </Slider>
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div style={{ marginTop: 124 }}>
            <TripInfoWizard />
          </div>
        );
      }
    }
  }
}

export default withWhiteLabelContext(RequestForm);