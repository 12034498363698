import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PickerGrid from "../PickerGrid/PickerGrid.jsx";
import PickerGridButton from "../PickerGrid/PickerGridButton.jsx";
import { withWhiteLabelContext } from "react-whitelabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../SearchDayPicker/WizardPages.scss";
import { tripWizardDateTime } from "../../utils/date-time";

class SearchIsHourlyPicker extends React.Component {
  constructor(props) {
    super(props);
    if (typeof document !== "undefined") {
      document.title = this.props.label.title + " - Select Type";
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Row>
          <Col className={"wizardPageTitle"}>
            <FontAwesomeIcon icon="hourglass-half" /> Hourly Ride?
          </Col>
        </Row>
        <Row>
          <Col className={"wizardPageTitle"}>
            {tripWizardDateTime(this.props.date)}
          </Col>
        </Row>
        <Row>
          <Col className={"wizardPageTitle"}>Is this an hourly ride?</Col>
        </Row>
        <Row>
          <Col className={"wizardPageTitle wizardPageSubTitle"}>
            Example: wedding limo, wine tour, night on the town
          </Col>
        </Row>
        <div className={"binaryChoice"} style={{ marginTop: 16 }}>
          <PickerGrid
            name="isHourly"
            defaultValue={
              typeof this.props.isHourly !== "undefined"
                ? this.props.isHourly
                : undefined
            }
            onChange={this.props.setTripType}
            segmentSize={2}
          >
            <PickerGridButton value={true}>YES</PickerGridButton>
            <PickerGridButton value={false}>NO</PickerGridButton>
          </PickerGrid>
        </div>
      </>
    );
  }
}

export default withWhiteLabelContext(SearchIsHourlyPicker);
