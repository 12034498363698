import React from "react";
import { withWhiteLabelContext } from "react-whitelabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PickerGrid from "../PickerGrid/PickerGrid.jsx";
import PickerGridButton from "../PickerGrid/PickerGridButton.jsx";
import { tripWizardDate } from "../../utils/date-time";
import { DateTime } from "luxon";

import "../SearchDayPicker/WizardPages.scss";
import "./SearchHourPicker.scss";
import AllTimesLocal from "../AllTimesLocalComponent/AllTimesLocal.jsx";

function formatHour(date) {
  let hours = date.hour;
  hours = hours % 12;
  hours = hours ? hours : 12; // convert 0 to 12
  return hours;
}

function formatMinute(date) {
  let minutes = date.minute;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return minutes;
}

function formatAMPM(date) {
  return date.hour >= 12 ? "pm" : "am";
}

class SearchHourPicker extends React.Component {
  constructor(props) {
    super(props);
    if (typeof document !== "undefined") {
      document.title = this.props.label.title + " - Select Time";
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  createHourArray = () => {
    const inDate = this.props.date;
    let dateByHours = [];
    let midnightDate = DateTime.fromObject({
      year: inDate.year,
      month: inDate.month,
      day: inDate.day,
    });
    let lastDateCreated = midnightDate;
    while (inDate.day === lastDateCreated.day) {
      dateByHours.push(lastDateCreated);
      lastDateCreated = lastDateCreated.plus({ hours: 1 });
    }
    return dateByHours;
  };

  render() {
    const day = this.props.date;
    if (this.props.date) {
      return (
        <>
          <Row>
            <Col className={"wizardPageTitle"}>
              <FontAwesomeIcon icon="clock" /> Time of Trip
            </Col>
          </Row>
          <Row>
            <Col className={"wizardPageTitle"}>
              {tripWizardDate(this.props.date)}
            </Col>
          </Row>
          <Row>
            <Col className={"wizardPageTitle"}>Select Pickup Hour:</Col>
          </Row>
          <Row>
            <Col xs={12} style={{ marginTop: 16 }}>
              <PickerGrid
                name="hour-picker"
                defaultValue={this.props.selectedHour}
                onChange={this.props.setHour}
                segmentSize={4}
              >
                {this.createHourArray().map((date, idx) => (
                  <PickerGridButton
                    value={date.hour}
                    key={idx}
                    className="hourCell"
                    disabled={date.hour < day.hour}
                  >
                    <span className="hour">{formatHour(date)}:</span>
                    <span className={"blankMinutes"}>{formatMinute(date)}</span>
                    <span className="ampm"> {formatAMPM(date)}</span>
                    {date.hour === 0 ? (
                      <div className="halfDayLabel">midnight</div>
                    ) : (
                      <></>
                    )}
                    {date.hour === 12 ? (
                      <div className="halfDayLabel">noon</div>
                    ) : (
                      <></>
                    )}
                  </PickerGridButton>
                ))}
              </PickerGrid>
            </Col>
          </Row>
          <AllTimesLocal />
        </>
      );
    }
  }
}

export default withWhiteLabelContext(SearchHourPicker);
