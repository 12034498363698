import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { DateTime } from "luxon";

import { routePaths } from "../../routes/routes";
import { endpoints, keys } from "../../globals.js";

const SubmitAuction = ({
  searchParams,
  setCompleteDate,
  setSearchResults,
  setError,
}) => {
  const [redirect, setRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    const dateParts = searchParams.ride_date.split("/"),
      timeParts = searchParams.pickup_time.split(":");
    axios
      .post(endpoints.limos + "/api/bid/search.json", {
        ...searchParams,
        is_dropoff_google_place_id: searchParams.dropoff_place_id,
        is_pickup_google_place_id: searchParams.pickup_place_id,
        /* is_dropoff_google_place_id: false,
         * is_pickup_google_place_id: false,
         * drop_off_place: '200 Muir Rd, Martinez, CA 94553, USA',
         * pickup_place: '100 N Wiget Ln, Walnut Creek, CA 94598, USA', */
        /* service_type: 100, */
        /* pax: 10, */
        api_key: keys.limos,
      })
      .then((response) => {
        if (response.data && response.data.response_code === 200) {
          const dropOffTimeParts = response.data.search.is_hourly
            ? searchParams.drop_off_time.split(":")
            : false;
          const pickupHour = parseInt(timeParts[0]);
          const dropoffHour = dropOffTimeParts
            ? parseInt(dropOffTimeParts[0])
            : pickupHour; // make a 0 duration
          const pickupDateTime = DateTime.fromObject({
            year: parseInt(dateParts[2]),
            month: parseInt(dateParts[0]),
            day: parseInt(dateParts[1]),
            hour: pickupHour,
            minute: parseInt(timeParts[1]),
            zone: response.data.search.pickup_time_zone,
          });
          setCompleteDate(
            pickupDateTime,
            pickupHour > dropoffHour
              ? dropoffHour + 24 - pickupHour
              : dropoffHour - pickupHour
          );
          setSearchResults(
            response.data.search.results,
            response.data.search.resolved_pickup_place,
            response.data.search.resolved_dropoff_place,
            response.data.search.is_hourly,
            () => setRedirect(response.data.bid_request.uuid)
          );
        } else {
          if (
            response.data &&
            response.data.search &&
            response.data.search.errors &&
            typeof response.data.search.errors === "object" &&
            response.data.search.errors.length > 0
          ) {
            setErrorMessage(response.data.search.errors[0].error.message);
          } else {
            setErrorMessage("An unknown error occurred");
            setError(response);
          }
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(err);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (redirect) {
    return <Redirect to={`${routePaths.auctionStatus}/${redirect}`} />;
  } else {
    return (
      <>
        <h1>Submitting your reverse auction...</h1>
        <p>
          We'll notify you when someone places a bid and when the auction
          closes.
        </p>
        <p>Stay here and we'll soon display your auction page.</p>
        <p>{errorMessage}</p>
      </>
    );
  }
};

export default SubmitAuction;
