import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withWhiteLabelContext } from "react-whitelabel";
import { tripWizardDateTime } from "../../utils/date-time";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import "./SearchTripTypePicker.scss";

const rideTypes = [
  { displayName: "WEDDING", serviceType: 4 },
  { displayName: "CORPORATE OUTING", serviceType: 4096 },
  { displayName: "PROM", serviceType: 16384 },
  { displayName: "WINE TOUR", serviceType: 65536 },
  { displayName: "CONCERT / EVENT", serviceType: 128 },
  { displayName: "BACHELOR/BACHELORETTE PARTY", serviceType: 32 },
  { displayName: "BIRTHDAY / ANNIVERSARY", serviceType: 64 },
  { displayName: "NIGHT ON THE TOWN", serviceType: 8192 },
  { displayName: "FUNERAL", serviceType: 256 },
  { displayName: "JUST DRIVE", serviceType: 4096 },
];

class SearchTripTypePicker extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col className={"wizardPageTitle"}>
            <FontAwesomeIcon icon="glass-cheers" /> Special Occasion?
          </Col>
        </Row>
        <Row>
          <Col className={"wizardPageTitle"}>
            {tripWizardDateTime(this.props.date)}
          </Col>
        </Row>
        <Row>
          <Col className={"wizardPageTitle"}>What kind of ride is it?</Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ToggleButtonGroup
              type="radio"
              className={"tripTypeCell"}
              name="search-results-sort"
              defaultValue="Default"
            >
              {rideTypes.map((sortObj, idx) => {
                return (
                  <ToggleButton
                    className={"tripTypeButton"}
                    block
                    key={idx}
                    onClick={() => this.props.setTripType(sortObj.serviceType)}
                    value={sortObj.displayName}
                  >
                    {sortObj.displayName}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Col>
        </Row>
      </>
    );
  }
}
export default withWhiteLabelContext(SearchTripTypePicker);
