import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import "./NoResultsEmail.scss";

import { endpoints, keys } from "../../globals.js";

const FORM = "form";
const SUBMITTING = "submitting";
const SUBMITTED = "submitted";

const NoResultsEmail = ({ uuid = "unknown" }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [state, setState] = useState(FORM);

  console.log("erros", errors);
  const onSubmit = ({ email, phone }) => {
    setState(SUBMITTING);
    axios({
      url: `${endpoints.limos}/api/signup/no_result.json`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: {
        api_key: keys.limos,
        email,
        phone_number: phone,
        search_uuid: uuid,
      },
    }).then(() => setState(SUBMITTED));
  };

  let body;

  if (state === FORM) {
    body = (
      <form onSubmit={handleSubmit(onSubmit)}>
        <label style={{display: "block"}}>
          <h4>
            Or enter your email address and we will reach out to you as soon as
            possible.
          </h4>
          <input
            placeholder="Email"
            className="form-control email input"
            type="email"
            {...register("Email", { required: true })}
          />
          <input
            placeholder="Phone Number (optional)"
            className="form-control phone-number-input"
            type="tel"
            {...register("Phone Number (optional)", { required: false })}
          />
        </label>
        {errors?.Email && <span>Email address is required</span>}

        <Button block className="btn-rounded" type="submit">
          Submit
        </Button>
      </form>
    );
  }
  if (state === SUBMITTING) {
    body = <h3>Submitting...</h3>;
  }
  if (state === SUBMITTED) {
    body = (
      <>
        <h3>Email address and phone number submitted</h3>
        <p>We will contact you shortly!</p>
      </>
    );
  }
  return (
    <Row style={{ marginTop: "2rem" }}>
      {/* <Col xs={12} md={{ span: 8 }} lg={{ span: 3 }}>
        <div className="image-container">
          <img src="https://www.limos.com/system/media/images/156541/original/3_6_Suburban.jpg?1379859656"></img>
        </div>
      </Col>

      <Col xs={12} md={{ span: 8 }} lg={{ span: 6 }} >
        <div className="middle-text-container">
          <span class="call-for-pricing-copy">
            Black Cadillac Escalade ESV
          </span>
        </div>
      </Col>

      <Col xs={12} md={{ span: 8 }} lg={{ span: 3 }}>
        <div className="call-for-pricing-container">
          <span class="call-for-pricing-copy">
            Call for Pricing
          </span>
        </div>
      </Col> */}

      <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} style={{ marginTop: "2rem" }}>
        {body}
      </Col>
    </Row>
  );
};

export default NoResultsEmail;
