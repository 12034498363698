import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withWhiteLabelContext } from "react-whitelabel";
import "./AllTimesLocal.scss";

class AllTimesLocal extends React.Component {
  render() {
    return (
      <Row>
        <Col xs={12} className={"allTimesLocal"}>
          All selected dates & times are local to pickup location
        </Col>
      </Row>
    );
  }
}

export default withWhiteLabelContext(AllTimesLocal);
