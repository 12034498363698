import React from "react";

import "./VehiclesLanding.scss";

const VehiclesLanding = () => (
  <div className="landing-page">
    <div className="landing-header">
      <div className="landing-inner">
        <h1>Vehicles</h1>
        <div className="divider"></div>
        <img
          alt="A row of vehicles."
          src="https://assets.s3.amazonaws.com/images/front-end/Vehicles.png"
        />
        <h2>
          We do Sedan/Luxury Sedan, SUV, Limo, Stretch SUV, Executive Vans,
          Shuttles, Coaches. Below are some of our most popular vehicles.
        </h2>
      </div>
    </div>

    <div className="landing-section flex">
      <img
        alt="A sedan"
        src="https://assets.s3.amazonaws.com/images/front-end/vehicle-page/sedan.png"
      />
      <div>
        <h2>Sedan</h2>
        <p>Accommodates up to 4 passengers</p>
        <ul>
          <li>Leather Interior</li>
          <li>Seats 3-4 Comfortably</li>
          <li>Air Conditioned</li>
          <li>AM/FM Radio</li>
          <li>Bluetooth</li>
          <li>Professional Chauffeur</li>
        </ul>
      </div>
    </div>

    <div className="landing-section flex reverse">
      <img
        alt="A suv"
        src="https://assets.s3.amazonaws.com/images/front-end/vehicle-page/suv.png"
      />
      <div>
        <h2>SUV</h2>
        <p>Accommodates up to 7 passengers</p>
        <ul>
          <li>Leather Interior</li>
          <li>Air Conditioned</li>
          <li>Cargo Space</li>
          <li>Professional Chauffeur</li>
        </ul>
      </div>
    </div>

    <div className="landing-section flex">
      <img
        alt="A stretch limo"
        src="https://assets.s3.amazonaws.com/images/front-end/vehicle-page/stretch-limo.png"
      />
      <div>
        <h2>Stretch Limo</h2>
        <p>Accommodates up to 10 passengers</p>
        <ul>
          <li>Professional Chauffeur</li>
          <li>Limo bar</li>
          <li>Custom Interior Lighting</li>
          <li>CD/DVD Player</li>
          <li>Leather Interior</li>
          <li>Stereo System</li>
        </ul>
      </div>
    </div>

    <div className="landing-section flex reverse">
      <img
        alt="A suv"
        src="https://assets.s3.amazonaws.com/images/front-end/vehicle-page/stretch-suv.png"
      />
      <div>
        <h2>Stretch SUV</h2>
        <p>Accommodates up to 14 passengers</p>
        <ul>
          <li>Professional Chauffeur</li>
          <li>Limo bar</li>
          <li>Custom Interior Lighting</li>
          <li>CD/DVD Player</li>
          <li>Leather Interior</li>
          <li>Stereo System</li>
        </ul>
      </div>
    </div>

    <div className="landing-section flex">
      <img
        alt="A sedan"
        src="https://assets.s3.amazonaws.com/images/front-end/vehicle-page/sprinter.png"
      />
      <div>
        <h2>Executive Sprinter Van</h2>
        <p>Accommodates up to 16 passengers</p>
        <ul>
          <li>Professional Chauffeur</li>
          <li>Luggage area</li>
          <li>Rear AC/Heating</li>
          <li>Custom Interior Lighting</li>
          <li>TV, DVD, and CD Capabilities</li>
          <li>Luxury Leather Interior</li>
        </ul>
      </div>
    </div>

    <div className="landing-section flex reverse">
      <img
        alt="A shuttle"
        src="https://assets.s3.amazonaws.com/images/front-end/vehicle-page/shuttle.png"
      />
      <div>
        <h2>Shuttle</h2>
        <p>Accommodates up to 44 passengers</p>
        <ul>
          <li>Panoramic Front Viewing Window</li>
          <li>Frameless Coach Windows</li>
          <li>Electric Plug Entry Door</li>
          <li>DVD Player</li>
          <li>Premium Rear Audio</li>
          <li>Roof-mounted HVAC</li>
          <li>Separate Luggage Area</li>
          <li>LED Interior / Exterior Lighting</li>
          <li>Passenger Retractable Seats</li>
          <li>Emergency Escape Windows</li>
          <li>TV, DVD, and CD Capabilities</li>
        </ul>
      </div>
    </div>

    <div className="landing-section">
      <div className="flex">
        <img
          alt="A coach"
          src="https://assets.s3.amazonaws.com/images/front-end/vehicle-page/coach.png"
        />
        <div>
          <h2>Coach</h2>
          <p>Accommodates up to 56 passengers</p>
          <ul>
            <li>Frameless Coach Windows</li>
            <li>Electrical Outlets</li>
            <li>DVD Player</li>
            <li>Premium Audio</li>
            <li>Lavatory</li>
            <li>Hardwood Floors</li>
            <li>LED Interior / Exterior Lighting</li>
            <li>Passenger Retractable Seats</li>
            <li>Luxury Interior</li>
            <li>Overhead Storage</li>
            <li>TV, DVD, and CD Capabilities</li>
            <li>Under Carriage Storage</li>
            <li>Privacy Shades</li>
          </ul>
        </div>
      </div>
      <div style={{ paddingTop: 0 }}>
        <h3>Additional Amenities</h3>
        <div className="flex">
          <div>
            <h4>Comfort</h4>
            <ul>
              <li>Leather Seats</li>
              <li>Lighting & Climate Control</li>
              <li>Onboard Restrooms</li>
            </ul>
          </div>
          <div>
            <h4>Productivity</h4>
            <ul>
              <li>Workstations</li>
              <li>Secure Wi-Fi</li>
              <li>Collaborative Workspaces</li>
            </ul>
          </div>
          <div>
            <h4>Accessibility</h4>
            <ul>
              <li>Bike Racks</li>
              <li>ADA Wheelchair Lifts</li>
              <li>Bike & Luggage Storage</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default VehiclesLanding;
