import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withWhiteLabelContext } from "react-whitelabel";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import { routePaths } from "../../routes/routes";

class Footer extends React.Component {
  render() {
    const { telephone } = this.props.label;
    return (
      <>
        <Container>
          <footer>
            <Row className="center footerLinks">
              <Col
                xs={12}
                md={{ span: 8, offset: 2 }}
                lg={{ span: 6, offset: 3 }}
              >
                <a href={`tel:${telephone}`}>
                  <FontAwesomeIcon icon="phone" /> {telephone}
                </a>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={{ span: 8, offset: 2 }}
                lg={{ span: 6, offset: 3 }}
              >
                <Row>
                  <Col xs={6} md={4}>
                    About
                    <ul>
                      <li>
                        <Link to={routePaths.siteTerms}>Terms</Link>
                      </li>
                      <li>
                        <Link to={routePaths.privacyPolicy}>Privacy</Link>
                      </li>
                      <li>
                        <a href="https://www.limos.com/limo-price-guide">
                          Pricing Guide
                        </a>
                      </li>
                      <li>
                        <a href="https://www.limos.com/vehicle-guide">
                          Vehicle Guide
                        </a>
                      </li>
                      <li>
                        <a href="https://www.limos.com/limos-101-pricing-and-planning-guide">
                          Limos 101
                        </a>
                      </li>
                      <li>
                        <a href="https://www.limos.com/customers/faqs">FAQs</a>
                      </li>
                      <li>
                        <a href="https://www.limos.com/operators/join">
                          Partner With Us
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={6} md={4}>
                    Limos.com
                    <ul>
                      <li>
                        <a href="https://www.limos.com/special-occasions/wedding">
                          Wedding Limos
                        </a>
                      </li>
                      <li>
                        <a href="https://www.limos.com/special-occasions/prom">
                          Prom Limos
                        </a>
                      </li>
                      <li>
                        <a href="https://www.limos.com/special-occasions/birthday">
                          Birthday Limos
                        </a>
                      </li>
                      <li>
                        <a href="https://www.limos.com/special-occasions/nightout">
                          Night Out Limos
                        </a>
                      </li>
                      <li>
                        <a href="https://www.limos.com/special-occasions/sports">
                          Sporting Events Limos
                        </a>
                      </li>
                      <li>
                        <a href="https://www.limos.com/special-occasions/concert">
                          Concert Limos
                        </a>
                      </li>
                      <li>
                        <a href="https://www.limos.com/special-occasions/wine">
                          Wine Tour Limos
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={6} md={4}>
                    Service
                    <ul>
                      <li>
                        <a href="https://www.limos.com/special-occasions/airport-limo">
                          Airport Car Service
                        </a>
                      </li>
                      <li>
                        <a href="https://www.limos.com/special-occasions/cruiseport">
                          Cruise Port Service
                        </a>
                      </li>
                      <li>
                        <a href="https://www.limos.com/special-occasions/destination">
                          One Way Transfer
                        </a>
                      </li>
                      <li>
                        <a href="https://www.limos.com/event-request">
                          Corporate Events
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </footer>
        </Container>
        <Switch>
          <Route path={[routePaths.root, routePaths.contact]} exact>
            <div className="vehicleBottom vehicleBottomHidden">
              <Image
                src={this.props.label.vehicleImage}
                className="vehicleSelection"
                fluid
              />
            </div>
          </Route>
        </Switch>
        <noscript><img src="https://ws.zoominfo.com/pixel/622fd41d62f5120020f9975c" width="1" height="1" className="footer-gta-fixel" /></noscript>
      </>
    );
  }
}

export default withWhiteLabelContext(Footer);
