import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import { withWhiteLabelContext } from "react-whitelabel";
import { Redirect } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getServiceTypeFormCode } from "../../utils/serviceType.js";

import { routePaths } from "../../routes/routes";

import MapComponent from "../MapComponent/MapComponent.jsx";
import moment from "moment";

import "./SearchResultCheckout.scss";

class SearchResultCheckout extends Component {
  state = {
    redirect: false,
    reservationTerms: "",
    showReviewsModal: false,
    propsData: null,
  };

  componentWillReceiveProps(props) {
    if (props.optedReturnedTrip && props.resultHandle1 && props.resultHandle2) {
      this.navigateToCheckout();
    }
  }

  addToCart = () => {
    if (
      this.props.optedReturnedTrip == true &&
      (!this.props.resultHandle1 || !this.props.resultHandle2)
    ) {
      this.props.clickCallBack();
    } else {
      this.navigateToCheckout();
    }
  };

  navigateToCheckout = () => {
    this.props.addItemToCart(
      this.props.searchResult,
      this.props.legName,
      () => {
        if (this.props.cartCallback) {
          this.props.cartCallback();
        }
        if (this.props.showCheckout) {
          this.setState({ redirect: true });
        }
      },
      this.props.resultHandle1,
      this.props.resultHandle2,
      this.props.resultHandler1Data,
      this.props.resultHandler2Data
    );
  };

  setReservationTerms = (reservationTerms) => {
    this.setState({ reservationTerms });
  };

  createPricingBreakdown() {}

  render() {
    if (this.state.redirect) {
      return <Redirect to={routePaths.checkout} push />;
    }
    const amenities = this.props.amenities
      ? this.props.amenities.map(
          (item, key) => item.vehicle_feature_definition.description
        )
      : [];

    const registerCallChatClick = () => {
      if (typeof window.dataLayer !== "undefined") {
        window.dataLayer.push({ event: "search-call-chat-clicked" });
      }
    };

    const {
      reviewsLink = [],
      showCallChat = false,
      openChatWindow = () => "nothing",
      label,
      completeDate,
      isHourly,
      bookingParamValues,
    } = this.props;
    const { day, month, year, hour, minute, second } = completeDate;

    const getSessionData = JSON.parse(sessionStorage.getItem("formData"));
    const duration = getSessionData?.duration || 0;

    const serviceTypeText = getServiceTypeFormCode(
      bookingParamValues.service_type
    );
    const isRoundTrip = bookingParamValues.is_round_trip === "1" ? true : false;

    const zeroPad = (n) => ("0" + n).slice(-2);

    const getRideDate = () =>
      moment(`${zeroPad(month)}/${zeroPad(day)}/${year}`).format(
        "MMMM DD, YYYY"
      );

    const getRideReturnDate = () => {
      const [month, day, year] =
        bookingParamValues?.round_trip_ride_date.split("/");
      return moment(`${zeroPad(month)}/${zeroPad(day)}/${year}`).format(
        "MMMM DD, YYYY"
      );
    };

    const time = `${zeroPad(hour)}:${zeroPad(minute)}:${zeroPad(second)}`;

    const getRideTime = () => moment(time, "HH:mm:ss").format("hh:mm A");


    const returnTime = isRoundTrip ? `${zeroPad(bookingParamValues.round_trip_pickup_time?.split(":")[0])}:${zeroPad(bookingParamValues.round_trip_pickup_time?.split(":")[1])}:${zeroPad(0)}` : null;

    const getReturnRideTime = () =>
      moment(returnTime, "HH:mm:ss").format("hh:mm A");

    return (
      <div className="search-result-wide">
        <div className="content-container">
          <div className="content-item">
            <span>Date</span>
          </div>
          <div className="content-value">
            <span>{getRideDate()}</span>
          </div>

          {isRoundTrip && (
            <>
              <div className="content-item">
                <span>Return Date</span>
              </div>
              <div className="content-value">
                <span>{getRideReturnDate()}</span>
              </div>
            </>
          )}

          <div className="content-item">
            <span>Time</span>
          </div>
          <div className="content-value">
            <span>{getRideTime()}</span>
          </div>

          {isRoundTrip && (
            <>
              <div className="content-item">
                <span>Return Time</span>
              </div>
              <div className="content-value">
                <span>{getReturnRideTime()}</span>
              </div>
            </>
          )}

          <div className="content-item">
            <span>Pickup Address</span>
          </div>
          <div className="content-value">
            <span>{this.props.pickupPlace?.display}</span>
          </div>

          <div className="content-item">
            <span>Dropoff Address</span>
          </div>
          <div className="content-value">
            <span>{this.props.dropoffPlace?.display}</span>
          </div>

          {!isHourly && (
            <>
              <div className="content-item">
                <span>Round Trip</span>
              </div>
              <div className="content-value">
                <span>{isRoundTrip ? "Yes" : "No"}</span>
              </div>
            </>
          )}

          {isHourly && (
            <>
              <div className="content-item">
                <span>Requested Hours</span>
              </div>
              <div className="content-value">
                <span>{`${duration} (plus travel time if applicable)`}</span>
              </div>
            </>
          )}

          <div className="content-item">
            <span>Service Type</span>
          </div>
          <div className="content-value">
            <span>{serviceTypeText}</span>
          </div>
        </div>

        {isRoundTrip && (
          <>
            <div className="vehicle-info-container">
              <div className="vehicle-details-container">
                <div className="vehicle-name">
                  <span>
                    {this.props.resultHandler1Data.vehicle_color +
                      " " +
                      this.props.resultHandler1Data.vehicle_make +
                      " " +
                      this.props.resultHandler1Data.vehicle_model}
                  </span>
                </div>
                <div className="passenger-details">
                  <span>{`${
                    this.props.resultHandler1Data.vehicle_capacity
                      ? this.props.resultHandler1Data.vehicle_capacity
                      : 0
                  }  Passengers / ${
                    this.props.resultHandler1Data.luggage_capacity
                      ? this.props.resultHandler1Data.luggage_capacity
                      : 0
                  } Luggage`}</span>
                </div>
                <div className="amenties-details">
                  <span>
                    {this.props.resultHandler1Data.vehicle_features
                      ?.map((veh, i) => {
                        return veh.vehicle_feature_definition.description;
                      })
                      .join(", ")}
                  </span>
                </div>
              </div>
              <div className="vehicle-image-container">
                <Carousel
                  interval={null}
                  prevIcon={
                    <FontAwesomeIcon
                      className="carousel-arrow"
                      icon="arrow-circle-left"
                    />
                  }
                  nextIcon={
                    <FontAwesomeIcon
                      className="carousel-arrow"
                      icon="arrow-circle-right"
                    />
                  }
                  key="carousel-images"
                >
                    {[this.props.resultHandler1Data.vehicle_url].map((imageUrl, index) => {
                      let formatImageURL = imageUrl.replace("http://", "https://");
                      return (
                        <Carousel.Item key={index}>
                          <div className="carousel-image-container">
                            {formatImageURL && (
                              <img
                                src={formatImageURL}
                                alt="Car"
                                onError={(e) => {
                                  // If the image fails to load, display a placeholder image or handle the error
                                  e.target.onerror = null; // Prevent infinite loop in case of repeated error
                                  e.target.src = "https://assets.s3.amazonaws.com/images/front-end/new-landing/limos.com.jpg"; // Set a fallback image URL
                                }}
                              />
                            )}
                          </div>
                        </Carousel.Item>
                      );
                    })}
                </Carousel>
              </div>
            </div>

            <div className="vehicle-info-container">
              <div className="vehicle-details-container">
                <div className="vehicle-name">
                  <span>
                    {this.props.resultHandler2Data.vehicle_color +
                      " " +
                      this.props.resultHandler2Data.vehicle_make +
                      " " +
                      this.props.resultHandler2Data.vehicle_model}
                  </span>
                </div>
                <div className="passenger-details">
                  <span>{`${
                    this.props.resultHandler2Data.vehicle_capacity
                      ? this.props.resultHandler2Data.vehicle_capacity
                      : 0
                  }  Passengers / ${
                    this.props.resultHandler2Data.luggage_capacity
                      ? this.props.resultHandler2Data.luggage_capacity
                      : 0
                  } Luggage`}</span>
                </div>
                <div className="amenties-details">
                  <span>
                    <span>
                      {this.props.resultHandler2Data.vehicle_features
                        ?.map((veh, i) => {
                          return veh.vehicle_feature_definition.description;
                        })
                        .join(", ")}
                    </span>
                  </span>
                </div>
              </div>
              <div className="vehicle-image-container">
                <Carousel
                  interval={null}
                  prevIcon={
                    <FontAwesomeIcon
                      className="carousel-arrow"
                      icon="arrow-circle-left"
                    />
                  }
                  nextIcon={
                    <FontAwesomeIcon
                      className="carousel-arrow"
                      icon="arrow-circle-right"
                    />
                  }
                  key="carousel-images"
                >
                    {[this.props.resultHandler2Data.vehicle_url].map((imageUrl, index) => {
                      let formatImageURL = imageUrl.replace("http://", "https://");
                      return (
                        <Carousel.Item key={index}>
                          <div className="carousel-image-container">
                            <img
                              src={formatImageURL}
                              alt="Car"
                              onError={(e) => {
                                // If the image fails to load, set a fallback image or handle the error
                                e.target.onerror = null; // Prevent infinite loop in case of repeated error
                                e.target.src = "https://assets.s3.amazonaws.com/images/front-end/new-landing/limos.com.jpg"; // Replace with your fallback image URL
                              }}
                            />
                          </div>
                        </Carousel.Item>
                      );
                    })}

                </Carousel>
              </div>
            </div>
          </>
        )}

        {!isRoundTrip && (
          <div className="vehicle-info-container">
            <div className="vehicle-details-container">
              <div className="vehicle-name">
                <span>{this.props.displayName}</span>
              </div>
              <div className="passenger-details">
                <span>{`${
                  this.props.passengerCapacity
                    ? this.props.passengerCapacity
                    : 0
                }  Passengers / ${
                  this.props.luggageCapacity ? this.props.luggageCapacity : 0
                } Luggage`}</span>
              </div>
              <div className="amenties-details">
                <span>{amenities?.join(", ")}</span>
              </div>
            </div>
            <div className="vehicle-image-container">
              <Carousel
                interval={null}
                prevIcon={
                  <FontAwesomeIcon
                    className="carousel-arrow"
                    icon="arrow-circle-left"
                  />
                }
                nextIcon={
                  <FontAwesomeIcon
                    className="carousel-arrow"
                    icon="arrow-circle-right"
                  />
                }
                key="carousel-images"
              >
                {this.props.images.map((imageUrl, index) => {
                  let formatImageURL = imageUrl.replace("http://", "https://");
                  return (
                    <Carousel.Item key={index}>
                      <div className="carousel-image-container">
                        <img
                          src={formatImageURL}
                          alt="Car"
                          onError={(e) => {
                            // If the image fails to load, display a fallback image
                            e.target.onerror = null; // Prevent infinite loop in case of repeated error
                            e.target.src = "https://assets.s3.amazonaws.com/images/front-end/new-landing/limos.com.jpg";
                          }}
                        />
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </div>
        )}

        {this.props.displayContextIsCheckout ? (
          <MapComponent
            pickupPlace={this.props.pickupPlace}
            dropoffPlace={this.props.dropoffPlace}
          />
        ) : (
          <></>
        )}

        <div className="extra-note-container">
          <p className="note-text">
            Note: Extra Passengers can be added to this booking after checkout!
          </p>
        </div>
      </div>
    );
  }
}

export default withWhiteLabelContext(SearchResultCheckout);
