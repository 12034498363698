import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

import Button from "react-bootstrap/Button";

import Slider from "../Slider/Slider.jsx";
import Spinner from "../UI/Spinner/Spinner";
import TypeAheadFormField from "../TypeAheadFormField/TypeAheadFormField.jsx";

import Form from "react-bootstrap/Form";

import "./EventsPage.scss";

const eventTypes = [
  "business",
  "recreation",
  "sport event",
  "convention",
  "group movement",
  "special event",
  "recurring event",
  "other",
];

const rideTypes = [
  "airport transfer",
  "point-to-point transfer",
  "hourly-as-direct services",
];

const toKebabCase = (string) => string.replace(/\s+/g, "-").toLowerCase();
const toTitleCase = (string) =>
  string.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

const EventRequestForm = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState("");
  const [queryResults, setQueryResults] = useState([]);
  const [focusedInput, setFocusedInput] = useState();
  const [formInputs, setFormInputs] = useState({
    name: "",
    email: "",
    phone: "",
    est_passengers: "",
    event_name: "",
    event_type: eventTypes.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: false,
      }),
      {}
    ),
    ride_type: rideTypes.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: false,
      }),
      {}
    ),
    event_location: {},
    event_start_date: null,
    event_end_date: null,
    message: "",
    recaptcha: false,
  });

  const handleInputChange = (e) =>
    setFormInputs({ ...formInputs, [e.target.name]: e.target.value });

  const handleCheckboxSelection = (type, key, value) => {
    setFormInputs({
      ...formInputs,
      [type]: {
        ...formInputs[type],
        [key]: value,
      },
    });
  };

  const handleSetRecaptcha = (recaptcha) =>
    setFormInputs({
      ...formInputs,
      recaptcha,
    });

  const handleSubmit = async (e) => {
    formError && setFormError("");
    setFormSubmitting(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", formInputs.name);
    formData.append("email", formInputs.email);
    formData.append("phone", formInputs.phone);
    formData.append("message", formInputs.message);
    formData.append("est_passengers", formInputs.est_passengers);
    formData.append("event_name", formInputs.event_name);
    formData.append("event_start_date", formInputs.event_start_date);
    formData.append("event_end_date", formInputs.event_end_date);
    formData.append("event_location", formInputs.event_location.display);
    formData.append("g-recaptcha-response", formInputs.recaptcha);
    Object.entries(formInputs.event_type).forEach(([key, value]) => {
      value && formData.append("event_type[]", key);
    });
    Object.entries(formInputs.ride_type).forEach(([key, value]) => {
      value && formData.append("ride_type[]", key);
    });

    try {
      await axios({
        url: "https://www.limos.com/corporate/event_request_contact_us",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      });
      setFormSubmitted(true);
    } catch (error) {
      console.error(error);
      setFormError(error.message);
    }

    setFormSubmitting(false);
  };

  const onTypeaheadSearch = (query, id, finished) => {
    let googleResults = [],
      airportResults = [],
      googleFinished = false,
      airportFinished = query.length < 2;
    const google = window.google;

    const finishSearch = () => {
      if (googleFinished && airportFinished) {
        setQueryResults([...airportResults, ...googleResults]);
        finished();
      }
    };

    const googleSuggestions = (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        console.log(`google place api error: ${status}`);
        googleFinished = true;
        finished();
        return;
      }

      googleResults = predictions
        .filter(
          (res) =>
            !res.types.includes("locality") && !res.types.includes("airport")
        )
        .map((res) => ({
          placeId: res.place_id,
          types: res.types,
          display: res.description,
        }));
      googleFinished = true;
      finishSearch();
    };

    const airportSuggestions = (data) => {
      airportResults = data.slice(0, 1).map((res) => ({
        placeId: res.id,
        display: res.text,
        is_airport: true,
      }));
      airportFinished = true;
      finishSearch();
    };

    new google.maps.places.AutocompleteService().getPlacePredictions(
      { input: query, types: [] },
      googleSuggestions
    );
    fetch(`/services/location/airports?n=${encodeURIComponent(query)}`)
      .then((res) => res.json())
      .then(airportSuggestions);
  };

  return (
    <div className="hero-form">
      {formSubmitted ? (
        <>
          <h4>Thank You!</h4>
          <p>A Limos.com representative will contact you shortly.</p>
          <Button
            block
            className="search-button"
            type="button"
            data-test="submit-search"
            onClick={() => {
              setFormSubmitted(false);
              setFormInputs({
                ...formInputs, // keeps name, phone, email
                est_passengers: "",
                event_name: "",
                event_type: eventTypes.reduce(
                  (acc, curr) => ({
                    ...acc,
                    [curr]: false,
                  }),
                  {}
                ),
                ride_type: rideTypes.reduce(
                  (acc, curr) => ({
                    ...acc,
                    [curr]: false,
                  }),
                  {}
                ),
                event_location: {},
                event_start_date: null,
                event_end_date: null,
                message: "",
              });
            }}
          >
            Submit Another Request
          </Button>
        </>
      ) : (
        <>
          <p style={{ textAlign: "center" }}>
            Interested in a quote for your offsite meeting or event? Fill out
            the form below and an events specialist will be in touch within 2
            business days!
          </p>
          <form onSubmit={handleSubmit}>
            <div className="rbt">
              <input
                type="text"
                placeholder="Full Name"
                required
                name="name"
                autoComplete="name"
                onChange={handleInputChange}
                value={formInputs.name}
                className="rbt-input-main form-control rbt-input"
              />

              <input
                type="email"
                placeholder="Email"
                required
                name="email"
                autoComplete="email"
                onChange={handleInputChange}
                value={formInputs.email}
                className="rbt-input-main form-control rbt-input"
              />

              <input
                type="tel"
                placeholder="Phone"
                required
                name="phone"
                autoComplete="tel"
                onChange={handleInputChange}
                value={formInputs.phone}
                className="rbt-input-main form-control rbt-input"
              />

              <input
                type="text"
                placeholder="Event Name"
                required
                name="event_name"
                onChange={handleInputChange}
                value={formInputs.event_name}
                className="rbt-input-main form-control rbt-input"
              />

              <div className="checkboxes-holder">
                <h4>Event Type</h4>
                {eventTypes.map((type) => (
                  <div className="checkboxes" key={type}>
                    <input
                      type="checkbox"
                      id={toKebabCase(type)}
                      name={toKebabCase(type)}
                      checked={formInputs.event_type.type}
                      onChange={(e) =>
                        handleCheckboxSelection(
                          "event_type",
                          type,
                          e.target.checked
                        )
                      }
                    />
                    <label htmlFor={toKebabCase(type)}>
                      {toTitleCase(type)}
                    </label>
                  </div>
                ))}
              </div>

              <TypeAheadFormField
                setPlaceValid={(valid) =>
                  console.log("event_location valid?", valid)
                }
                inputProps={{
                  "data-hj-whitelist": true,
                  className: "data-hj-whitelist",
                  "data-test": "pickup-place",
                }}
                name="event-location"
                id="event-location"
                useGoogle={true}
                onSearch={onTypeaheadSearch}
                google={typeof window !== "undefined" && window.google}
                placeholder="Event Location, Search City, Zip or Airport"
                onPlaceQuery={(param) => console.log(param)}
                queryResults={queryResults || []}
                onSelected={(selectedItem) =>
                  setFormInputs({
                    ...formInputs,
                    event_location: selectedItem,
                  })
                }
              />

              <DateRangePicker
                startDate={formInputs.event_start_date}
                startDateId="startDate"
                endDate={formInputs.event_end_date}
                endDateId="endDate"
                onDatesChange={({ startDate, endDate }) =>
                  setFormInputs({
                    ...formInputs,
                    event_start_date: startDate,
                    event_end_date: endDate,
                  })
                }
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
              />

              <Form.Group controlId="est_passengers" className="rbt">
                <Form.Control
                  as="select"
                  data-test="pax"
                  name="est_passengers"
                  onChange={handleInputChange}
                  value={formInputs.est_passengers}
                >
                  <option value="">Est. Passengers</option>
                  {Array(8)
                    .fill()
                    .map((_, i) => (
                      <option value={i + 1} key={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  <option value="custom">9+</option>
                </Form.Control>
              </Form.Group>

              <div className="checkboxes-holder">
                <h4>Ride Types</h4>
                {rideTypes.map((type) => (
                  <div className="checkboxes" key={type}>
                    <input
                      type="checkbox"
                      id={toKebabCase(type)}
                      name={toKebabCase(type)}
                      checked={formInputs.ride_type.type}
                      onChange={(e) =>
                        handleCheckboxSelection(
                          "ride_type",
                          type,
                          e.target.checked
                        )
                      }
                    />
                    <label htmlFor={toKebabCase(type)}>
                      {toTitleCase(type)}
                    </label>
                  </div>
                ))}
              </div>

              <div className="message-input">
                <h4>
                  <label htmlFor="message">Message</label>
                </h4>
                <textarea
                  value={formInputs.message}
                  onChange={handleInputChange}
                  id="message"
                  name="message"
                  rows="4"
                  cols="50"
                  placeholder="Please describe in detail the type of event you are planning and who your attendees are (i.e., executives, managers, media, celebrity, students, etc.)"
                />
              </div>

              <div
                style={{
                  margin: "24px auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ReCAPTCHA
                  sitekey="6LfQXQ4TAAAAAKOCRzXHyTkP7rnlCgvCM1MlPw9q"
                  onChange={handleSetRecaptcha}
                />
              </div>

              <Button
                block
                className="search-button"
                type="submit"
                data-test="submit-search"
                disabled={!formInputs.recaptcha}
              >
                {formSubmitting ? <Spinner /> : "Submit Request"}
              </Button>
              {Boolean(formError) ? (
                <p
                  style={{
                    marginTop: "2rem",
                    color: "red",
                    textAlign: "center",
                  }}
                >
                  {formError}
                </p>
              ) : (
                <></>
              )}
            </div>
          </form>
        </>
      )}
    </div>
  );
};

const solutions = [
  "Pre-event consultation to ensure we book the perfect transportation for your next event",
  "Airport Arrival and Departure Service",
  "Full Service Greet Staff",
  "24/7/365 Customer Care Support",
  "Local Shuttle Transportation",
  "Hourly as Directed Charters for Holiday Parties and Special Events",
  "Point-to-Point Transfer Service",
  "Full Fleet Availability: Sedans, Suv’s, Executive Vans, Mini Buses and Motor Coaches",
  "Access to Luxury Vehicles",
  "Vip Transportation Service",
];

const Slide = ({ img, key, ...props }) => (
  <div
    key={key}
    className="slide"
    style={{
      ...props.styles,
      backgroundImage: `url("${img}")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    }}
  />
);

export default function EventsPage() {
  return (
    <div id="events-page">
      <div className="jumbotron-header">
        <div className="jumbotron-content">
          <div className="info-text">
            <h1>Event Transportation, Simplified.</h1>
            <h2>
              Limos.com specializes in providing end-to-end global
              transportation management for corporate meetings and events. We’ll
              take care of selecting and booking the most cost effective,
              professional and reliable vendors to fulfill all your corporate
              transportation needs.
            </h2>
            <div className="services">
              <h3>Full Service Ground Transportation Solutions Include:</h3>
              <ul>
                {solutions.map((solution) => (
                  <li key={solution}>{solution}</li>
                ))}
              </ul>
            </div>
          </div>
          <EventRequestForm />
        </div>
        <div className="colorLayer" />
        <Slider
          {...{
            dots: false,
            infinite: true,
            speed: 5000,
            autoplay: true,
            fade: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: false,
          }}
        >
          {[
            "https://assets.s3.amazonaws.com/images/front-end/events-page/tourbus.jpg",
            "https://assets.s3.amazonaws.com/images/front-end/events-page/business.jpg",
            "https://assets.s3.amazonaws.com/images/front-end/events-page/golf.jpg",
            "https://assets.s3.amazonaws.com/images/front-end/events-page/dinner.jpg",
            "https://assets.s3.amazonaws.com/images/front-end/events-page/wedding.jpg",
            "https://assets.s3.amazonaws.com/images/front-end/events-page/airport.jpg",
            "https://assets.s3.amazonaws.com/images/front-end/events-page/bar.jpg",
            "https://assets.s3.amazonaws.com/images/front-end/events-page/convention.jpg",
          ].map((img, i) => (
            <Slide img={img} key={i} />
          ))}
        </Slider>
      </div>
    </div>
  );
}
