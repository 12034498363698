import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import "./SearchResult.scss";
import { MemoryRouter, Redirect, Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Rating from "react-rating";
import { routePaths } from "../../routes/routes";
import MapComponent from "../MapComponent/MapComponent";
import MiniTripInfoComponent from "../MiniTripInfoComponent/MiniTripInfoComponent";
import { withWhiteLabelContext } from "react-whitelabel";

class SearchResult extends Component {
  state = {
    redirect: false,
    reservationTerms: ""
  };

  addToCart = () => {
    this.props.addItemToCart(
      this.props.searchResult,
      this.props.legName,
      () => {
        if (this.props.cartCallback) {
          this.props.cartCallback();
        }
        if (this.props.showCheckout) {
          this.setState({ redirect: true });
        }
      }
    );
  };

  setReservationTerms = (reservationTerms) => {
    this.setState({ reservationTerms });
  };

  createPricingBreakdown() {}

  render() {
    const amenities = this.props.amenities
      ? this.props.amenities.map((item, key) => (
          <li key={key}>
            <FontAwesomeIcon className="checkMarkIcon" icon="check-square" />
            {item.vehicle_feature_definition.description}
          </li>
        ))
      : [];
    if (this.state.redirect) {
      return <Redirect to={routePaths.checkout} push />;
    } else {
      let dateRow = <></>;
      if (this.props.displayContextIsCheckout) {
        dateRow = (
          <>
            <Row>
              <Col xs={12} className={"searchResultReferenceNumber"}>
                {!this.props.showConfirmationNumber ? (
                  <>Ref. #: {this.props.resultHandle}</>
                ) : (
                  <></>
                )}
                {this.props.showConfirmationNumber &&
                this.props.confirmationNumber ? (
                  <>Res. #: {this.props.confirmationNumber}</>
                ) : (
                  <></>
                )}
                {this.props.showConfirmationNumber &&
                !this.props.confirmationNumber ? (
                  <>Generating Reservation Number</>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <MiniTripInfoComponent
              pickupPlace={this.props.pickupPlace}
              dropoffPlace={this.props.dropoffPlace}
              completeDate={this.props.completeDate}
              returnDate={this.props.returnDate}
              showDate={true}
              showTrip={false}
            />
          </>
        );
      }
      const {
        reviewsLink = [],
        showCallChat = false,
        showPrice = true,
        openChatWindow = () => "nothing",
        showAmenities = true,
        showReviews = true,
      } = this.props;
      const { telephone } = this.props.label;
      const registerCallChatClick = () => {
        if (typeof window.dataLayer !== "undefined") {
          window.dataLayer.push({ event: "search-call-chat-clicked" });
        }
      };
      return (
        <div className={"cardContainer"}>
          <Container className={"cardInnerContainer"}>
            <div className={""}>
              <MemoryRouter>
                {dateRow}
                <Row>
                  <Col xs={12} className={"vehicleTitle"}>
                    {this.props.displayName}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={"center gratuity"}>
                    <FontAwesomeIcon icon="thumbs-up" />{" "}
                    <em>Gratuity included!</em>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={"carousel-outer"}>
                    <Switch>
                      <Route path={"/"} exact>
                        <Carousel
                          interval={null}
                          prevIcon={
                            <FontAwesomeIcon
                              className="carousel-arrow"
                              icon="arrow-circle-left"
                            />
                          }
                          nextIcon={
                            <FontAwesomeIcon
                              className="carousel-arrow"
                              icon="arrow-circle-right"
                            />
                          }
                          key="carousel-images"
                        >
                          {this.props.images.map((imageUrl, index) => {
                            let formatImageURL = imageUrl.replace(
                              "http://",
                              "https://"
                            );
                            return (
                              <Carousel.Item key={index} height={200}>
                                <div className={"carouselImageContainer"}>
                                  <img
                                    src={ formatImageURL.includes("/system/media") ? formatImageURL : "https://assets.s3.amazonaws.com/images/front-end/new-landing/limos.com.jpg" }
                                    alt="Car"
                                  />
                                </div>
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </Route>
                      <Route path={"/amenities"}>
                        <div className={"amenities"}>{amenities}</div>
                      </Route>
                      <Route path={"/reviews"}>
                        <Carousel
                          interval={null}
                          prevIcon={
                            <FontAwesomeIcon
                              className="carousel-arrow"
                              icon="arrow-circle-left"
                            />
                          }
                          nextIcon={
                            <FontAwesomeIcon
                              className="carousel-arrow"
                              icon="arrow-circle-right"
                            />
                          }
                          key="carousel-reviews"
                        >
                          {reviewsLink
                            .filter((review) => {
                              if (
                                review.review === "" ||
                                review.review === null
                              ) {
                                return false;
                              }
                              return true;
                            })
                            .map((review, index) => (
                              <Carousel.Item key={index} height={200}>
                                <div className={"carouselImageContainer"}>
                                  <div
                                    className={"reviews"}
                                    style={{ overflow: "auto" }}
                                  >
                                    <div className="stars">
                                      <Rating
                                        start={0}
                                        stop={5}
                                        fractions={8}
                                        initialRating={review.rating}
                                        readonly={true}
                                        quiet={true}
                                        fullSymbol={
                                          <FontAwesomeIcon icon={"star"} />
                                        }
                                        emptySymbol={
                                          <FontAwesomeIcon
                                            icon={"star"}
                                            className={"emptyStar"}
                                          />
                                        }
                                      />
                                    </div>
                                    <div>{review.review}</div>
                                  </div>
                                </div>
                              </Carousel.Item>
                            ))}
                        </Carousel>
                      </Route>
                    </Switch>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8}>
                    <Row>
                      <Col xs={8} className="stars">
                        {!this.props.hideDetails && (
                          <Rating
                            start={0}
                            stop={5}
                            fractions={8}
                            initialRating={this.props.starRating}
                            readonly={true}
                            quiet={true}
                            fullSymbol={<FontAwesomeIcon icon={"star"} />}
                            emptySymbol={
                              <FontAwesomeIcon
                                icon={"star"}
                                className={"emptyStar"}
                              />
                            }
                          />
                        )}
                      </Col>
                      <Col xs={4} className={"operatorId"}>
                        {!!this.props.hideDetails ? (
                          <></>
                        ) : (
                          <>Op:{this.props.operatorId}</>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        {!!this.props.hideDetails ? (
                          <></>
                        ) : (
                          <>
                            Rating:{" "}
                            {this.props.starRating
                              ? this.props.starRating
                              : "-"}
                            {"/"}5.0{" "}
                            {showReviews && (
                              <>
                                •{" "}
                                <Link to={"/reviews"}>
                                  {this.props.searchResult.result.num_reviews}{" "}
                                  Reviews{" "}
                                </Link>
                              </>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={4}>
                    <Row>
                      <Col xs={12} className={"heroPrice"}>
                        <div className={showPrice ? "" : "hidden"}>
                          <span className={"dollarSign"}>$</span>
                          {this.props.grandTotal
                            ? Math.ceil(this.props.grandTotal / 100)
                            : this.props.isHourly
                            ? Math.ceil(
                                parseFloat(
                                  this.props.searchResult.result.grand_total
                                ) /
                                  this.props.searchResult.result
                                    .total_hours_count
                              )
                            : Math.ceil(
                                parseFloat(
                                  this.props.searchResult.result.grand_total
                                )
                              )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className={"priceSubtitle"}>
                        <div className={showPrice ? "" : "hidden"}>
                          {this.props.grandTotal
                            ? "$" + this.props.grandTotal / 100
                            : this.props.isHourly
                            ? "/hr (total price)"
                            : "(total price)"}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {!!this.props.hideDetails ? (
                  <></>
                ) : (
                  <Row noGutters={true} className={"capacityValueRow"}>
                    <Col xs={3} className={"capacityBox"}>
                      <div className="capacityBoxInner">
                        <FontAwesomeIcon
                          icon={{ prefix: "fal", iconName: "users" }}
                        />
                        <span className={"capacityValue"}>
                          {this.props.passengerCapacity}
                        </span>{" "}
                      </div>{" "}
                    </Col>
                    <Col xs={3} className={"capacityBox"}>
                      <div className="capacityBoxInner">
                        <FontAwesomeIcon
                          icon={{ prefix: "fal", iconName: "suitcase" }}
                        />
                        <span className={"capacityValue"}>
                          {this.props.luggageCapacity}
                        </span>{" "}
                      </div>{" "}
                    </Col>
                    <Col xs={6}>
                      {showAmenities && (
                        <Switch>
                          <Route path={"/"} exact>
                            <Link to={"/amenities"} className="no-underline">
                              <Button className={"amenitiesButton"} block>
                                {" "}
                                <span className={"amenitiesIcon"}>
                                  <FontAwesomeIcon icon={"car"} />
                                </span>
                                <span className={"amenitiesButtonText"}>
                                  {" "}
                                  AMENITIES{" "}
                                </span>{" "}
                              </Button>
                            </Link>
                          </Route>
                          <Route path={"/"}>
                            <Link to={"/"} className="no-underline">
                              <Button className={"amenitiesButton"} block>
                                {" "}
                                <span className={"amenitiesIcon"}>
                                  <FontAwesomeIcon
                                    icon={{ prefix: "fa", iconName: "images" }}
                                  />
                                </span>
                                <span className={"amenitiesButtonText"}>
                                  {" "}
                                  IMAGES{" "}
                                </span>{" "}
                              </Button>
                            </Link>
                          </Route>
                        </Switch>
                      )}
                    </Col>
                  </Row>
                )}
              </MemoryRouter>
            </div>
            {this.props.displayContextIsCheckout ? (
              <>
                <MiniTripInfoComponent
                  pickupPlace={this.props.pickupPlace}
                  dropoffPlace={this.props.dropoffPlace}
                  completeDate={this.props.completeDate}
                  showDate={false}
                  showTrip={true}
                />

                <MapComponent
                  pickupPlace={this.props.pickupPlace}
                  dropoffPlace={this.props.dropoffPlace}
                />
              </>
            ) : (
              <></>
            )}
          </Container>
          <Row>
            {this.props.displayContextIsCheckout ||
            !!this.props.hideSelectRide ? (
              <></>
            ) : (
              <Col xs={12}>
                <div className="addToCartButton">
                  {showCallChat ? (
                    <>
                      <Button
                        onClick={(e) => {
                          registerCallChatClick();
                          openChatWindow(e);
                        }}
                        className="btn-rounded chatButton small-font"
                      >
                        <FontAwesomeIcon icon="comments" /> Live Chat Now
                      </Button>
                      <a
                        href={`tel:${telephone}`}
                        className="btn btn-rounded btn-block linkButton small-font"
                        onClick={registerCallChatClick}
                      >
                        <FontAwesomeIcon icon="phone" /> {telephone}
                      </a>
                    </>
                  ) : (
                    <>
                      <Button onClick={this.addToCart}>SELECT RIDE</Button>
                    </>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </div>
      );
    }
  }
}

export default withWhiteLabelContext(SearchResult);
