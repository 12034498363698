import React from "react"
import { Redirect } from "react-router-dom"
import {
  CardElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js"
import axios from "axios"
import { withWhiteLabelContext } from "react-whitelabel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Typeahead } from "react-bootstrap-typeahead"

import { routePaths } from "../../routes/routes"
import { features, endpoints, keys } from "../../globals.js"
import { addressComponent } from "../../utils/address.js"
import useLocalStorage from "../../hooks/useLocalStorage"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Spinner from "react-bootstrap/Spinner"

import BookingConditions from "../BookingConditions/BookingConditions.jsx"
import PromoCode from "./PromoCode.jsx"
import SearchResultCheckout from "../SearchResultCheckout/SearchResultCheckout"

import CountdownTimer from "../CountdownTimer"

import "./CheckoutPage.scss"
import moment from "moment"
import { Container } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

const CancelToken = axios.CancelToken
let clientSecretCancel

// The pricing api requires booleans to be specified as t or f strings.
const apiBool = bool => (bool ? "t" : "f")

function formatMoney(n) {
  return (
    (n < 0 ? "-" : "") +
    Math.abs(n / 100)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
  )
}

const ToolTip = props => {
  return (
    <div className="hover-icon">
      <span className="dot">i</span>
      <span className="tooltiptext">{props.text}</span>
    </div>
  )
}
class CheckoutPage extends React.Component {
  extrasKey = 0
  constructor(props) {
    super(props)
    this.state = {
      resultHandle1: props.cart.resultHandle1 ? props.cart.resultHandle1 : null,
      resultHandle2: props.cart.resultHandle2 ? props.cart.resultHandle2 : null,
      resultHandler1Data: props.cart.resultHandler1Data
        ? props.cart.resultHandler1Data
        : [],
      resultHandler2Data: props.cart.resultHandle2Data
        ? props.cart.resultHandler2Data
        : [],
      search_results: [],
      loading: true,
      email: "",
      password: "",
      phone: "",
      name: "",
      lastName: "",
      token: false,
      stripeLoaded: false,
      canMakeBrowserPayment: false,
      paymentRequest: false,
      forceFallback: false,
      client_secret: false,
      fetchingClientSecret: false,
      paymentInProgress: false,
      paymentSucceeded: false,
      paymentNetworkIsuue: false,
      termsChecked: false,
      showModal: false,
      showConditionsModal: false,
      paymentError: false,
      "postal-code": "",
      addressItem: {},
      addressQueryResults: [],
      hideAutofillAddress: false,
      manualAddress: "",
      checkingPromoCode: false,
      promoCodeFailedMessage: false,
      apiGrandTotal: false,
      reservationTerms: false,
      airlines: [],
      carrier: { code: "none specified" },
      flight: "",
      flightLinked: false,
      flightLinkLoading: false,
      flightLinkLoader: false,
      flightLinkingError: "",
      checkoutErrors: [],
      flightNumberForm: false,
      flightCarrier: false,
      extras: {
        "suggested-gratuity": {
          name: "suggested-gratuity",
          displayName: "Gratuity",
          amount: 0,
          title: "Gratuity",
          subtitle: `Don't worry about about cash, Tip Your driver now`,
          selected: true,
          visible: false,
          disabled: true,
          tooltip:
            "This gratuity is mandatory and set by each individual operator",
          order: 10,
        },
        insurance: {
          name: "insurance",
          displayName: "Trip Assurance",
          amount: 0,
          title: "Trip Assurance",
          subtitle: `If your plans change, enjoy worry-free trip cancellations or re-booking(details below)`,
          selected: false,
          visible: true,
          order: 100,
        },
        "stops-option": {
          name: "stops-option",
          displayName: "Extra Stop",
          amount: 0,
          title: "Extra Stop",
          subtitle: `Add an extra stop, pick up friends!`,
          selected: false,
          visible: false,
          order: 200,
        },
        "stops-option-return": {
          name: "stops-option-return",
          displayName: "Extra Stop (Return Trip)",
          amount: 0,
          title: "Extra Stop (Return Trip)",
          subtitle: `Add an extra stop, pick up friends!`,
          selected: false,
          visible: false,
          order: 200,
        },
        "meet-and-greet": {
          name: "meet-and-greet",
          displayName: "Meet & Greet",
          amount: 0,
          title: "Meet and Greet inside airport",
          subtitle: `Your driver will meet you inside the terminal`,
          selected: false,
          visible: false,
          order: 300,
        },
      },
      result_handle: "",
      promoCode: { code: false, amount: 0, order: 9001 },
      showCountdown: false,
      isTermsChecked: false,
      showTermsAndConditionsReminder: false,
      isFormSubmitted: false,
      showTripModal: false,
      pax_first_name: "",
      pax_last_name: "",
      pax_phone_num: "",
      pax_email: "",
      payment_pan: "",
      payment_expiration_date: "",
      payment_postal_code: "",
      payment_security_code: "",
      payment_street1: "",
      payment_state: "",
      payment_city: "",
      acceptTerms: false,
      trip_assurance: false,
      errors: {},
    }
    if (!this.props.completeDate) {
      this.props.loadBookingState()
    }
    if (typeof document !== "undefined") {
      document.title = this.props.label.title + " - Checkout"
    }
    console.log("new staging");
    this.handleSubmit = this.handleSubmit.bind(this)
    this.autocomplete = null
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.mozioFlag = this.props.cart.pickupLeg?.result.operator_handle === "MOZ"
  }

  setReservationTerms = () => {
    this.setState({ reservationTerms: true })
  }

  fetchClientSecret() {
    if (clientSecretCancel) {
      clientSecretCancel()
    }
    const extras = this.state.extras
    this.setState(state => {
      return {
        fetchingClientSecret: true,
        promoCodeFailedMessage: false,
        promoCode: { code: state.promoCode.code, amount: 0 },
        canMakeBrowserPayment: false,
      }
    })
    const roundTrip = !!this.props.cart.dropoffLeg
    const url = this.props.label.bids
      ? `${endpoints.limos}/api/bid/${this.props.cart.pickupLeg.bidId}/purchase.json`
      : `${endpoints.limos}/api/pricing/${
          this.props.cart.pickupLeg
            ? this.props.cart.pickupLeg.result.result_handle
            : this.props.cart.resultHandle1
        }${
          roundTrip
            ? "/" + this.props.cart.dropoffLeg?.result?.result_handle
            : ""
        }/options.json`
    let returnData = {}
    const mozioPayload = {
      api_key: keys.limos,
      promo_code: false,
      number_of_stops: 0,
      add_meet_and_greet: "f",
      add_insurance: "f",
      include_gratuity: "t",
      grand_total: this.props.cart.pickupLeg.result?.grand_total || 0,
    }
    if (roundTrip) {
      returnData = {
        return: {
          api_key: keys.limos,
          promo_code: this.state.promoCode.code,
          number_of_stops: extras["stops-option-return"].selected ? 1 : 0,
          add_meet_and_greet:
            apiBool(extras["meet-and-greet"].selected) &&
            parseFloat(this.props.cart.pickupLeg.result.meet_greet_option) ===
              0,
          add_insurance: apiBool(extras["insurance"].selected),
          include_gratuity: apiBool(extras["suggested-gratuity"].selected),
        },
      }
    }

    const actualData = {
      api_key: keys.limos,
      promo_code: this.state.promoCode.code,
      number_of_stops: extras["stops-option"].selected ? 1 : 0,
      add_meet_and_greet: apiBool(
        extras["meet-and-greet"].selected &&
          parseFloat(this.props.cart.pickupLeg.result.meet_greet_option) !== 0
      ),
      add_insurance: apiBool(extras["insurance"].selected),
      include_gratuity: apiBool(extras["suggested-gratuity"].selected),
      ...returnData,
    }

    axios({
      url,
      method: "post",
      data: this.mozioFlag ? mozioPayload : actualData,
      cancelToken: new CancelToken(function executor(c) {
        clientSecretCancel = c
      }),
    })
      .then(response => {
        this.setState({
          client_secret: response.data.sec_key,
          apiGrandTotal: response.data.grand_total,
          promoCode: {
            amount: response.data.promo_discount_amount,
            code: this.state.promoCode.code,
          },
          promoCodeFailedMessage: response.data.promo_code_message,
          fetchingClientSecret: false,
          promoCodeLoading: false,
        })
        this.props.setBookedRide({
          grandTotal: response.data.grand_total,
          isAirport: this.props.pickupPlace && this.props.pickupPlace.isAirport,
          airlineCode: this.state.carrier && this.state.carrier.code,
          flightNumber: this.state.flightNumber,
        })
      })
      .catch(thrown => {
        this.setState({ fetchingClientSecret: false })
        if (!axios.isCancel(thrown)) {
          this.props.setError(thrown)
        }
      })
  }

  updateCart = () => {
    window.scrollTo(0, 0)
    const extras = this.state.extras
    const roundTrip = !!this.props.cart.dropoffLeg
    const returnLeg = roundTrip
      ? this.props.cart.dropoffLeg?.result
      : {
          service_charge: "0",
          trip_ins_price: "0",
          stops_option: "0",
          meet_greet_option: "0",
        }
    this.setState(
      {
        extras: {
          ...extras,
          "suggested-gratuity": {
            ...extras["suggested-gratuity"],
            amount: this.props.cart.pickupLeg
              ? parseFloat(this.props.cart.pickupLeg.result.service_charge) *
                  100 +
                parseFloat(returnLeg.service_charge) * 100
              : 0,
          },
          insurance: {
            ...extras["insurance"],
            amount: this.props.cart.pickupLeg
              ? parseFloat(this.props.cart.pickupLeg.result.trip_ins_price) *
                  100 +
                parseFloat(returnLeg.trip_ins_price) * 100
              : 0,
          },
          "stops-option": {
            ...extras["stops-option"],
            amount: this.props.cart.pickupLeg
              ? parseFloat(this.props.cart.pickupLeg.result.stops_option) * 100
              : 0,
            visible: this.props.cart.pickupLeg
              ? parseFloat(this.props.cart.pickupLeg.result.stops_option) !== 0
              : 0,
          },
          "stops-option-return": {
            ...extras["stops-option-return"],
            amount: parseFloat(returnLeg.stops_option) * 100,
            visible: parseFloat(returnLeg.stops_option) !== 0,
          },
          "meet-and-greet": {
            ...extras["meet-and-greet"],
            amount: this.props.cart.pickupLeg
              ? parseFloat(this.props.cart.pickupLeg.result.meet_greet_option) *
                100
              : 0,
            visible: this.props.pickupPlace.isAirport,
          },
        },
      },
      () => this.fetchClientSecret()
    )
  }

  linkFlight = async e => {
    e.preventDefault()
    this.setState({ flightLinkLoader: true })

    const { apiGrandTotal, carrier, flightNumber } = this.state
    const { pickupPlace, dropoffPlace, bookingParamValues } = this.props

    // const flightParams = {
    //   api_key: keys.limos,
    //   airline_code: carrier.code,
    //   flight_num: flightNumber,
    //   pickup_place: pickupPlace?.airportCode,
    //   ride_date: moment(bookingParamValues?.ride_date).format("YYYY/MM/DD"),
    //   pickup_time: bookingParamValues.pickup_time,
    // }

    // right now comment this code for user to not getting stuck at flight linking issue

    // const res = await fetch(
    //   `${endpoints.limos}/v1/biz/reservations/validate_flight?${Object.keys(
    //     flightParams
    //   )
    //     .map(
    //       (key) =>
    //         encodeURIComponent(key) +
    //         "=" +
    //         encodeURIComponent(flightParams[key])
    //     )
    //     .join("&")}`,
    //   { method: "POST" }
    // );
    // if (res) {
    //   this.setState({ flightLinkLoader: false });
    // }
    // const data = await res.json();

    // if (data?.code !== "0") {
    //   this.setState({ flightLinkingError: data.message });
    //   return
    // }

    this.props.setBookedRide({
      grandTotal: apiGrandTotal,
      isAirport:
        (pickupPlace && pickupPlace.isAirport) ||
        (dropoffPlace && dropoffPlace?.isAirport),
      airlineCode: carrier && carrier.code,
      flightNumber: flightNumber,
    })

    this.setState({ flightLinked: true })
  }

  setFlight = e => {
    this.setState({ flightNumber: e.target.value })
    this.setState({ flightNumberForm: true })
  }

  checkInputs = () => {
    if (this.state.carrier?.code && this.state.carrier?.name && this.state.flightNumber) {
      this.setState({ flightLinkLoading: true })
    }
  }

  componentDidMount() {
    this.updateCart()
    this.setState({
      originalPickupPlace: this.props.pickupPlace,
      originalDropoffPlace: this.props.dropoffPlace,
      stripeLoaded: this.props.stripe,
    })
    if (this.props.bookingParamValues.is_round_trip == "1") {
      this.setState({ result_handle: this.props.cart.resultHandle1 })
    } else if (this.props.cart.pickupLeg) {
      this.setState({
        result_handle: this.props.cart.pickupLeg.result.result_handle,
      })
    }
    window.dataLayer.push({ event: "checkout", source: "checkout" })
    fetch("/airlines.json")
      .then(res => res.json())
      .then(res => this.setState({ airlines: res }))
    if (typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({ event: "checkout", source: "checkout" })
    }
  }

  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace()
    let address = addressObject.address_components
    this.setState(prevState => ({
      ...prevState,
      payment_street1: this.mapinstance?.value || "",
      payment_city: address[3].long_name,
      payment_state: address[6].short_name,
      payment_postal_code: address[7]?.long_name ?? "",
    }))
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cart.pickupLeg !== this.props.cart.pickupLeg) {
      this.updateCart()
    }
    if (!prevProps.stripe && this.props.stripe) {
      this.setState({ stripeLoaded: true })
    }
    if (
      this.state.stripeLoaded &&
      this.state.client_secret !== prevState.client_secret
    ) {
      const paymentRequest = this.props.stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Grand Total",
          amount: this.state.apiGrandTotal || 1,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
      })
      paymentRequest.canMakePayment().then(result => {
        this.setState({
          canMakeBrowserPayment: !!result,
          paymentRequest,
        })
      })
      const clientSecret = this.state.client_secret
      paymentRequest.on("paymentmethod", async ev => {
        this.setState({ paymentError: false })
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { error: confirmError } =
          await this.props.stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: ev.paymentMethod.id,
              save_payment_method: true,
            },
            { handleActions: false }
          )

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          this.setState({ paymentError: confirmError.message })
          ev.complete("fail")
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success")
          this.setState({ paymentSucceeded: true })
        }
      })
    }

    // autocomplete fix

    // if (this.mapinstance) {
    //   this.autocomplete = new window.google.maps.places.Autocomplete(
    //     this.mapinstance,
    //     {
    //       fields: ["address_components", "geometry"],
    //       types: ["address"],
    //     }
    //   );
    //   this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    // }
  }
  // form
  handleSubmit = async event => {
    event.preventDefault()
    // test data for local env.
    // let testData = {
    //   api_key: 'GYQPR-cVlSZTzsVzen',
    //   api_key: 'GYQPV-FtfMVNotXtjb',
    //   result_handle: xyz,
    //   pax_first_name: 'Gloria',
    //   pax_last_name: 'Tester',
    //   pax_phone_num: '14155672345',//14155672345
    //   pax_email: 'gloria@tester.com',
    //   payment_name: 'Jim Wilson',
    //   payment_pan: '4111111111111111',
    //   payment_expiration_date: '03/2023',
    //   payment_security_code: '123',
    //   payment_street1: '123 Smith Road',
    //   payment_city: 'San Francisco',
    //   payment_state: 'CA',
    //   payment_postal_code: '55555',
    //   pickup_street1: '100 broadway street'
    // };

    // "result_handle": "461781190",
    // "result_handle_leg2": "461781257",
    // "pax_first_name": "Gloria",
    // "pax_last_name": "Tester",
    // "pax_phone_num": "14155672345",
    // "pax_email": "gloria@tester.com",
    // "payment_pan": "4111111111111111",
    // "payment_expiration_date": "11/2023",
    // "payment_postal_code": "55555",
    // "pickup_street1": "100 Broadway",
    // "pickup_city": "San Francisco",
    // "pickup_state": "CA",
    // "pickup_postal_code": "94111",
    // "dropoff_street1": "100 Broadway",
    // "dropoff_city": "Oakland",
    // "dropoff_state": "CA",
    // "dropoff_postal_code": "94111",
    // "api_key": "GYQPR-cVlSZTzsVzen",
    // "is_round_trip": "Y"
    //  }

    const pickup = this.props
    const showFlight =
      (this.props.pickupPlace && this.props.pickupPlace.isAirport) || (this.props.dropoffPlace && this.props.dropoffPlace.isAirport);

    const {
      pax_first_name,
      pax_last_name,
      pax_phone_num,
      pax_email,
      payment_pan,
      payment_expiration_date,
      payment_security_code,
      payment_street1,
      payment_city,
      payment_state,
      payment_postal_code,
      trip_assurance,
      acceptTerms,
      promoCode,
      carrier,
      flightNumber,
    } = this.state

    // Perform validation
    const errors = {}

    this.validateField("pax_first_name", pax_first_name, errors)
    this.validateField("pax_last_name", pax_last_name, errors)
    this.validateField("pax_phone_num", pax_phone_num, errors)
    this.validateField("pax_email", pax_email, errors)
    this.validateField("payment_pan", payment_pan, errors)
    this.validateField("payment_security_code", payment_security_code, errors)
    this.validateField(
      "payment_expiration_date",
      payment_expiration_date,
      errors
    )
    this.validateField("payment_postal_code", payment_postal_code, errors)
    this.validateField("payment_street1", payment_street1, errors)
    this.validateField("payment_city", payment_city, errors)
    this.validateField("payment_state", payment_state, errors)
    this.validateField("acceptTerms", acceptTerms, errors)

    // If there are errors, update state and return
    if (Object.keys(errors).length > 0) {
      this.setState({ errors })
      return
    }

    let formData = {
      pax_first_name,
      pax_last_name,
      pax_phone_num,
      pax_email,
      payment_pan,
      payment_name: "",
      payment_security_code,
      payment_expiration_date,
      payment_postal_code,
      payment_street1,
      payment_city,
      payment_state,
      acceptTerms,
      pickup_street1: this.props.pickupPlace.display || "",
      trip_assurance: trip_assurance ? "1" : "0",
      api_key: "GYQPV-FtfMVNotXtjb",
      result_handle: pickup ? pickup.cart.pickupLeg.result.result_handle : "",
      promo_code: promoCode.code || "",
    }

    if (showFlight) {
      formData["airline_handle"] = carrier ? carrier.name : ""
      formData["flight_handle"] = flightNumber || ""
      formData["airline_code"] = carrier ? carrier.code : ""
    }

    try {
      this.setState({ isFormSubmitted: true })
      const settings = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
      let fetchResponse
      if (
        pickup.bookingParamValues &&
        pickup.bookingParamValues.is_round_trip == "1"
      ) {
        formData["result_handle"] = pickup.cart.resultHandle1
        formData["result_handle_leg2"] = pickup.cart.resultHandle2
        formData["dropoff_street1"] = ""
        formData["dropoff_city"] = ""
        formData["dropoff_state"] = ""
        formData["dropoff_postal_code"] = ""
        formData["is_round_trip"] = "Y"
      }

      fetchResponse = await fetch(
        `${endpoints.limos}/v1/biz/reservations/create_reservation.json?` +
          new URLSearchParams(formData),
        settings
      )

      //const fetchResponse = await fetch(`${endpoints.limos}/v1/biz/reservations.json?` + new URLSearchParams(formData), settings);
      const data = await fetchResponse.json()
      if (data && data.response_code == 200) {
        localStorage.setItem("reservationId", data.reservation.reservation_uuid)
        this.setState({ paymentSucceeded: true })
        this.setState({ isFormSubmitted: false })
        this.setState({ paymentNetworkIsuue: false })
        // this.setState({checkoutErrors: []});
      } else {
        this.setState({ paymentNetworkIsuue: true })
        this.setState({ checkoutErrors: data.reservation.errors })
        this.setState({ isFormSubmitted: false })
      }
    } catch (e) {
      console.log("Error while we are processing your request ", e.message)
      this.setState({ isFormSubmitted: false })
    }
  }

  validateField = (fieldName, value, errors = {}) => {
    switch (fieldName) {
      case "pax_first_name":
        if (!value) {
          errors.pax_first_name = "First name is required"
        } else {
          delete errors.pax_first_name
        }
        break
      case "pax_last_name":
        if (!value) {
          errors.pax_last_name = "Last name is required"
        } else {
          delete errors.pax_last_name
        }
        break
      case "pax_phone_num":
        if (!value) {
          errors.pax_phone_num = "Phone number is required"
        } else {
          delete errors.pax_phone_num
        }
        break
      case "pax_email":
        if (!value) {
          errors.pax_email = "Email is required"
        } else if (!this.validateEmail(value)) {
          errors.pax_email = "Invalid email format"
        } else {
          delete errors.pax_email
        }
        break
      case "payment_pan":
        if (!value) {
          errors.payment_pan = "Card number is required"
        } else if (!this.validateCardNumber(value)) {
          errors.payment_pan = "Invalid card number"
        } else {
          delete errors.payment_pan
        }
        break
      case "payment_security_code":
        if (!value) {
          errors.payment_security_code = "CVV is required"
        } else if (!this.validateCVV(value)) {
          errors.payment_security_code = "Invalid CVV"
        } else {
          delete errors.payment_security_code
        }
        break
      case "payment_expiration_date":
        if (!value) {
          errors.payment_expiration_date = "Expiry date is required"
        } else if (!this.validateExpiry(value)) {
          errors.payment_expiration_date = "Invalid expiry date"
        } else {
          delete errors.payment_expiration_date
        }
        break

      case "payment_postal_code":
        if (!value) {
          errors.payment_postal_code = "Zip code is required."
        } else {
          delete errors.payment_postal_code
        }
        break

      case "payment_street1":
        if (!value) {
          errors.payment_street1 = "Billing address is required."
        } else {
          delete errors.payment_street1
        }
        break

      case "payment_city":
        if (!value) {
          errors.payment_city = "Payment city is required."
        } else {
          delete errors.payment_city
        }
        break

      case "payment_state":
        if (!value) {
          errors.payment_state = "Payment state is required."
        } else {
          delete errors.payment_state
        }
        break

      case "acceptTerms":
        if (!value) {
          errors.acceptTerms = "Please agree to the terms and conditions"
        } else {
          delete errors.acceptTerms
        }
        break
      default:
        break
    }

    this.setState({ errors })
  }

  validateEmail = email => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  validateCardNumber = cardNumber => {
    // Remove all non-digit characters from the card number
    const strippedNumber = cardNumber.replace(/\D/g, "")

    // American Express: Starts with 34 or 37, 15 digits
    // Visa: Starts with 4, 13 or 16 digits
    // Mastercard: Starts with 51-55, 16 digits
    // Discover: Starts with 6011, 16 digits
    const cardRegex =
      /^(?:3[47]\d{13})|(?:4\d{12}(?:\d{3})?)|(?:5[1-5]\d{14})|(?:6011\d{12})$/

    return cardRegex.test(strippedNumber)
  }

  validateCVV = cvv => {
    // CVV should be 3 or 4 digits
    const cvvRegex = /^\d{3,4}$/
    return cvvRegex.test(cvv)
  }

  validateExpiry = expiry => {
    // Expiry should be in MM/YYYY format
    const expiryRegex = /^(0[1-9]|1[0-2])\/\d{2}$/

    if (!expiryRegex.test(expiry)) {
      return false
    }

    // Get the current date
    const currentDate = new Date()
    const currentYear = ("" + currentDate.getFullYear()).substring(2)
    const currentMonth = currentDate.getMonth() + 1

    // Extract month and year from the expiry date
    const [expiryMonth, expiryYear] = expiry
      .split("/")
      .map(str => parseInt(str, 10))

    // Validate the expiry month and year
    if (
      expiryYear < currentYear ||
      (expiryYear === currentYear && expiryMonth < currentMonth)
    ) {
      return false
    }

    return true
  }

  handleCardSubmit = async event => {
    event.preventDefault()
    const pickup = this.props
    // this.setState({ paymentInProgress: true, paymentError: false });
    // let line1;
    // if (this.state["street-address"]) {
    //   line1 = this.state["street-address"].split(",")[0];
    // } else if (this.state.addressItem.number) {
    //   const { number, street_name } = this.state.addressItem;
    //   line1 = number + " " + street_name;
    // } else {
    //   line1 = this.state.manualAddress.split(",")[0];
    // }

    // const billing_details = {
    //   name: this.state.name,
    //   email: this.state.email,
    //   phone: this.state.phone,
    //   address: {
    //     line1,
    //     postal_code: this.state["postal-code"],
    //   },
    // };

    // const pickup = this.props.cart.pickupLeg;
    // const dropoff = this.props.cart.dropoffLeg;
    // const roundTrip = !!this.props.cart.dropoffLeg;
    // const { operator_real_charge } = pickup.result;
    // let costOfRide = operator_real_charge || 0;
    // if (roundTrip) {
    //   costOfRide += dropoff?.result?.operator_real_charge || 0;
    // }

    // costOfRide += operator_real_charge;

    // const { elements } = this.props;
    // const { error: confirmError, paymentIntent } =
    //   await this.props.stripe.confirmCardPayment(this.state.client_secret, {
    //     payment_method: {
    //       card: elements.getElement("card"),
    //       billing_details,
    //     },
    //     save_payment_method: true,
    //   });

    // if (confirmError) {
    //   this.setState({
    //     paymentInProgress: false,
    //     paymentError: confirmError.message,
    //   });
    // } else {
    //   if (paymentIntent.status === "succeeded") {
    //     try {
    //       const res = await fetch(
    //         `${endpoints.limos}/v1/biz/reservations/save_checkout_data`,
    //         {
    //           method: "PUT",
    //           body: JSON.stringify({
    //             api_key: keys.limos,
    //             name: billing_details.name,
    //             phone: billing_details.phone,
    //             email: billing_details.email,
    //             address: billing_details.address.line1,
    //             zip: billing_details.address.postal_code,
    //             cost_of_ride: costOfRide,
    //             with_trip_assurance: this.state.extras.insurance.selected,
    //           }),
    //         }
    //       );

    //       if (res.status === 200) {
    //         console.log("successfuly stored checkout success to database");
    //       }
    //     } catch (e) {
    //       console.log("save checkout success error ", e.message);
    //     }

    //     this.setState({ paymentInProgress: false, paymentSucceeded: true });
    //   }
    // }
  }

  setTermsChecked = checked => {
    this.setState({ termsChecked: checked })
  }

  handleChange = event => {
    const { name, value, type, checked } = event.target
    const newValue = type === "checkbox" ? checked : value
    this.setState({
      [name]: newValue,
    })
  }

  handleBlur = event => {
    const { name, value } = event.target
    const { errors } = this.state
    this.validateField(name, value, errors)
  }

  onExtraChange = (name, checked) => {
    this.setState(
      state => {
        return {
          extras: {
            ...state.extras,
            [name]: {
              ...state.extras[name],
              selected: checked,
            },
          },
        }
      },
      () => {
        this.fetchClientSecret()
        this.extrasKey++
      }
    )
  }

  selectedExtras = () => {
    // unused but example of how to get values
    // returns an object of checked items with name mapping to value
    let ret = {}
    Object.values(this.state.extras)
      .filter(extra => extra.selected)
      .forEach(x => (ret[x.name] = x.value))
    return ret
  }

  setPromoCode = (amount, code) => {
    this.setState({ promoCode: { amount, code }, promoCodeLoading: true }, () =>
      this.fetchClientSecret()
    )
  }

  setAddress = item => {
    if (item) {
      this.service = new window.google.maps.places.PlacesService(this.instance)
      this.service.getDetails(
        { placeId: item.placeId, fields: ["address_components"] },
        (res, status) => {
          this.setState({
            addressItem: {
              number: addressComponent(res.address_components, "street_number"),
              street_name: addressComponent(res.address_components, "route"),
            },
            "postal-code": addressComponent(
              res.address_components,
              "postal_code"
            ),
          })
        }
      )
    } else {
      this.setState({ addressItem: {} })
    }
  }

  onAddressQuery = (_, results) => {
    this.setState({ addressQueryResults: results })
  }

  addressInputChange = addr => {
    this.setState({ manualAddress: addr })
  }

  toggleModalShow = e => {
    this.setState({ showModal: !this.state.showModal })
  }

  openModal = e => {
    this.setState({ showConditionsModal: !this.state.showConditionsModal })
  }

  openTripModal = e => {
    this.setState({ showTripModal: !this.state.showTripModal })
  }

  toggleConditionsModalShow = e => {
    this.setState({ showConditionsModal: !this.state.showConditionsModal })
  }

  onCountdownEnd = () => {
    window.history.back()
    this.setState({
      showCountdown: false,
    })
  }

  toggleTermsChecked = () => {
    this.setState({
      isTermsChecked: !this.state.isTermsChecked,
      showTermsAndConditionsReminder: false,
    })
  }

  getRoundTripBreakDown = () => {
    const { resultHandle1Data, resultHandle2Data } = this.props.cart
    const isFlatRateForFirstLeg = resultHandle1Data.rate_type === "FLAT"
    const isFlatRateForSecondLeg = resultHandle2Data.rate_type === "FLAT"

    return (
      <div className="round-trip-wrapper">
        <div className="round-trip-heading-container">
          <div className="first_leg_container">
            <span>First Leg</span>
          </div>

          <div className="second_leg_container">
            <span>Second Leg</span>
          </div>
        </div>

        <div className="round-trip-price-breakdown-container">
          <div className="round-trip-price-breakdown-container__first_leg">
            <div className="price-breakdown-row">
              <div className="cell-item">
                <div>
                  {resultHandle1Data.rate_type === "FLAT"
                    ? "Flat Rate"
                    : "Hourly Rate"}
                </div>

                <div className="extra-hours-text">
                  <span>
                    {isFlatRateForFirstLeg
                      ? ""
                      : `(${
                          resultHandle1Data.total_hours_count
                            ? resultHandle1Data.total_hours_count
                            : "-"
                        } hrs minimum)`}
                  </span>
                </div>
              </div>

              <div className="cell-value">
                <span className="dollar-sign">$</span>
                {Math.ceil(resultHandle1Data.base_rate)}
              </div>
              <div className="cell-hrs">
                <span className="dollar-sign">/hr</span>
              </div>
            </div>

            <div className="price-breakdown-row">
              <div className="cell-item">Tip</div>
              <div className="cell-value">
                <span className="dollar-sign">$</span>
                {isFlatRateForFirstLeg
                  ? parseFloat(resultHandle1Data.service_charge)
                  : parseFloat(resultHandle1Data.service_charge) > 0 &&
                    parseFloat(resultHandle1Data.total_hours_count) > 0
                  ? Math.ceil(
                      parseFloat(resultHandle1Data.service_charge) /
                        resultHandle1Data.total_hours_count
                    )
                  : "-"}
              </div>
              <div className="cell-hrs">
                <span className="dollar-sign">/hr</span>
              </div>
            </div>

            <div className="price-breakdown-row">
              <div className="cell-item">Fuel Fee</div>
              <div className="cell-value">
                <span className="dollar-sign">$</span>
                {isFlatRateForFirstLeg
                  ? parseFloat(resultHandle1Data.surcharge) * 0.49
                  : parseFloat(resultHandle1Data.surcharge) > 0 &&
                    parseFloat(resultHandle1Data.total_hours_count) > 0
                  ? Math.ceil(
                      (parseFloat(resultHandle1Data.surcharge) * 0.49) /
                        resultHandle1Data.total_hours_count
                    )
                  : "-"}
              </div>
              <div className="cell-hrs">
                <span className="dollar-sign">/hr</span>
              </div>
            </div>

            <div className="price-breakdown-row">
              <div className="cell-item">Regulatory Fee</div>
              <div className="cell-value">
                <span className="dollar-sign">$</span>
                {isFlatRateForFirstLeg
                  ? parseFloat(resultHandle1Data.surcharge) * 0.51
                  : parseFloat(resultHandle1Data.surcharge) > 0 &&
                    parseFloat(resultHandle1Data.total_hours_count) > 0
                  ? Math.ceil(
                      (parseFloat(resultHandle1Data.surcharge) * 0.51) /
                        resultHandle1Data.total_hours_count
                    )
                  : "-"}
              </div>
              <div className="cell-hrs">
                <span className="dollar-sign">/hr</span>
              </div>
            </div>
          </div>

          <div className="round-trip-price-breakdown-container__second_leg">
            <div className="price-breakdown-row">
              <div className="cell-item">
                <div>
                  {resultHandle2Data.rate_type === "FLAT"
                    ? "Flat Rate"
                    : "Hourly Rate"}
                </div>

                <div className="extra-hours-text">
                  <span>
                    {isFlatRateForSecondLeg
                      ? ""
                      : `(${
                          resultHandle2Data.total_hours_count
                            ? resultHandle2Data.total_hours_count
                            : "-"
                        } hrs minimum)`}
                  </span>
                </div>
              </div>

              <div className="cell-value">
                <span className="dollar-sign">$</span>
                {Math.ceil(resultHandle2Data.base_rate)}
              </div>
              <div className="cell-hrs">
                <span className="dollar-sign">/hr</span>
              </div>
            </div>

            <div className="price-breakdown-row">
              <div className="cell-item">Tip</div>
              <div className="cell-value">
                <span className="dollar-sign">$</span>
                {isFlatRateForSecondLeg
                  ? parseFloat(resultHandle2Data.service_charge)
                  : parseFloat(resultHandle2Data.service_charge) > 0 &&
                    parseFloat(resultHandle2Data.total_hours_count) > 0
                  ? Math.ceil(
                      parseFloat(resultHandle2Data.service_charge) /
                        resultHandle2Data.total_hours_count
                    )
                  : "-"}
              </div>
              <div className="cell-hrs">
                <span className="dollar-sign">/hr</span>
              </div>
            </div>

            <div className="price-breakdown-row">
              <div className="cell-item">Fuel Fee</div>
              <div className="cell-value">
                <span className="dollar-sign">$</span>
                {isFlatRateForSecondLeg
                  ? parseFloat(resultHandle2Data.surcharge) * 0.49
                  : parseFloat(resultHandle2Data.surcharge) > 0 &&
                    parseFloat(resultHandle2Data.total_hours_count) > 0
                  ? Math.ceil(
                      (parseFloat(resultHandle2Data.surcharge) * 0.49) /
                        resultHandle2Data.total_hours_count
                    )
                  : "-"}
              </div>
              <div className="cell-hrs">
                <span className="dollar-sign">/hr</span>
              </div>
            </div>

            <div className="price-breakdown-row">
              <div className="cell-item">Regulatory Fee</div>
              <div className="cell-value">
                <span className="dollar-sign">$</span>
                {isFlatRateForSecondLeg
                  ? parseFloat(resultHandle2Data.surcharge) * 0.51
                  : parseFloat(resultHandle2Data.surcharge) > 0 &&
                    parseFloat(resultHandle2Data.total_hours_count) > 0
                  ? Math.ceil(
                      (parseFloat(resultHandle2Data.surcharge) * 0.51) /
                        resultHandle2Data.total_hours_count
                    )
                  : "-"}
              </div>
              <div className="cell-hrs">
                <span className="dollar-sign">/hr</span>
              </div>
            </div>
          </div>
        </div>

        <div className="total-hr-line"></div>
      </div>
    )
  }

  getPriceForHourlyRate = () => {
    let isExtraHours =
      Number(this.props?.cart?.pickupLeg.result.priced_extra_hours) > 0

    let rateForExtraHours = Math.ceil(
      (Number(this.props?.cart?.pickupLeg.result.total_hours_count) *
        parseFloat(this.props?.cart?.pickupLeg.result.base_rate)) /
        this.props?.cart?.pickupLeg.result.total_hours_count
    )

    let rateForHoursOnly = Math.ceil(
      this.props?.cart?.pickupLeg.result.base_rate
    )

    return isExtraHours ? rateForExtraHours : rateForHoursOnly
  }

  getActualPriceForHourlyRate = () => {
    let getPrice = this.getPriceForHourlyRate()

    return Math.ceil(Number(getPrice * 0.2 + getPrice))
  }

  isFlatRateTrip = () => {
    return this.props?.cart?.pickupLeg.result.rate_type === "FLAT"
  }

  getTipAmount = () => {
    const isFlatRate = this.isFlatRateTrip()
    return isFlatRate
      ? Math.ceil(parseFloat(this.props?.cart?.pickupLeg.result.service_charge))
      : Math.ceil(
          parseFloat(this.props?.cart?.pickupLeg.result.service_charge) /
            this.props?.cart?.pickupLeg.result.total_hours_count
        )
  }

  getFuelFeeAmount = () => {
    const isFlatRate = this.isFlatRateTrip()
    return isFlatRate
      ? Math.ceil(
          parseFloat(this.props?.cart?.pickupLeg.result.surcharge) * 0.49
        )
      : Math.ceil(
          (parseFloat(this.props?.cart?.pickupLeg.result.surcharge) * 0.49) /
            this.props?.cart?.pickupLeg.result.total_hours_count
        )
  }

  getRegulatoryFeeAmount = () => {
    const isFlatRate = this.isFlatRateTrip()

    return isFlatRate
      ? Math.ceil(
          parseFloat(this.props?.cart?.pickupLeg.result.surcharge) * 0.51
        )
      : Math.ceil(
          (parseFloat(this.props?.cart?.pickupLeg.result.surcharge) * 0.51) /
            this.props?.cart?.pickupLeg.result.total_hours_count
        )
  }

  getBreakDownMarkUp = () => {
    // return                               {!this.mozioFlag && (
    //   <div className="price-breakdown-container">
    //     {/* <div className="promo-savings-cta">
    //                                                                     <span>
    //                                                                       20% discount applied! Your savings: $
    //                                                                       {totalDiscountAmount}
    //                                                                     </span>
    //                                                                   </div> */}

    //     <div className="price-bd-cta">
    //       <div className="price-breakdown-heading">
    //         PRICE BREAKDOWN
    //       </div>
    //       <div className="promo-savings-cta">
    //         <span>
    //           20% discount applied! Your savings: $
    //           {totalDiscountAmount}!
    //           {/*  You saved $
    //                                          {totalDiscountAmount} Book Now! */}
    //         </span>
    //       </div>
    //     </div>

    //     {this.props.bookingParamValues
    //       .is_round_trip === "1" ? (
    //       this.getRoundTripBreakDown()
    //     ) : (
    //       <>
    //         <div className="price-breakdown-row">
    //           <div className="cell-item">
    //             <div>
    //               {this.props?.cart?.pickupLeg.result
    //                 .rate_type === "FLAT"
    //                 ? "Flat Rate"
    //                 : "Hourly Rate"}
    //             </div>

    //             <div className="extra-hours-text">
    //               {/* <ToolTip
    //                                                                           text={`${this.props?.cart?.pickupLeg.result.total_hours_count} hrs of service`}
    //                                                                         /> */}
    //               {this.props?.cart?.pickupLeg.result
    //                 .rate_type != "FLAT" && (
    //                 <span>{`(${
    //                   this.props?.cart?.pickupLeg
    //                     .result.total_hours_count
    //                     ? this.props?.cart?.pickupLeg
    //                         .result.total_hours_count
    //                     : "-"
    //                 } hrs minimum)`}</span>
    //               )}
    //             </div>
    //           </div>

    //           <div className="actual-price">
    //             <span className="dollar-sign">
    //               ${" "}
    //               {this.getActualPriceForHourlyRate()}
    //             </span>
    //           </div>

    //           <div className="cell-value">
    //             <span className="dollar-sign">$</span>
    //             {this.getPriceForHourlyRate()}
    //           </div>
    //           <div className="cell-hrs">
    //             <span className="dollar-sign">
    //               /hr
    //             </span>
    //           </div>
    //         </div>

    //         <div className="price-breakdown-row">
    //           <div className="cell-item">Tip</div>
    //           <div className="actual-price"></div>
    //           <div className="cell-value">
    //             <span className="dollar-sign">$</span>
    //             {this.getTipAmount()}
    //           </div>
    //           <div className="cell-hrs">
    //             <span className="dollar-sign">
    //               /hr
    //             </span>
    //           </div>
    //         </div>

    //         <div className="price-breakdown-row">
    //           <div className="cell-item">
    //             Fuel Fee
    //           </div>
    //           <div className="actual-price"></div>
    //           <div className="cell-value">
    //             <span className="dollar-sign">$</span>
    //             {this.getFuelFeeAmount()}
    //           </div>
    //           <div className="cell-hrs">
    //             <span className="dollar-sign">
    //               /hr
    //             </span>
    //           </div>
    //         </div>

    //         <div className="price-breakdown-row">
    //           <div className="cell-item">
    //             Regulatory Fee
    //           </div>
    //           <div className="actual-price"></div>
    //           <div className="cell-value">
    //             <span className="dollar-sign">$</span>
    //             {this.getRegulatoryFeeAmount()}
    //           </div>
    //           <div className="cell-hrs">
    //             <span className="dollar-sign">
    //               /hr
    //             </span>
    //           </div>
    //         </div>

    //         <div className="total-hr-line"></div>
    //       </>
    //     )}
    //   </div>
    // )}
  }

  render() {
    const pickup = this.props.cart.pickupLeg
    const dropoff = this.props.cart.dropoffLeg
    const roundTrip = !!this.props.cart.dropoffLeg

    console.log("roundTrip", roundTrip)

    const {
      pax_first_name,
      pax_last_name,
      pax_phone_num,
      pax_email,
      payment_pan,
      payment_expiration_date,
      payment_security_code,
      payment_street1,
      payment_city,
      payment_state,
      payment_postal_code,
      trip_assurance,
      acceptTerms,
      errors,
    } = this.state

    const getGrandTotalPrice =
      this.props.bookingParamValues.is_round_trip == "1"
        ? parseFloat(
            parseFloat(this.props.cart.resultHandle1Data.grand_total) +
              parseFloat(this.props.cart.resultHandle2Data?.grand_total)
          )
        : Math.ceil(parseFloat(pickup.result?.grand_total))

    const tripAssuranceAmount = (getGrandTotalPrice * 0.1).toFixed(2)

    const isTripAssurance = this.state.trip_assurance
      ? Math.ceil(getGrandTotalPrice + getGrandTotalPrice * 0.1)
      : getGrandTotalPrice

    const totalFinalPrice =
      this.state.promoCode?.amount !== 0
        ? isTripAssurance - formatMoney(this.state.promoCode.amount)
        : isTripAssurance

    const totalDiscountAmount = Math.ceil(totalFinalPrice * 0.2)

    if (!pickup.result) {
      return <Redirect to={routePaths.root} />
    } else if (this.state.token) {
      return <>logged in {document.cookie}</>
    } else {
      const brand = this.props.label
      let {
        operator_real_charge,
        tax,
        operator_surcharges,
        limos_gas_fee,
        limos_license_fee,
      } = pickup.result
      tax = parseFloat(tax)
      if (roundTrip) {
        operator_real_charge += dropoff?.result?.operator_real_charge
        tax += parseFloat(dropoff?.result?.tax)
        operator_surcharges += dropoff?.result?.operator_surcharges
        limos_gas_fee += dropoff?.result?.limos_gas_fee
        limos_license_fee += dropoff?.result?.limos_license_fee
      }
      const paymentRequest = this.state.paymentRequest
      const canMakeBrowserPayment = this.state.canMakeBrowserPayment
      let browserPaymentElement, fallbackPaymentElement

      if (this.state.paymentSucceeded) {
        return (
          <Redirect
            to={{
              pathname: routePaths.confirmation,
              details: {
                paymentStatus: this.state.paymentSucceeded,
              },
            }}
          />
        )
      } else if (this.state.fetchingClientSecret) {
        browserPaymentElement = (
          <Spinner className={"spinner"} animation="border" size={"sm"} />
        )
      } else if (canMakeBrowserPayment && !features.disableBrowserPayment) {
        browserPaymentElement = (
          <>
            <h2 style={{ color: "white" }}>Quick Pay</h2>
            <div>
              {this.state.paymentError ? (
                <span className="error-label">{this.state.paymentError}</span>
              ) : (
                <></>
              )}
            </div>
            <PaymentRequestButtonElement
              options={{
                paymentRequest,
                style: {
                  paymentRequestButton: { type: "book", theme: "dark" },
                },
              }}
            />
          </>
        )
      }

      if (!paymentRequest || !this.state.reservationTerms) {
        browserPaymentElement = <></>
        fallbackPaymentElement = <></>
      }
      const showFlight =
        (this.props.pickupPlace &&
          this.props.pickupPlace.isAirport &&
          !this.state.flightLinked) ||
        (roundTrip &&
          this.props.dropoffPlace &&
          this.props.dropoffPlace?.isAirport &&
          !this.state.flightLinked) || (this.props.dropoffPlace && this.props.dropoffPlace.isAirport &&  !this.state.flightLinked);
    

      return (
        <div className="main-container" style={{ marginTop: 56 }}>
          {/* {this.state.showCountdown && (
            <CountdownTimer
              text="Reserve your ride before this offer expires!"
              onCountdownEnd={this.onCountdownEnd}
            />
          )} */}
          <div ref={el => (this.instance = el)}></div>

          {/* <Container className="checkout-page-title-container" fuild>
          <Row lg={12}>
            <Col className="checkoutPageTitle">
              <h1>
                <FontAwesomeIcon icon={"money-bill-wave"} />
                 Purchase your rides
              </h1>
            </Col>
          </Row>
          </Container> */}

          <div className="checkout-page-container">
            <div className="flight-carrier-right-container">
              {/* show flight and form card starts here */}
              {showFlight ? (
                <>
                  <Row lg={12} className="flight-carrier-details">
                    <Col
                      className="checkoutPageTitle"

                      // xs={12}
                      // md={{ span: 8, offset: 2 }}
                      // lg={{ span: 6, offset: 3 }}
                    >
                      <form onSubmit={this.linkFlight} className="form-jumbo">
                        <div className="rbt top-text">
                          <FontAwesomeIcon icon={"plane-arrival"} /> Enter your
                          flight details to ensure a flawless pickup
                        </div>
                        <p>
                          NOTE: If your flight number is HAL1933, you would
                          select &nbsp;
                          <strong>Hawai'ian Airlines for the Carrier</strong>,
                          and &nbsp;
                          <strong>1933 for the Flight Number</strong>
                        </p>
                        <div class="add-form-border form-field service-type-dropdown-container">
                          <div
                            class="passengers-count-field fullWidth"
                            id="carrier-filter"
                          >
                            <div class="new-form-label">
                              <label class="form-label"> Carrier * </label>
                            </div>
                            <Typeahead
                              id={"carrier-typeahead"}
                              className="carrier-input service-type data-hj-whitelist form-control down-arrow"
                              placeholder={"Carrier"}
                              onChange={selectedItem => {
                                this.setState({ carrier: selectedItem[0] })
                                this.setState({ flightCarrier: true })
                                this.checkInputs()
                              }}
                              labelKey={"name"}
                              options={this.state.airlines}
                              inputProps={{
                                "data-test": "carrier-typeahead",
                              }}
                              onBlur={e => {
                                this.checkInputs()
                              }}
                            />
                          </div>
                        </div>
                        <div class="add-form-border form-field passenger-duration-container">
                          <div class="passengers-count-field fullWidth">
                            <div class="new-form-label">
                              <label class="form-label"> Flight Number* </label>
                            </div>
                            <div class="passenger-input-field">
                              <input
                                type="text"
                                placeholder="Flight Number"
                                required
                                id="flightNumber"
                                name="flight-number"
                                onChange={e => {
                                  this.setFlight(e)
                                  this.checkInputs()
                                }}
                                className="data-hj-whitelist form-control"
                                onBlur={e => {
                                  this.checkInputs()
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="rbt">
                          <button
                            type="submit"
                            className="btn-rounded btn btn-primary btn-block form-button"
                            data-test="link-flight"
                            disabled={!this.state.flightLinkLoading}
                          >
                            {this.state.flightLinkLoader ? (
                              <>
                                <Spinner
                                  className={"spinner"}
                                  animation="border"
                                  size={"sm"}
                                />
                                Linking flight
                              </>
                            ) : (
                              <>Link Flight Data</>
                            )}
                          </button>
                          {this.state.flightLinkingError ? (
                            <p style={{ color: "red", marginTop: "1rem" }}>
                              {this.state.flightLinkingError}
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </form>
                    </Col>
                  </Row>
                  <Row lg={12}>
                    <Col
                      // xs={12}
                      // md={{ span: 8, offset: 2 }}
                      // lg={{ span: 6, offset: 3 }}
                      // lg={12}
                      style={{ paddingTop: 16 }}
                    >
                      <p style={{ textAlign: "center", fontFamily: "SFCompactDisplay-Medium" }}>
                          Almost there, just enter your flight details to
                          continue booking your ride.
                      </p>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row lg={12} className="booking-conditions-container">
                    <Col className={"termsAndCheckoutButton"}>
                      <div className="assistance-text-container">
                        <a
                          href="tel:8444273445"
                          className="assistance-text-container__link"
                        >
                          <img
                            className="small-image"
                            src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Limos-help-center.png"
                            alt="lady-img"
                          ></img>
                          <div className="text-container">
                            <span className="line1">
                              Need some help? Call us @ 844.427.3445
                            </span>
                            <span className="line2">
                              {`Reservation #${
                                this.props.search_uuid_number
                                  ? this.props.search_uuid_number
                                  : ""
                              }`}
                            </span>
                          </div>
                        </a>
                      </div>

                      <div className="booking-conditions-hr-line"></div>
                      {/*
                  <BookingTerms
                    showModal={this.state.showModal}
                    toggleShow={this.toggleModalShow}
                  /> */}
                      {/* <BookingConditions
                        resultHandle={pickup.result.result_handle}
                        setTermsChecked={this.setTermsChecked}
                        checkedValue={this.state.termsChecked}
                        setReservationTerms={this.setReservationTerms}
                        reservationTerms={this.state.reservationTerms}
                        searchResult={this.state.searchResult}
                        toggleTermsModal={this.toggleModalShow}
                        toggleConditionsModal={this.toggleConditionsModalShow}
                        assuranceSelected={this.state.extras.insurance.selected}
                        hooks={{
                          unused_with_trip_assurance: () =>
                            this.onExtraChange("insurance", true),
                          unused_with_out_trip_assurance: () =>
                            this.onExtraChange("insurance", false),
                        }}
                        showModal={this.state.showConditionsModal}
                        toggleShow={this.toggleConditionsModalShow}
                      /> */}
                      <div className="checkout-wrapper">
                        <div className="checkout-wrapper__title_countdown_container">
                          <div className="checkout-wrapper-title-container">
                            <h1 className="booking-conditions-title">
                              Confirm Now to Get 20% Off
                            </h1>
                          </div>
                          <div className="checkout-wrapper-countdown-container">
                            <CountdownTimer minutes={10} seconds={0} />
                          </div>
                        </div>
                        <div className="fallback-payment-container">
                          {this.props.bookingParamValues.is_round_trip ? (
                            <h3 className="round-trip-heading">Round Trip</h3>
                          ) : null}
                          {/* <div className="credit-card-heading-container">
          <h2>Credit Card Details</h2>
          <p>
            <FontAwesomeIcon icon={"lock"} /> Your information will be processed securely.
          </p>
          </div> */}

                          <div className="checkout-form">
                            <form onSubmit={this.handleSubmit}>
                              <div className="flex-container">
                                <div className="form-group">
                                  <input
                                    className="form-control data-hj-whitelist"
                                    name="pax_first_name"
                                    label="First Name"
                                    type="text"
                                    value={pax_first_name}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                  />
                                  <label htmlFor="pax_first_name">
                                    First Name
                                  </label>

                                  {errors.pax_first_name && (
                                    <div className="text-danger">
                                      {errors.pax_first_name}
                                    </div>
                                  )}
                                </div>

                                <div className="form-group">
                                  <input
                                    className="form-control data-hj-whitelist"
                                    name="pax_last_name"
                                    type="text"
                                    value={pax_last_name}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                  />
                                  <label htmlFor="pax_last_name">
                                    Last Name
                                  </label>
                                  {errors.pax_first_name && (
                                    <div className="text-danger">
                                      {errors.pax_last_name}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="flex-container">
                                <div className="form-group">
                                  <input
                                    className="form-control data-hj-whitelist"
                                    name="pax_phone_num"
                                    type="text"
                                    value={
                                      pax_phone_num === ""
                                        ? "+1"
                                        : pax_phone_num
                                    }
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                  />
                                  <label htmlFor="pax_phone_num">
                                    Phone Number
                                  </label>
                                  {errors.pax_phone_num && (
                                    <div className="text-danger">
                                      {errors.pax_phone_num}
                                    </div>
                                  )}
                                </div>
                                <div className="form-group">
                                  <input
                                    className="form-control data-hj-whitelist"
                                    name="pax_email"
                                    type="email"
                                    value={pax_email}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                  />
                                  <label htmlFor="pax_email">Email</label>
                                  {errors.pax_email && (
                                    <div className="text-danger">
                                      {errors.pax_email}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="flex-container">
                                <div className="form-group card-number">
                                  <input
                                    name="payment_pan"
                                    type="text"
                                    className="form-control data-hj-whitelist"
                                    value={payment_pan}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                  />
                                  <label htmlFor="payment_pan">
                                    Card Number
                                  </label>
                                  {errors.payment_pan && (
                                    <div className="text-danger">
                                      {errors.payment_pan}
                                    </div>
                                  )}
                                </div>
                                <div className="payment-expiry-cvv">
                                  <div className="form-group expiry-date">
                                    <input
                                      name="payment_expiration_date"
                                      type="text"
                                      className="form-control data-hj-whitelist"
                                      value={payment_expiration_date}
                                      onChange={this.handleChange}
                                      onBlur={this.handleBlur}
                                    />
                                    <label htmlFor="payment_expiration_date">
                                      EXP (mm/yy){" "}
                                    </label>
                                    {errors.payment_expiration_date && (
                                      <div className="text-danger">
                                        {errors.payment_expiration_date}
                                      </div>
                                    )}
                                  </div>
                                  <div className="form-group cvv">
                                    <input
                                      name="payment_security_code"
                                      type="text"
                                      className="form-control data-hj-whitelist"
                                      value={payment_security_code}
                                      onChange={this.handleChange}
                                      onBlur={this.handleBlur}
                                    />
                                    <label htmlFor="payment_security_code">
                                      CVV
                                    </label>
                                    {errors.payment_security_code && (
                                      <div className="text-danger">
                                        {errors.payment_security_code}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="flex-container">
                                <div className="form-group">
                                  <input
                                    name="payment_street1"
                                    type="text"
                                    className="form-control data-hj-whitelist"
                                    value={payment_street1}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                  />
                                  <label htmlFor="payment_street1">
                                    Billing Address
                                  </label>
                                  {errors.payment_street1 && (
                                    <div className="text-danger">
                                      {errors.payment_street1}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="flex-container">
                                <div className="form-group">
                                  <input
                                    name="payment_city"
                                    type="text"
                                    className="form-control data-hj-whitelist"
                                    value={payment_city}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                  />
                                  <label htmlFor="payment_city">
                                    Payment City
                                  </label>
                                  {errors.payment_city && (
                                    <div className="text-danger">
                                      {errors.payment_city}
                                    </div>
                                  )}
                                </div>

                                <div className="form-group">
                                  <input
                                    name="payment_state"
                                    type="text"
                                    className="form-control data-hj-whitelist"
                                    value={payment_state}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                  />
                                  <label htmlFor="payment_state">
                                    Payment State
                                  </label>
                                  {errors.payment_state && (
                                    <div className="text-danger">
                                      {errors.payment_state}
                                    </div>
                                  )}
                                </div>

                                <div className="form-group">
                                  <input
                                    name="payment_postal_code"
                                    type="text"
                                    className="form-control data-hj-whitelist"
                                    value={payment_postal_code}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                  />
                                  <label htmlFor="payment_postal_code">
                                    Zip Code
                                  </label>
                                  {errors.payment_postal_code && (
                                    <div className="text-danger">
                                      {errors.payment_postal_code}
                                    </div>
                                  )}
                                </div>
                              </div>

                              {!roundTrip && (
                                <PromoCode
                                  setPromoCode={this.setPromoCode}
                                  loading={this.state.promoCodeLoading}
                                  failedMessage={
                                    this.state.promoCodeFailedMessage
                                  }
                                  promoCode={this.state.promoCode}
                                />
                              )}

                              {!this.mozioFlag && (
                                <div className="form-group form-check">
                                  <input
                                    name="trip_assurance"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={trip_assurance}
                                    onChange={this.handleChange}
                                  />
                                  <label
                                    htmlFor="trip_assurance"
                                    className="form-check-label trip-assurance"
                                    onClick={this.openTripModal}
                                  >
                                    {`Add Trip Assurance for $${tripAssuranceAmount}`}
                                  </label>
                                </div>
                              )}

                              {this.state.showTripModal && (
                                <>
                                  <Modal
                                    show={this.state.showTripModal}
                                    onHide={this.openTripModal}
                                  >
                                    <Modal.Header closeButton>
                                      <h3>Trip Assurance Conditions</h3>
                                    </Modal.Header>
                                    <Modal.Body className="termsModal">
                                      <div className="bookingTermsFrame">
                                        <div className="bookingTermsContainer booking-terms">
                                          <div className="">
                                            <h5>With Trip Assurance</h5>
                                            <p>
                                              Receive a full refund (minus trip
                                              assurance cost) when you cancel
                                              your ride, for any reason, 7 days
                                              or more prior to your service pick
                                              up time. Cancellations made within
                                              7 days of the date of your ride
                                              are not eligible for a refund.
                                              Cancellations due to flight
                                              cancellations are allowed with
                                              appropriate documentation.
                                            </p>
                                          </div>

                                          <div className="">
                                            <h5>Without Trip Assurance</h5>
                                            <p>
                                              All ride payments are
                                              non-refundable, but transferable.
                                              Cancellations will receive a ride
                                              credit. The total ride credit can
                                              be used toward a future ride with
                                              Limos.com within 6 months from
                                              booking date if the ride is
                                              cancelled. All sales are
                                              final.Cancellations due to flight
                                              cancellations are allowed with
                                              appropriate documentation.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Modal.Body>
                                  </Modal>
                                </>
                              )}

                              <div className="form-group form-check">
                                <input
                                  name="acceptTerms"
                                  type="checkbox"
                                  checked={acceptTerms}
                                  className="form-check-input"
                                  onChange={this.handleChange}
                                  onBlur={this.handleBlur}
                                />
                                <label
                                  htmlFor="acceptTerms"
                                  className="form-check-label"
                                  onClick={this.openModal}
                                >
                                  I agree to &nbsp;
                                  <label className="terms-condition">
                                    Terms and Condition & Cancellation Policy
                                  </label>
                                </label>
                                {errors.acceptTerms && (
                                  <div className="text-danger">
                                    {errors.acceptTerms}
                                  </div>
                                )}
                              </div>
                              {this.state.showConditionsModal && (
                                <>
                                  <BookingConditions
                                    resultHandle={pickup.result.result_handle}
                                    setTermsChecked={this.setTermsChecked}
                                    checkedValue={this.state.termsChecked}
                                    setReservationTerms={
                                      this.setReservationTerms
                                    }
                                    reservationTerms={
                                      this.state.reservationTerms
                                    }
                                    searchResult={this.state.searchResult}
                                    toggleTermsModal={this.toggleModalShow}
                                    toggleConditionsModal={
                                      this.toggleConditionsModalShow
                                    }
                                    assuranceSelected={
                                      this.state.extras.insurance.selected
                                    }
                                    hooks={{
                                      unused_with_trip_assurance: () =>
                                        this.onExtraChange("insurance", true),
                                      unused_with_out_trip_assurance: () =>
                                        this.onExtraChange("insurance", false),
                                    }}
                                    showModal={this.state.showConditionsModal}
                                    toggleShow={this.toggleConditionsModalShow}
                                  />
                                </>
                              )}
                              <div className="form-group checkout-btn-container">
                                {!this.state.isFormSubmitted ? (
                                  <button
                                    type="submit"
                                    className="checkout-button btn-primary btn-block"
                                  >
                                    <span className="checkout-button-label">{` Confirm and Pay $${Math.round(
                                      Number(totalFinalPrice)
                                    )}`}</span>
                                    <span className="total-price-label">
                                      {"(Total price includes fees and tips)"}
                                    </span>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    disabled
                                    className="loader-button btn btn-primary form-submit-button"
                                  >
                                    <span className="form-loader"></span>
                                  </button>
                                )}
                                <div className="pay-securely-container">
                                  <img
                                    src="https://assets.s3.amazonaws.com/images/front-end/new-landing/PNGs/Secure.png"
                                    alt="secure-icon"
                                    height={"21"}
                                    width={"21"}
                                  />
                                  <p className="securely-text">
                                    Pay securely with MES
                                  </p>
                                </div>

                                <div className="payment-images-container">
                                  <ul className="payment-images">
                                    <li
                                      className="payment-icon visa"
                                      style={{
                                        width: "50px",
                                      }}
                                    >
                                      <img
                                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Bitmap.png"
                                        alt="visa-image"
                                        height="100%"
                                        width="100%"
                                        style={{ objectFit: "contain" }}
                                      />
                                    </li>

                                    <li
                                      className="payment-icon mastercard"
                                      style={{
                                        width: "50px",
                                      }}
                                    >
                                      <img
                                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Group+3.png"
                                        alt="mastercard-image"
                                        height="100%"
                                        width="100%"
                                        style={{ objectFit: "contain" }}
                                      />
                                    </li>

                                    <li
                                      className="payment-icon amex"
                                      style={{
                                        width: "50px",
                                      }}
                                    >
                                      <img
                                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Bitmap+2.png"
                                        alt="amex-image"
                                        height="100%"
                                        width="100%"
                                        style={{ objectFit: "contain" }}
                                      />
                                    </li>
                                    <li
                                      className="payment-icon discover"
                                      style={{
                                        width: "50px",
                                      }}
                                    >
                                      <img
                                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Discover-limos.jpg"
                                        alt="amex-image"
                                        height="100%"
                                        width="100%"
                                        style={{ objectFit: "contain" }}
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </form>
                          </div>

                          {/* form integration ends here */}
                          {this.state.paymentNetworkIsuue && (
                            <div className="network-error-message">
                              <p>
                                Uh oh, something went wrong, please try again
                              </p>
                              <ul>
                                {this.state.checkoutErrors.map(function (
                                  item,
                                  i
                                ) {
                                  return <li key={i}>{item.error.message}</li>
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              {/* show flight and form card ends here */}
              {/* {<Row>
            <Col
              xs={12}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
            >
              <PromoCode
                setPromoCode={this.setPromoCode}
                loading={this.state.promoCodeLoading}
                failedMessage={this.state.promoCodeFailedMessage}
                promoCode={this.state.promoCode}
              />
            </Col>
          </Row> } */}
              {/* <Row>
            <Col
              xs={12}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              style={{ marginTop: 16 }}
            >
              <Extras
                extras={Object.values(this.state.extras)}
                onChange={this.onExtraChange}
                key={this.extrasKey}
              />
            </Col>
          </Row> */}

              <Row lg={12}>
                <Col
                // xs={12}
                // md={{ span: 8, offset: 2 }}
                // lg={{ span: 6, offset: 3 }}
                >
                  {/* <Pricing
                items={[
                  this.props.isHourly
                    ? {
                        displayName: "Ride Cost - all inclusive",
                        amount:
                          parseFloat(pickup.result.grand_total) * 100 -
                          parseFloat(pickup.result.service_charge) * 100,
                        unitAmount:
                          (parseFloat(pickup.result.grand_total) * 100 -
                            parseFloat(pickup.result.service_charge) * 100) /
                          pickup.result.total_hours_count,
                        unitShort: "hr",
                        unit: "hour",
                        quantity: pickup.result.total_hours_count,
                        order: 0,
                      }
                    : {
                        displayName: "Ride Cost - all inclusive",
                        amount:
                          operator_real_charge +
                          operator_surcharges +
                          limos_gas_fee +
                          limos_license_fee,
                        order: 0,
                      },
                  ...Object.values(this.state.extras).filter(
                    (extra) => extra.selected
                  ),
                  {
                    displayName: "Tax",
                    amount: tax * 100,
                    hideIfZero: true,
                    order: 1000,
                  },
                  ...(this.state.promoCode.amount !== 0
                    ? [
                        {
                          displayName: "Promo Code",
                          amount: 0 - this.state.promoCode.amount,
                          order: 9001,
                        },
                      ]
                    : []),
                ]}
                loading={this.state.fetchingClientSecret}
                overrideTotal={this.state.apiGrandTotal}
              /> */}

                  {/* <p
                style={{
                  fontWeight: "bold",
                  margin: "16px auto 0px auto",
                  textAlign: "left",
                }}
              >
                Note: Extra Passengers can be added to this booking after
                checkout!
              </p> */}
                </Col>
              </Row>
            </div>

            <div className="checkout-page-left-container">
              <Row lg={12} className="search-results-checkout-container">
                <Col
                // xs={12}
                // md={{ span: 8, offset: 2 }}
                // lg={{ span: 6, offset: 3 }}
                // lg={12}
                >
                  {!dropoff ? (
                    <>
                      <SearchResultCheckout
                        displayName={
                          pickup.result.vehicle_color +
                          " " +
                          pickup.result.vehicle_make +
                          " " +
                          pickup.result.vehicle_model
                        }
                        images={[
                          pickup.result.vehicle_url,
                          ...pickup.result.vehicle_images.urls.split(","),
                        ]}
                        starRating={pickup.result.average_review}
                        operatorId={pickup.result.operator_handle}
                        reviewsLink={pickup.result.reviews}
                        displayPrice={
                          this.props.bookingParamValues.is_round_trip == "1"
                            ? this.props.cart.resultHandle1Data.grand_total +
                              this.props.cart.resultHandle2Data.grand_total
                            : pickup.result.grand_total -
                              this.state.promoCode.amount
                        }
                        passengerCapacity={pickup.result.vehicle_capacity}
                        luggageCapacity={pickup.result.luggage_capacity}
                        resultHandle={pickup.result.result_handle}
                        showDate={true}
                        amenities={pickup.result.vehicle_features}
                        displayContextIsCheckout={true}
                        pickupPlace={this.props.pickupPlace}
                        dropoffPlace={this.props.dropoffPlace}
                        isHourly={this.props.isHourly}
                        resultHandle1={this.state.resultHandle1}
                        resultHandle2={this.state.resultHandle2}
                        resultHandler1Data={this.state.resultHandler1Data}
                        resultHandler2Data={this.state.resultHandler2Data}
                        completeDate={this.props.completeDate}
                        searchResult={this.props.cart.pickupLeg}
                        showPriceLabel={true}
                        roundTripPrice={
                          this.props.cart.resultHandle1Data &&
                          this.props.cart.resultHandle2Data
                            ? parseFloat(
                                this.props.cart.resultHandle1Data.grand_total
                              ) +
                              parseFloat(
                                this.props.cart.resultHandle2Data.grand_total
                              )
                            : null
                        }
                        bookingParamValues={this.props.bookingParamValues}
                      />
                    </>
                  ) : (
                    <>
                      <SearchResultCheckout
                        displayName={
                          pickup.result.vehicle_color +
                          " " +
                          pickup.result.vehicle_make +
                          " " +
                          pickup.result.vehicle_model +
                          " & " +
                          dropoff?.result?.vehicle_color +
                          " " +
                          dropoff?.result?.vehicle_make +
                          " " +
                          dropoff?.result?.vehicle_model
                        }
                        images={[
                          pickup.result.vehicle_url,
                          dropoff?.result?.vehicle_url,
                        ]}
                        hideDetails={true}
                        grandTotal={
                          this.props.bookingParamValues.is_round_trip == "1"
                            ? parseFloat(
                                this.props.cart.resultHandle1Data.grand_total +
                                  this.props.cart.resultHandle2Data.grand_total
                              ) * 100
                            : (parseFloat(pickup.result.grand_total) -
                                this.state.promoCode.amount) *
                              100
                        }
                        resultHandle={
                          pickup.result.result_handle +
                          " & " +
                          pickup.result.result_handle
                        }
                        resultHandle1={this.state.resultHandle1}
                        resultHandle2={this.state.resultHandle2}
                        resultHandler1Data={
                          this.props.cart.resultHandle1Data
                            ? this.props.cart.resultHandle1Data
                            : []
                        }
                        resultHandler2Data={
                          this.props.cart.resultHandle2Data
                            ? this.props.cart.resultHandle2Data
                            : []
                        }
                        showDate={true}
                        roundTripPrice={
                          this.props.cart.resultHandle1Data &&
                          this.props.cart.resultHandle2Data
                            ? parseFloat(
                                this.props.cart.resultHandle1Data.grand_total
                              ) +
                              parseFloat(
                                this.props.cart.resultHandle2Data.grand_total
                              )
                            : null
                        }
                        displayContextIsCheckout={true}
                        pickupPlace={this.props.pickupPlace}
                        dropoffPlace={this.props.dropoffPlace}
                        isHourly={this.props.isHourly}
                        completeDate={this.props.completeDate}
                        returnDate={this.props.returnDate}
                        searchResult={this.props.cart.pickupLeg}
                        showPriceLabel={true}
                        bookingParamValues={this.props.bookingParamValues}
                      />
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )
    }
  }
}

const CheckoutPageWrapper = props => {
  const [userId] = useLocalStorage("LIMOS-userId")
  return <CheckoutPage {...props} userId={userId} />
}

export default withWhiteLabelContext(CheckoutPageWrapper)
