import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { withWhiteLabelContext } from "react-whitelabel";

import { routePaths } from "../../routes/routes";

class SiteTerms extends Component {
  render() {
    return (
      <Row style={{ marginTop: 124 }}>
        <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          <h2>Terms of Service Agreement</h2>

          <p>
            This Terms of Service Agreement (the "Agreement") is a legally
            binding agreement that governs your relationship with On Demand
            iCARS Inc. (Limos.com), d/b/a ForGround.com (the "Company"), and the
            use of limos.com, forground.com, and related websites (such sites
            being collectively referred to herein as the "Sites" and the
            services available on such Sites being referred to herein as the
            "Services"). If you do not agree to this Agreement, please do not
            use the Services. In addition to the terms and conditions in this
            Agreement, users may be asked to agree to additional terms when
            using different parts of our Sites. The terms and conditions of this
            Agreement, along with such additional terms and conditions, will be
            referred to as the "Terms." The Company reserves the right, at its
            discretion, to change, modify, add, or remove portions of any of the
            Terms at any time. Please check the Terms periodically for changes.
            Your use of the Services after such Terms have been posted will mean
            that you have read, understand, and agree to be bound by the Terms.
          </p>

          <p>
            In this Agreement, the Company will refer to you and all of your
            affiliates, agents, employees, representatives and subcontractors as
            "you" and to the Company and its employees and agents as "we", "us",
            "Company" or "Limos.com." References herein to Sites include any and
            all Sites now, or hereafter, owned or operated by us. These Terms
            were last updated on 03/08/2018.
          </p>

          <ol>
            <li>
              <p>DEFINED TERMS</p>
              <ol type="a">
                <li>“Call Center” means our 24/7 customer support center.</li>
                <li>
                  “User” means a person who visits the Site to search for and
                  potentially book ground transportation.
                </li>
                <li>
                  “Affiliate” means a limousine company that has agreed to join
                  the Limos.com marketplace of ground transportation providers.
                  Affiliates display their available vehicle inventory based on
                  the search criteria entered by a user on the Site.
                </li>
                <li>
                  “Partner” means a company who agrees to promote Limos.com
                  through external marketing channels.
                </li>
                <li>
                  “Third Party Software” means software created or hosted by a
                  company outside of Limos.com.
                </li>
              </ol>
            </li>

            <li>
              <p>
                INTRODUCTION. Limos.com provides an online marketplace that
                connects you with limousine companies offering car services in
                your requested area. You can either search for and book car
                services through the Site or Call Center, or you may book
                directly with a limousine operator. Upon visiting the Site, you
                have the option to search and book a car service with or without
                completing a customer registration.
              </p>
            </li>

            <li>
              <p>
                SERVICES. We are committed to making your total experience a
                positive one. Upon registering, you may choose your own login
                email and optional password, or we will assign you a password to
                access our services through the Sites. Upon entering your
                information and the request for which you would like limousine
                service, we will provide you with a list of limousine companies
                in your area, if any, who may be interested in fulfilling your
                service needs; however, we do not guarantee that we will be able
                to match your service needs with a limousine company or that
                there are limousine companies in your area that either are
                capable or willing to complete your service needs. We will
                either fulfill the booking directly or connect you with a
                limousine provider with whom you may book directly. Although we
                take certain steps to examine the credentials of our listed
                limousine companies, we make no guarantees or representations
                regarding the skills or representations of such limousine
                companies or the quality of the service that each company may
                perform for you if you elect to retain their services. We
                further do not endorse or recommend the services of any
                particular limousine company.
              </p>
            </li>

            <li>
              <p>SERVICES DISPUTES AND RESOLUTIONS.</p>
              <ol type="a">
                <li>
                  <u>Booking directly with a limousine company</u>. If you enter
                  into a contract or otherwise reach agreement with a limousine
                  company, we will not guarantee or warrant their performance,
                  outcome or quality of the services performed. The limousine
                  companies are not employees or agents of ours, nor are we an
                  agent of the limousine companies. We do not perform, and will
                  not be responsible for, any of the services requested by you
                  in your service request. The applicable federal, state,
                  provincial and/or local laws shall govern your rights. Should
                  you have a dispute or claim for damages with any limousine
                  company, you must address such dispute with the limousine
                  company directly. YOU HEREBY AGREE TO RELEASE COMPANY (AND OUR
                  OFFICERS, DIRECTORS, AFFILIATES, EMPLOYEES AND AGENTS) AND ANY
                  OTHER PERSON, FIRM, OR ENTITY (INCLUDING OUR BUSINESS PARTNERS
                  SUCH AS A PRODUCT MANUFACTURER OR SUPPLIER WHO MAY EMPLOY THE
                  COMPANY'S SERVICES) FROM ANY DAMAGES OR CLAIMS (INCLUDING
                  CONSEQUENTIAL AND INCIDENTAL DAMAGES) OF EVERY KIND OR NATURE,
                  SUSPECTED AND UNSUSPECTED, KNOWN AND UNKNOWN, AND DISCLOSED OR
                  UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH
                  DISPUTES AND YOUR DEALINGS WITH LIMOUSINE COMPANIES.
                </li>
                <li>
                  <u>Booking through the Site or Call Center</u>. While we have
                  no legal obligation, if you book directly through the Site, we
                  have developed a resolution process to try and assist you in
                  resolving any problems that may arise during the course of
                  limousine service(s) you booked through us. You must
                  participate and use good faith efforts to resolve problems
                  through the Company resolution process. You must also provide
                  accurate and complete contact information in order for us to
                  assist you, post booking. Failure to provide accurate
                  information could mean we are unable to assist you in
                  resolving a dispute, post booking.. Such limited assistance
                  provided by us will in no way void or nullify the release and
                  indemnification described in the Terms.
                </li>
              </ol>
            </li>

            <li>
              <p>
                INFORMATION YOU PROVIDE TO US. Upon using the Services, you will
                be prompted to disclose certain information about yourself and
                your service requirements on our Site. Some of this information
                may be sent to limousine companies who will use this information
                to respond to you or to other persons or entities in connection
                with our business. By providing this information to us, or by
                submitting a service quote request, you are requesting, and you
                expressly consent to being contacted by us and by our limousine
                companies and providers via phone, email or other reasonable
                means, at any of your contact numbers or addresses, even if you
                are listed on any federal, state, provincial or other applicable
                “Do Not Call” list, in order that we may provide the Services
                set forth on our Site, to service your account, to reasonably
                address matters pertaining to your account, or for other
                purposes reasonably related to your service request and our
                business, including marketing related emails, provided you agree
                to receive such emails. For complete details on our use of your
                information, please see our{" "}
                <Link to={routePaths.privacyPolicy}>privacy statement</Link>.
                You agree that by completing a service request, you are entering
                into a business relationship with Company and its partners and,
                therefore, agree to be contacted by Company and/or its partners.
                You promise that all information you provide will be accurate,
                current and truthful to the best of your knowledge. If you
                provide any information that is untrue, not current or
                incomplete, or Company has reasonable grounds to suspect that
                such information is untrue, inaccurate, not current or
                incomplete, Company has the right to refuse any current or
                future use of the Services (or any portion thereof) by you. You
                are responsible for any use of Company services by persons to
                whom you intentionally or negligently allow access to your
                password.
              </p>
            </li>

            <li>
              <p>
                YOUR USE OF OUR SERVICES. You acknowledge and agree that you are
                at lest eighteen (18) years of age and that your use of Company
                services is for your personal use and not for any commercial or
                advertising purposes. You agree that all of the content and
                information posted on the Sites, including but not limited to
                our limousine company profiles/web stores and ratings &amp;
                reviews (including any ratings and reviews or other content
                posted by you), is the sole and exclusive property of Company,
                and that you have no right to reproduce, post, publish, or
                otherwise use such information other than for your personal use
                relating to your service request. You acknowledge that a
                violation of the foregoing could result in significant damages,
                and you agree that you are liable to Company for any such
                damages, and will indemnify Company in the event of any third
                party claims against Company based on or arising from your
                violation of the foregoing. Company reserves the right to revoke
                your access to the Sites and Services at any time. All
                information about limousine.com companies is confidential and
                for your personal use only. If it is determined or suspected by
                Company in its sole discretion that you are misusing or
                attempting to misuse or circumvent the Services, or are using or
                attempting to use the Services for any inappropriate,
                non-personal, or commercial purposes, including but not limited
                to activities such as hacking, infiltrating, fraud, advertising,
                jamming or spamming, Company reserves the right, in its sole
                discretion, to immediately terminate your access without notice
                and to initiate, without notice, appropriate legal actions or
                proceedings to seek appropriate remedies and/or damages,
                including but not limited to lost revenue, repairs, legal fees,
                costs and expenses, and to seek injunctions or other equitable
                remedies. TO KNOWINGLY INPUT FALSE INFORMATION, INCLUDING BUT
                NOT LIMITED TO NAME, PHONE NUMBER, ADDRESS OR E-MAIL ADDRESS IS
                A SERIOUS AND FRAUDULENT MATTER THAT COULD RESULT IN SIGNIFICANT
                COSTS AND DAMAGES INCLUDING INVASION OF PRIVACY RIGHTS, TO
                COMPANY AND ITS LIMOUSINE COMPANY PARTNERS, AND TO CONSUMERS, AS
                WELL AS THE LOSS OF TIME, EFFORT AND EXPENSE RESPONDING TO AND
                PURSUING SUCH FALSE INFORMATION AND REQUEST, AND FURTHER, COULD
                RESULT IN REGULATORY FINES AND PENALTIES. ACCORDINGLY, IF YOU
                KNOWINGLY INPUT FALSE INFORMATION IN A SERVICE REQUEST,
                INCLUDING BUT NOT LIMITED TO SOME ONE ELSE'S NAME, E-MAIL
                ADDRESS, PHYSICAL ADDRESS OR PHONE NUMBER OR A RANDOM OR MADE UP
                NAME, ADDRESS, E-MAIL OR PHONE NUMBER, YOU AGREE TO FULLY
                INDEMNIFY AND BE LIABLE TO COMPANY AND EACH COMPANY SERVICE
                PROVIDER WHO ACCEPTS SUCH SERVICE REQUESTS, FOR THE ACTUAL
                DAMAGES, DIRECT, PUNITIVE AND CONSEQUENTIAL, AND ANY REGULATORY
                OR JUDICIAL FINES OR PENALTIES THAT MAY ARISE FROM SUCH
                INTENTIONAL, MISLEADING, HARMFUL AND FRAUDULENT ACTIVITY, PLUS
                REASONABLE LEGAL FEES, COST AND EXPENSES RELATING THERETO, WHICH
                EVER IS GREATER.
              </p>
            </li>

            <li>
              <p>
                NOTICE SPECIFIC TO DOCUMENTS AND INFORMATION AVAILABLE ON THE
                SITES. Permission to use documents (such as press releases,
                datasheets, content, informational items and FAQs – the
                “Documents”) from the Services is granted, provided that (1) the
                below copyright notice appears in all copies and that both the
                copyright notice and this permission notice appear, (2) use of
                such documents from the Sites is for your informational and
                non-commercial or personal use only and will not be copied or
                posted on any network computer or broadcast in any media, and
                (3) no modifications of any Documents are made. Educational
                institutions (specifically K-12, universities and state
                community colleges) may download and reproduce the Documents for
                distribution in the classroom. Distribution outside the
                classroom requires express written permission of Company. Use
                for any other purpose is expressly prohibited by law, and may
                result in severe civil and criminal penalties. Violators will be
                prosecuted to the maximum extent possible. Documents specified
                above do not include the design or layout of the Sites or any
                other Company owned, operated, licensed or controlled site.
                Elements of the Sites are protected by trade dress, trademark,
                unfair competition, and other laws and may not be copied or
                imitated in whole or in part. No logo, graphic, sound or image
                from any Sites may be copied or retransmitted unless expressly
                permitted by Company. COMPANY AND/OR ITS RESPECTIVE SUPPLIERS
                MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF
                THE INFORMATION CONTAINED IN THE DOCUMENTS AND RELATED GRAPHICS
                PUBLISHED IN CONNECTION WITH COMPANY SERVICES FOR ANY PURPOSE.
                ALL SUCH DOCUMENTS AND RELATED GRAPHICS ARE PROVIDED "AS IS"
                WITHOUT WARRANTIES OF ANY KIND. COMPANY AND/OR ITS RESPECTIVE
                SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH
                REGARD TO THIS INFORMATION, INCLUDING ALL IMPLIED WARRANTIES AND
                CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                TITLE AND NON-INFRINGEMENT. THE DOCUMENTS AND RELATED GRAPHICS
                PUBLISHED ON THE SITES COULD INCLUDE TECHNICAL INACCURACIES OR
                TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
                INFORMATION HEREIN. COMPANY AND/OR ITS RESPECTIVE SUPPLIERS MAY
                MAKE IMPROVEMENTS AND/OR CHANGES IN THE SERVICES AT ANY TIME.
              </p>
            </li>

            <li>
              <p>
                LINKS TO THIRD PARTY SITES. The links in the Sites will allow
                you to leave the Sites. The linked sites are not under the
                control of Company and Company is not responsible for the
                content or functionality of any linked site or any link
                contained in a linked site, or any changes or updates to such
                sites. Company is not responsible for webcasting or any other
                form of transmission received from any linked site. Company is
                providing these links to you only as a convenience, and the
                inclusion of any link does not imply endorsement by Company of
                the site.
              </p>
            </li>

            <li>
              <p>
                THIRD PARTY SOFTWARE. The Sites and Services may operate or
                interface with software or other technology that is licensed to
                Company from third parties (“Third Party Licensors”), which is
                not proprietary to Company, but which Company has the necessary
                rights to license to you (“Third Party Software”) as an
                component of the Services. You agree that (a) only use/access
                such third party software will be made in accordance with this
                Agreement, (b) no Third Party Licensor makes any warranties,
                conditions, undertakings or representations of any kind, either
                express or implied, to you concerning such third party software
                or the Products themselves, (c) no Third Party Licensor will
                have any obligation or liability to you as a result of this
                Agreement or your use/access of such Third Party Software, (d)
                such third party software may be licensed under license terms
                which grant you additional rights or contain additional
                restrictions in relation to such materials, beyond those set
                forth in this Agreement, and such additional license rights and
                restrictions may be described or linked to the applicable
                webpage for the Third Party Licensors.
              </p>
            </li>

            <li>
              <p>LIMOUSINE COMPANY PRESCREENING PROCEDURES AND DISCLAIMERS.</p>
              <ol type="a">
                <li>
                  Company uses the following criteria as part of its
                  registration enrollment process ("registration") for new
                  limousine companies, with the exception of Master Accounts (as
                  described below) applying for membership in our network.
                  Company confirms at the time of a limousine company’s
                  registration that a prospective new limousine company member:
                  <ol type="i">
                    <li>
                      can provide evidence of the minimum required licensing. We
                      recommend you confirm these licensing requirements with
                      the business and the applicable state and local licensing
                      authorities prior to initiating the enrollment process. In
                      some states, licenses may be required by the county or
                      local authority in which the limousine services are being
                      performed (such as picking up passengers at a specific
                      airport). We recommend you confirm these license
                      requirements before proceeding with your services. We also
                      recommend that you ask the limousine company to provide
                      you with a copy of their license.
                    </li>
                    <li>
                      can provide evidence of the appropriate, applicable
                      bonding and current levels of automobile, general
                      liability and indemnity insurance.
                    </li>
                    <li>
                      is in good standing within the state in which it maintains
                      its principal place of business (applies for business
                      types that require a Secretary of State filing).
                    </li>
                    <li>
                      Limousine company information is confirmed at the time of
                      registration. This may change, expire or be
                      revoked/suspended over time; while Company attempts to
                      maintain accurate and up-to-date information, and confirms
                      changes when notified, we perform screening solely at the
                      time the member applies for membership in the Company
                      network, and, therefore, we cannot guarantee that profile
                      and screening information is accurate or that a company’s
                      licensing or insurance is in effect at the time of your
                      service.
                    </li>
                  </ol>
                </li>
                <li>
                  It is common practice for limousine companies to farm-out
                  limousine services to other limousine companies. In the event
                  that the limousine company you selected chooses to farm out
                  your ride, Limos.com is not responsible and/or liable for any
                  3rd party claims. Therefore, when you book a ride, you HEREBY
                  AGREE TO RELEASE COMPANY (AND OUR OFFICERS, DIRECTORS,
                  AFFILIATES, EMPLOYEES AND AGENTS) AND ANY OTHER PERSON, FIRM,
                  OR ENTITY (INCLUDING OUR BUSINESS PARTNERS SUCH AS A PRODUCT
                  MANUFACTURER OR SUPPLIER WHO MAY EMPLOY THE COMPANY'S
                  SERVICES) FROM ANY DAMAGES OR CLAIMS (INCLUDING CONSEQUENTIAL
                  AND INCIDENTAL DAMAGES) OF EVERY KIND OR NATURE, SUSPECTED AND
                  UNSUSPECTED, KNOWN AND UNKNOWN, AND DISCLOSED OR UNDISCLOSED,
                  ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE ACTIONS OR
                  INACTIONS OF THE FARMED OUT RIDE LIMOUSINE SERVICE PROVIDERS.
                </li>
                <li>
                  Limousine Companies Profiles: Company allows limousine
                  companies to post profiles about themselves and their business
                  on the Sites. Company does not review or verify the
                  information or representations set forth in those profiles as
                  they are self-reported by the limousine company.
                </li>
                <li>
                  Notwithstanding Section 7 a, i-iii above, Company does not
                  make any representations or warranties that members are
                  appropriately licensed, free of significant state-level civil
                  legal judgments, or carry appropriate automobile, general
                  liability, and indemnity insurance or bonding at the time of
                  registration, during the period they are members, or
                  thereafter. As circumstances can change daily, Company advises
                  consumers to check these matters for themselves especially at
                  future dates. Company does not represent or warrant that the
                  information received from state, provincial or other
                  government agencies or from third party sources is accurate,
                  error free, or that it is up to date or updated at the time
                  that Company checks such information. Most agencies have
                  periodic update cycles or schedules and this information is
                  not updated on a real-time basis by such agencies and third
                  party sources.
                </li>
              </ol>
            </li>

            <li>
              <p>
                REGISTRATION DISCLAIMER: COMPANY EXPRESSLY DISCLAIMS ANY AND ALL
                WARRANTIES, EXPRESS OR IMPLIED RELATING TO THE REGISTRATION
                PROCESS, CRITERIA, PROCEDURES, OR INFORMATION OBTAINED OR
                PRESENTED IN THE REGISTRATION PROCESS OR DISCLOSURES INCLUDING
                BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS
                FOR A PARTICULAR USE, OR THAT THE SCREENING OR VERIFICATION
                PROCEDURES OR STANDARDS ARE SUFFICIENT OR THAT THE INFORMATION
                RECEIVED IN THESE SCREENING OR VERIFICATION PROCEDURES IS
                ACCURATE, TIMELY OR ERROR FREE.
              </p>
            </li>

            <li>
              <p>POSTING RATINGS AND REVIEWS ON THE SITES.</p>
              <ol type="a">
                <li>
                  Company reserves the right, but not the obligation, to refuse
                  to post or to remove any review if Company determines (in its
                  sole discretion) that it contains or features any of the
                  following:
                </li>
                <li>
                  Offensive, harmful and/or abusive language, including without
                  limitation: expletives, profanities, obscenities, harassment,
                  vulgarities, sexually explicit language, hate speech (e.g.,
                  racist/discriminatory speech) or references to illegal
                  activity.
                </li>
                <li>
                  Reviews that are submitted by a reviewer whose personal
                  information does not match our records as someone who
                  completed a booking with Site and the corresponding limousine
                  operator.
                </li>
                <li>
                  Reviews that do not address the services of the limousine
                  company or reviews with no qualitative value (e.g., "ride has
                  not happened yet ").
                </li>
                <li>
                  Language that violates the standards of good taste or the
                  standards of the Site.
                </li>
                <li>
                  Comments concerning a different limousine company or any
                  reviewers who are present or former drivers or employees of a
                  limousine company being reviewed.
                </li>
                <li>
                  Information not related to work requested in the service
                  request.
                </li>
                <li>Statements that are or appear to be false.</li>
                <li>Comments that disparage Company.</li>
              </ol>
            </li>

            <li>
              <p>RATINGS AND REVIEWS ARE NOT ENDORSED BY COMPANY. </p>
              <ol type="a">
                <li>
                  All ratings and reviews of a limousine company displayed to
                  you reflect the opinions of other consumers, and do not
                  reflect or represent the opinions or representations of
                  Company. Company disclaims any and all representations or
                  warranties with regard to the ratings and reviews.
                </li>
                <li>
                  Reviews do not reflect the views of Company; its parent,
                  subsidiary or affiliated companies; or its employees,
                  officers, directors, or shareholders.
                </li>
                <li>
                  Company does not assume responsibility or liability for any
                  Review or for any claims, damages, or losses resulting from
                  any use of the Site or the materials contained therein.
                </li>
              </ol>
            </li>

            <li>
              <p>
                INDEMNIFICATION. YOU AGREE TO INDEMNIFY COMPANY, AND ITS
                SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
                CO-BRANDERS, AND OTHER PARTNERS AND HOLD THEM EACH HARMLESS FROM
                ANY AND ALL CLAIMS OR DEMANDS, INCLUDING ATTORNEY'S FEES, MADE
                BY ANY THIRD PARTY DUE TO OR ARISING FROM YOUR USE OF THE
                COMPANY SERVICES OR IN CONNECTION WITH THE SITES OR YOUR
                VIOLATION OF THESE TERMS AND CONDITIONS, OR ARISING FROM YOUR
                VIOLATION OF ANY RIGHTS OF A THIRD PARTY.
              </p>
            </li>

            <li>
              <p>
                WARRANTY. You acknowledge and agree that the Services are
                provided to you on an "AS IS" basis without any warranty
                whatsoever, and your sole and exclusive remedy, and Company's
                sole obligation to you or any third party for any claim arising
                out of your use of the Services or the Sites, is that you are
                free to discontinue your use of the Services or the Sites at any
                time. EXCEPT AS EXPRESSLY SET FORTH HEREIN, COMPANY EXPRESSLY
                DISCLAIMS ANY IMPLIED WARRANTIES OF ANY KIND, INCLUDING, BUT NOT
                LIMITED TO, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
                PARTICULAR PURPOSE, AND YOU AGREE THAT COMPANY SHALL HAVE NO
                LIABILITY FOR DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                CONSEQUENTIAL (INCLUDING LOST PROFIT), EXEMPLARY OR PUNITIVE
                DAMAGES (EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES) ARISING OUT OF THIS AGREEMENT OR ANY CONSEQUENCES
                WHICH FLOW FROM IT. SOME STATES AND PROVINCES DO NOT ALLOW
                LIMITATIONS ON OR EXCLUSION OF INCIDENTAL OR CONSEQUENTIAL
                DAMAGES. IN SUCH STATES AND PROVINCES, THE ABOVE EXCLUSIONS MAY
                NOT APPLY TO YOU.
              </p>
            </li>

            <li>
              <p>GENERAL PROVISIONS.</p>
              <ol type="a">
                <li>
                  The Terms will inure to the benefit of Company's successors,
                  assigns and licensees.
                </li>
                <li>
                  If any provision of these Terms shall be deemed unlawful, void
                  or unenforceable, for any reason, by any court of competent
                  jurisdiction that provision shall be modified in order to make
                  it enforceable, while maintaining the spirit of the provision.
                  Alternatively, if modification is not possible, such provision
                  shall be stricken and shall not affect the validity and
                  enforceability of the remaining terms.
                </li>
                <li>
                  The failure of Company to exercise or enforce any right or
                  provision of the Terms shall not constitute a waiver of such
                  right or provision.
                </li>
                <li>
                  You agree that regardless of any statute or law to the
                  contrary, any claim or cause of action arising out of or
                  related to use of the Sites or Services must be filed within
                  one (1) year after such claim or cause of action arose or be
                  forever barred.
                </li>
                <li>
                  The section titles in the Terms are for convenience only and
                  have no legal or contractual effect.
                </li>
                <li>
                  The Terms are governed by the laws of the State of California
                  as such laws are applied to agreements entered into and to be
                  performed entirely in the State of California and between
                  California residents. You agree to submit to jurisdiction in
                  California and that any claim arising under these Terms will
                  be brought solely in a court in San Francisco County,
                  California.
                </li>
                <li>
                  Any notices required to be given in writing to Company or any
                  questions concerning this Agreement should be addressed to:{" "}
                  <a href="mailto:customerservice@limos.com">
                    customerservice@limos.com
                  </a>
                  .
                </li>
              </ol>
            </li>

            <li>
              <p>DMCA</p>
              <p>
                If you are a copyright owner or an agent thereof and believe
                that any submitted content or other content on our Site
                infringes upon your copyrights, you may submit a notification
                pursuant to the Digital Millennium Copyright Act ("DMCA") by
                providing our designated copyright agent with the following
                information in writing (see 17 U.S.C. 512(c)(3) for further
                detail):
              </p>
              <ol type="a">
                <li>
                  A physical or electronic signature of the copyright owner or
                  authorized agent;
                </li>
                <li>
                  Identification of the copyrighted work(s) claimed to have been
                  infringed;
                </li>
                <li>
                  Identification of the material that is alleged to be
                  infringing or is the subject of the allegedly infringing
                  activity that you desire to have removed or access to which is
                  to be disabled;
                </li>
                <li>
                  Information that is specific and sufficient to permit us to
                  locate the material, including the URL and/or a specific
                  description of where the material is located;
                </li>
                <li>
                  Information reasonably sufficient to permit the service
                  provider to contact you, such as a mailing address, telephone
                  number, or email address;
                </li>
                <li>
                  A statement that the copyright owner or its authorized agent
                  has a good faith belief that use of the material in the manner
                  complained of is not authorized by the copyright owner, its
                  agent, or the law; and
                </li>
                <li>
                  A statement that the information in the notification is
                  accurate, and made under penalty of perjury, and, if an agent
                  is providing the notification, a statement that the agent is
                  authorized to act on behalf of the owner of an exclusive right
                  that is allegedly infringed.
                </li>
              </ol>
              <p>
                You may send the above information to: On Demand iCARS Inc., 50
                Pier 50, San Francisco, CA 94158, email: legal@limos.com. For
                clarity, only DMCA notices should go to the copyright agent.
                Other feedback, comments, requests for technical support, and
                other communications should be directed to Limos.com customer
                support at customerservice@limos.com. You acknowledge that if
                you fail to comply with all of the requirements of this section,
                your DMCA notice may not be valid.
              </p>
              <p>
                Be aware that if you materially misrepresent that a product or
                activity is infringing your copyrights, you may be liable for
                any damages (including costs and attorneys’ fees). If you are
                not sure whether material available online infringes your
                copyright, we suggest that you first contact an attorney.
              </p>
              <p>COUNTER-NOTICE</p>
              <p>
                If you believe that your content that was removed (or to which
                access was disabled) is not infringing, or that you have the
                authorization from the copyright owner, the copyright owner’s
                agent, or pursuant to the law, to post and use the content, you
                may send a counter-notice to the designated copyright agent
                above with the following information:
              </p>
              <ol type="a">
                <li>Your physical or electronic signature;</li>
                <li>
                  Identification of the content that has been remove or to which
                  access has been disabled;
                </li>
                <li>
                  Information that is specific and sufficient to describe where
                  the content appeared before it was removed or disabled;
                </li>
                <li>
                  A statement that you have a good faith belief that the content
                  was removed or disabled as a result of a mistake or a
                  misidentification of the content; and
                </li>
                <li>
                  Your name, address, telephone number, and email address, a
                  statement that you consent to the jurisdiction of the federal
                  court in San Francisco, California, and a statement that you
                  will accept service of process from the person who provided
                  notification of the alleged infringement.
                </li>
              </ol>
              <p>
                If a counter-notice is received by the copyright agent,
                Limos.com may send a copy of the counter-notice to the original
                complaining party informing that person that it may replace the
                removed content or cease disabling it in 10 business days.
                Unless the copyright owner files an action seeking a court order
                against the content provider, member, or user, the removed
                content may be replaced, or access to it restored, in 10 to 14
                business days or more after receipt of the counter-notice, at
                Limos.com’s sole discretion.
              </p>
            </li>
          </ol>

          <br />
          <p>© Limos.com. All rights reserved.</p>
        </Col>
      </Row>
    );
  }
}

export default withWhiteLabelContext(SiteTerms);
