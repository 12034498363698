import React, { useState, useEffect, useContext } from "react";
import { WhiteLabelContext } from "react-whitelabel";
import "./ContestLanding.scss";
import FooterUpdated from "../FooterUpdated/FooterUpdated.jsx";
import { useForm } from "react-hook-form";
import useApi from "../../hooks/useAPI";
import { API_ENDPOINTS } from "../../constants/constants";

const Contest = (props) => {
  const { searchFormTitle, searchFormSubtitle, searchFormDescription } =
    useContext(WhiteLabelContext);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });
  const { enrollEmailForContest, loading, error, data } = useApi();
  const [errorEnroll, setErrorEnroll] = useState();
  const [successEnroll, setSuccessEnroll] = useState();
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://assets.s3.amazonaws.com/images/front-end/contestImages/TiktokScript.js";
    console.log(script.src);

    script.async = true;

    script.crossOrigin = "anonymous";

    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    setErrorEnroll(error);
    setSuccessEnroll("");
  }, [error]);

  useEffect(() => {
    setErrorEnroll("");
    setSuccessEnroll(data);
  }, [data]);

  const onSubmit = (data) => {
    enrollEmailForContest(API_ENDPOINTS.contest, "POST", data);
  };
  return (
    <>
      <div className="container-contest">
        <div className="contest-hero-banner" style={{ position: "relative" }}>
          <div className="img-container">
            <div className="banner-container">
              <img src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Prom-limos.com-contest.jpg" />
            </div>
            <div className="contest-desc">
              <div className="limos-logo-txt">LIMOS.COM</div>
              <div className="logo-desc">
                Get ready to ride in style this prom season with a FREE luxury
                limo!
              </div>
              <div className="contest-golden-ticket">
                <div className="carnival-ticket-desc">
                  <img src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Win-Free-limo.png" />
                </div>
              </div>
              <div className="steps-video-container">
                <div className="contest-steps">
                  <div className="steps-row">
                    <div className="steps-container">
                      <div className="step-number">Step</div>
                      <div className="step-circle">1</div>
                    </div>
                    <div className="step-text">
                      Enter your email{" "}
                      <u>
                        <a href="#enroll-contest-container">below</a>{" "}
                      </u>
                      and submit 📧
                    </div>
                  </div>
                  <hr />
                  <div className="steps-row">
                    <div className="steps-container">
                      <div className="step-number">Step</div>
                      <div className="step-circle">2</div>
                    </div>
                    <div className="step-text">
                      Create a short and fun video clip, see some{" "}
                      <u>
                        <a href="#">samples</a>
                      </u>{" "}
                      here. Make sure to mention going to your special prom
                      night with Limos.com 📽️
                    </div>
                    <hr />
                  </div>
                  <hr />
                  <div className="steps-row">
                    <div className="steps-container">
                      <div className="step-number">Step</div>
                      <div className="step-circle">3</div>
                    </div>
                    <div className="step-text show-lg">
                      Share this video clip on your TikTok/Snapchat/Instagram/
                      <br />
                      Social Media 👀. Tag us with the below handles and use the
                      hashtag #limos.com so all your friends can see!
                    </div>
                    <div className="step-text show-md">
                      Share this video clip on your TikTok/Snapchat/Instagram/
                      Social Media 👀. Tag us with the below handles and use the
                      hashtag #limos.com so all your friends can see!
                    </div>

                    <hr />
                  </div>
                  <hr />
                  <div className="steps-row">
                    <div className="steps-container">
                      <div className="step-number">Step</div>
                      <div className="step-circle">4</div>
                    </div>
                    <div className="step-text">
                      Post with the most qualified likes wins a free luxury limo
                      to prom! 🚀 <br /> Or you get 10% if you get at least 100
                      likes on your post. And no bots! Real and unique likes
                      only 😛
                    </div>
                    <hr />
                  </div>
                </div>
                <div className="contest-videos">
                  <video width="100%" height="100%" controls>
                    <source
                      src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Prom+with+captions_v2.MOV"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contest-videos-sm">
          <video width="100%" height="100%" controls>
            <source
              src="https://assets.s3.amazonaws.com/images/front-end/new-landing/PromNight.MP4"
              type="video/mp4"
            />
          </video>
        </div>

        <div className="contest-images-container" id="enroll-contest-container">
          <div className="contest-img-desc">
            <div className="contest-desc-container">
              <p className="contest-desc-lg">
                Luxurious interiors.
                <br /> Free sparking water.
                <br /> White glove service. <br />
                For you and your friends.
              </p>
              <p className="contest-desc-mb">
                Luxurious interiors. Free sparking water. White glove service.
                For you and your friends.
              </p>
              <div className="limos-about">
                We’ve been in transportation for over 33 years and we know how
                to make you arrive in style!
              </div>
              {/* <div className="limos-about show-md">
                We’ve been in transportation for over 33 years and we know how
                to make you arrive in style!
              </div> */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  className="contest-enroll"
                  id="enroll-contest"
                  style={{ width: "95%", margin: "auto auto" }}
                >
                  <input
                    {...register("enroll_email", {
                      required: {
                        value: true,
                        message: "Email address is required",
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Email address is required",
                      },
                    })}
                    aria-invalid={errors.enroll_email ? "true" : "false"}
                    type="text"
                    placeholder="Your Email"
                  />
                  {errors.enroll_email && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginTop: "0.3rem",
                      }}
                    >
                      {errors.enroll_email?.message}
                    </p>
                  )}

                  <button className="enroll-btn">
                    Enter chance to win Free Limo!
                  </button>

                  <div className="server-error" style={{ marginTop: "0.4rem" }}>
                    {successEnroll?.length > 0 && (
                      <p style={{ color: "green", fontSize: "10px" }}>
                        {successEnroll}
                      </p>
                    )}
                    {errorEnroll?.length > 0 && (
                      <p style={{ color: "red", fontSize: "10px" }}>
                        {errorEnroll?.message}
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="contest-images">
            <div className="contest-img-list">
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Free-limos.com-contest.jpg"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
        <div className="contest-packages">
          <span>Popular packages for Prom</span>
          <div className="contest-package-imgs">
            <div className="pack-image-conainer">
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Limo-Lux.jpg"
                height="100"
                width="100"
              />
              <div className="contest-package-desc">
                <p>Lux Package</p>
                <p className="sub-text">starting at $119</p>
              </div>
            </div>
            <div className="pack-image-conainer">
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Limo-Lux-Plus.jpg"
                height="100"
                width="100"
              />
              <div className="contest-package-desc">
                <p>Lux Plus Package</p>
                <p className="sub-text">starting at $119</p>
              </div>
            </div>
          </div>
        </div>
        <div className="contest-custom-request">
          <a href="#footer-section">Contact us for custom requests!</a>
        </div>
        <div className="popular-grad-cities">
          <h4>Our Most Popular Cities for Prom and Graduations</h4>
          <div className="contest-cities-container">
            <div className="pack-image-conainer">
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/contestImages/SanFrancisco.jpg"
                height="100"
                width="100"
              />
              <div className="contest-package-desc">
                <p>San Francisco</p>
                <p className="sub-text">starting at $119</p>
              </div>
            </div>
            <div className="pack-image-conainer">
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/contestImages/NewYork.jpg"
                height="100"
                width="100"
              />
              <div className="contest-package-desc">
                <p>New York City</p>
                <p className="sub-text">starting at $99</p>
              </div>
            </div>
            <div className="pack-image-conainer">
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/contestImages/LosAngeles.jpg"
                height="100"
                width="100"
              />
              <div className="contest-package-desc">
                <p>Los Angeles</p>
                <p className="sub-text">starting at $99</p>
              </div>
            </div>
          </div>
        </div>
        <div className="contest-add-cities">
          <button className="">And over 50 more cities across the U.S!</button>
        </div>
      </div>
    </>
  );
};

export default Contest;
