import React from "react";
import PickerGrid from "../PickerGrid/PickerGrid.jsx";
import PickerGridButton from "../PickerGrid/PickerGridButton.jsx";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tripWizardDateHour } from "../../utils/date-time";
import AllTimesLocal from "../AllTimesLocalComponent/AllTimesLocal.jsx";

const minuteIntervals = new Array(4).fill().map((x, i) => i * 15);

const formatHour = (hour) => (hour > 12 ? hour - 12 : hour === 0 ? 12 : hour);

export default class SearchMinutePicker extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Row>
          <Col className={"wizardPageTitle"}>
            <FontAwesomeIcon icon="clock" /> Time of Trip
          </Col>
        </Row>
        <Row>
          <Col className={"wizardPageTitle"}>
            {tripWizardDateHour(this.props.date)}
          </Col>
        </Row>
        <Row>
          <Col className={"wizardPageTitle"}>Select Exact Pickup Time:</Col>
        </Row>
        <div style={{ margin: "32px auto" }}>
          <PickerGrid
            name="minute-picker"
            defaultValue={this.props.selectedMinute}
            onChange={this.props.setMinute}
            segmentSize={4}
          >
            {minuteIntervals.map((minute, idx) => (
              <PickerGridButton value={minute} className="minuteCell" key={idx}>
                <span className="hour-minute">
                  {formatHour(this.props.selectedHour) +
                    ":" +
                    (minute === 0 ? "00" : minute)}
                </span>
                <span className="ampm">
                  {this.props.selectedHour >= 12 ? "pm" : "am"}
                </span>
              </PickerGridButton>
            ))}
          </PickerGrid>
        </div>
        <AllTimesLocal />
      </>
    );
  }
}
