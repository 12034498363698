import React, { createContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalShown, setModalShown] = useState(false);

  const value = useMemo(
    () => ({
      modalShown,
      setModalShown,
    }),
    [modalShown, setModalShown]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

ModalContext.propTypes = {
  children: PropTypes.node,
};
