import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const Giveaway = () => {
  return (
    <>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          <h1>Limos.com Ride Giveaway</h1>
          <h2>Official Contest Rules</h2>
          <h3>Who Can Enter</h3>
          <ol>
            <li>
              To be eligible to participate in this contest, entrants must be at
              least 18 years or older.
            </li>
            <li>
              Employees of Limos.com, their affiliated companies, and
              advertising and promotion agencies and the immediate family
              members of each are ineligible. The term “immediate family”
              includes spouses, grandparents, parents, siblings, children and
              grandchildren.
            </li>
          </ol>
          <h3>How to Enter</h3>
          <h4>Entry online, on Limos.com</h4>
          <ul>
            <li>
              No purchase is necessary to enter. Only one entry allowed per day
              per site visitor.{" "}
            </li>
            <li>
              To enter, visit Limos.com to enter your email on the contest pop
              up. No mechanically reproduced or completed entries will be
              accepted.{" "}
            </li>
            <li>
              The contest starts on 12/23/2020, and all entries must be received
              by Limos.com by 3 p.m. PST on the 13th of each month. Limos.com is
              not responsible for lost, late, or misdirected entries, printing
              errors, server unavailability, computer, or any other electronic
              malfunction.
            </li>
            <li>
              On the 15th of each month, three (3) winners will be selected by
              random drawing from among valid entries. Remaining valid entries
              will not carry over to the next drawing.
            </li>
          </ul>
          <h3>The Prize(s)</h3>
          <ol>
            <li>
              Three (3) winners will be selected. 1st will receive a free ride,
              2nd a 15% discount, and 3rd a 5% discount.{" "}
            </li>
            <li>
              Each winner will be notified by email. Before claiming the prize,
              the winner must confirm email response within 24hrs of
              notification by Limos.com.
            </li>
            <li>
              If a winner does not respond within 24hrs, eligibility, then the
              prize will be forfeited, and the prize will be awarded to an
              alternate winner randomly selected from among remaining valid
              entries.{" "}
            </li>
            <li>
              There will be no substitutions or cash alternatives for any prize
              except at the sole discretion of Limos.com. Limos.com has the
              right to substitute a prize of similar value. No transfer or
              assignment of prizes is allowed.
            </li>
            <li>
              Each winner is responsible for paying all local, county, state and
              federal taxes on prizes based on the estimated retail value of the
              prizes as set forth in these rules. Each winner must complete and
              sign a Form W-9.{" "}
            </li>
            <li>Only one prize will be awarded per household.</li>
            <li>
              Limos.com is not liable if the provider of any part of the prize
              fails to satisfy its obligations to furnish its portion of the
              prize.{" "}
            </li>
            <li>
              Limos.com is not responsible for any liability arising directly or
              indirectly from the award or use of the prize.
            </li>
            <li>
              In claiming your prize, you acknowledge that Limos.com has the
              right to publicize your name, character, likeness, photograph,
              voice and the fact that you are the winner for promotional
              purposes without financial remuneration.
            </li>
          </ol>
          <h3>Odds of Winning/Miscellaneous</h3>
          <ol>
            <li>
              Odds of winning depend on the number of valid entries received.
            </li>
            <li>
              Complete contest rules are available online at Limos.com 7 days a
              week.
            </li>
            <li>
              For a list of prize winners, email customersupport@limos.com
            </li>
            <li>
              This contest is subject to all applicable laws and regulations and
              is void where prohibited. Limos.com accepts no responsibility or
              liability in connection with any injuries, losses or damages of
              any kind caused by or resulting from the acceptance, possession or
              use of any prize awarded hereunder.
            </li>
            <li>
              Limos.com cannot be held liable for any lost or stolen entries,
              prizes, or contest information. Limos.com is not responsible for
              any computer or electronic malfunction, server unavailability,
              technological glitch, or human error relating to this contest
              and/or the use of short-code.
            </li>
            <li>
              Limos.com reserves the right to make rules and contest changes,
              and change the aforementioned contest dates.
            </li>
            <li>
              By entering this contest, each entrant waives any and all claims
              of liability against Limos.com, and their respective parents,
              affiliates, officers, directors, employees and agents, for any and
              all claims, loss, damage or injury which may occur as a result of
              entrant’s participation in this contest.
            </li>
          </ol>
        </Col>
      </Row>
    </>
  );
};

export default Giveaway;
