import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withWhiteLabelContext } from "react-whitelabel";
import buildUrl from "build-url";
import Select from "react-select";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

import Spinner from "../UI/Spinner/Spinner";
import TypeAheadFormField from "../TypeAheadFormField/TypeAheadFormField.jsx";
import TripInfoWizard from "../TripInfoWizard/TripInfoWizard.jsx";
import CallAndChatModal from "../CallAndChatModal/CallAndChatModal.jsx";
import DatePicker from "react-datepicker";
import Slider from "../Slider/Slider.jsx";
import FieldInvalid from "./FieldInvalid.jsx";

import { fullHumanDateTime } from "../../utils/date-time";
import { addressComponent } from "../../utils/address.js";
import { getServiceTypeFormCode } from "../../utils/serviceType.js";
import { routePaths } from "../../routes/routes";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "./NewSearchForm.scss";
import "react-datepicker/dist/react-datepicker.css";

import { listOfCruisePort } from "../../utils/ListOfCursiePort";

const SCREENS = { form: "form", tripInfoWizard: "tripInfoWizard" };

const zeroPad = (n) => ("0" + n).slice(-2);

const roundTripCode = ["100", "99", "131072", "102", "101"];

const hourlyTripCode = [
  "4",
  "65536",
  "128",
  "32",
  "64",
  "8192",
  "256",
  "4096",
  "16384",
];

const hasErrors = (errObj) =>
  Object.values(errObj).some((error) => error.length !== 0);

const Slide = ({ img, key, ...props }) => (
  <div
    key={key}
    className="slide"
    style={{
      ...props.styles,
      backgroundImage: `url("${img}")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    }}
  />
);

const storeFormDataInLocalStorage = {
  serviceType: "",
  rideDate: null,
  hour: 17,
  minute: 0,
  returnHour: 18,
  returMinute: 0,
  pax: "",
  duration: "",
  isRoundTrip: false,
};

const errorsObj = {
  firstName: "First name is required.",
  lastName: "Last Name is required.",
  email: "Email is required.",
  phoneNumber: "Phone Number is required.",
  serviceType: "Service Type is required.",
  rideDate: "Trip date is required.",
  pax: "Passengers is required.",
  duration: "Hours is required.",
  returnDate: "Return date is required.",
  selectedToCruisePortOption: "To Cruise port is required.",
  selectedFromCruisePortOption: "From Cruise port is required.",
};

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

class SearchForm extends Component {
  state = {
    redirectToSearchResults: false,
    redirectToSubmitAuction: false,
    screen: SCREENS.form,
    redirectItem: { rideDate: "", pickupTime: "" },
    loading: false,
    partySizeState: [1, 0, 0],
    pickupPlaceValid: true,
    dropoffPlaceValid: true,
    showContactModal: false,
    badLocations: {
      pickupPlace: false,
      dropoffPlace: false,
    },
    slideshowImages: shuffle(this.props.images) || [],
    rideDate: null,
    hour: 17,
    minute: 0,
    returnDate: null,
    returnHour: 18,
    returMinute: 0,
    serviceType: "",
    pax: "",
    isRoundTrip: false,
    sameAsPickAddress: false,
    selectedToCruisePortOption: null,
    selectedFromCruisePortOption: null,
    defaultDropoffValue: [],
    duration: "",
    email: "",
    notes: "",
    phoneNumber: "",
    gcaptcha: null,
    firstName: "",
    lastName: "",
  };

  constructor(props) {
    super(props);
    if (typeof document !== "undefined") {
      document.title = this.props.label.title;
    }
  }

  toggleModalShow = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showContactModal: !this.state.showContactModal });
  };

  setPlace = (place, key) => {
    if (place && !place.is_airport) {
      this.service = new window.google.maps.places.PlacesService(this.instance);
      this.service.getDetails(
        {
          placeId: place.placeId,
          fields: [
            "types",
            "geometry",
            "formatted_address",
            "address_components",
          ],
        },
        (res, status) => {
          if (
            !addressComponent(res.address_components, "street_number") &&
            !addressComponent(res.address_components, "route")
          ) {
            this.setState({
              badLocations: {
                ...this.state.badLocations,
                [key]:
                  "We were unable to find a street address for this place. ",
              },
            });
          } else {
            this.setState({
              badLocations: { ...this.state.badLocations, [key]: false },
            });
          }
          return this.props.setPlace(
            place,
            key,
            res.geometry.location.lat(),
            res.geometry.location.lng(),
            res.formatted_address,
            place.placeId,
            false,
            ""
          );
        }
      );
      this.getDataFromLocalStorage(key, place);
    } else if (place && place.is_airport) {
      this.props.setPlace(
        place,
        key,
        "",
        "",
        place.display,
        "",
        true,
        place.placeId
      );
      this.getDataFromLocalStorage(key, place);
    }
  };

  setPlaceValid = (key, valid) => {
    this.setState({ [key]: valid }, this.intermediateValidate);
  };

  intermediateValidate = () => {
    const { errors } = this.props;
    if (!this.state.pickupPlaceValid) {
      errors.pickupPlace =
        "Your pickup address is too broad. Please enter an exact address.";
    }
    if (!this.state.dropoffPlaceValid) {
      errors.dropoffPlace =
        "Your dropoff address is too broad. Please enter an exact address.";
    }
  };

  validate = () => {
    const errors = this.props.createInitialSearchFormErrorState();
    this.props.setSearchFormErrors(errors);
    let dynamicHeight = 0;

    if (this.state.serviceType == "101") {
      if (!this.state.selectedFromCruisePortOption) {
        errors.selectedFromCruisePortOption = "From Cruise port is required";
      }
    } else {
      if (
        this.props.pickupPlace.lat === 0 &&
        this.props.pickupPlace.lng === 0
      ) {
        errors.pickupPlace = "Pickup location required.";
      }
    }

    if (!this.state.pickupPlaceValid) {
      errors.pickupPlace =
        "Your pickup address is too broad. Please enter a more specific location.";
    }

    if (this.state.serviceType == "102") {
      if (!this.state.selectedToCruisePortOption) {
        errors.selectedToCruisePortOption = "To Cruise port is required";
      }
    } else {
      if (
        this.props.dropoffPlace.lat === 0 &&
        this.props.dropoffPlace.lng === 0
      ) {
        errors.dropoffPlace = "Dropoff location required.";
      }
    }

    if (!this.state.dropoffPlaceValid) {
      errors.dropoffPlace =
        "Your dropoff address is too broad. Please enter a a more specific location.";
    }

    if (this.state.serviceType === "") {
      errors.serviceType = "Service Type is required.";
    }

    if (!this.state.rideDate) {
      errors.rideDate = "Trip date is required";
    }

    if (!this.state.hour) {
      errors.rideHour = "Trip hour is required";
    }

    if (!this.state.returnDate && this.state.isRoundTrip) {
      errors.returnDate = "Return date is required";
    }

    if (this.state.isRoundTrip) {
      if (this.state.rideDate && this.state.returnDate) {
        let rideD = moment(this.state.rideDate);
        let returnD = moment(this.state.returnDate);
        if (rideD.diff(returnD, "days") === 0) {
          if (
            this.state.hour > this.state.returnHour ||
            this.state.hour === this.state.returnHour
          ) {
            if (!(this.state.returMinute - this.state.minute > 59)) {
              errors.returnDate = "Round trip should be more than 1hrs";
            } else {
              errors.returnDate =
                "Return Date should be after Trip Date & Time";
            }
          }
        }
        if (rideD.diff(returnD, "days") > 0) {
          errors.returnDate = "Return Date should be after Trip Date & Time";
        }
      }
    }

    if (hourlyTripCode.includes(this.state.serviceType)) {
      if (!this.state.duration) {
        errors.duration = "Hours is required";
      }
      if (this.state.duration < 1) {
        errors.duration = "Minimum 1 hrs required";
      }
    }

    if (!this.state.returnHour && this.state.isRoundTrip) {
      errors.returnHour = "Trip return hour is required";
    }

    if (!this.state.pax) {
      errors.pax = "Passengers is required.";
    }

    if (this.props.isRequestAQuoteForm) {
      if (!isValidEmail(this.state.email)) {
        errors.email = "Please enter a valid email";
      }

      if (!/^[0-9]{10,}$/.test(this.state.phoneNumber)) {
        errors.phoneNumber = "Please enter a valid phone number";
      }

      if (!this.state.firstName) {
        errors.firstName = "First name is required.";
      }

      if (!this.state.lastName) {
        errors.lastName = "Last Name is required.";
      }

      if (!this.state.gcaptcha) {
        errors.gcaptcha = "Captcha is required.";
      }
    }

    // for (const validKey in errors) {
    //   const validValue = errors[validKey];
    //   if (validKey && validValue) {
    //     dynamicHeight++;
    //   }
    // }

    // this.props.setDynamicHeight(dynamicHeight*30 + 850)

    // if (this.state.rideDate !== null) {
    //   const formatRideDate = moment(this.state.rideDate).format("MMM DD,gggg");
    //   const calculateRideTime = `${formatRideDate} ${zeroPad(this.state.hour) + ":" + zeroPad(this.state.minute) + ":" + zeroPad(0)}`;
    //   const todayDate = new Date().getTime();
    //   const calcaulteDistance = Number(new Date(String(calculateRideTime)).getTime()) - Number(todayDate);
    //   const differenceHours = Math.floor((calcaulteDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    //   if (differenceHours < 12) {
    //     errors.rideDate = "Difference should be 12hrs from now.";
    //   }
    // }

    return !hasErrors(errors);
  };

  onBlurValidate = (e) => {
    let fieldName;
    const erros = this.props.searchFormErrors;
    this.props.setSearchFormErrors(erros);
    if (typeof e === "undefined") {
      fieldName = "rideDate";
    } else {
      fieldName = e.target.name;
    }
    let state = this.state;
    if (!state[fieldName]) {
      erros[fieldName] = errorsObj[fieldName];
    } else {
      erros[fieldName] = "";
    }
  };

  componentDidMount() {
    if (hasErrors(this.props.searchFormErrors)) {
      this.validate();
    }
    if (!this.props.isRequestAQuoteForm) {
      let localItem = sessionStorage.getItem("formData");
      if (!localItem) {
        sessionStorage.setItem(
          "formData",
          JSON.stringify(storeFormDataInLocalStorage)
        );
      } else {
        localItem = JSON.parse(localItem);
        this.setState({
          serviceType: localItem.serviceType,
          rideDate: new Date(localItem.rideDate),
          duration: localItem.duration,
          pax: localItem.pax,
          hour: localItem.hour,
          minute: localItem.minute,
          returnHour: localItem.returnHour,
          returMinute: localItem.returMinute,
          isRoundTrip: localItem.isRoundTrip,
        });
      }
    }

    if (sessionStorage?.getItem("formData")) {
      this.setPlace(
        sessionStorage?.getItem("formData")?.pickupPlace,
        "pickupPlace"
      );
    }
  }

  onTypeaheadSearch = (query, id, showOnlyAirports, finished) => {
    if (query.length < 3) {
      finished();
      return;
    }
    let googleResults = [],
      airportResults = [],
      googleFinished = false,
      airportFinished = false;
    const google = window.google;

    const finishSearch = () => {
      if (googleFinished && airportFinished) {
        this.props.onPlaceQuery(id, airportResults.concat(googleResults));
        finished();
      }
    };

    const googleSuggestions = (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        console.log(`google place api error: ${status}`);
      } else {
        googleResults = predictions
          .filter(
            (res) =>
              !res.types.includes("locality") && !res.types.includes("airport")
          )
          .map((res) => {
            return {
              placeId: res.place_id,
              types: res.types,
              display: res.description,
            };
          });
      }
      googleFinished = true;
      finishSearch();
    };

    const airportSuggestions = (data) => {
      let filterSearchAirport = data.filter((item, index) => {
        return item.id.toLowerCase() === query.toLowerCase();
      });

      let searchWithText = data.filter((item) => {
        return item.text.toLowerCase().includes(query.toLowerCase());
      });

      if (filterSearchAirport.length === 0) {
        airportResults = searchWithText.map((res) => {
          return { placeId: res.id, display: res.text, is_airport: true };
        });
      } else {
        airportResults = filterSearchAirport.map((res) => {
          return { placeId: res.id, display: res.text, is_airport: true };
        });
      }

      airportFinished = true;
      finishSearch();
    };

    new google.maps.places.AutocompleteService().getPlacePredictions(
      { input: query, types: showOnlyAirports ? ["airport"] : [] },
      googleSuggestions
    );
    fetch(`/services/location/airports?n=${encodeURIComponent(query)}`)
      .then((res) => res.json())
      .then(airportSuggestions);
  };

  bidsHandler = () => {
    this.orderHandler(true);
  };

  orderHandler = (bids = false) => {
    let code = "131072";
    if (this.props.pickupPlace.isAirport) {
      code = "99";
    } else if (this.props.dropoffPlace?.isAirport) {
      code = "100";
    }
    if (this.validate()) {
      this.trackEvent("search-submitted");
      const roundTripParams = this.state.isRoundTrip
        ? {
            is_round_trip: "1",
            round_trip_ride_date: moment(this.state.returnDate).format(
              "MM/DD/YYYY"
            ),
            round_trip_pickup_time:
              this.state.returnHour + ":" + zeroPad(this.state.returMinute),
          }
        : {};
      const dropoffTimeForHourly = () => {
        const dropoffTime = moment(
          this.state.hour + ":" + zeroPad(this.state.minute),
          "HH:mm"
        )
          .add(this.state.duration * 60, "minutes")
          .format("HH:mm");
        return dropoffTime;
      };

      const params = new URLSearchParams(window.location.search);
      const mozioParam = params.get('mozio');
      let mozObj = {};

      if (mozioParam === "1") {
        mozObj.mozio = "1";
      }

      const queryParams = {
        ...roundTripParams,
        ...mozObj,
        ride_date: moment(this.state.rideDate).format("MM/DD/YYYY"), // TODO make locale independent
        pickup_time: this.state.hour + ":" + zeroPad(this.state.minute),
        drop_off_time: this.state.isRoundTrip
          ? this.state.returnHour + ":" + zeroPad(this.state.returMinute)
          : hourlyTripCode.includes(this.state.serviceType)
          ? dropoffTimeForHourly()
          : this.state.hour + ":" + zeroPad(this.state.minute),
        pickup_place_id: !this.props.pickupPlace.airportCode,
        dropoff_place_id: !this.props.dropoffPlace?.airportCode,
        pickup_place: this.state.selectedFromCruisePortOption
          ? this.state.selectedFromCruisePortOption.lat +
            ", " +
            this.state.selectedFromCruisePortOption.lng
          : this.props.pickupPlace.isAirport
          ? this.props.pickupPlace.airportCode
          : this.props.pickupPlace.placeId,
        drop_off_place: this.state.selectedToCruisePortOption
          ? this.state.selectedToCruisePortOption.lat +
            ", " +
            this.state.selectedToCruisePortOption.lng
          : this.props.dropoffPlace?.isAirport
          ? this.props.dropoffPlace?.airportCode
          : this.props.dropoffPlace?.placeId,
        pax: this.state.pax,
        vehicle_types: [1, 2, 3, 4, 5, 6, 7, 11, 12, 13], // all types for now, limit by pax (size)
        service_type: this.state.serviceType,
        email: this.props.searchForm?.email,
        bids: bids,
      };

      if (this.props.isRequestAQuoteForm) {
        const payload = {
          ...queryParams,
          ride_date: moment(this.state.rideDate).format("MMM DD,YYYY"),
          round_trip_ride_date: this.state.isRoundTrip
            ? moment(this.state.returnDate).format("MMM DD,YYYY")
            : "",
          is_round_trip: this.state.isRoundTrip ? "Yes" : "No",
          round_trip_pickup_time: this.state.isRoundTrip
            ? this.state.returnHour + ":" + zeroPad(this.state.returMinute)
            : "",
          hours: this.state.hour,
          service_type: getServiceTypeFormCode(this.state.serviceType),
          pickup_place: this.state.selectedFromCruisePortOption
            ? this.state.selectedFromCruisePortOption?.label
            : this.props.pickupPlace.item?.display,
          drop_off_place: this.state.selectedToCruisePortOption
            ? this.state.selectedToCruisePortOption?.label
            : this.props.dropoffPlace.item?.display,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          notes: this.state.notes,
          phoneNumber: this.state.phoneNumber,
          "g-recaptcha-response": this.state.gcaptcha,
        };

        emailjs
          .send(
            "service_p6dvwdj",
            "template_5m7pyiq",
            payload,
            "2CC3n-4BQ-cOrf3jv"
          )
          .then(
            function () {
              console.log("Confirmation SUCCESS!");
              window.location = `${routePaths.thankyou}`;
            },
            function (error) {
              console.log("Confirmation Failure!", error);
            }
          );
      } else {
        this.props.setSearchParams(queryParams);
        this.setState({
          loading: true,
          redirectToSearchResults: !bids,
          redirectToSubmitAuction: bids,
          redirectItem: buildUrl("", {
            queryParams,
          }),
        });
      }
    }
  };

  setScreen = (screen) => {
    this.setState({ screen });
  };

  trackEvent = (name) => {
    if (typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({ event: name, source: "search-form" });
    }
  };

  setPartySize = (v) => {
    const regexForNum = /^[1-9][0-9]*$/;
    if (v === "" || regexForNum.test(v)) {
      this.getDataFromLocalStorage("pax", v);
      this.setState({ pax: v });
    }
  };

  setDuration = (v) => {
    const regexForNum = /^(0?[1-9]|[1-9][0-9])$/;
    if (v === "" || regexForNum.test(v)) {
      this.getDataFromLocalStorage("duration", v);
      this.setState({ duration: v });
    }
  };

  setCustomPartySize = (v) => {
    this.props.setCustomPartySize(v);
  };

  setRideDate = (date) => {
    this.getDataFromLocalStorage("rideDate", date);
    this.setState({ rideDate: date });
  };

  setHour = (v) => {
    this.getDataFromLocalStorage("hour", Number(v));
    this.setState({ hour: Number(v) });
  };

  setMinute = (v) => {
    this.getDataFromLocalStorage("minute", Number(v));
    this.setState({ minute: Number(v) });
  };

  setReturnDate = (date) => {
    this.setState({ returnDate: date });
  };

  setReturnHour = (v) => {
    this.getDataFromLocalStorage("returnHour", Number(v));
    this.setState({ returnHour: Number(v) });
  };

  setReturnMinute = (v) => {
    this.getDataFromLocalStorage("returnMinute", Number(v));
    this.setState({ returMinute: Number(v) });
  };

  setServiceType = (v) => {
    this.getDataFromLocalStorage("serviceType", v);
    this.setState({
      serviceType: v,
      isRoundTrip: false,
      sameAsPickAddress: false,
      rideDate: null,
      hour: 17,
      minute: 0,
      pax: "",
      returnDate: null,
      returMinute: 0,
      returnHour: 18,
      selectedToCruisePortOption: null,
      selectedFromCruisePortOption: null,
      duration: "",
    });
  };

  handleIsRoundTrip = () => {
    this.getDataFromLocalStorage("isRoundTrip", !this.state.isRoundTrip);
    this.setState((state) => {
      return { isRoundTrip: !state.isRoundTrip };
    });
  };

  handleSameAsPickAddress = () => {
    this.setState((state) => {
      return { sameAsPickAddress: !state.sameAsPickAddress };
    });
    if (
      this.state.serviceType == "4096" &&
      this.props.pickupPlace &&
      this.props.pickupPlace.item
    ) {
      this.setState({ defaultDropoffValue: [this.props.pickupPlace.item] });
    }
  };

  handleOnChangeToCruisePort = (v) => {
    this.setState({
      selectedToCruisePortOption: v,
    });
  };

  handleOnChangeFromCruisePort = (v) => {
    this.setState(
      {
        selectedFromCruisePortOption: v,
      },
      () => {
        this.props.setPlace(
          "",
          "pickupPlace",
          v.lat,
          v.lng,
          v.label,
          "",
          false,
          ""
        );
      }
    );
  };

  getDataFromLocalStorage = (fieldType, inputValue) => {
    let formObject = sessionStorage.getItem("formData");
    if (formObject) {
      formObject = JSON.parse(formObject);
      formObject[fieldType] = inputValue;
      sessionStorage.setItem("formData", JSON.stringify(formObject));
    }
  };

  handleSetRecaptcha = (captchaData) => {
    this.setState({
      gcaptcha: captchaData,
    });
  };

  setValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    if (this.state.redirectToSearchResults) {
      return (
        <Redirect
          push
          to={routePaths.searchResults + this.state.redirectItem}
        />
      );
    } else if (this.state.redirectToSubmitAuction) {
      return <Redirect push to={routePaths.submitAuction} />;
    } else {
      // maybe change this to a switch
      if (this.state.screen === SCREENS.form) {
        const formElementsArray = [];
        for (let key in this.state.searchForm) {
          formElementsArray.push({
            id: key,
            config: this.state.searchForm[key],
          });
        }
        const errors = this.props.searchFormErrors;
        const invalidClass = (value) => (value ? " field-invalid" : ""),
          completeDate = this.props.completeDate,
          returnDate = this.props.returnDate;
        let dateValue = "";
        if (this.props.finishedTripWizard) {
          dateValue = fullHumanDateTime(completeDate);
          if (returnDate) {
            dateValue += " & " + fullHumanDateTime(returnDate);
          }
        }
        const previousPickupPlace =
          (this.props.pickupPlace?.display && [
            { display: this.props.pickupPlace?.display },
          ]) ||
          [];
        const previousDropoffPlace =
          (this.props.dropoffPlace?.display && [
            { display: this.props.dropoffPlace?.display },
          ]) ||
          [];
        const {
          formTitle,
          isRequestAQuoteForm = false,
          formButtonText,
          classFlag,
        } = this.props;
        const { bids } = this.props.label;
        let form = (
          <div className="hero-form new-search-form" align="center">
            <h3 className="form-title" style={{ fontSize: "20px" }}>
              {formTitle}
            </h3>
            <div className="background-blue"></div>
            <div ref={(el) => (this.instance = el)}></div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                bids ? this.bidsHandler() : this.orderHandler();
              }}
            >
              {isRequestAQuoteForm && (
                <>
                  <div className="add-form-border form-field name-container">
                    <div className="first-name-form-field">
                      <div className="new-form-label">
                        <Form.Label>First Name*</Form.Label>
                      </div>
                      <Form.Control
                        type="text"
                        className="data-hj-whitelist"
                        data-test="firstName"
                        name="firstName"
                        autoComplete="off"
                        placeholder={"First name"}
                        value={this.state.firstName}
                        onChange={(e) => this.setValue(e)}
                        onBlur={(e) => {
                          this.onBlurValidate(e);
                        }}
                      ></Form.Control>
                    </div>

                    <div className="last-name-form-field">
                      <div className="new-form-label">
                        <Form.Label> Last Name* </Form.Label>
                      </div>
                      <Form.Control
                        type="text"
                        className="data-hj-whitelist"
                        data-test="lastName"
                        name="lastName"
                        autoComplete="off"
                        placeholder={"Last name"}
                        onBlur={(e) => {
                          this.onBlurValidate(e);
                        }}
                        onChange={(e) => this.setValue(e)}
                        value={this.state.lastName}
                      ></Form.Control>
                    </div>
                  </div>

                  <div className="form-error-message">
                    <p>
                      <FieldInvalid value={errors.firstName} />
                    </p>
                  </div>
                  <div className="form-error-message">
                    <p>
                      <FieldInvalid value={errors.lastName} />
                    </p>
                  </div>

                  <div className="form-field add-form-border">
                    <div className="email-form-field">
                      <div className="new-form-label">
                        <Form.Label>Email*</Form.Label>
                      </div>
                      <Form.Control
                        type="email"
                        className="data-hj-whitelist"
                        data-test="email"
                        name="email"
                        autoComplete="off"
                        placeholder={"Email"}
                        value={this.state.email}
                        onChange={(e) => this.setValue(e)}
                        onBlur={(e) => {
                          this.onBlurValidate(e);
                        }}
                      ></Form.Control>
                    </div>
                  </div>

                  <div className="form-error-message">
                    <p>
                      <FieldInvalid value={errors.email} />
                    </p>
                  </div>

                  <div className="form-field add-form-border">
                    <div className="phone-number-form-field">
                      <div className="new-form-label">
                        <Form.Label>Phone*</Form.Label>
                      </div>
                      <Form.Control
                        type="text"
                        className="data-hj-whitelist"
                        data-test="phone-number"
                        name="phoneNumber"
                        autoComplete="off"
                        placeholder={"Phone"}
                        value={this.state.phoneNumber}
                        onChange={(e) => this.setValue(e)}
                        onBlur={(e) => {
                          this.onBlurValidate(e);
                        }}
                      ></Form.Control>
                    </div>
                  </div>

                  <div className="form-error-message">
                    <p>
                      <FieldInvalid value={errors.phoneNumber} />
                    </p>
                  </div>

                  <div className="form-field add-form-border">
                    <div className="notes-form-field">
                      <div className="new-form-label">
                        <Form.Label>Notes</Form.Label>
                      </div>
                      <Form.Control
                        type="text"
                        className="data-hj-whitelist"
                        data-test="notes"
                        name="notes"
                        autoComplete="off"
                        placeholder={"Notes"}
                        value={this.state.notes}
                        onChange={(e) => this.setValue(e)}
                        // onBlur={(e) => {
                        //   this.onBlurValidate(e);
                        // }}
                      ></Form.Control>
                    </div>
                  </div>
                </>
              )}

              <div
                className="form-field add-form-border service-type-dropdown-container"
                onClick={() => this.trackEvent("service-type")}
              >
                <div className="new-form-label">
                  {/* <LabelForClickableDiv htmlFor="menu" className="label-half-width-container">
                   Service Type*
                  </LabelForClickableDiv> */}
                  <Form.Label className="label-half-width-container">
                    Service Type*
                  </Form.Label>
                  <div className="down-arrow-container">
                    <div
                      className="down-arrow-container-indicator"
                      aria-hidden="true"
                    >
                      <svg
                        height="20"
                        width="20"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        focusable="false"
                        className="svg-down-icon"
                      >
                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                      </svg>
                    </div>
                  </div>
                </div>

                <Form.Control
                  as="select"
                  name="serviceType"
                  onChange={(e) => this.setServiceType(e.target.value)}
                  className="service-type data-hj-whitelist"
                  value={this.state.serviceType}
                  onBlur={(e) => {
                    this.onBlurValidate(e);
                  }}
                >
                  <option value="">Choose Service type</option>
                  <optgroup label="Most Popular:">
                    <option value="100">To Airport</option>
                    <option value="99">From Airport</option>
                    <option value="131072">Point-to-Point (one-way)</option>
                    <option value="4096">Just Drive (hourly)</option>
                  </optgroup>
                  <optgroup label="Special Occasions:">
                    <option value="16384">Prom / School Event</option>
                    <option value="4">Wedding</option>
                    <option value="64">Birthday / Anniversary</option>
                    <option value="8192">Night-on-the-Town</option>
                    <option value="128">Concert / Sporting Event</option>
                    <option value="32">Bachelor(ette) Party</option>
                    <option value="102">To Cruiseport</option>
                    <option value="101">From Cruiseport</option>
                    <option value="65536">Wine/Private Tour</option>
                    <option value="256">Funeral</option>
                  </optgroup>
                </Form.Control>
              </div>
              <div className="form-error-message">
                <FieldInvalid value={errors.serviceType} />
              </div>

              {this.state.serviceType === "101" ? (
                <>
                  <div
                    className="form-field"
                    onClick={() => this.trackEvent("from-cruise-port")}
                  >
                    <Select
                      options={listOfCruisePort}
                      name="selectedFromCruisePortOption"
                      placeholder={"Type and search cruiseports"}
                      onChange={this.handleOnChangeFromCruisePort}
                      classNamePrefix={"react-select"}
                      className={"react-select-cruiseport-dropdown"}
                    />
                  </div>
                  <div className="form-error-message">
                    <FieldInvalid value={errors.selectedFromCruisePortOption} />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="form-field add-form-border"
                    onClick={() => this.trackEvent("pickup-place")}
                  >
                    <div className="new-form-label">
                      <Form.Label> Pickup Location* </Form.Label>
                    </div>
                    <TypeAheadFormField
                      setPlaceValid={(valid) =>
                        this.setPlaceValid("pickupPlaceValid", valid)
                      }
                      inputProps={{
                        "data-hj-whitelist": true,
                        className: "data-hj-whitelist",
                        "data-test": "pickup-place",
                        name: "pickupPlace",
                      }}
                      name="pickup_place"
                      id="pickupPlace"
                      useGoogle={true}
                      onSearch={this.onTypeaheadSearch}
                      google={typeof window !== "undefined" && window.google}
                      className={invalidClass(
                        errors.pickupPlace ||
                          this.state.badLocations.pickupPlace
                      )}
                      placeholder={
                        this.state.serviceType === "99"
                          ? "Enter Airport name or Airport code"
                          : "Enter pickup location"
                      }
                      value={
                        this.props.pickupPlace.item || previousPickupPlace[0]
                      }
                      onPlaceQuery={this.props.onPlaceQuery}
                      queryResults={
                        this.props.pickupPlaceQueryResults.length > 0
                          ? this.props.pickupPlaceQueryResults
                          : previousPickupPlace
                      }
                      onSelected={(selectedItem) =>
                        this.setPlace(selectedItem, "pickupPlace")
                      }
                      showOnlyAirports={this.state.serviceType == "99"}
                      onBlur={(e) => {
                        this.onBlurValidate(e);
                      }}
                      defaultInputValue={
                        (typeof window !== "undefined" &&
                          JSON.parse(sessionStorage.getItem("formData"))
                            ?.pickupPlace?.display) ||
                        ""
                      }
                    />
                  </div>
                  <div className="form-error-message">
                    <FieldInvalid value={errors.pickupPlace} />
                    <FieldInvalid value={this.state.badLocations.pickupPlace} />
                  </div>
                </>
              )}

              {this.state.serviceType === "102" ? (
                <>
                  <div
                    className="form-field"
                    onClick={() => this.trackEvent("to-cruise-port")}
                  >
                    <Select
                      name="selectedToCruisePortOption"
                      options={listOfCruisePort}
                      placeholder={"Type and search cruiseports"}
                      onChange={this.handleOnChangeToCruisePort}
                      classNamePrefix={"react-select"}
                      className={
                        "react-select-cruiseport-dropdown data-hj-whitelist"
                      }
                    />
                  </div>
                  <div className="form-error-message">
                    <FieldInvalid value={errors.selectedToCruisePortOption} />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="form-field add-form-border"
                    onClick={() => this.trackEvent("dropoff-place")}
                  >
                    <div className="new-form-label">
                      <Form.Label> Dropoff Location*</Form.Label>
                    </div>
                    <TypeAheadFormField
                      inputProps={{
                        "data-hj-whitelist": true,
                        className: "data-hj-whitelist",
                        "data-test": "dropoff-place",
                        name: "dropoffPlace",
                      }}
                      name="dropoff_place"
                      setPlaceValid={(valid) =>
                        this.setPlaceValid("dropoffPlaceValid", valid)
                      }
                      useGoogle={true}
                      google={typeof window !== "undefined" && window.google}
                      className={invalidClass(
                        errors.dropoffPlace ||
                          this.state.badLocations.dropoffPlace
                      )}
                      placeholder={
                        this.state.serviceType === "100"
                          ? "Enter Airport name or Airport code"
                          : "Enter dropoff location"
                      }
                      id="dropoffPlace"
                      onSearch={this.onTypeaheadSearch}
                      value={
                        this.props.dropoffPlace?.item || previousDropoffPlace[0]
                      }
                      queryResults={
                        this.props.dropoffPlaceQueryResults.length > 0
                          ? this.props.dropoffPlaceQueryResults
                          : previousDropoffPlace
                      }
                      onPlaceQuery={this.props.onPlaceQuery}
                      onSelected={(selectedItem) =>
                        this.setPlace(selectedItem, "dropoffPlace")
                      }
                      showOnlyAirports={this.state.serviceType == 100}
                      onBlur={(e) => {
                        this.onBlurValidate(e);
                      }}
                      defaultInputValue={
                        (typeof window !== "undefined" &&
                          JSON.parse(sessionStorage.getItem("formData"))
                            ?.dropoffPlace?.display) ||
                        ""
                      }
                    />
                  </div>
                  <div className="form-error-message">
                    <FieldInvalid value={errors.dropoffPlace} />
                    <FieldInvalid
                      value={this.state.badLocations.dropoffPlace}
                    />
                  </div>
                </>
              )}

              {roundTripCode.includes(this.state.serviceType) && (
                <div className="form-field">
                  <div className="round-trip-checkbox-container">
                    <div className="form-checkboxes">
                      <input
                        className=""
                        type="checkbox"
                        checked={this.state.isRoundTrip}
                        value={this.state.isRoundTrip}
                        onChange={this.handleIsRoundTrip}
                        id="roundTrip"
                      />
                      <Form.Label className="">Roundtrip</Form.Label>
                    </div>
                  </div>
                </div>
              )}

              <div className="add-form-border form-field trip-date-field">
                <div
                  className={"trip-date-picker" + invalidClass(errors.rideDate)}
                  onClick={() => this.trackEvent("trip-wizard")}
                >
                  <div className="new-form-label">
                    <Form.Label> Trip Date* </Form.Label>
                  </div>
                  <DatePicker
                    className="form-control rbt-input-main rbt-input data-hj-whitelist"
                    name="rideDate"
                    placeholderText={"Select Date"}
                    minDate={new Date()}
                    selected={this.state.rideDate}
                    onChange={this.setRideDate}
                    onCalendarClose={this.onBlurValidate}
                    isClearable={false}
                    dateFormat="eee, MMMM do, yyyy"
                    // dateFormat="ee MMMM do, yyyy"
                    autoComplete="off"
                  />
                </div>

                <div
                  className={
                    "trip-time-picker" +
                    invalidClass(errors.hour || errors.minute)
                  }
                  onClick={() => this.trackEvent("trip-time-picker")}
                >
                  <div className="new-form-label">
                    <Form.Label> Trip Time* </Form.Label>
                  </div>

                  <div className="hour-time-container">
                    <Form.Control
                      as="select"
                      name="hour"
                      className="hour-type data-hj-whitelist"
                      value={this.state.hour}
                      onChange={(e) => this.setHour(e.target.value)}
                    >
                      <option value="0">12 AM</option>
                      <option value="1">1 AM</option>
                      <option value="2">2 AM</option>
                      <option value="3">3 AM</option>
                      <option value="4">4 AM</option>
                      <option value="5">5 AM</option>
                      <option value="6">6 AM</option>
                      <option value="7">7 AM</option>
                      <option value="8">8 AM</option>
                      <option value="9">9 AM</option>
                      <option value="10">10 AM</option>
                      <option value="11">11 AM</option>
                      <option value="12">12 PM</option>
                      <option value="13">1 PM</option>
                      <option value="14">2 PM</option>
                      <option value="15">3 PM</option>
                      <option value="16">4 PM</option>
                      <option value="17">5 PM</option>
                      <option value="18">6 PM</option>
                      <option value="19">7 PM</option>
                      <option value="20">8 PM</option>
                      <option value="21">9 PM</option>
                      <option value="22">10 PM</option>
                      <option value="23">11 PM</option>
                    </Form.Control>

                    <p className="timepicker-dropdownDivider">:</p>

                    <Form.Control
                      as="select"
                      name="minute"
                      onChange={(e) => this.setMinute(e.target.value)}
                      className="minute-type data-hj-whitelist"
                      value={this.state.minute}
                    >
                      <option value="0">00</option>
                      <option value="15">15</option>
                      <option value="30">30</option>
                      <option value="45">45</option>
                    </Form.Control>
                  </div>
                </div>
              </div>
              <div className="form-error-message">
                <p>
                  <FieldInvalid value={errors.rideDate} />
                </p>
                <p>
                  <FieldInvalid value={errors.rideHour} />
                </p>
                <p>
                  <FieldInvalid value={errors.rideMinute} />
                </p>
              </div>

              {this.state.isRoundTrip && (
                <>
                  <div className="add-form-border form-field trip-date-field">
                    <div
                      className={
                        "trip-date-picker" + invalidClass(errors.returnDate)
                      }
                      onClick={() => this.trackEvent("returnDate")}
                    >
                      <div className="new-form-label">
                        <Form.Label> Return Date* </Form.Label>
                      </div>
                      <DatePicker
                        className="form-control rbt-input-main rbt-input data-hj-whitelist"
                        name="returnDate"
                        placeholderText={"Select Date"}
                        minDate={new Date()}
                        selected={this.state.returnDate}
                        onChange={this.setReturnDate}
                        onBlur={(e) => {
                          this.onBlurValidate(e);
                        }}
                        isClearable={false}
                        dateFormat="eee, MMMM, do, yyyy"
                        autoComplete="off"
                      />
                    </div>

                    <div
                      className={
                        "trip-time-picker" + invalidClass(errors.returnTime)
                      }
                      onClick={() => this.trackEvent("returnTime")}
                    >
                      <div className="new-form-label">
                        <Form.Label> Return Trip Time* </Form.Label>
                      </div>

                      <div className="hour-time-container">
                        <Form.Control
                          as="select"
                          name="hour"
                          onChange={(e) => this.setReturnHour(e.target.value)}
                          // onBlur={() => {
                          //   this.validate();
                          // }}
                          className="hour-type data-hj-whitelist"
                          value={this.state.returnHour}
                        >
                          <option value="0">12 AM</option>
                          <option value="1">1 AM</option>
                          <option value="2">2 AM</option>
                          <option value="3">3 AM</option>
                          <option value="4">4 AM</option>
                          <option value="5">5 AM</option>
                          <option value="6">6 AM</option>
                          <option value="7">7 AM</option>
                          <option value="8">8 AM</option>
                          <option value="9">9 AM</option>
                          <option value="10">10 AM</option>
                          <option value="11">11 AM</option>
                          <option value="12">12 PM</option>
                          <option value="13">1 PM</option>
                          <option value="14">2 PM</option>
                          <option value="15">3 PM</option>
                          <option value="16">4 PM</option>
                          <option value="17">5 PM</option>
                          <option value="18">6 PM</option>
                          <option value="19">7 PM</option>
                          <option value="20">8 PM</option>
                          <option value="21">9 PM</option>
                          <option value="22">10 PM</option>
                          <option value="23">11 PM</option>
                        </Form.Control>

                        <p className="timepicker-dropdownDivider">:</p>

                        <Form.Control
                          as="select"
                          name="minute"
                          onChange={(e) => this.setReturnMinute(e.target.value)}
                          // onBlur={() => {
                          //   this.validate();
                          // }}
                          className="minute-type data-hj-whitelist"
                          value={this.state.returMinute}
                        >
                          <option value="0">00</option>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="45">45</option>
                        </Form.Control>
                      </div>
                    </div>
                  </div>
                  <div className="form-error-message">
                    <p>
                      <FieldInvalid value={errors.returnDate} />
                    </p>
                    <p>
                      <FieldInvalid value={errors.returnHour} />
                    </p>
                    <p>
                      <FieldInvalid value={errors.returMinute} />
                    </p>
                  </div>
                </>
              )}

              <div className="add-form-border form-field passenger-duration-container">
                {hourlyTripCode.includes(this.state.serviceType) && (
                  <>
                    <div className="duration-form-field">
                      <div className="new-form-label">
                        <Form.Label>Hours*</Form.Label>
                      </div>
                      <div className="input-duration-field">
                        <Form.Control
                          type="text"
                          className="data-hj-whitelist"
                          data-test="duration"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          name="duration"
                          autoComplete="off"
                          placeholder={"# of hours"}
                          value={this.state.duration}
                          onChange={(e) => this.setDuration(e.target.value)}
                          onBlur={(e) => {
                            this.onBlurValidate(e);
                          }}
                        ></Form.Control>
                      </div>
                    </div>
                  </>
                )}

                <div
                  className={`${"passengers-count-field"} ${
                    !hourlyTripCode.includes(this.state.serviceType)
                      ? "fullWidth"
                      : ""
                  }`}
                >
                  <div className="new-form-label">
                    <Form.Label> Passengers* </Form.Label>
                  </div>
                  <div className="passenger-input-field">
                    <Form.Control
                      type="text"
                      className="data-hj-whitelist"
                      data-test="pax"
                      name="pax"
                      autoComplete="off"
                      placeholder={"# of passengers"}
                      onBlur={(e) => {
                        this.onBlurValidate(e);
                      }}
                      onChange={(e) => this.setPartySize(e.target.value)}
                      value={this.state.pax}
                    ></Form.Control>
                  </div>
                </div>
              </div>

              <div className="form-error-message">
                <p>
                  <FieldInvalid value={errors.pax} />
                </p>
                {hourlyTripCode.includes(this.state.serviceType) && (
                  <p>
                    <FieldInvalid value={errors.duration} />
                  </p>
                )}
              </div>

              {isRequestAQuoteForm && (
                <>
                  <div className="form-item google-recaptcha">
                    <ReCAPTCHA
                      name="gcaptcha"
                      className="recaptcha_class"
                      sitekey="6LeIYNMfAAAAADZUqEBTV-j2ViNc5K31ZW6HpFWN"
                      onChange={this.handleSetRecaptcha}
                    />
                  </div>
                  <div className="form-error-message">
                    <p>
                      <FieldInvalid value={errors.gcaptcha} />
                    </p>
                  </div>
                </>
              )}

              <Button
                block
                className={!classFlag ? "btn-white" : "search-results-button"}
                type="submit"
                id="search-button"
                data-test="submit-search"
              >
                {formButtonText}
              </Button>
              {/* {bids ? (
                <Button
                  block
                  className="search-button button-inverse"
                  onClick={() => this.orderHandler()}
                >
                  Free instant quote
                </Button>
              ) : (
                <></>
              )} */}
            </form>
          </div>
        );
        if (this.state.loading) {
          form = <Spinner />;
        }
        return (
          <div id="index-page">
            <CallAndChatModal
              openChatWindow={this.props.openChatWindow}
              showModal={this.state.showContactModal}
              toggleShow={this.toggleModalShow}
            />
            {this.state.slideshowImages && (
              <div className="jumbotron-header">
                <div className="jumbotron-content">
                  {/* <div className="info-text">
                    <div className="vehicle-image">
                      <img
                        src="https://assets.s3.amazonaws.com/images/front-end/Vehicles.png"
                        alt=""
                      />
                    </div>
                    <h1>{this.props.formTitle}</h1>
                    <h2>{this.props.formSubtitle}</h2>
                  </div> */}
                  {form}
                </div>
                {this.props.muteSlideImages && <div className="colorLayer" />}
                <Slider
                  {...{
                    dots: false,
                    infinite: true,
                    speed: 5000,
                    autoplay: true,
                    fade: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    pauseOnHover: false,
                  }}
                >
                  {this.state.slideshowImages.map((img, i) => (
                    <Slide img={img} key={i} />
                  ))}
                </Slider>
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div style={{ marginTop: 124 }}>
            <TripInfoWizard />
          </div>
        );
      }
    }
  }
}

export default withWhiteLabelContext(SearchForm);