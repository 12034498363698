import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { withWhiteLabelContext } from "react-whitelabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc";

import { routePaths } from "../../routes/routes";
import { endpoints, keys } from "../../globals.js";

import useLocalStorage from "../../hooks/useLocalStorage";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";

import CarSpinner from "../CarSpinner/CarSpinner.jsx";
import CallAndChat from "../CallAndChat/CallAndChat.jsx";
import SearchResultWide from "../SearchResultWide/SearchResultWide.jsx";

import "../Contact/Contact.scss";
import "./Confirmation.scss";

class CalendarDropdown extends React.Component {
  render() {
    return (
      <ul className="calendarList">
        {this.props.children.map((x, i) => (
          <li key={i}>{x}</li>
        ))}
      </ul>
    );
  }
}

const STATUS = {
  booking: "booking",
  booked: "booked",
  support: "support",
  failed: "failed",
};

const TITLES = {
  booked: "Congratulations on booking your ride with Limos.com!",
  booking: "Please wait a moment while your booking is processing!",
  support: "Attention, additional steps required!",
  failed: "Uh oh, something went wrong!",
};

const bookedJumboMessage = {
  booked: "Please check your email for reservation details.",
  support:
    "Your ride has been processed, however please contact our customer support team to finalize your reservation.",
};

const DEFAULT_PASSENGER = {
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
};

class Confirmation extends React.Component {
  timerId = 0;

  constructor(props) {
    super(props);
    if (typeof document !== "undefined") {
      document.title = this.props.label.title + " - Confirmation";
    }
    console.log("confirmation props", this.props);

    this.state = {
      status: STATUS.booking,
      reservationHandle: "",
      reservationId: false,
      reservationUrl: "",
      flightLinked: false,
      flightLinkLoading: false,
      /* reservationId: -1, */
      pollDelay: 1000,
      pollDelayCounter: 1,
      // additionalInfo
      extraPassenger: DEFAULT_PASSENGER,
      extraPassengerSubmissionError: "",
      specialRequests: "",
      specialRequestsSubmissionError: "",
      additionalInfoSubmitting: false,
      additionalInfoSupport: false,
    };
  }

  pollBooking = () => {
    //this.linkFlight(data.reservation_handle);
    this.setState({
      status: STATUS.booked,
      reservationHandle: "",
      reservationId: "",
      reservationUrl: "",
    });
    //this.reportRevenue(data.reservation_id);

    // gta tag
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = "gtag('event', 'conversion', {'send_to' : 'AW-1072543120/TkRsCI6qv-oCEJDrtv8D'});";
    document.body.appendChild(script);
    // const url = this.props.label.bids
    //   ? `${endpoints.limos}/api/bid/${this.props.cart.pickupLeg.bidId}/booking_status.json`
    //   : `${endpoints.limos}/api/reservations/${this.props.cart.pickupLeg.result.result_handle}/booking_status.json`;
    // axios
    //   .get(url)
    //   .then(({ data }) => {
    //     if (data.status === STATUS.booked) {
    //       this.linkFlight(data.reservation_handle);
    //       this.setState({
    //         status: STATUS.booked,
    //         reservationHandle: data.reservation_handle,
    //         reservationId: data.reservation_id,
    //         reservationUrl: data.customer_reservation_url || "",
    //       });
    //       this.reportRevenue(data.reservation_id);

    //       // gta tag
    //       const script = document.createElement('script');
    //       script.type = 'text/javascript';
    //       script.async = true;
    //       script.innerHTML = "gtag('event', 'conversion', {'send_to' : 'AW-1072543120/TkRsCI6qv-oCEJDrtv8D'});";
    //       document.body.appendChild(script);
    //     } else if (data.status === STATUS.support) {
    //       this.linkFlight(data.reservation_handle);
    //       this.setState({
    //         status: STATUS.support,
    //         reservationHandle: data.reservation_handle,
    //         reservationId: data.reservation_id,
    //         reservationUrl: data.customer_reservation_url || "",
    //       });
    //       this.reportRevenue(data.reservation_id);
    //     } else if (data.status === STATUS.failed) {
    //       this.setState({ status: STATUS.failed });
    //     } else if (this.state.pollDelayCounter > 120) {
    //       this.setState({ status: STATUS.failed });
    //     } else {
    //       this.setState({ pollDelayCounter: this.state.pollDelayCounter + 1 });
    //       if (this.timerId) {
    //         clearTimeout(this.timerId);
    //       }
    //       this.timerId = setTimeout(this.pollBooking, this.state.pollDelay);
    //     }
    //   })
    //   .catch((err) => {
    //     this.props.setError(err);
    //   });
    // if (data.status === STATUS.booked) {
    //   this.linkFlight(data.reservation_handle);
    //   this.setState({
    //     status: STATUS.booked,
    //     reservationHandle: data.reservation_handle,
    //     reservationId: data.reservation_id,
    //     reservationUrl: data.customer_reservation_url || "",
    //   });
    //   this.reportRevenue(data.reservation_id);

    //   // gta tag
    //   const script = document.createElement('script');
    //   script.type = 'text/javascript';
    //   script.async = true;
    //   script.innerHTML = "gtag('event', 'conversion', {'send_to' : 'AW-1072543120/TkRsCI6qv-oCEJDrtv8D'});";
    //   document.body.appendChild(script);
    // } else if (data.status === STATUS.support) {
    //   this.linkFlight(data.reservation_handle);
    //   this.setState({
    //     status: STATUS.support,
    //     reservationHandle: data.reservation_handle,
    //     reservationId: data.reservation_id,
    //     reservationUrl: data.customer_reservation_url || "",
    //   });
    //   this.reportRevenue(data.reservation_id);
    // } else if (data.status === STATUS.failed) {
    //   this.setState({ status: STATUS.failed });
    // } else if (this.state.pollDelayCounter > 120) {
    //   this.setState({ status: STATUS.failed });
    // } else {
    //   this.setState({ pollDelayCounter: this.state.pollDelayCounter + 1 });
    //   if (this.timerId) {
    //     clearTimeout(this.timerId);
    //   }
    //   this.timerId = setTimeout(this.pollBooking, this.state.pollDelay);
    // }
  };

  linkFlight = async (reservationHandle) => {
    if (this.props.bookedRide.isAirport && this.props.pickupPlace.isAirport) {
      const { airlineCode, flightNumber } = this.props.bookedRide;

      let bodyFormData = new FormData();
      bodyFormData.set("api_key", keys.limos);
      bodyFormData.set("airline_handle", airlineCode);
      bodyFormData.set("flight_handle", flightNumber);
      this.setState({ flightLinkLoading: true });

      axios({
        url: `${endpoints.limos}/api/reservations/${reservationHandle}/update.json`,
        method: "put",
        /* data: bodyFormData, */
        headers: { "Content-Type": "application/json" },
        data: {
          api_key: keys.limos,
          airline_code: airlineCode,
          flight_number: flightNumber,
        },
      });
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.state.status === STATUS.booking) {
      this.pollBooking();
    }
  };

  componentWillUnmount = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = 0;
    }
  };

  reportRevenue = (reservationId) => {
    if (typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({
        event: "ride-booked",
        reservationid: reservationId,
        value: this.props.recordGAValue
          ? this.props.bookedRide.grandTotal / 100
          : 0,
      });
    }
  };

  handleSubmitAdditionalInfo = async () => {
    const {
      extraPassenger: { first_name, last_name, email, phone },
      reservationHandle,
      specialRequests,
    } = this.state;

    const formEmpty =
      first_name === DEFAULT_PASSENGER.first_name &&
      last_name === DEFAULT_PASSENGER.last_name &&
      email === DEFAULT_PASSENGER.email &&
      phone === DEFAULT_PASSENGER.phone &&
      !specialRequests?.length;

    if (formEmpty) {
      return;
    }

    this.setState({ additionalInfoSubmitting: true });
    let errored = false;

    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(email).toLowerCase()
      ) &&
      first_name?.length &&
      last_name?.length &&
      phone?.length
    ) {
      try {
        // fetch("/a/api/add-rows-to-spreadsheet", {
        //   method: "POST",
        //   body: JSON.stringify({
        //     docKey: "feedback",
        //     items: [
        //       {
        //         name: `${first_name} ${last_name}`,
        //         email,
        //         phone,
        //       },
        //     ],
        //     userId: this.props.userId,
        //   }),
        // });

        await axios({
          url: `${endpoints.limos}/api/reservations/${reservationHandle}/add_pax.json`,
          method: "post",
          headers: { "Content-Type": "application/json" },
          data: {
            api_key: keys.limos,
            reservation_handle: reservationHandle,
            first_name,
            last_name,
            email,
            phone,
          },
        });
      } catch (e) {
        errored = true;
        this.setState({
          additionalInfoSupport: true,
          extraPassengerSubmissionError:
            "There was a problem submitting the additional passengers, please call support to complete this process. Sorry for the inconvenience!",
        });
      }
    } else {
      errored = true;
      this.setState({
        additionalInfoSubmitting: false,
        extraPassengerSubmissionError:
          "Please ensure the first name, last name, email and phone are properly filled out for your passenger information.",
      });
    }

    if (!errored && specialRequests?.length) {
      try {
        await axios({
          url: `${endpoints.limos}/api/reservations/${reservationHandle}/add_special_requests.json`,
          method: "post",
          headers: { "Content-Type": "application/json" },
          data: {
            api_key: keys.limos,
            reservation_handle: reservationHandle,
            special_requests: specialRequests,
          },
        });
      } catch (e) {
        errored = true;
        this.setState({
          additionalInfoSupport: true,
          specialRequestsSubmissionError:
            "There was a problem submitting the special request information, please call support to complete this process. Sorry for the inconvenience!",
        });
      }
    }

    if (!errored) {
      this.setState({
        extraPassenger: DEFAULT_PASSENGER,
        specialRequests: "",
        additionalInfoSubmitting: false,
        additionalInfoSuccess: true,
      });
      window.scrollTo(0, 0);
    }
  };

  render() {
    const pickup = this.props.cart.pickupLeg;
    if (
      !this.props.bookedRide.grandTotal ||
      this.props.bookedRide.grandTotal === 0
    ) {
      return <Redirect to={routePaths.root} />;
    } else {
      const { completeDate, label, bookedRide } = this.props;
      const { reservationUrl } = this.state;
      // iOS is nice and doesn't let you choose what calendar to use so we have to hide outlook
      // on iOS since it also uses ical format
      const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const calendarComponentDateTimeFormat = "yyyyMMdd'T'HHmmss";
      const AddToCalendarDropdown = AddToCalendarHOC(Button, CalendarDropdown);
      const bookingUrlSnippet = reservationUrl
        ? `View your booking: https://www.limos.com${reservationUrl}`
        : "";
      const calendarEvent = {
        description: `Picking up for your ${label.domain} booking.

For support call: ${label.telephone}

${bookingUrlSnippet}`,
        duration: "0.5",
        endDatetime: completeDate
          .plus({ minutes: 30 })
          .toFormat(calendarComponentDateTimeFormat),
        startDatetime: completeDate.toFormat(calendarComponentDateTimeFormat),
        timezone: completeDate.toFormat("z"),
        title: `Picking up for ride ${this.state.reservationHandle} | ${label.domain}`,
      };
      return (
        <Container className="hero-form confirmation-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {this.state.status === STATUS.booking && (
              <div
                style={{
                  width: "100%",
                }}
              >
                <CarSpinner white />
              </div>
            )}
            <p style={{ textAlign: "center", marginTop: 24, color: "white" }}>
              {TITLES[this.state.status]}
            </p>
          </div>
          {this.state.status === STATUS.failed && (
            <CallAndChat
              openChatWindow={this.props.openChatWindow}
              titleMessage={"Booking failed, please contact support:"}
            />
          )}
          <Row>
            <Col
              xs={12}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
            >
              <div className={`bookingStatus ${this.state.status}`}>
                {this.state.status === STATUS.booking
                  ? "Processing Ride"
                  : this.state.status === STATUS.booked
                  ? "You're Booked!"
                  : this.state.status === STATUS.support
                  ? "Ride Processed - Contact Support"
                  : "Booking Failed"}
              </div>
              {(this.state.status === STATUS.booked ||
                this.state.status === STATUS.support) && (
                <>
                  <Jumbotron className="bookedJumbotron">
                    <h1>Great Choice!</h1>
                    {bookedJumboMessage[this.state.status]}
                    <div className={"pageTitle"}>
                      Reservation Number: {localStorage.getItem("reservationId")}
                    </div>
                    <br />
                    {!this.state.additionalInfoSuccess && (
                      <>
                        <h2>Before You Go!</h2>
                        <p>
                          Enjoy the smoothest ride possible by submitting
                          additional passengers information, as well as any
                          special requests you have for your booking!
                        </p>
                        <h3>Additional Passenger Information</h3>
                        {this.state.extraPassengerSubmissionError && (
                          <p>{this.state.extraPassengerSubmissionError}</p>
                        )}
                        <div className="passenger-input-form">
                          <input
                            type="text"
                            placeholder="First Name"
                            required
                            name="first_name"
                            onChange={(e) =>
                              this.setState({
                                extraPassenger: {
                                  ...this.state.extraPassenger,
                                  first_name: e.target.value,
                                },
                              })
                            }
                            value={this.state.extraPassenger.first_name}
                            className="rbt-input-main form-control rbt-input"
                          />
                          <input
                            type="text"
                            placeholder="Last Name"
                            required
                            name="last_name"
                            onChange={(e) =>
                              this.setState({
                                extraPassenger: {
                                  ...this.state.extraPassenger,
                                  last_name: e.target.value,
                                },
                              })
                            }
                            value={this.state.extraPassenger.last_name}
                            className="rbt-input-main form-control rbt-input"
                          />
                          <input
                            type="email"
                            placeholder="Email"
                            required
                            name="email"
                            onChange={(e) =>
                              this.setState({
                                extraPassenger: {
                                  ...this.state.extraPassenger,
                                  email: e.target.value,
                                },
                              })
                            }
                            value={this.state.extraPassenger.email}
                            className="rbt-input-main form-control rbt-input"
                          />
                          <input
                            type="tel"
                            placeholder="Phone"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            required
                            name="phone"
                            onChange={(e) =>
                              this.setState({
                                extraPassenger: {
                                  ...this.state.extraPassenger,
                                  phone: e.target.value,
                                },
                              })
                            }
                            value={this.state.extraPassenger.phone}
                            className="rbt-input-main form-control rbt-input"
                          />
                        </div>
                        <br />
                        <h3>Special Requests</h3>
                        {this.state.specialRequestsSubmissionError && (
                          <p>{this.state.specialRequestsSubmissionError}</p>
                        )}
                        <textarea
                          style={{ width: "100%", padding: 16 }}
                          onChange={(e) =>
                            this.setState({ specialRequests: e.target.value })
                          }
                          value={this.state.specialRequests}
                        />

                        <button
                          onClick={this.handleSubmitAdditionalInfo}
                          disabled={this.state.additionalInfoSubmitting}
                          className="btn-rounded btn btn-primary btn-block"
                        >
                          Submit Additional Booking Info
                        </button>
                        <br />
                      </>
                    )}
                    {this.state.additionalInfoSuccess && (
                      <p>We've recieved your passenger and special requests!</p>
                    )}
                    {this.state.status === STATUS.support ||
                    this.state.additionalInfoSupport ? (
                      <>
                        <br />
                        <br />
                        <CallAndChat
                          openChatWindow={this.props.openChatWindow}
                          fullWidth={true}
                        />
                      </>
                    ) : (
                      <AddToCalendarDropdown
                        items={
                          isiOS
                            ? [
                                SHARE_SITES.GOOGLE,
                                SHARE_SITES.ICAL,
                                SHARE_SITES.YAHOO,
                              ]
                            : undefined
                        }
                        buttonText={
                          <>
                            <FontAwesomeIcon icon={"calendar-day"} /> Add to
                            calendar
                          </>
                        }
                        className="addToCalendarContainer"
                        event={calendarEvent}
                      />
                    )}
                  </Jumbotron>
                  <img
                    src={`https://www.shareasale.com/sale.cfm?tracking=${
                      this.state.reservationId
                    }&amount=${
                      bookedRide.grandTotal / 100
                    }&merchantID=84131&transtype=sale&sscidmode=6&sscid=41k3`}
                    width="1"
                    height="1"
                    alt="Share a Sale"
                  ></img>
                  <script
                    defer
                    async
                    type="text/javascript"
                    src="https://shareasaleanalytics.com/j.js"
                  ></script>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
            >
              <SearchResultWide
                grandTotal={this.props.bookedRide.grandTotal}
                displayName={
                  pickup.result.vehicle_color +
                  " " +
                  pickup.result.vehicle_make +
                  " " +
                  pickup.result.vehicle_model
                }
                images={[
                  pickup.result.vehicle_url,
                  ...pickup.result.vehicle_images.urls.split(","),
                ]}
                starRating={pickup.result.average_review}
                operatorId={pickup.result.operator_handle}
                reviewsLink={pickup.result.reviews}
                displayPrice={pickup.result.grand_total}
                passengerCapacity={pickup.result.vehicle_capacity}
                luggageCapacity={pickup.result.luggage_capacity}
                resultHandle={pickup.result.result_handle}
                showDate={true}
                amenities={pickup.result.vehicle_features}
                displayContextIsCheckout={true}
                pickupPlace={this.props.pickupPlace}
                dropoffPlace={this.props.dropoffPlace}
                isHourly={this.props.isHourly}
                completeDate={this.props.completeDate}
                searchResult={this.props.cart.pickupLeg}
                tripWizardValues={this.props.tripWizardValues}
                showConfirmationNumber={true}
                confirmationNumber={this.state.reservationHandle}
                resultHandle1 = {this.props.cart.resultHandle1}
                resultHandle2 = {this.props.cart.resultHandle2}
                resultHandler1Data = {this.props.cart.resultHandler1Data}
                resultHandler2Data = {this.props.cart.resultHandler2Data}
                roundTripPrice = {(this.props.cart.resultHandle1Data && this.props.cart.resultHandle2Data) ? (parseFloat(this.props.cart.resultHandle1Data.grand_total) + parseFloat(this.props.cart.resultHandle2Data.grand_total)): null}
                showPriceLabel = {true}
              />
            </Col>
          </Row>
          <CallAndChat
            titleMessage={"Need help with your ride?"}
            openChatWindow={this.props.openChatWindow}
          />
        </Container>
      );
    }
  }
}

const ConfirmationWrapper = (props) => {
  const [userId] = useLocalStorage("LIMOS-userId");
  return <Confirmation {...props} userId={userId} />;
};

export default withWhiteLabelContext(ConfirmationWrapper);
